import { useState, useCallback, useEffect } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";

import { getAuthUser } from "services/authentication";

const usersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
    {
      field: "root",
      condition: "==",
      value: false,
    },
    {
      field: "system",
      condition: "==",
      value: false,
    },
  ],
};

function Accounts({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
  selectedAccount,
  setSelectedAccount,
  authUserDisabled,
  setAuthUserDisabled,
}) {
  const { values, setFieldValue } = mainForm;
  const {
    displayName,
    designation,
    email,
    medicalID,
    roles,
    preferredLogbookColumns,
    preferredFlashcardColumns,
  } = mainFormField;

  const [accountOptions, setAccountOptions] = useState([]);

  const { collectionData: usersData } = useCollectionSnapshot(
    "users",
    usersQueries
  );

  const resetAccount = useCallback(() => {
    setSelectedAccount(null);
    setFieldValue(displayName.name, "");
    setFieldValue(designation.name, "");
    setFieldValue(email.name, "");
    setFieldValue(medicalID.name, "");
    setFieldValue(roles.name, []);
    setFieldValue(preferredLogbookColumns.name, []);
    setFieldValue(preferredFlashcardColumns.name, []);
  }, [
    designation.name,
    displayName.name,
    email.name,
    medicalID.name,
    preferredFlashcardColumns.name,
    preferredLogbookColumns.name,
    roles.name,
    setFieldValue,
    setSelectedAccount,
  ]);

  const getUser = useCallback(
    async (uid) => {
      const result = await getAuthUser({ uid: uid });
      const userRecord = result.data;
      setAuthUserDisabled(userRecord.disabled);
    },
    [setAuthUserDisabled]
  );

  useEffect(() => {
    try {
      const users = usersData;
      setAccountOptions(users);
      if (!users.includes(selectedAccount)) {
        resetAccount();
        setAuthUserDisabled(null);
      } else if (selectedAccount) {
        getUser(selectedAccount.id);
      }
    } catch (err) {
      dispatchMainError(err);
    }
  }, [
    dispatchMainError,
    getUser,
    resetAccount,
    selectedAccount,
    setAuthUserDisabled,
    usersData,
  ]);

  useEffect(() => {
    try {
      if (!values.displayName) {
        setSelectedAccount(null);
      }
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, setSelectedAccount, values.displayName]);

  return (
    <Card id="accounts">
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Accounts</MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                Accounts
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                size="small"
                value={selectedAccount}
                getOptionLabel={(option) => option.data.displayName}
                onChange={(e, account) => {
                  setSelectedAccount(account);
                  // update account info value
                  if (account) {
                    setFieldValue(displayName.name, account.data.displayName);
                    setFieldValue(designation.name, account.data.designation);
                    setFieldValue(email.name, account.data.email);
                    setFieldValue(medicalID.name, account.data.medicalID);
                    setFieldValue(roles.name, account.data.roles);
                    setFieldValue(
                      preferredLogbookColumns.name,
                      account.data.preferredLogbookColumns
                    );
                    setFieldValue(
                      preferredFlashcardColumns.name,
                      account.data.preferredFlashcardColumns
                    );
                  }
                }}
                options={accountOptions}
                disabled={modeDisabled}
                renderInput={(params) => (
                  <TextField {...params} label="Select Account" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDBox>
                {authUserDisabled !== null && authUserDisabled === true && (
                  <MDButton variant="outlined" color="error" size="small">
                    Inactive
                  </MDButton>
                )}
                {authUserDisabled !== null && authUserDisabled === false && (
                  <MDButton variant="outlined" color="success" size="small">
                    Active
                  </MDButton>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Accounts;
