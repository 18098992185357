import * as Yup from "yup";
import form from "pages/settings/cusum/manage/schemas/form";

const {
  formField: {
    acceptableFailureRate,
    unacceptableFailureRate,
    type1Error,
    type2Error,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [acceptableFailureRate.name]: Yup.string()
    .required(acceptableFailureRate.errorMsg)
    .max(200, acceptableFailureRate.invalidMsg),
  [unacceptableFailureRate.name]: Yup.string()
    .required(unacceptableFailureRate.errorMsg)
    .max(200, unacceptableFailureRate.invalidMsg),
  [type1Error.name]: Yup.string()
    .required(type1Error.errorMsg)
    .max(200, type1Error.invalidMsg),
  [type2Error.name]: Yup.string()
    .required(type2Error.errorMsg)
    .max(200, type2Error.invalidMsg),
});

export { validations as default, noValidation };
