import { useParams } from "react-router-dom";
import IndividualRecord from "pages/cohortstatistics/viewindividualrecord/IndividualRecord";

// using Proxy Page to remount when route to same route location but different param
function IndividualRecordProxy() {
  const { id } = useParams();
  return <IndividualRecord key={`${id}`} />;
}

export default IndividualRecordProxy;
