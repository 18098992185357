import * as Yup from "yup";
import form, {
  permissionsForm,
} from "pages/settings/roles/manage/schemas/form";

const {
  formField: { roleName, permissions },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [roleName.name]: Yup.string()
    .required(roleName.errorMsg)
    .max(200, roleName.invalidMsg),
  [permissions.name]: Yup.array()
    // .min(1, serviceTeams.min1Msg)
    .max(200, permissions.max200Msg),
});

const {
  formField: { permissionName },
} = permissionsForm;

const permissionsValidation = Yup.object().shape({
  [permissionName.name]: Yup.string()
    .required(permissionName.errorMsg)
    .max(200, permissionName.invalidMsg)
    .nullable(true),
});

export { validations as default, noValidation, permissionsValidation };
