import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav2 from "components/organisms/Topnav2";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

// CseSurvey page components
import Questionnaire from "pages/csesurveys/manage/components/Questionnaire";
//import CusumChart from "pages/csesurveys/manage/components/CusumChart";
import Sidenav from "pages/csesurveys/manage/components/Sidenav";

import { Formik, Form } from "formik";
import form from "pages/csesurveys/manage/schemas/form";

import { AllowedTo } from "react-abac";

import { useCseSurveyManager } from "pages/csesurveys/hooks/useCseSurveyManager";

function CseSurvey() {
  const [isProgressing, setIsProgressing] = useState(false);
  const { mode, recordId } = useParams();

  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitNew,
    submitEdit,
    response,
    dispatchError,
  } = useCseSurveyManager(mode, recordId);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const { formId, formField } = form;

  const handleSubmit = async (values, actions) => {
    try {
      setIsProgressing(true);
      switch (mode) {
        case "new":
          await submitNew(values);
          // Checking on response.error?.name statement
          await navigateAfter("/csesurveys/viewchart/view", 3000);
          break;
        case "edit":
          await submitEdit(values);
          await navigateAfter("/csesurveys/records/allrecords", 3000);
          break;
        default:
          actions.setSubmitting(false);
          break;
      }
    } catch (err) {
      dispatchError(err);
      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
      setIsProgressing(false);
    }
  };

  return (
    <DashboardLayout>
      <Topnav2 title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={3}>
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        <MDBox>
          {response.error?.name !== "OperationInvalidError" && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Sidenav />
              </Grid>

              <Grid item xs={12} lg={10}>
                <MDBox mb={3}>
                  <Formik
                    enableReinitialize
                    initialValues={response.data}
                    validationSchema={modeValidation}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form id={formId} autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Questionnaire
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              mode={mode}
                              respondedCseSurveyNumber={
                                response.data.csesurveyNumber
                              }
                              dispatchMainError={dispatchError}
                            />
                          </Grid>

                          {/*<Grid item xs={12}>
                            <CusumChart
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              mode={mode}
                              respondedCseSurveyNumber={
                                response.data.csesurveyNumber
                              }
                              dispatchMainError={dispatchError}
                            />
                            </Grid>*/}

                          <Grid item xs={12}>
                            <MDBox
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <MDButton
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                {modeSubmit}
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CseSurvey;
