import { storage } from "configs/firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const getURL = (fullPath) => {
  const storageRef = ref(storage, fullPath);
  return getDownloadURL(storageRef);
};

const addFile = async (fullPath, file) => {
  const storageRef = ref(storage, fullPath);
  const uploadResult = await uploadBytes(storageRef, file);
  return uploadResult.ref;
};

const purgeFile = async (fullPath) => {
  const storageRef = ref(storage, fullPath);
  return await deleteObject(storageRef);
};

export { addFile, purgeFile, getURL };
