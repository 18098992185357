import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { ErrorMessage, Field } from "formik";
import CheckboxWrapper from "components/molecules/Formik/Wrapper/CheckboxWrapper";

function FormCheckbox({ form, field, value, onChange, disabled, ...rest }) {
  const { values, setFieldValue } = form;

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    values[field.name] ? setIsChecked(true) : setIsChecked(false);
  }, [field.name, values]);

  //checkbox value does not change, it is different from other input value
  //only checked checkbox's value will be submitted in normal usage
  const permanentValue = value;
  const handleChange = (e) => {
    setFieldValue(field.name, e.target.checked);
    setIsChecked(e.target.checked);
    onChange && onChange(e);
  };

  return (
    <MDBox mb={2}>
      <Field
        name={field.name}
        label={field.label}
        value={permanentValue}
        state={isChecked}
        onChange={handleChange}
        as={CheckboxWrapper}
        disabled={disabled}
        {...rest}
      />
      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          <ErrorMessage name={field.name}></ErrorMessage>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormCheckbox
FormCheckbox.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default FormCheckbox;
