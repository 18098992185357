import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav2 from "components/organisms/Topnav2";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

import DataTable from "components/molecules/Tables/DataTable";
import MiniStatisticsCard from "components/molecules/Cards/StatisticsCards/MiniStatisticsCard";

import { AllowedTo } from "react-abac";
import { useCseSurveyManager } from "pages/cohortstatistics/hooks/useCseSurveyManager";
import { useDocument } from "hooks/useDocument";
import DefaultLineChart from "components/charts/LineCharts/DefaultLineChart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
function CseSurvey() {
  const [isProgressing, setIsProgressing] = useState(true);
  //const { mode, recordId } = useParams();
  const { retrieveDoc } = useDocument();
  const [graphLabels, setGraphLabels] = useState([]);
  const [graphDatasets, setGraphDatasets] = useState([]);
  const [totalAttempts, setTotalAttempts] = useState(0);
  const [minCusumScore, setMinCusumScore] = useState(0);
  const [maxCusumScore, setMaxCusumScore] = useState(0);

  const navigate = useNavigate();

  const { mode, id, displayname } = useParams();
  console.log("mode: " + mode);
  console.log("id: " + id);
  console.log("displayname: " + displayname);
  const { modeTitle, modePermission, response } = useCseSurveyManager(mode);

  const columns = [
    { Header: "No", Footer: "No", accessor: "rowIdx", isVisible: false },
    {
      Header: "Submission Date",
      Footer: "Submission Date",
      accessor: "date",
      isVisible: false,
    },
    {
      Header: "Patient ID",
      Footer: "Patient ID",
      accessor: "patientID",
      isVisible: false,
    },
    {
      Header: "Venue",
      Footer: "Venue",
      accessor: "venue",
      isVisible: false,
    },
    {
      Header: "Q1",
      Footer: "Q1",
      accessor: "question1",
      isVisible: false,
    },
    {
      Header: "Q2",
      Footer: "Q2",
      accessor: "question2",
      isVisible: false,
    },
    {
      Header: "Q3",
      Footer: "Q3",
      accessor: "question3",
      isVisible: false,
    },
    {
      Header: "Q4",
      Footer: "Q4",
      accessor: "question4",
      isVisible: false,
    },
    {
      Header: "Remark",
      Footer: "Remark",
      accessor: "remark",
      isVisible: false,
    },
  ];
  const [rows, setRows] = useState([]);

  const initializeFieldValue = useCallback(async () => {
    //const retrievedRecords = await retrieveDoc(id, "records");
    const retrievedRecords = await retrieveDoc("records", id);
    const cusum_labels = [];
    const cusum_values = retrievedRecords.data.cseCusumValues;
    for (let idx = 0; idx < cusum_values.length; idx++) {
      cusum_labels.push(idx);
    }
    setGraphLabels(cusum_labels);

    const retrievedCusumSettings = await retrieveDoc("methods", "cusum");
    const h1 = retrievedCusumSettings.data.upperDecisionLimit;
    const h0 = retrievedCusumSettings.data.lowerDecisionLimit;

    let num_h0, num_h1;
    let cusum_max = retrievedRecords.data.cseCusumStats.max;
    let cusum_min = retrievedRecords.data.cseCusumStats.min;
    if (cusum_max < h1) num_h1 = 1;
    else num_h1 = Math.ceil(cusum_max / h1);
    if (cusum_min > h0) num_h0 = 1;
    else num_h0 = Math.ceil(cusum_min / h0);
    const labels_len = cusum_labels.length;

    // Statistics
    setTotalAttempts(labels_len - 1);
    setMinCusumScore(parseFloat(cusum_min).toFixed(2));
    setMaxCusumScore(parseFloat(cusum_max).toFixed(2));

    // Cusum line
    let datasets = [
      {
        label: "Cusum",
        color: "info",
        data: cusum_values,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ];

    // h1 lines
    for (let ii = 0; ii < num_h1; ii++) {
      datasets.push({
        label: (ii + 1).toString() + "h1",
        color: "warning",
        data: Array(labels_len).fill((ii + 1) * h1),
        borderDash: [10, 5],
        pointRadius: 0,
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBorderWidth: 0,
      });
    }

    // h0 lines
    for (let ii = 0; ii < num_h0; ii++) {
      datasets.push({
        label: (ii + 1).toString() + "h0",
        color: "success",
        data: Array(labels_len).fill((ii + 1) * h0),
        borderDash: [10, 5],
        pointRadius: 0,
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBorderWidth: 0,
      });
    }
    setGraphDatasets(datasets);

    // Table records
    if (retrievedRecords.data !== undefined) {
      let cseRecords = retrievedRecords.data.cseRecords;

      const m_rows = [];
      let rowIdx = 1;
      for (let record of cseRecords) {
        const remark = record?.remark;

        m_rows.push({
          rowIdx: rowIdx,
          date: record["createdAt"],
          patientID: record["patientID"],
          venue: record["venue"],
          question1: record["question1"],
          question2: record["question2"],
          question3: record["question3"],
          question4: record["question4"],
          remark: remark,
        });
        rowIdx = rowIdx + 1;
      }
      setRows(m_rows);
    }
    setIsProgressing(false);
  }, [id, retrieveDoc]);

  useEffect(() => {
    try {
      initializeFieldValue();
    } catch (err) {}
  }, [initializeFieldValue]);

  const defaultLineChartData1 = {
    labels: graphLabels,
    datasets: graphDatasets,
  };

  return (
    <DashboardLayout>
      <Topnav2 title={modeTitle + " - " + displayname} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      {isProgressing && <SubmissionProgress />}
      {response.error && (
        <>
          {response.error?.name === "OperationInvalidError" && (
            <InvalidOperation />
          )}
          <ErrorSnackbar
            title={modeTitle}
            dateTime=""
            message={response.error?.message}
            autoDismiss
          />
        </>
      )}
      {response.success && (
        <SuccessSnackbar
          title={modeTitle}
          dateTime=""
          message={response.success}
          autoDismiss
        />
      )}
      <MDBox pt={3} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "No Attempts" }}
                count={totalAttempts}
                icon={{ color: "info", component: "numbers" }}
                direction="left"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "Best Cusum Score" }}
                count={minCusumScore}
                icon={{ color: "info", component: "thumb_up" }}
                direction="left"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "Worst Cusum Score" }}
                count={maxCusumScore}
                icon={{ color: "info", component: "thumb_down" }}
                direction="left"
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={3} pb={3}>
        <Card id="individual-cusum-chart" sx={{ overflow: "visible" }}>
          <MDBox m={3}>
            <MDBox>
              <Grid item xs={12} md={12}>
                <DefaultLineChart
                  icon={{ component: "insights" }}
                  title="Cusum chart"
                  description=""
                  chart={defaultLineChartData1}
                />
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <MDBox pt={3} pb={3}>
        <Card id="individual-cse-survey-record" sx={{ overflow: "visible" }}>
          <MDBox m={3}>
            <MDBox>
              <DataTable
                table={{
                  columns: columns,
                  rows: rows,
                }}
                canGlobalSearch
                canColumnSearch
                canColumnSort
              />
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>

      <MDBox pt={0} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={4} md={4} lg={4}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                navigate("/cohortstatistics/all/");
              }}
            >
              <ArrowBackIcon />
              &nbsp;&nbsp;
              {"Back"}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default CseSurvey;
