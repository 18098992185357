const insertionOrderOptions = ["Random", "Order"];

const leechActionOptions = ["Tag", "Suspend"];

const newReviewOrderOptions = [
  "Show after reviews", // review must studied before showing new
  "Show before reviews", // new show first before review
  "Mixed with reviews", // random order
];

const interdayLearningReviewOrderOptions = [
  "Show after reviews", // review studied before learning
  "Show before reviews", // learning show first before review
  "Mixed with reviews", // random order
];

const reviewSortOrderOptions = [
  "Due date, then random", // longest due date show first, then other random
  "Ascending intervals", // shorter interval first
  "Descending intervals", // larger interval first
];

export {
  insertionOrderOptions,
  leechActionOptions,
  newReviewOrderOptions,
  interdayLearningReviewOrderOptions,
  reviewSortOrderOptions,
};
