import form, {
  flashcardFrontImgAttachmentsForm,
  flashcardBackImgAttachmentsForm,
} from "pages/logbook/manage/schemas/form";

const {
  formField: {
    caseId,
    date,
    durationHour,
    duration,
    residencyYear,
    role,
    site,
    supervisor,
    age,
    ageUnit,
    asa,
    emergency,
    operationLevel1,
    operationLevel2,
    priority,
    anaesthesiaMode,
    airwayManagement,
    additionalProcedures,
    disposition,
    notes,
    createFlashcard,
    flashcardFront,
    flashcardFrontImgAttachments,
    flashcardBack,
    flashcardBackImgAttachments,
  },
} = form;

const initialValues = {
  [caseId.name]: "",
  [date.name]: new Date(),
  [durationHour.name]: "",
  [duration.name]: "",
  [residencyYear.name]: "",
  [role.name]: "",
  [site.name]: "",
  [supervisor.name]: "",
  [age.name]: "",
  [ageUnit.name]: "YEAR",
  [asa.name]: "",
  [emergency.name]: false,
  [operationLevel1.name]: "",
  [operationLevel2.name]: "",
  [priority.name]: "",
  [anaesthesiaMode.name]: [],
  [airwayManagement.name]: [],
  [additionalProcedures.name]: [],
  [disposition.name]: [],
  [notes.name]: "",
  [createFlashcard.name]: false,
  [flashcardFront.name]: "",
  [flashcardFrontImgAttachments.name]: [],
  [flashcardBack.name]: "",
  [flashcardBackImgAttachments.name]: [],
};

const {
  formField: { flashcardFrontImgAttachmentFile },
} = flashcardFrontImgAttachmentsForm;

const flashcardFrontImgAttachmentsInitialValues = {
  [flashcardFrontImgAttachmentFile.name]: "",
};

const {
  formField: { flashcardBackImgAttachmentFile },
} = flashcardBackImgAttachmentsForm;

const flashcardBackImgAttachmentsInitialValues = {
  [flashcardBackImgAttachmentFile.name]: "",
};

export {
  initialValues as default,
  flashcardFrontImgAttachmentsInitialValues,
  flashcardBackImgAttachmentsInitialValues,
};
