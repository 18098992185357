import PropTypes from "prop-types";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { ErrorMessage, Field } from "formik";
import DatePickerWrapper from "components/molecules/Formik/Wrapper/DatePickerWrapper";

import format from "date-fns/format";
import parse from "date-fns/parse";
import isValid from "date-fns/isValid";

function FormDatePicker({
  form,
  field,
  inputFormat,
  onChange,
  disabled,
  ...rest
}) {
  const { values, errors, touched, setFieldValue } = form;
  const value = values[field.name]
    ? parse(values[field.name], inputFormat, new Date())
    : null;
  const error = disabled ? false : errors[field.name] && touched[field.name];
  const success = disabled
    ? false
    : !!values[field.name] &&
      values[field.name].length > 0 &&
      !errors[field.name];
  const handleChange =
    onChange ??
    ((value) => {
      if (isValid(value)) {
        setFieldValue(field.name, format(value, inputFormat));
      } else {
        setFieldValue(field.name, "");
      }
    });

  return (
    <MDBox mb={2}>
      <Field
        name={field.name}
        type={field.type}
        label={field.label}
        value={value}
        inputFormat={inputFormat}
        onChange={handleChange}
        as={DatePickerWrapper}
        disabled={disabled}
        error={error}
        success={success}
        {...rest}
      />
      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          <ErrorMessage name={field.name}></ErrorMessage>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormDatePicker
FormDatePicker.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default FormDatePicker;
