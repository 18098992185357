import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";
import FormCheckbox from "components/molecules/Formik/FormCheckbox";
//import FormCheckboxGroup from "components/molecules/Formik/FormCheckboxGroup";

//import { roleOptions } from "pages/settings/user/new/schemas/setups";

import InfoIcon from "@mui/icons-material/Info";

import {
  leechActionOptions,
  newReviewOrderOptions,
  interdayLearningReviewOrderOptions,
  reviewSortOrderOptions,
} from "pages/settings/flashcard/manage/schemas/setups";

import initialValues from "pages/settings/flashcard/manage/schemas/initialValues";

function FlashcardSettings({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  // const { values: mainFormValues, setFieldValue: setMainFormFieldValue } =
  //   mainForm;

  const {
    autoShare,
    newCardsPerDay,
    maximumReviewPerDay,
    learningSteps,
    graduatingInterval,
    easyInterval,
    relearningSteps,
    minimumInterval,
    leechThreshold,
    leechAction,
    newReviewOrder,
    interdayLearningReviewOrder,
    reviewSortOrder,
    maximumInterval,
    startingEase,
    easyBonus,
    hardInterval,
    newInterval,
  } = mainFormField;

  return (
    <Card id="flashcard-variables" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox>
          <MDBox mb={2}>
            <MDTypography variant="h5">Sharing</MDTypography>
          </MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {autoShare.label}
                <Tooltip title={autoShare.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormCheckbox
                form={mainForm}
                field={autoShare}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
          </Grid>
          <MDBox mb={2}>
            <MDTypography variant="h5">Daily Limits</MDTypography>
          </MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {newCardsPerDay.label}
                <Tooltip title={newCardsPerDay.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={newCardsPerDay}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[newCardsPerDay.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {maximumReviewPerDay.label}
                <Tooltip
                  title={maximumReviewPerDay.tooltip}
                  placement="top-start"
                >
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={maximumReviewPerDay}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[maximumReviewPerDay.name]}
              </MDTypography>
            </Grid>
          </Grid>
          <MDBox my={2}>
            <MDTypography variant="h5">New Cards</MDTypography>
          </MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {learningSteps.label}
                <Tooltip title={learningSteps.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={learningSteps}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[learningSteps.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {graduatingInterval.label}
                <Tooltip
                  title={graduatingInterval.tooltip}
                  placement="top-start"
                >
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={graduatingInterval}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[graduatingInterval.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {easyInterval.label}
                <Tooltip title={easyInterval.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={easyInterval}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[easyInterval.name]}
              </MDTypography>
            </Grid>
          </Grid>
          <MDBox my={2}>
            <MDTypography variant="h5">Lapses</MDTypography>
          </MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {relearningSteps.label}
                <Tooltip title={relearningSteps.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={relearningSteps}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[relearningSteps.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {minimumInterval.label}
                <Tooltip title={minimumInterval.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={minimumInterval}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[minimumInterval.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {leechThreshold.label}
                <Tooltip title={leechThreshold.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={leechThreshold}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[leechThreshold.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {leechAction.label}
                <Tooltip title={leechAction.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormAutocompleteFast
                form={mainForm}
                field={leechAction}
                size="small"
                options={leechActionOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[leechAction.name]}
              </MDTypography>
            </Grid>
          </Grid>
          <MDBox my={2}>
            <MDTypography variant="h5">Display Order</MDTypography>
          </MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {newReviewOrder.label}
                <Tooltip title={newReviewOrder.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormAutocompleteFast
                form={mainForm}
                field={newReviewOrder}
                size="small"
                options={newReviewOrderOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[newReviewOrder.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {interdayLearningReviewOrder.label}
                <Tooltip
                  title={interdayLearningReviewOrder.tooltip}
                  placement="top-start"
                >
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormAutocompleteFast
                form={mainForm}
                field={interdayLearningReviewOrder}
                size="small"
                options={interdayLearningReviewOrderOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[interdayLearningReviewOrder.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {reviewSortOrder.label}
                <Tooltip title={reviewSortOrder.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormAutocompleteFast
                form={mainForm}
                field={reviewSortOrder}
                size="small"
                options={reviewSortOrderOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[reviewSortOrder.name]}
              </MDTypography>
            </Grid>
          </Grid>
          <MDBox my={2}>
            <MDTypography variant="h5">Advanced</MDTypography>
          </MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {maximumInterval.label}
                <Tooltip title={maximumInterval.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={maximumInterval}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[maximumInterval.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {startingEase.label}
                <Tooltip title={startingEase.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={startingEase}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[startingEase.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {easyBonus.label}
                <Tooltip title={easyBonus.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={easyBonus}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[easyBonus.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {hardInterval.label}
                <Tooltip title={hardInterval.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={hardInterval}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[hardInterval.name]}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {newInterval.label}
                <Tooltip title={newInterval.tooltip} placement="top-start">
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={newInterval}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="body3">
                Default: {initialValues[newInterval.name]}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default FlashcardSettings;
