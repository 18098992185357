import * as Yup from "yup";
import form, { rolesForm } from "pages/settings/user/new/schemas/form";

const {
  formField: { displayName, designation, email, password, medicalID, roles },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [displayName.name]: Yup.string()
    .required(displayName.errorMsg)
    .max(200, displayName.invalidMsg),
  [designation.name]: Yup.string()
    .required(designation.errorMsg)
    .max(200, designation.invalidMsg),
  [email.name]: Yup.string()
    .required(email.errorMsg)
    .max(200, email.invalidMsg)
    .email(email.invalidMsg),
  [password.name]: Yup.string()
    .required(password.errorMsg)
    .matches(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()-\\[{}\\]:;',?/*~$^+=<>]).{8,100}$",
      password.invalidMsg
    ),
  [medicalID.name]: Yup.string()
    .required(medicalID.errorMsg)
    .max(200, medicalID.invalidMsg),
  [roles.name]: Yup.array().min(1, roles.min1Msg).max(20, roles.max20Msg),
});

const {
  formField: { roleName },
} = rolesForm;

const rolesValidation = Yup.object().shape({
  [roleName.name]: Yup.string()
    .required(roleName.errorMsg)
    .max(200, roleName.invalidMsg)
    .nullable(true),
});

export { validations as default, noValidation, rolesValidation };
