import PropTypes from "prop-types";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import MDTypography from "components/atoms/MDTypography";

function CheckboxWrapper({
  name,
  value,
  state,
  onChange,
  label,
  disabled,
  ...rest
}) {
  return (
    <MDTypography variant="body3" fontWeight="regular" color="text">
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            value={value}
            checked={state}
            onChange={onChange}
            name={name}
          />
        }
        label={label}
        {...rest}
      />
    </MDTypography>
  );
}

// typechecking props for CheckboxWrapper
CheckboxWrapper.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

export default CheckboxWrapper;
