import { useParams } from "react-router-dom";
import Flashcard from "pages/flashcard/manage/Flashcard";
import FlashcardRevision from "pages/flashcard/revise/FlashcardRevision";
import FlashcardDiscovery from "pages/flashcard/discover/FlashcardDiscovery";

// using Proxy Page to remount when route to same route location but different param
function FlashcardProxy() {
  const { mode, flashcardId } = useParams();

  if (mode === "revise") {
    return <FlashcardRevision key={`${mode}/${flashcardId}`} />;
  } else if (mode === "discover") {
    return <FlashcardDiscovery key={`${mode}/${flashcardId}`} />;
  } else {
    return <Flashcard key={`${mode}/${flashcardId}`} />;
  }
}

export default FlashcardProxy;
