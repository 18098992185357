import PropTypes from "prop-types";

import { useState, useEffect } from "react";

import MDSnackbar from "components/atoms/MDSnackbar";

function SuccessSnackbar({ title, dateTime, message, autoDismiss }) {
  const [show, setShow] = useState(true);
  const [isUnmounted, setIsUnmounted] = useState(false);

  useEffect(() => {
    let timer;
    if (!isUnmounted) {
      autoDismiss && (timer = setTimeout(setShow, 3000, false));
    }
    return () => {
      setIsUnmounted(true);
      timer && clearTimeout(timer);
    };
  }, [autoDismiss, isUnmounted]);

  return (
    <MDSnackbar
      color="success"
      icon="notifications"
      title={title}
      dateTime={dateTime}
      content={message}
      open={show}
      close={() => setShow(false)}
    />
  );
}

SuccessSnackbar.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  autoDismiss: PropTypes.bool,
};

export default SuccessSnackbar;
