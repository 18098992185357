import form from "pages/flashcard/discover/schemas/form";

const {
  formField: {
    // anaesthesiaMode,
    // airwayManagement,
    // additionalProcedures,
    // disposition,
    // tags,
    operationLevel1,
    operationLevel2,
  },
} = form;

const initialValues = {
  // [anaesthesiaMode.name]: [],
  // [airwayManagement.name]: [],
  // [additionalProcedures.name]: [],
  // [disposition.name]: [],
  // [tags.name]: [],
  [operationLevel1.name]: "",
  [operationLevel2.name]: "",
};

export { initialValues as default };
