import * as Yup from "yup";
import parse from "date-fns/parse";
import form from "pages/csesurveys/manage/schemas/form";

const {
  formField: { submissionDate, patientID },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [submissionDate.name]: Yup.date()
    .transform((value, originalValue) => {
      return parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .required(submissionDate.errorMsg)
    .typeError(submissionDate.invalidMsg),
  [patientID.name]: Yup.string()
    .required(patientID.errorMsg)
    .max(200, patientID.invalidMsg)
    .matches("^[0-9]{3}[a-zA-Z]$", patientID.invalidMsg),
});

export { validations as default, noValidation };
