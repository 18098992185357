import { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";

import { AllowedTo } from "react-abac";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import FormBasicAutocomplete from "components/molecules/Formik/FormBasicAutocomplete";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LaunchIcon from "@mui/icons-material/Launch";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import StarsIcon from "@mui/icons-material/Stars";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav2 from "components/organisms/Topnav2";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import SubmissionProgress from "components/organisms/SubmissionProgress";
import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import ContainerDimensions from "react-container-dimensions";

import { useFlashcardDiscoveryManager } from "pages/flashcard/hooks/useFlashcardDiscoveryManager";

import { useDocument } from "hooks/useDocument";

import { Formik, Form } from "formik";
import form from "pages/flashcard/discover/schemas/form";
import initialValues from "pages/flashcard/discover/schemas/initialValues";
import validations from "pages/flashcard/discover/schemas/validations";

import { operationConfigs } from "schema/setups";

import { useDocumentSnapshot } from "hooks/useDocumentSnapshot";
import { Permission } from "models/abac";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

function FlashcardDiscovery() {
  const { mode } = useParams();

  const {
    submitRating,
    submitEndorse,
    submitDisendorse,
    submitAdd,
    submitFilterDiscovery,
    response,
    modeTitle,
    // modeValidation,
    // modeSubmit,
    modePermission,
    dispatchDismiss,
    dispatchError,
  } = useFlashcardDiscoveryManager(mode);

  const { user } = useAuthContext();

  const { retrieveDoc, retrieveDocs } = useDocument();

  const [filterValues, setFilterValues] = useState(initialValues);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isMyFlashcard, setIsMyFlashcard] = useState(false);
  const [isPublicFlashcard, setIsPublicFlashcard] = useState(false);
  const [flashcardIndex, setFlashcardIndex] = useState(0);

  const { documentData: configData } = useDocumentSnapshot(
    "flashcardconfigs",
    user.uid
  );

  const { documentData: userData } = useDocumentSnapshot("users", user.uid);

  const nextFlashcard = () => {
    const nextFlashcardIdx = flashcardIndex + 1;

    if (nextFlashcardIdx < response.data.length) {
      setFlashcardIndex(nextFlashcardIdx);
      setIsFlipped(false);
    }
  };

  const backFlashcard = () => {
    const backFlashcardIdx = flashcardIndex - 1;
    if (backFlashcardIdx >= 0) {
      setFlashcardIndex(backFlashcardIdx);
      setIsFlipped(false);
    }
  };

  const { formId, formField } = form;

  const { operationLevel1, operationLevel2 } = formField;

  const defaultOption = useMemo(() => ["All"], []);

  const handleSubmit = async (values, actions) => {
    try {
      await submitFilterDiscovery(values);
      closeFilterDialog();
      dispatchDismissAfter(1000);
    } catch (err) {
      console.error(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  // const retrieveFlashcardList = useCallback(async () => {
  //   const publicflashcardsQueries = {
  //     whereQueries: [
  //       {
  //         field: "deletedAt",
  //         condition: "==",
  //         value: null,
  //       },
  //     ],
  //     orderByQueries: [
  //       {
  //         field: "flashcardEndorsementCount",
  //         direction: "desc",
  //       },
  //       {
  //         field: "flashcardRating",
  //         direction: "desc",
  //       },
  //     ],
  //     limitQuery: { number: 10 },
  //   };

  //   const retrievedHighestRatingFlashcards = await retrieveDocs(
  //     "publicflashcards",
  //     publicflashcardsQueries
  //   );

  //   if (retrievedHighestRatingFlashcards.length > 0) {
  //     const currentFlashcardIdx = retrievedHighestRatingFlashcards.findIndex(
  //       (card) => card.id === flashcardId
  //     );

  //     setFlashcardList(retrievedHighestRatingFlashcards);
  //     setFlashcardIndex(currentFlashcardIdx);
  //   }
  // }, [flashcardId, retrieveDocs]);

  const initFlashcardPrivacy = useCallback(async () => {
    if (response.data[flashcardIndex].id) {
      setIsPublicFlashcard(!!response.data[flashcardIndex]?.sharedFrom);

      const myforkedflashcardsQueries = {
        whereQueries: [
          {
            field: "deletedAt",
            condition: "==",
            value: null,
          },
          {
            field: "forkedFrom",
            condition: "==",
            value: response.data[flashcardIndex].id,
          },
        ],
      };

      const retrievedMyForkedFlashcards = await retrieveDocs(
        "myflashcards",
        myforkedflashcardsQueries
      );

      const retrievedMyOwnFlashcard =
        response.data[flashcardIndex]?.sharedFrom &&
        (await retrieveDoc(
          "myflashcards",
          response.data[flashcardIndex]?.sharedFrom
        ));

      setIsMyFlashcard(
        retrievedMyForkedFlashcards.length > 0 ||
          retrievedMyOwnFlashcard?.data.createdBy === user.uid
      );
    }
  }, [flashcardIndex, response.data, retrieveDoc, retrieveDocs, user.uid]);

  const initFilter = useCallback(async () => {
    const retrievedOperationLevel1 =
      userData.discoveryFilterOperationLevel1 ?? null;
    const retrievedOperationLevel2 =
      userData.discoveryFilterOperationLevel2 ?? null;
    const selectedFilters = {
      [operationLevel1.name]: retrievedOperationLevel1,
      [operationLevel2.name]: retrievedOperationLevel2,
    };
    setFilterValues(selectedFilters);
  }, [operationLevel1.name, operationLevel2.name, userData]);

  const [openRateMe, setOpenRateMe] = useState(false);

  const showRateMeDialog = () => {
    setOpenRateMe(true);
  };

  const closeRateMeDialog = () => {
    setOpenRateMe(false);
  };

  const [openEndorseMe, setOpenEndorseMe] = useState(false);

  const showEndorseMeDialog = () => {
    setOpenEndorseMe(true);
  };

  const closeEndorseMeDialog = () => {
    setOpenEndorseMe(false);
  };

  const [openDisendorseMe, setOpenDisendorseMe] = useState(false);

  const showDisendorseMeDialog = () => {
    setOpenDisendorseMe(true);
  };

  const closeDisendorseMeDialog = () => {
    setOpenDisendorseMe(false);
  };

  const [openFilter, setOpenFilter] = useState(false);

  const closeFilterDialog = () => {
    setOpenFilter(false);
  };

  const openFilterDialog = () => {
    setOpenFilter(true);
  };

  const [openList, setOpenList] = useState(false);

  const closeListDialog = () => {
    setOpenList(false);
  };

  const openListDialog = () => {
    setOpenList(true);
  };

  useEffect(() => {
    try {
      if (userData) {
        initFilter();
      }
      if (response.data.length > 0 && userData) {
        initFlashcardPrivacy();
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  }, [
    dispatchError,
    initFilter,
    initFlashcardPrivacy,
    response.data.length,
    userData,
  ]);

  const ratingSection = response.data.length > 0 &&
    response.data[flashcardIndex] &&
    configData && (
      <MDBox display="flex" justifyContent="center">
        <ContainerDimensions>
          {({ width }) => {
            const cardWidth = width > 650 ? 650 : width;
            return (
              <MDBox
                width={cardWidth}
                bgColor="white"
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDBox>
                  <MDBox display="flex" alignItems="center" gap={1}>
                    <Tooltip title="Cards with higher ratings are ranked higher and being recomended in the Discovery List.">
                      <MDBox
                        display="flex"
                        sx={{
                          p: 1,
                          border: "2px dashed grey",
                        }}
                        bgColor="white"
                        borderRadius="xl"
                        alignItems="center"
                      >
                        <Rating
                          readOnly
                          value={response.data[flashcardIndex].flashcardRating}
                        />
                      </MDBox>
                    </Tooltip>
                    <Tooltip title="Endorsement supersede the ranking of ratings.">
                      <MDBox
                        display="flex"
                        sx={{
                          px: 1,
                          border: "2px dashed grey",
                        }}
                        bgColor="white"
                        borderRadius="xl"
                        alignItems="center"
                      >
                        {/* <MDTypography variant="body2">Endorsed</MDTypography> */}
                        <MDBox mt={1} mr={1}>
                          <ThumbUpIcon color="success" size="small" />
                        </MDBox>
                        <MDTypography variant="body3">
                          {
                            response.data[flashcardIndex]
                              .flashcardEndorsementCount
                          }
                        </MDTypography>
                      </MDBox>
                    </Tooltip>
                    {/* ) : null} */}
                  </MDBox>
                </MDBox>

                <MDBox display="flex" gap={0.5} alignItems="center">
                  <MDBox>
                    {!response.data[flashcardIndex].flashcardRatingUsers?.some(
                      (ele) => ele.userId === user.uid
                    ) ? (
                      <Tooltip title="Rate Me Please">
                        <IconButton
                          sx={{ fontSize: 40 }}
                          color="info"
                          onClick={() => {
                            showRateMeDialog();
                          }}
                        >
                          <StarsIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="You have already rated the card.">
                        <IconButton sx={{ fontSize: 40, color: "#a9b9c6" }}>
                          <StarsIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </MDBox>
                  <MDBox>
                    <AllowedTo perform={Permission.ENDORSE_FLASHCARD}>
                      {!response.data[flashcardIndex].flashcardEndorsedBy?.some(
                        (ele) => ele === user.uid
                      ) ? (
                        <Tooltip title="Endorse Me">
                          <Avatar
                            sx={{
                              bgcolor: "#58af52",
                              width: 35,
                              height: 35,
                            }}
                            onClick={() => {
                              showEndorseMeDialog();
                            }}
                          >
                            <ThumbUpIcon />
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <Tooltip title="You have already endorsed the card.">
                          <Avatar
                            sx={{ bgcolor: "#a9b9c6", width: 35, height: 35 }}
                          >
                            <ThumbUpIcon />
                          </Avatar>
                        </Tooltip>
                      )}
                    </AllowedTo>
                  </MDBox>
                  <MDBox>
                    <AllowedTo perform={Permission.ENDORSE_FLASHCARD}>
                      {response.data[flashcardIndex].flashcardEndorsedBy?.some(
                        (ele) => ele === user.uid
                      ) ? (
                        <Tooltip title="Disendorse Me">
                          <Avatar
                            sx={{ bgcolor: "#f78917", width: 35, height: 35 }}
                            color="primary"
                            onClick={() => {
                              showDisendorseMeDialog();
                            }}
                          >
                            <ThumbDownIcon />
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <Tooltip title="You haven't endorse the card.">
                          <Avatar
                            sx={{ bgcolor: "#a9b9c6", width: 35, height: 35 }}
                          >
                            <ThumbDownIcon />
                          </Avatar>
                        </Tooltip>
                      )}
                    </AllowedTo>
                  </MDBox>
                </MDBox>
              </MDBox>
            );
          }}
        </ContainerDimensions>
      </MDBox>
    );

  const sharingSection = response.data.length > 0 &&
    response.data[flashcardIndex] && (
      <MDBox mt={1} display="flex" justifyContent="center">
        {!isMyFlashcard && isPublicFlashcard && (
          <MDButton
            onClick={async () => {
              await submitAdd(response.data[flashcardIndex].id);
              dispatchDismissAfter(1000);
            }}
            color="info"
          >
            Add to Revision Bank
          </MDButton>
        )}
        {isMyFlashcard && isPublicFlashcard && (
          <MDTypography variant="body2" fontWeight="bold">
            Existed In My Revision Bank
          </MDTypography>
        )}
      </MDBox>
    );

  const dispatchDismissAfter = (flashcardId, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        dispatchDismiss(flashcardId);
      }, millisecond);
    });
  };

  return (
    <DashboardLayout>
      <Topnav2 title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            {response.error?.name === "OperationIncompleteError" && (
              <IncompleteOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            <MDBox pt={2} pb={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDBox p={2} borderRadius={25} bgColor="white">
                    <MDBox display="flex" justifyContent="center">
                      <ContainerDimensions>
                        {({ width }) => {
                          const cardWidth = width > 650 ? 650 : width;
                          return (
                            <MDBox
                              pb={2}
                              width={cardWidth}
                              bgColor="white"
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <MDBox display="flex" gap={2}></MDBox>
                              <MDBox display="flex" gap={2}>
                                <Tooltip title="Filter">
                                  <Avatar
                                    sx={{ bgcolor: "#1A73E8" }}
                                    onClick={() => {
                                      openFilterDialog();
                                    }}
                                  >
                                    <FilterAltIcon />
                                  </Avatar>
                                </Tooltip>
                                <Tooltip title="Discovery List">
                                  <Avatar
                                    sx={{ bgcolor: "#1A73E8" }}
                                    onClick={() => {
                                      openListDialog();
                                    }}
                                  >
                                    <ListAltIcon />
                                  </Avatar>
                                </Tooltip>
                              </MDBox>
                            </MDBox>
                          );
                        }}
                      </ContainerDimensions>
                    </MDBox>

                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ContainerDimensions>
                        {({ width }) => {
                          const cardWidth = width > 650 ? 650 : width;
                          return (
                            <>
                              <Tooltip title="Backward">
                                <IconButton
                                  sx={{
                                    fontSize: { xs: 50, lg: 100 },
                                    mr: -2,
                                    ml: -4,
                                  }}
                                  color={flashcardIndex > 0 ? "dark" : "light"}
                                  onClick={() => {
                                    backFlashcard();
                                  }}
                                >
                                  <ArrowLeftIcon />
                                </IconButton>
                              </Tooltip>
                              <MDBox
                                width={cardWidth}
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <ContainerDimensions>
                                  {({ width }) => {
                                    const cardWidth = width > 650 ? 650 : width;
                                    return (
                                      <MDBox
                                        sx={{
                                          p: 2,
                                          border: "2px solid grey",
                                          boxShadow: "5px 5px lightgrey",
                                        }}
                                        width={cardWidth}
                                        bgColor="white"
                                        borderRadius="lg"
                                        mb={1}
                                        minHeight={300}
                                      >
                                        {response.data.length > 0 &&
                                        response.data[flashcardIndex] ? (
                                          <>
                                            <MDTypography
                                              variant="h5"
                                              fontWeight="bold"
                                            >
                                              <pre
                                                style={{
                                                  fontFamily: "inherit",
                                                  whiteSpace: "pre-wrap",
                                                }}
                                              >
                                                {
                                                  response.data[flashcardIndex]
                                                    .flashcardFront
                                                }
                                              </pre>
                                            </MDTypography>
                                            {response.data[flashcardIndex]
                                              .flashcardFrontImgAttachments
                                              ?.length > 0 && (
                                              <ContainerDimensions>
                                                {({ width }) => {
                                                  const imgWidth =
                                                    width > 650 ? 650 : width;
                                                  return (
                                                    <MDBox>
                                                      {response.data[
                                                        flashcardIndex
                                                      ].flashcardFrontImgAttachments?.map(
                                                        (attachment, idx) => (
                                                          <MDBox
                                                            key={"front" + idx}
                                                            borderRadius="lg"
                                                            width={imgWidth}
                                                            component="img"
                                                            src={
                                                              attachment.attachmentURL
                                                            }
                                                            maxWidth="100%"
                                                            position="relative"
                                                            zIndex={1}
                                                          />
                                                        )
                                                      )}
                                                    </MDBox>
                                                  );
                                                }}
                                              </ContainerDimensions>
                                            )}
                                          </>
                                        ) : (
                                          <MDTypography
                                            variant="h4"
                                            fontWeight="bold"
                                            color="error"
                                          >
                                            No Cards Match Your Search
                                          </MDTypography>
                                        )}
                                      </MDBox>
                                    );
                                  }}
                                </ContainerDimensions>
                                <MDBox m={2}>
                                  <MDButton
                                    variant="gradient"
                                    color="info"
                                    size="large"
                                    disabled={isFlipped}
                                    onClick={() => {
                                      setIsFlipped(!isFlipped);
                                    }}
                                  >
                                    Show Answer
                                  </MDButton>
                                </MDBox>
                                {isFlipped && (
                                  <ContainerDimensions>
                                    {({ width }) => {
                                      const cardWidth =
                                        width > 650 ? 650 : width;
                                      return (
                                        <MDBox
                                          sx={{
                                            p: 2,
                                            border: "2px solid grey",
                                            boxShadow: "5px 5px lightgrey",
                                          }}
                                          width={cardWidth}
                                          bgColor="white"
                                          borderRadius="lg"
                                          mb={1}
                                          minHeight={300}
                                        >
                                          {response.data.length > 0 &&
                                          response.data[flashcardIndex] ? (
                                            <>
                                              <MDTypography
                                                variant="h5"
                                                fontWeight="bold"
                                              >
                                                <pre
                                                  style={{
                                                    fontFamily: "inherit",
                                                    whiteSpace: "pre-wrap",
                                                  }}
                                                >
                                                  {
                                                    response.data[
                                                      flashcardIndex
                                                    ].flashcardBack
                                                  }
                                                </pre>
                                              </MDTypography>
                                              {response.data[flashcardIndex]
                                                .flashcardBackImgAttachments
                                                ?.length > 0 && (
                                                <ContainerDimensions>
                                                  {({ width }) => {
                                                    const imgWidth =
                                                      width > 650 ? 650 : width;
                                                    return (
                                                      <MDBox>
                                                        {response.data[
                                                          flashcardIndex
                                                        ].flashcardBackImgAttachments?.map(
                                                          (attachment, idx) => (
                                                            <MDBox
                                                              key={"back" + idx}
                                                              borderRadius="lg"
                                                              width={imgWidth}
                                                              component="img"
                                                              src={
                                                                attachment.attachmentURL
                                                              }
                                                              maxWidth="100%"
                                                              position="relative"
                                                              zIndex={1}
                                                            />
                                                          )
                                                        )}
                                                      </MDBox>
                                                    );
                                                  }}
                                                </ContainerDimensions>
                                              )}
                                            </>
                                          ) : (
                                            <MDTypography
                                              variant="h4"
                                              fontWeight="bold"
                                              color="error"
                                            >
                                              No Cards Match Your Search
                                            </MDTypography>
                                          )}
                                        </MDBox>
                                      );
                                    }}
                                  </ContainerDimensions>
                                )}
                              </MDBox>

                              <Tooltip title="Forward">
                                <IconButton
                                  sx={{
                                    fontSize: { xs: 50, lg: 100 },
                                    ml: -2,
                                    mr: -4,
                                  }}
                                  color={
                                    flashcardIndex < response.data.length - 1
                                      ? "dark"
                                      : "light"
                                  }
                                  onClick={() => {
                                    nextFlashcard();
                                  }}
                                >
                                  <ArrowRightIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          );
                        }}
                      </ContainerDimensions>
                    </MDBox>

                    {ratingSection}

                    <MDBox
                      display="flex"
                      flexDirection="column"
                      sx={{
                        mt: 1,
                        pt: 1,
                        borderTop: "2px solid silver",
                      }}
                      gap={1}
                    >
                      {sharingSection}
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              <Dialog open={openRateMe} onClose={closeRateMeDialog}>
                <DialogTitle textAlign="center">Rate Me</DialogTitle>
                <DialogContent dividers>
                  <StyledRating
                    sx={{ fontSize: 40 }}
                    name="highlight-selected-only"
                    IconContainerComponent={IconContainer}
                    getLabelText={(value) => customIcons[value].label}
                    highlightSelectedOnly
                    onChange={async (event, newValue) => {
                      const flashcardRatingUsers =
                        response.data[flashcardIndex].flashcardRatingUsers ??
                        [];
                      const flashcardRatingCount =
                        response.data[flashcardIndex].flashcardRatingCount ?? 0;
                      const flashcardRating =
                        response.data[flashcardIndex].flashcardRating ?? 0;
                      const finalRatingUsers = flashcardRatingUsers.concat([
                        {
                          userId: user.uid,
                          rating: newValue,
                        },
                      ]);
                      const finalRatingCount = flashcardRatingCount + 1;
                      const finalRating =
                        (newValue + flashcardRating * flashcardRatingCount) /
                        finalRatingCount;
                      await submitRating(
                        {
                          flashcardRatingUsers: finalRatingUsers,
                          flashcardRating: finalRating,
                          flashcardRatingCount: finalRatingCount,
                        },
                        response.data[flashcardIndex].id
                      );
                      dispatchDismissAfter(1000);
                      closeRateMeDialog();
                    }}
                  />
                </DialogContent>
              </Dialog>
              <Dialog open={openEndorseMe} onClose={closeEndorseMeDialog}>
                <DialogTitle textAlign="center">Endorse Me</DialogTitle>
                <DialogContent dividers>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <MDTypography variant="body1">Confirm?</MDTypography>

                    <Tooltip title="Endorse">
                      <IconButton
                        size="large"
                        color="success"
                        onClick={async () => {
                          const finalEndorsedBy = response.data[flashcardIndex]
                            .flashcardEndorsedBy
                            ? [
                                ...response.data[flashcardIndex]
                                  .flashcardEndorsedBy,
                                user.uid,
                              ]
                            : [user.uid];
                          const finalEndorsementCount = finalEndorsedBy.length;
                          await submitEndorse(
                            {
                              flashcardEndorsedBy: [
                                ...new Set(finalEndorsedBy),
                              ],
                              flashcardEndorsementCount: finalEndorsementCount,
                            },
                            response.data[flashcardIndex].id
                          );
                          dispatchDismissAfter(1000);
                          closeEndorseMeDialog();
                        }}
                      >
                        <ThumbUpIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </DialogContent>
              </Dialog>
              <Dialog open={openDisendorseMe} onClose={closeDisendorseMeDialog}>
                <DialogTitle textAlign="center">Disendorse Me</DialogTitle>
                <DialogContent dividers>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <MDTypography variant="body1">Confirm?</MDTypography>
                    <Tooltip title="Disendorse">
                      <IconButton
                        size="large"
                        color="warning"
                        onClick={async () => {
                          const finalEndorsedBy = response.data[
                            flashcardIndex
                          ].flashcardEndorsedBy?.filter(
                            (item) => item !== user.uid
                          );
                          const finalEndorsementCount = finalEndorsedBy.length;
                          await submitDisendorse(
                            {
                              flashcardEndorsedBy: [
                                ...new Set(finalEndorsedBy),
                              ],
                              flashcardEndorsementCount: finalEndorsementCount,
                            },
                            response.data[flashcardIndex].id
                          );
                          dispatchDismissAfter(1000);
                          closeDisendorseMeDialog();
                        }}
                      >
                        <ThumbDownIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </DialogContent>
              </Dialog>
              <SwipeableDrawer
                PaperProps={{
                  sx: { width: 340 },
                }}
                open={openFilter}
                onClose={closeFilterDialog}
                onOpen={openFilterDialog}
              >
                <Formik
                  enableReinitialize
                  initialValues={filterValues}
                  validationSchema={validations}
                  onSubmit={handleSubmit}
                >
                  {(formik) => {
                    const operationLevel1Options = () => {
                      try {
                        const opLvl1Opts = operationConfigs.map((ele) => {
                          return ele.name;
                        });

                        return ["All", ...opLvl1Opts];
                      } catch (err) {
                        console.error(err);
                        return defaultOption;
                      }
                    };

                    const operationLevel2Options = (
                      selectedOperationLevel1
                    ) => {
                      try {
                        const opLvl2Opts = operationConfigs?.find(
                          (ele) => ele.name === selectedOperationLevel1
                        );

                        return opLvl2Opts
                          ? [
                              "All",
                              ...opLvl2Opts.children?.map((ele) => {
                                return ele.name;
                              }),
                            ]
                          : defaultOption;
                      } catch (err) {
                        console.error(err);
                        return defaultOption;
                      }
                    };

                    return (
                      <Form id={formId} autoComplete="off">
                        <MDBox mt={1} ml={2}>
                          <Grid
                            container
                            p={1}
                            alignItems="center"
                            rowSpacing={2}
                          >
                            <Grid item xs={12}>
                              <MDTypography variant="body3" fontWeight="bold">
                                {operationLevel1.label}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12}>
                              <FormBasicAutocomplete
                                form={formik}
                                field={operationLevel1}
                                size="small"
                                options={operationLevel1Options()}
                                // isOptionEqualToValue={(option, value) =>
                                //   option.value === value.value
                                // }
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <FormBasicAutocomplete
                                form={formik}
                                field={operationLevel2}
                                size="small"
                                options={operationLevel2Options(
                                  formik.values.operationLevel1
                                )}
                                // isOptionEqualToValue={(option, value) =>
                                //   option.value === value.value
                                // }
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>

                            <Grid item xs={12}>
                              <MDBox
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                              >
                                <MDBox></MDBox>
                                <MDButton
                                  disabled={formik.isSubmitting}
                                  type="submit"
                                  variant="gradient"
                                  color="dark"
                                >
                                  Filter
                                </MDButton>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Form>
                    );
                  }}
                </Formik>
              </SwipeableDrawer>
              <SwipeableDrawer
                PaperProps={{
                  sx: { width: 340 },
                }}
                open={openList}
                onClose={closeListDialog}
                onOpen={openListDialog}
              >
                <MDBox p={2}>
                  {response.data.length > 0 &&
                    response.data.map((card, idx) => {
                      return (
                        <MDBox
                          key={card.id}
                          sx={{
                            p: 1,
                            border:
                              card.id !== response.data[flashcardIndex].id
                                ? "1px solid black"
                                : "4px solid black",
                          }}
                          borderRadius="lg"
                          mb={2}
                        >
                          <MDBox
                            mb={2}
                            display="flex"
                            justifyContent="space-between"
                            gap={1}
                          >
                            <MDBox display="flex" gap={1}>
                              <MDBox
                                display="flex"
                                sx={{
                                  p: 1,
                                  border: "1px dashed grey",
                                }}
                                bgColor="white"
                                borderRadius="xl"
                                alignItems="center"
                              >
                                <Rating readOnly value={card.flashcardRating} />
                              </MDBox>

                              <MDBox
                                display="flex"
                                sx={{
                                  px: 1,
                                  border: "1px dashed grey",
                                }}
                                bgColor="white"
                                borderRadius="xl"
                                alignItems="center"
                              >
                                <MDBox mt={1} mr={1}>
                                  <ThumbUpIcon color="success" size="small" />
                                </MDBox>
                                <MDTypography variant="body3">
                                  {card.flashcardEndorsementCount}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                            {card.id !== response.data[flashcardIndex].id ? (
                              <Tooltip title="Open">
                                <Avatar
                                  sx={{
                                    bgcolor: "#1A73E8",
                                  }}
                                  onClick={() => {
                                    setFlashcardIndex(idx);
                                    setIsFlipped(false);
                                  }}
                                >
                                  <LaunchIcon size="small" />
                                </Avatar>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Currently Viewing">
                                <Avatar
                                  sx={{
                                    bgcolor: "#000000",
                                  }}
                                >
                                  <AutoStoriesIcon />
                                </Avatar>
                              </Tooltip>
                            )}
                          </MDBox>

                          <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap={2}
                          >
                            <MDBox
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                              alignItems="center"
                              gap={2}
                              sx={{
                                p: 1,
                                border: "1px solid grey",
                                boxShadow: "5px 5px lightgrey",
                              }}
                              borderRadius="lg"
                              mb={1}
                            >
                              <MDTypography variant="caption">
                                <pre
                                  style={{
                                    fontFamily: "inherit",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {card.flashcardFront.substring(0, 15) + "..."}
                                </pre>
                              </MDTypography>
                              {card.flashcardFrontImgAttachments?.map(
                                (attachment, idx) => (
                                  <MDBox
                                    key={"front" + idx}
                                    borderRadius="lg"
                                    width={100}
                                    component="img"
                                    src={attachment.attachmentURL}
                                    maxWidth="100%"
                                    position="relative"
                                    zIndex={1}
                                  />
                                )
                              )}
                            </MDBox>
                            <MDBox
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                              alignItems="center"
                              gap={2}
                              sx={{
                                p: 1,
                                border: "1px solid grey",
                                boxShadow: "5px 5px lightgrey",
                              }}
                              borderRadius="lg"
                              mb={1}
                            >
                              <MDTypography variant="caption">
                                <pre
                                  style={{
                                    fontFamily: "inherit",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {card.flashcardBack.substring(0, 15) + "..."}
                                </pre>
                              </MDTypography>
                              {card.flashcardBackImgAttachments?.map(
                                (attachment, idx) => (
                                  <MDBox
                                    key={"back" + idx}
                                    borderRadius="lg"
                                    width={100}
                                    component="img"
                                    src={attachment.attachmentURL}
                                    maxWidth="100%"
                                    position="relative"
                                    zIndex={1}
                                  />
                                )
                              )}
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      );
                    })}
                </MDBox>
              </SwipeableDrawer>
            </MDBox>
            <Footer />
          </>
        )}
      </AllowedTo>
    </DashboardLayout>
  );
}

export default FlashcardDiscovery;
