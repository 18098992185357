const operationConfigs = [
  {
    id: "O&G",
    name: "Obstetrics and Gynaecolog",
    label: "Obstetrics and Gynaecolog",
    children: [
      {
        id: "LSCS",
        name: "Lower segment caesarean section",
        label: "Lower segment caesarean section",
      },
      {
        id: "LA",
        name: "Labour analgesia",
        label: "Labour analgesia",
      },
      {
        id: "DCH",
        name: "Dilation and curettage, hysteroscop",
        label: "Dilation and curettage, hysteroscop",
      },
      {
        id: "HSO",
        name: "Hysterectomy (subtotal or total), with or without bilateral salpingo-oophorectomy (laparoscopic or open)",
        label:
          "Hysterectomy (subtotal or total), with or without bilateral salpingo-oophorectomy (laparoscopic or open)",
      },
      {
        id: "SO",
        name: "Salpingo-oophorectomy (laparoscopic or open)",
        label: "Salpingo-oophorectomy (laparoscopic or open)",
      },
      {
        id: "VH",
        name: "Vaginal hysterectomy, with or without pelvic floor repair (and related procedures)",
        label:
          "Vaginal hysterectomy, with or without pelvic floor repair (and related procedures)",
      },
      {
        id: "CY",
        name: "Cystectomy (laparoscopic or open)",
        label: "Cystectomy (laparoscopic or open)",
      },
      {
        id: "MY",
        name: "Myomectomy (laparoscopic or open)",
        label: "Myomectomy (laparoscopic or open)",
      },
      {
        id: "PY",
        name: "Polypectomy",
        label: "Polypectomy",
      },
      {
        id: "OY",
        name: "Oopherectomy",
        label: "Oopherectomy",
      },
      {
        id: "IUCD",
        name: "Change/insertion/removal of mirena/IUCD",
        label: "Change/insertion/removal of mirena/IUCD",
      },
      {
        id: "RC",
        name: "Removal of retained products of conception",
        label: "Removal of retained products of conception",
      },
      {
        id: "EUA",
        name: "Evacuation of uterus/abortion (laparoscopic or open)",
        label: "Evacuation of uterus/abortion (laparoscopic or open)",
      },
      {
        id: "ELVL",
        name: "Excision of labial/vulval lesion",
        label: "Excision of labial/vulval lesion",
      },
      {
        id: "CC",
        name: "Cervical cerclage",
        label: "Cervical cerclage",
      },
      {
        id: "CCB",
        name: "Cervical cone biopsy",
        label: "Cervical cone biopsy",
      },
      {
        id: "CA",
        name: "Cervical amputation",
        label: "Cervical amputation",
      },
      {
        id: "RCP",
        name: "Removal of cervical polyp",
        label: "Removal of cervical polyp",
      },
      {
        id: "RVT",
        name: "Repair of vaginal tear",
        label: "Repair of vaginal tear",
      },
      {
        id: "EA",
        name: "Endometrial ablation",
        label: "Endometrial ablation",
      },
      {
        id: "IDBAC",
        name: "Incision and drainage of bartholin abscess/cyst",
        label: "Incision and drainage of bartholin abscess/cyst",
      },
      {
        id: "EVH",
        name: "Evacuation of vulva hematoma",
        label: "Evacuation of vulva hematoma",
      },
      {
        id: "TFTR",
        name: "Transcervical fallopian tubal recanalisation",
        label: "Transcervical fallopian tubal recanalisation",
      },
      {
        id: "TLS",
        name: "Tubal ligation/sterilization procedure (laparoscopic or open)",
        label: "Tubal ligation/sterilization procedure (laparoscopic or open)",
      },
      {
        id: "REP",
        name: "Removal of Ectopic pregnancy",
        label: "Removal of Ectopic pregnancy",
      },
      {
        id: "RER",
        name: "Ruptured ectopic removal (laparoscopic or open)",
        label: "Ruptured ectopic removal (laparoscopic or open)",
      },
      {
        id: "IVF",
        name: "Retrieval of oocytes (IVF)",
        label: "Retrieval of oocytes (IVF)",
      },
      {
        id: "PFR",
        name: "Pelvic floor reconstruction/repair",
        label: "Pelvic floor reconstruction/repair",
      },
      {
        id: "TFVT",
        name: "Tension free vaginal taping",
        label: "Tension free vaginal taping",
      },
      {
        id: "COL1",
        name: "Colposcopy",
        label: "Colposcopy",
      },
      {
        id: "COL2",
        name: "Colporrhaphy",
        label: "Colporrhaphy",
      },
      {
        id: "SAC",
        name: "Sacrocolpopexy",
        label: "Sacrocolpopexy",
      },
      {
        id: "HYM",
        name: "Hymenectomy",
        label: "Hymenectomy",
      },
      {
        id: "FEN",
        name: "Fenton's procedure",
        label: "Fenton's procedure",
      },
      {
        id: "VUL",
        name: "Vulvectomy",
        label: "Vulvectomy",
      },
      {
        id: "RT",
        name: "Radical trachelectomy (removal of cervix), with or withoyt lymphadenectomy",
        label:
          "Radical trachelectomy (removal of cervix), with or withoyt lymphadenectomy",
      },
      {
        id: "TRM",
        name: "Transcervical resection of myoma (hystereoscopic)",
        label: "Transcervical resection of myoma (hystereoscopic)",
      },
      {
        id: "FS",
        name: "Fetal surgery",
        label: "Fetal surgery",
      },
      {
        id: "UAE",
        name: "Uterine artery embolization",
        label: "Uterine artery embolization",
      },
      {
        id: "UTRS",
        name: "Uterine tumour related surgery",
        label: "Uterine tumour related surgery",
      },
      {
        id: "OTRS",
        name: "Ovarian tumour related surgery",
        label: "Ovarian tumour related surgery",
      },
      {
        id: "ETRS",
        name: "Endometrial tumour related surgery",
        label: "Endometrial tumour related surgery",
      },
      {
        id: "VTRS",
        name: "Vulva/vagina tumour related surgery",
        label: "Vulva/vagina tumour related surgery",
      },
      {
        id: "MAS",
        name: "Mastectomy (with our without axillary clearance)",
        label: "Mastectomy (with our without axillary clearance)",
      },
      {
        id: "BAS",
        name: "Breast augmentation surgery",
        label: "Breast augmentation surgery",
      },
      {
        id: "BFS",
        name: "Breast flap surgery",
        label: "Breast flap surgery",
      },
      {
        id: "EBP",
        name: "Epidural blood patch",
        label: "Epidural blood patch",
      },
    ],
  },
];

const asaOptions = ["1", "2", "3", "4", "5", "6"];

const priorityOptions = [
  "Elective",
  "Immediate",
  "Critical",
  "Early",
  "Semi-elective",
];

const anaesthesiaModeList = [
  {
    id: "GA",
    name: "GA",
    label: "GA",
    children: [
      {
        id: "GA-VOL",
        name: "Volatiles",
        label: "Volatiles",
      },
      {
        id: "GA-TIVA",
        name: "TIVA",
        label: "TIVA",
      },
    ],
  },
  {
    id: "MAC",
    name: "MAC/Sedation",
    label: "MAC/Sedation",
  },
  {
    id: "SPI",
    name: "Spinal",
    label: "Spinal",
  },
  {
    id: "EPI",
    name: "Epidural",
    label: "Epidural",
  },
  {
    id: "CSE",
    name: "CSE",
    label: "CSE",
  },
  {
    id: "PNB",
    name: "Peripheral nerve block",
    label: "Peripheral nerve block",
    children: [
      {
        id: "PNB-CSS",
        name: "Catheter or Single Shot",
        label: "Catheter or Single Shot",
      },
      {
        id: "PNB-TOB",
        name: "Type of block (Free text)",
        label: "Type of block (Free text)",
      },
    ],
  },
];

const airwayManagementList = [
  {
    id: "SA",
    name: "Supraglottic airway",
    label: "Supraglottic airway",
  },
  {
    id: "INT",
    name: "Intubation",
    label: "Intubation",
    children: [
      {
        id: "INT-DIR",
        name: "Direct",
        label: "Direct",
      },
      {
        id: "INT-VID",
        name: "Videolaryngoscopy",
        label: "Videolaryngoscopy",
      },
      {
        id: "INT-FIB",
        name: "Fibreoptic bronchoscopy",
        label: "Fibreoptic bronchoscopy",
      },
      {
        id: "INT-OTH",
        name: "Others (Free text)",
        label: "Others (Free text)",
      },
    ],
  },
  {
    id: "ETT",
    name: "ETT",
    label: "ETT",
    children: [
      {
        id: "ETT-ORA",
        name: "Oral",
        label: "Oral",
      },
      {
        id: "ETT-NAS",
        name: "Nasal",
        label: "Nasal",
      },
      {
        id: "ETT-BB",
        name: "Bronchial blocker",
        label: "Bronchial blocker",
      },
      {
        id: "ETT-DLT",
        name: "DLT",
        label: "DLT",
      },
    ],
  },
  {
    id: "OTH",
    name: "Others",
    label: "Others",
    children: [
      {
        id: "OTH-JV",
        name: "Jet ventilation",
        label: "Jet ventilation",
      },
      {
        id: "OTH-OTH",
        name: "Others (Free text)",
        label: "Others (Free text)",
      },
    ],
  },
];

const additionalProceduresList = [
  {
    id: "IA",
    name: "IA",
    label: "IA",
  },
  {
    id: "CVP",
    name: "CVP",
    label: "CVP",
  },
  {
    id: "VAS",
    name: "Vascath",
    label: "Vascath",
  },
  {
    id: "PAC",
    name: "Pulmonary artery catheter",
    label: "Pulmonary artery catheter",
  },
  {
    id: "CSF",
    name: "CSF drain",
    label: "CSF drain",
  },
  {
    id: "EPM",
    name: "Electrophysiologic monitor",
    label: "Electrophysiologic monitor",
  },
  {
    id: "TEE",
    name: "TEE",
    label: "TEE",
  },
];

const dispositionList = [
  {
    id: "GW",
    name: "GW",
    label: "GW",
  },
  {
    id: "HD",
    name: "HD",
    label: "HD",
  },
  {
    id: "ICU",
    name: "ICU",
    label: "ICU",
  },
  {
    id: "Death",
    name: "Death",
    label: "Death",
  },
];
export {
  operationConfigs,
  asaOptions,
  priorityOptions,
  anaesthesiaModeList,
  airwayManagementList,
  additionalProceduresList,
  dispositionList,
};
