import format from "date-fns/format";

const defaultsubmissionDate = format(new Date(), "dd/MM/yyyy");

const venueOptions = ["Labour ward", "Operating theatre"];
const defaultVenue = venueOptions[0];

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 3);

const deliverySites = ["GA Office", "Onsite", "Self-collect"];
const defaultDeliverySiteName = deliverySites[0];

const paymentTypeOptions = ["Non-progressive", "Progressive"];
const defaultPaymentType = paymentTypeOptions[0];

const answerOptions = ["YES", "NO"];
const defaultAnswerQ1 = answerOptions[0];
const defaultAnswerQ2 = answerOptions[0];
const defaultAnswerQ3 = answerOptions[0];
const defaultAnswerQ4 = answerOptions[0];

export {
  defaultsubmissionDate,
  venueOptions,
  defaultVenue,
  defaultDeliverySiteName,
  paymentTypeOptions,
  defaultPaymentType,
  defaultAnswerQ1,
  defaultAnswerQ2,
  defaultAnswerQ3,
  defaultAnswerQ4,
};
