import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { ErrorMessage, Field } from "formik";
import CheckboxGroupWrapper from "components/molecules/Formik/Wrapper/CheckboxGroupWrapper";

function FormCheckboxGroup({
  form,
  field,
  options,
  onChange,
  disabled,
  nameProperty,
  valueProperty,
  linkProperty,
  // originally checkbox group accept "row" or "column" property to display checkboxes direction accourdingly
  // only support parent and child level currently, shouldn't go to unecessary complexity which supposed to be using tree view
  parentRow,
  childRow,
  ...rest
}) {
  const { values, setFieldValue } = form;
  const value = values[field.name] ? values[field.name] : null;

  // object of {checkbox1name: boolean, checkbox2name: boolean ... } to keep track of each checkbox state
  const [isCheckedState, setIsCheckedState] = useState({});

  useEffect(() => {
    // converting array into object

    const initCheckedState = options.reduce((prev, curr) => {
      const stateObj = {};
      if (curr) {
        typeof curr === "string"
          ? (stateObj[curr] = false)
          : (stateObj[curr[valueProperty]] = false);
      }

      const childInitCheckedState =
        curr.children && curr.children.length > 0
          ? curr.children.reduce((prevChild, currChild) => {
              const childStateObj = {};
              if (currChild) {
                typeof currChild === "string"
                  ? (childStateObj[currChild] = false)
                  : (childStateObj[currChild[valueProperty]] = false);
              }
              return { ...prevChild, ...childStateObj };
            }, {})
          : {};

      return { ...prev, ...stateObj, ...childInitCheckedState };
    }, {});

    values[field.name].forEach((element) => {
      if (element) {
        typeof element === "string"
          ? (initCheckedState[element] = true)
          : (initCheckedState[element[valueProperty]] = true);
      }
    });

    setIsCheckedState(initCheckedState);
  }, [field.name, valueProperty, options, values]);

  const handleChange = (e) => {
    isCheckedState[e.target.value] = e.target.checked;

    // find if checked checkbox is parent option
    // const foundEventOption = options.find((option) => {
    //   if (option) {
    //     if (typeof option === "string") {
    //       return option === e.target.value;
    //     } else {
    //       return option[valueProperty] === e.target.value;
    //     }
    //   } else {
    //     return false;
    //   }
    // });

    // if checked parent checkbox, all child chekbox checked
    // if unchecked parent checkbox, all child checkbox unchecked

    // if (foundEventOption?.children && foundEventOption.children.length > 0) {
    //   foundEventOption.children.forEach((childOption) => {
    //     if (childOption) {
    //       if (typeof optchildOptionion === "string") {
    //         isCheckedState[childOption] = e.target.checked;
    //       } else {
    //         isCheckedState[childOption[valueProperty]] = e.target.checked;
    //       }
    //     }
    //   });
    // }

    // if checked child checkbox, verify if all child checkbox checked
    // if all child checkbox checked, check parent checkbox, else uncheck parent checkbox
    // const childOptions = options.flatMap((option) => {
    //   if (option.children && option.children.length > 0) {
    //     const linkedChildOptions = option.children.map((childOption) => ({
    //       ...childOption,
    //       parentValue: option[valueProperty],
    //     }));
    //     return linkedChildOptions;
    //   } else {
    //     return [];
    //   }
    // });

    // if (!foundEventOption) {
    //   const foundEventChildOption = childOptions.find((childOption) => {
    //     if (childOption) {
    //       if (typeof childOption === "string") {
    //         return childOption === e.target.value;
    //       } else {
    //         return childOption[valueProperty] === e.target.value;
    //       }
    //     } else {
    //       return false;
    //     }
    //   });

    //   if (foundEventChildOption) {
    //     const allSelected = childOptions
    //       .filter(
    //         (childOption) =>
    //           childOption.parentValue === foundEventChildOption.parentValue
    //       )
    //       .map((childOption) =>
    //         Object.entries(isCheckedState)
    //           .filter(([k, v]) => v === true)
    //           .map(([k, v]) => k)
    //           .includes(childOption[valueProperty])
    //       )
    //       .every((ele) => ele === true);

    //     isCheckedState[foundEventChildOption.parentValue] = allSelected;
    //   }
    // }

    // generate the selected checkbox list
    const selectedOptions = Object.entries(isCheckedState)
      .filter(([k, v]) => v === true)
      .map(([k, v]) => {
        return options
          .flatMap((option) => {
            if (option.children && option.children.length > 0) {
              return [option, ...option.children];
            } else {
              return option;
            }
          })
          .find((option) => {
            if (option) {
              if (typeof option === "string") {
                return option === k;
              } else {
                return option[valueProperty] === k;
              }
            } else {
              return false;
            }
          });
      });

    setFieldValue(field.name, selectedOptions);

    onChange && onChange(e);
  };

  return (
    <MDBox>
      <Field
        form={form}
        name={field.name}
        label={field.label}
        value={value}
        state={isCheckedState}
        options={options}
        onChange={handleChange}
        as={CheckboxGroupWrapper}
        disabled={disabled}
        nameProperty={nameProperty}
        valueProperty={valueProperty}
        linkProperty={linkProperty}
        parentRow={parentRow}
        childRow={childRow}
        {...rest}
      />
      <MDBox>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          <ErrorMessage name={field.name}></ErrorMessage>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormCheckboxGroup
FormCheckboxGroup.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  formfield: PropTypes.object.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showTitle: PropTypes.bool,
  children: PropTypes.node,
};

export default FormCheckboxGroup;
