const form = {
  formId: "logbook-form",
  formField: {
    caseId: {
      name: "caseId",
      label: "Case ID",
      type: "text",
      errorMsg: "Case ID is required.",
      invalidMsg: "Case ID is invalid.",
    },
    date: {
      name: "date",
      label: "Date",
      type: "box",
      errorMsg: "Date is required.",
      invalidMsg: "Date is invalid.",
    },
    durationHour: {
      name: "durationHour",
      label: "Duration (hours)",
      type: "text",
      errorMsg: "Duration is required.",
      invalidMsg: "Duration is invalid.",
    },
    duration: {
      name: "duration",
      label: "Duration (mins)",
      type: "text",
      errorMsg: "Duration is required.",
      invalidMsg: "Duration is invalid.",
    },
    residencyYear: {
      name: "residencyYear",
      label: "Year of Residency",
      type: "text",
      errorMsg: "Year of Residency is required.",
      invalidMsg: "Year of Residency is invalid.",
    },
    role: {
      name: "role",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
      invalidMsg: "Role is invalid.",
    },
    site: {
      name: "site",
      label: "Site",
      type: "text",
      errorMsg: "Site is required.",
      invalidMsg: "Site is invalid.",
    },
    supervisor: {
      name: "supervisor",
      label: "Supervisor",
      type: "text",
      errorMsg: "Supervisor is required.",
      invalidMsg: "Supervisor is invalid.",
    },
    age: {
      name: "age",
      label: "Age",
      type: "text",
      errorMsg: "Age is required.",
      invalidMsg: "Age is invalid.",
    },
    ageUnit: {
      name: "ageUnit",
      label: "Age Unit",
      type: "text",
      errorMsg: "Age Unit is required.",
      invalidMsg: "Age Unit is invalid.",
    },
    asa: {
      name: "asa",
      label: "ASA",
      type: "text",
      errorMsg: "ASA is required.",
      invalidMsg: "ASA is invalid.",
    },
    emergency: {
      name: "emergency",
      label: "Emergency",
      type: "text",
      errorMsg: "Emergency is required.",
      invalidMsg: "Emergency is invalid.",
    },
    operationLevel1: {
      name: "operationLevel1",
      label: "Subspecialty",
      type: "text",
      errorMsg: "Subspecialty is required.",
      invalidMsg: "Subspecialty is invalid.",
    },
    operationLevel2: {
      name: "operationLevel2",
      label: "Operation/Procedure",
      type: "text",
      errorMsg: "Sub-operation/Sub-procedure is required.",
      invalidMsg: "Sub-operation/Sub-procedure is invalid.",
    },
    priority: {
      name: "priority",
      label: "Priority of chit",
      type: "text",
      errorMsg: "Priority is required.",
      invalidMsg: "Priority is invalid.",
    },
    anaesthesiaMode: {
      name: "anaesthesiaMode",
      label: "Mode of anaesthesia ",
      type: "text",
      errorMsg: "Mode of anaesthesia is required.",
      invalidMsg: "Mode of anaesthesia is invalid.",
    },
    "PNB-TOB": {
      name: "PNB-TOB",
      label: "Others",
      type: "text",
      errorMsg: "Others is required.",
      invalidMsg: "Others is invalid.",
    },
    airwayManagement: {
      name: "airwayManagement",
      label: "Airway management",
      type: "text",
      errorMsg: "Airway management is required.",
      invalidMsg: "Airway management is invalid.",
    },
    additionalProcedures: {
      name: "additionalProcedures",
      label: "Additional procedures",
      type: "text",
      errorMsg: "Additional procedure is required.",
      invalidMsg: "Additional procedure is invalid.",
    },
    "INT-OTH": {
      name: "INT-OTH",
      label: "Others",
      type: "text",
      errorMsg: "Others is required.",
      invalidMsg: "Others is invalid.",
    },
    "OTH-OTH": {
      name: "OTH-OTH",
      label: "Others",
      type: "text",
      errorMsg: "Others is required.",
      invalidMsg: "Others is invalid.",
    },
    disposition: {
      name: "disposition",
      label: "Disposition",
      type: "text",
      errorMsg: "Disposition is required.",
      invalidMsg: "Disposition is invalid.",
    },
    notes: {
      name: "notes",
      label: "Notes",
      type: "text",
      errorMsg: "Notes are required.",
      invalidMsg: "Notes are invalid.",
    },
    createFlashcard: {
      name: "createFlashcard",
      label: "Create Flashcard",
      type: "box",
      errorMsg: "Create Flashcard is required.",
      invalidMsg: "Create Flashcard is invalid.",
    },
    flashcardFront: {
      name: "flashcardFront",
      label: "Flashcard (Front) Content",
      type: "text",
      errorMsg: "Flashcard (Front) is required.",
      invalidMsg: "Flashcard (Front) is invalid.",
    },
    flashcardFrontImgAttachments: {
      name: "flashcardFrontImgAttachments",
      label: "Upload Image Files",
      type: "box",
      min1Msg: "Must have at least 1 File.",
      max20Msg: "Must have at most 20 Files.",
    },
    flashcardBack: {
      name: "flashcardBack",
      label: "Flashcard (Back) Content",
      type: "text",
      errorMsg: "Flashcard (Back) is required.",
      invalidMsg: "Flashcard (Back) is invalid.",
    },
    flashcardBackImgAttachments: {
      name: "flashcardBackImgAttachments",
      label: "Upload Image Files",
      type: "box",
      min1Msg: "Must have at least 1 File.",
      max20Msg: "Must have at most 20 Files.",
    },
  },
};

const flashcardFrontImgAttachmentsForm = {
  formId: "flashcardfront-img-attachments-form",
  formField: {
    flashcardFrontImgAttachmentFile: {
      name: "flashcardFrontImgAttachmentFile",
      label: "Flashcard (Front) Image File",
      type: "box",
      errorMsg: "Flashbard Front Image File is required.",
      invalidTypeMsg: "File type must be jpg, jpeg or png.",
      invalidSizeMsg: "File size must be less than 5 MB.",
    },
  },
};

const flashcardBackImgAttachmentsForm = {
  formId: "flashcardfront-img-attachments-form",
  formField: {
    flashcardBackImgAttachmentFile: {
      name: "flashcardBackImgAttachmentFile",
      label: "Flashcard (Back) Image File",
      type: "box",
      errorMsg: "Flashbard Back Image File is required.",
      invalidTypeMsg: "File type must be jpg, jpeg or png.",
      invalidSizeMsg: "File size must be less than 5 MB.",
    },
  },
};

export {
  form as default,
  flashcardFrontImgAttachmentsForm,
  flashcardBackImgAttachmentsForm,
};
