const form = {
  formId: "account-form",
  formField: {
    displayName: {
      name: "displayName",
      label: "Display Name",
      type: "text",
      errorMsg: "Display Nameis required.",
      invalidMsg: "Display Name is invalid.",
    },
    designation: {
      name: "designation",
      label: "Designation",
      type: "text",
      errorMsg: "Designation is required.",
      invalidMsg: "Designation is invalid.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    medicalID: {
      name: "medicalID",
      label: "MCR",
      type: "text",
      errorMsg: "MCR is required,",
      invalidMsg: "MCR is required,",
    },
    roles: {
      name: "roles",
      label: "Roles",
      type: "box",
      min1Msg: "Must have at least 1 Role.",
      max50Msg: "Must have at most 50 Roles.",
    },
  },
};

const rolesForm = {
  formId: "roles-form",
  formField: {
    roleName: {
      name: "roleName",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
      invalidMsg: "Role is invalid.",
    },
  },
};

export { form as default, rolesForm };
