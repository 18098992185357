import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useFile } from "hooks/useFile";

import { useLogbookActionManager } from "pages/logbook/hooks/useLogbookActionManager";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import initialValues from "pages/logbook/manage/schemas/initialValues";

import validations, {
  noValidation,
} from "pages/logbook/manage/schemas/validations";

import _ from "lodash";

import moment from "moment-timezone";

import {
  anaesthesiaModeList,
  airwayManagementList,
  additionalProceduresList,
  dispositionList,
} from "schema/setups";

const collectionPathLogbooks = "logbooks";
const collectionPathFlashcards = "myflashcards";

const initialState = {
  isPending: true,
  data: initialValues,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    // case "DISMISS":
    //   return {
    //     isPending: false,
    //     data: initialValues,
    //     success: null,
    //     error: null,
    //   };
    case "IS_PENDING":
      return {
        isPending: true,
        data: null,
        success: null,
        error: null,
      };
    case "INITIAL_LOGBOOK":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_LOGBOOK":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "CREATED_LOGBOOK":
      const initialValuesNextLogbook = _.cloneDeep(initialValues);

      return {
        isPending: false,
        data: initialValuesNextLogbook,
        success: `Successfully created the logbook for ID: ${action.payload.caseId}.`,
        error: null,
      };
    case "UPDATED_LOGBOOK":
      return {
        isPending: true,
        data: initialValues,
        success: `Successfully updated the logbook for ID: ${action.payload.caseId}.`,
        error: null,
      };
    case "DELETED_LOGBOOK":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully deleted the logbook for ID: ${action.payload.caseId}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useLogbookManager = (mode, logbookId, flashcardId) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();

  const { getActionState } = useLogbookActionManager();

  const {
    createDoc,
    retrieveDoc,
    updateDoc,
    deleteDoc,
    serverTimestamp,
    convertToTimestamp,
  } = useDocument();

  const { getFileRef } = useFile();

  const { pathname } = useLocation();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  // const dispatchDismiss = useCallback(
  //   () => dispatchIfNotUnmounted({ type: "DISMISS" }),
  //   [dispatchIfNotUnmounted]
  // );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const toPersistenceValue = useCallback(
    async (document) => {
      try {
        if (document.flashcardFrontImgAttachments) {
          document.flashcardFrontImgAttachments =
            document.flashcardFrontImgAttachments.map((element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            });
        }

        if (document.flashcardBackImgAttachments) {
          document.flashcardBackImgAttachments =
            document.flashcardBackImgAttachments.map((element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            });
        }
        if (document.anaesthesiaMode) {
          document.anaesthesiaMode = document.anaesthesiaMode.map(
            (element) => element.id
          );
        }
        if (document.airwayManagement) {
          document.airwayManagement = document.airwayManagement.map(
            (element) => element.id
          );
        }
        if (document.additionalProcedures) {
          document.additionalProcedures = document.additionalProcedures.map(
            (element) => element.id
          );
        }
        if (document.disposition) {
          document.disposition = document.disposition.map(
            (element) => element.id
          );
        }
        return document;
      } catch (err) {
        console.error(err);
        dispatchError(err);
      }
    },
    [dispatchError]
  );

  // TODO: Refactor to DAO Layer
  const toPresentationValue = useCallback(
    async (data) => {
      try {
        if (mode !== "new" && data.date) data.date = data.date.toDate();

        if (data.flashcardFrontImgAttachments) {
          data.flashcardFrontImgAttachments.forEach(async (element) => {
            try {
              const fileRef = await getFileRef(element.attachmentPath);
              element.attachmentURL = fileRef.url;
            } catch (err) {
              element.attachmentURL = null;
            }
          });
        }

        if (data.flashcardBackImgAttachments) {
          data.flashcardBackImgAttachments.forEach(async (element) => {
            try {
              const fileRef = await getFileRef(element.attachmentPath);
              element.attachmentURL = fileRef.url;
            } catch (err) {
              element.attachmentURL = null;
            }
          });
        }

        if (data.anaesthesiaMode) {
          data.anaesthesiaMode = data.anaesthesiaMode.map((element) =>
            anaesthesiaModeList
              .flatMap((option) => {
                if (option.children && option.children.length > 0) {
                  return [option, ...option.children];
                } else {
                  return option;
                }
              })
              .find((ele) => ele.id === element)
          );
        }
        if (data.airwayManagement) {
          data.airwayManagement = data.airwayManagement.map((element) =>
            airwayManagementList
              .flatMap((option) => {
                if (option.children && option.children.length > 0) {
                  return [option, ...option.children];
                } else {
                  return option;
                }
              })
              .find((ele) => ele.id === element)
          );
        }
        if (data.additionalProcedures) {
          data.additionalProcedures = data.additionalProcedures.map((element) =>
            additionalProceduresList
              .flatMap((option) => {
                if (option.children && option.children.length > 0) {
                  return [option, ...option.children];
                } else {
                  return option;
                }
              })
              .find((ele) => ele.id === element)
          );
        }
        if (data.disposition) {
          data.disposition = data.disposition.map((element) =>
            dispositionList
              .flatMap((option) => {
                if (option.children && option.children.length > 0) {
                  return [option, ...option.children];
                } else {
                  return option;
                }
              })
              .find((ele) => ele.id === element)
          );
        }

        return data;
      } catch (err) {
        console.error(err);
        dispatchError(err);
      }
    },
    [dispatchError, getFileRef, mode]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      const retrievedLogbook =
        !!logbookId && (await retrieveDoc(collectionPathLogbooks, logbookId));

      const retrievedFlashcard =
        !!flashcardId &&
        (await retrieveDoc(collectionPathFlashcards, flashcardId));

      const { canEdit, canDelete } =
        mode !== "new" &&
        !!retrievedLogbook &&
        !!retrievedFlashcard &&
        getActionState(retrievedLogbook.data);

      switch (mode) {
        case "new":
          if (!pathname.includes("/logbook/manage")) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "INITIAL_LOGBOOK",
            payload: initialValues,
          });
          break;
        case "edit":
          if (!pathname.includes("/logbook/manage") && canEdit) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_LOGBOOK",
            payload: await toPresentationValue({
              ...retrievedLogbook.data,
              flashcardFront: retrievedFlashcard?.data.flashcardFront,
              flashcardBack: retrievedFlashcard?.data.flashcardBack,
              flashcardFrontImgAttachments:
                retrievedFlashcard?.data.flashcardFrontImgAttachments ?? [],
              flashcardBackImgAttachments:
                retrievedFlashcard?.data.flashcardBackImgAttachments ?? [],
            }),
          });
          break;
        case "view":
          if (!pathname.includes("/logbook/manage")) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_LOGBOOK",
            payload: await toPresentationValue({
              ...retrievedLogbook.data,
              flashcardFront: retrievedFlashcard?.data.flashcardFront,
              flashcardBack: retrievedFlashcard?.data.flashcardBack,
              flashcardFrontImgAttachments:
                retrievedFlashcard?.data.flashcardFrontImgAttachments ?? [],
              flashcardBackImgAttachments:
                retrievedFlashcard?.data.flashcardBackImgAttachments ?? [],
            }),
          });
          break;
        case "delete":
          if (!(pathname.includes("/logbook/manage") && canDelete)) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_LOGBOOK",
            payload: await toPresentationValue({
              ...retrievedLogbook.data,
              flashcardFront: retrievedFlashcard?.data.flashcardFront,
              flashcardBack: retrievedFlashcard?.data.flashcardBack,
              flashcardFrontImgAttachments:
                retrievedFlashcard?.data.flashcardFrontImgAttachments ?? [],
              flashcardBackImgAttachments:
                retrievedFlashcard?.data.flashcardBackImgAttachments ?? [],
            }),
          });
          break;
        default:
          throw operationInvalidError;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    logbookId,
    retrieveDoc,
    flashcardId,
    mode,
    getActionState,
    pathname,
    toPresentationValue,
    dispatchError,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "";
  let modeSubmit = "";
  let modeFieldDisabled = true;
  let modePermission = "";
  let modeValidation = noValidation;
  switch (mode) {
    case "new":
      modeTitle = "Logbook";
      modeSubmit = "Submit";
      modeFieldDisabled = false;
      modePermission = Permission.CREATE_LOGBOOK;
      modeValidation = validations;
      break;
    case "edit":
      modeTitle = "Edit Logbook";
      modeSubmit = "Update";
      modeFieldDisabled = false;
      modePermission = Permission.UPDATE_LOGBOOK;
      modeValidation = validations;
      break;
    case "view":
      modeTitle = "View Logbook";
      modeSubmit = "Close";
      modeFieldDisabled = true;
      modePermission = Permission.READ_LOGBOOK;
      modeValidation = noValidation;
      break;
    case "delete":
      modeTitle = "Delete Logbook";
      modeSubmit = "Delete";
      modeFieldDisabled = true;
      modePermission = Permission.DELETE_LOGBOOK;
      modeValidation = noValidation;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  const submitNew = async (values) => {
    try {
      // dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_LOGBOOK)) {
        values = await toPersistenceValue(values);

        const {
          flashcardFront,
          flashcardBack,
          flashcardFrontImgAttachments,
          flashcardBackImgAttachments,
          ...rest
        } = values;

        console.log(flashcardFrontImgAttachments);
        console.log(flashcardBackImgAttachments);

        const createdLogbook = await createDoc(
          collectionPathLogbooks,
          {
            ...rest,
            modifiedAt: null,
            modifiedBy: null,
            deletedAt: null,
            deletedBy: null,
          },
          user.uid
        );

        if (values.createFlashcard) {
          const retrievedFlashcardConfig = await retrieveDoc(
            "flashcardconfigs",
            user.uid
          );

          const learningSteps = retrievedFlashcardConfig.data.learningSteps
            .split(" ")
            .map(
              (ele) =>
                moment.duration(
                  ele.substring(0, ele.length - 1),
                  ele.substring(ele.length - 1)
                ) * 1
            );
          const interval = learningSteps[0];
          const revisedDate = moment()
            .tz("Asia/Singapore")
            .add(moment.duration(interval))
            .toDate();

          console.log(flashcardFrontImgAttachments);
          console.log(flashcardBackImgAttachments);
          const createdFlashcard = await createDoc(
            collectionPathFlashcards,
            {
              flashcardFront: flashcardFront,
              flashcardBack: flashcardBack,
              flashcardFrontImgAttachments: flashcardFrontImgAttachments,
              flashcardBackImgAttachments: flashcardBackImgAttachments,
              operationLevel1: values.operationLevel1,
              operationLevel2: values.operationLevel2,
              flashcardRatingUsers: [],
              flashcardRatingCount: 0,
              flashcardRating: 1,
              flashcardEndorsedBy: [],
              flashcardEndorsementCount: 0,
              toBeRevisedAt: convertToTimestamp(revisedDate),
              state: 0,
              stateRepetition: 0,
              hardRepetition: 0,
              goodRepetition: 0,
              interval: 0,
              efactor: 0,
              leechCount: 0,
              leechTagged: false,
              leechSuspended: false,
              logbookId: createdLogbook.id,
              modifiedAt: null,
              modifiedBy: null,
              deletedAt: null,
              deletedBy: null,
            },
            user.uid
          );

          await updateDoc(collectionPathLogbooks, createdLogbook.id, {
            flashcardId: createdFlashcard.id,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          });

          console.log(retrievedFlashcardConfig.data.autoShare);
          if (retrievedFlashcardConfig.data.autoShare) {
            delete createdFlashcard.data.state;
            delete createdFlashcard.data.interval;
            delete createdFlashcard.data.efactor;
            delete createdFlashcard.data.hardRepetition;
            delete createdFlashcard.data.goodRepetition;
            delete createdFlashcard.data.stateRepetition;
            delete createdFlashcard.data.leechCount;
            delete createdFlashcard.data.leechTagged;
            delete createdFlashcard.data.leechSuspended;

            console.log(flashcardFrontImgAttachments);
            console.log(flashcardBackImgAttachments);

            await createDoc(
              "publicflashcards",
              {
                ...createdFlashcard.data,
                flashcardRatingUsers: [],
                flashcardRatingCount: 0,
                flashcardRating: 0,
                flashcardEndorsedBy: [],
                flashcardEndorsementCount: 0,
                sharedFrom: createdFlashcard.id,
                modifiedAt: null,
                modifiedBy: null,
                deletedAt: null,
                deletedBy: null,
              },
              user.uid
            );
          }
        }

        dispatchIfNotUnmounted({
          type: "CREATED_LOGBOOK",
          payload: await toPresentationValue(createdLogbook.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitEdit = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_LOGBOOK)) {
        values = await toPersistenceValue(values);

        const {
          flashcardFront,
          flashcardBack,
          flashcardFrontImgAttachments,
          flashcardBackImgAttachments,
          ...rest
        } = values;

        const updatedDoc = await updateDoc(collectionPathLogbooks, logbookId, {
          ...rest,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        if (values.createFlashcard) {
          await updateDoc(collectionPathFlashcards, flashcardId, {
            flashcardFront: flashcardFront,
            flashcardBack: flashcardBack,
            flashcardFrontImgAttachments: flashcardFrontImgAttachments,
            flashcardBackImgAttachments: flashcardBackImgAttachments,
            operationLevel1: values.operationLevel1,
            operationLevel2: values.operationLevel2,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          });
        }

        dispatchIfNotUnmounted({
          type: "UPDATED_LOGBOOK",
          payload: await toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitDelete = async () => {
    try {
      //dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.DELETE_LOGBOOK)) {
        const deletedDoc = await deleteDoc(collectionPathLogbooks, logbookId, {
          deletedAt: serverTimestamp(),
          deletedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "DELETED_LOGBOOK",
          payload: await toPresentationValue(deletedDoc.id, deletedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to delete."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    submitNew,
    submitEdit,
    submitDelete,
    response,
    setIsUnmounted,
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    // dispatchDismiss,
  };
};
