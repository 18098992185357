import { useState, useRef } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { TransitionGroup } from "react-transition-group";

import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import FormField from "components/molecules/FormField";

import ContainerDimensions from "react-container-dimensions";

import { Formik } from "formik";

import FormTextField from "components/molecules/Formik/FormTextField";

import {
  flashcardFrontImgAttachmentsForm,
  flashcardBackImgAttachmentsForm,
} from "pages/flashcard/manage/schemas/form";
import {
  flashcardFrontImgAttachmentsInitialValues,
  flashcardBackImgAttachmentsInitialValues,
} from "pages/flashcard/manage/schemas/initialValues";
import {
  flashcardFrontImgAttachmentsValidation,
  flashcardBackImgAttachmentsValidation,
} from "pages/flashcard/manage/schemas/validations";

import { useFile } from "hooks/useFile";

import { v4 as uuidv4 } from "uuid";

function Information({
  mainForm,
  mainFormField,
  mode,
  modeDisabled,
  dispatchMainError,
}) {
  const [
    selectedFlashcardFrontImgAttachmentIndex,
    setSelectedFlashcardFrontImgAttachmentIndex,
  ] = useState(null);
  const [
    choosenFlashcardFlashcardFrontImgFile,
    setChoosenFlashcardFlashcardFrontImgFile,
  ] = useState(null);
  const inputFlashcardFlashcardFrontImgFile = useRef();

  const [
    selectedFlashcardBackImgAttachmentIndex,
    setSelectedFlashcardBackImgAttachmentIndex,
  ] = useState(null);
  const [choosenFlashcardBackImgFile, setChoosenFlashcardBackImgFile] =
    useState(null);
  const inputFlashcardBackImgFile = useRef();
  // const [isEditing, setIsEditing] = useState(false);

  const { uploadFile, deleteFile /*,dispatchError*/ } = useFile();

  // const [valueNotes, setValueNotes] = useState("");
  // const [valueFlashcardFront, setValueFlashcardFront] = useState("");
  // const [valueFlashcardBack, setValueFlashcardBack] = useState("");

  // destructure from Main Form for entire Question
  const { values: mainFormValues, setFieldValue: setMainFormFieldValue } =
    mainForm;
  const {
    flashcardFront,
    flashcardBack,
    flashcardFrontImgAttachments,
    flashcardBackImgAttachments,
  } = mainFormField;

  // destructure from Sub Form for current section
  const {
    formId: flashcardFrontImgAttachmentsFormId,
    formField: flashcardFrontImgAttachmentsFormField,
  } = flashcardFrontImgAttachmentsForm;
  const { flashcardFrontImgAttachmentFile } =
    flashcardFrontImgAttachmentsFormField;
  const {
    formId: flashcardBackImgAttachmentsFormId,
    formField: flashcardBackImgAttachmentsFormField,
  } = flashcardBackImgAttachmentsForm;
  const { flashcardBackImgAttachmentFile } =
    flashcardBackImgAttachmentsFormField;

  function renderItem(
    attachment,
    index,
    selectedIndex,
    handleRemove,
    handleListItemClick
  ) {
    return (
      <>
        <ListItem
          secondaryAction={
            !modeDisabled && (
              <>
                <IconButton
                  size="small"
                  color="error"
                  disabled={index !== selectedIndex}
                  onClick={() => handleRemove(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )
          }
          disablePadding
        >
          <ListItemButton
            selected={selectedIndex === index}
            onClick={() => handleListItemClick(index)}
          >
            <IconButton
              edge="start"
              size="small"
              color="info"
              disabled={index !== selectedIndex}
              href={attachment.attachmentURL}
              target="_blank"
              rel="noopener"
            >
              <VisibilityIcon />
            </IconButton>
            <ListItemText sx={{ ml: 1 }} primary={attachment.attachmentName} />
          </ListItemButton>
        </ListItem>
      </>
    );
  }

  const handleDocAttachmentListItemClick = (index) => {
    try {
      setSelectedFlashcardFrontImgAttachmentIndex(index);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleImgAttachmentListItemClick = (index) => {
    try {
      setSelectedFlashcardBackImgAttachmentIndex(index);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleRemoveFlashcardFrontImgAttachment = async (index) => {
    try {
      try {
        await deleteFile(
          mainFormValues.flashcardFrontImgAttachments[index].attachmentPath
        );
      } catch (err) {
        console.error(err);
      } finally {
        mainFormValues.flashcardFrontImgAttachments.splice(index, 1);
        setMainFormFieldValue(
          flashcardFrontImgAttachments.name,
          mainFormValues.flashcardFrontImgAttachments
        );
      }
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleRemoveFlashcardBackImgAttachment = async (index) => {
    try {
      try {
        await deleteFile(
          mainFormValues.flashcardBackImgAttachments[index].attachmentPath
        );
      } catch (err) {
        console.error(err);
      } finally {
        mainFormValues.flashcardBackImgAttachments.splice(index, 1);
        setMainFormFieldValue(
          flashcardBackImgAttachments.name,
          mainFormValues.flashcardBackImgAttachments
        );
      }
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const submitDocAttachment = async (values, actions) => {
    try {
      if (choosenFlashcardFlashcardFrontImgFile) {
        const uploadedFile = await uploadFile(
          `questionbank/${uuidv4()}/${
            choosenFlashcardFlashcardFrontImgFile.name
          }`,
          choosenFlashcardFlashcardFrontImgFile
        );

        values.attachmentPath = uploadedFile.path;
        values.attachmentName = uploadedFile.name;
        values.attachmentURL = uploadedFile.url;
        if (
          !mainFormValues.flashcardFrontImgAttachments
            .map((element) => element.attachmentURL)
            .includes(values.attachmentURL)
        ) {
          setMainFormFieldValue(flashcardFrontImgAttachments.name, [
            ...mainFormValues.flashcardFrontImgAttachments,
            values,
          ]);
        }
      }
      setChoosenFlashcardFlashcardFrontImgFile(null);
      inputFlashcardFlashcardFrontImgFile.current.value = "";
      actions.resetForm({ values: flashcardFrontImgAttachmentsInitialValues });
      actions.setSubmitting(false);
    } catch (err) {
      dispatchMainError(err);
      actions.setSubmitting(false);
    }
  };

  const submitImgAttachment = async (values, actions) => {
    try {
      if (choosenFlashcardBackImgFile) {
        const uploadedFile = await uploadFile(
          `questionbank/${uuidv4()}/${choosenFlashcardBackImgFile.name}`,
          choosenFlashcardBackImgFile
        );

        values.attachmentPath = uploadedFile.path;
        values.attachmentName = uploadedFile.name;
        values.attachmentURL = uploadedFile.url;
        if (
          !mainFormValues.flashcardBackImgAttachments
            .map((element) => element.attachmentURL)
            .includes(values.attachmentURL)
        ) {
          setMainFormFieldValue(flashcardBackImgAttachments.name, [
            ...mainFormValues.flashcardBackImgAttachments,
            values,
          ]);
        }
      }
      setChoosenFlashcardBackImgFile(null);
      inputFlashcardBackImgFile.current.value = "";
      actions.resetForm({ values: flashcardBackImgAttachmentsInitialValues });
      actions.setSubmitting(false);
    } catch (err) {
      dispatchMainError(err);
      actions.setSubmitting(false);
    }
  };

  // useEffect(() => {
  //   try {
  //     mainFormValues.notes !== undefined && setValueNotes(mainFormValues.notes);
  //     mainFormValues.flashcardFront !== undefined &&
  //       setValueFlashcardFront(mainFormValues.flashcardFront);
  //     mainFormValues.flashcardBack !== undefined &&
  //       setValueFlashcardBack(mainFormValues.flashcardBack);
  //   } catch (err) {}
  // }, [mode, mainFormValues]);

  return (
    <Card id="flashcard-information" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox
          variant="gradient"
          bgColor="warning"
          color="white"
          //coloredShadow="red"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="15rem"
          height="2.5rem"
          mt={-4}
        >
          <MDBox>
            <MDTypography variant="body2" color="white" fontWeight="medium">
              FLASHCARD
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mt={3} ml={2}>
          <MDBox sx={{ p: 1, border: "2px solid grey" }} borderRadius="xl">
            <Grid container p={1} rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <MDBox>
                    <MDTypography variant="body3" fontWeight="bold">
                      {flashcardFront.label}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextField
                    label="Enter free text here"
                    multiline
                    rows={4}
                    form={mainForm}
                    field={flashcardFront}
                    variant="outlined"
                    disabled={modeDisabled}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Formik
                    initialValues={flashcardFrontImgAttachmentsInitialValues}
                    validationSchema={flashcardFrontImgAttachmentsValidation}
                    onSubmit={submitDocAttachment}
                  >
                    {({
                      isSubmitting,
                      handleSubmit,
                      setFieldValue: setSectionFormFieldValue,
                    }) => (
                      <MDBox
                        id={flashcardFrontImgAttachmentsFormId}
                        autoComplete="off"
                      >
                        <MDBox p={1} bgColor="light" borderRadius="lg">
                          <MDBox mb={1}>
                            <MDTypography variant="body3" fontWeight="medium">
                              Upload {flashcardFrontImgAttachmentFile.label}
                            </MDTypography>
                          </MDBox>
                          <MDBox
                            bgColor="white"
                            borderRadius="lg"
                            p={1}
                            mb={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="baseline"
                            flexWrap="nowrap"
                          >
                            <MDBox flexBasis="100%">
                              <TextField
                                inputRef={inputFlashcardFlashcardFrontImgFile}
                                // size="small"
                                type="file"
                                fullWidth
                                onChange={(e) => {
                                  const file = e.currentTarget.files[0];
                                  if (file) {
                                    setSectionFormFieldValue(
                                      flashcardFrontImgAttachmentFile.name,
                                      file
                                    );
                                    if (file.size < 5 * 1024 * 1024) {
                                      setChoosenFlashcardFlashcardFrontImgFile(
                                        file
                                      );
                                    }
                                  }
                                }}
                                disabled={modeDisabled}
                              />
                              <FormField
                                type={flashcardFrontImgAttachmentFile.type}
                                name={flashcardFrontImgAttachmentFile.name}
                              />
                            </MDBox>
                            {!modeDisabled && (
                              <MDBox ml={2}>
                                <MDButton
                                  size="small"
                                  onClick={handleSubmit}
                                  color="success"
                                  disabled={
                                    (!!mainFormValues.flashcardFrontImgAttachments &&
                                      mainFormValues
                                        .flashcardFrontImgAttachments.length >=
                                        20) ||
                                    isSubmitting
                                  }
                                  iconOnly
                                >
                                  <UploadIcon />
                                </MDButton>
                              </MDBox>
                            )}
                          </MDBox>
                          <MDBox bgColor="white" borderRadius="lg">
                            <List dense>
                              <TransitionGroup>
                                {mainFormValues.flashcardFrontImgAttachments.map(
                                  (flashcardFrontImgAttachment, index) => (
                                    <Collapse
                                      key={
                                        flashcardFrontImgAttachment.attachmentURL
                                      }
                                    >
                                      {renderItem(
                                        flashcardFrontImgAttachment,
                                        index,
                                        selectedFlashcardFrontImgAttachmentIndex,
                                        handleRemoveFlashcardFrontImgAttachment,
                                        handleDocAttachmentListItemClick
                                      )}
                                    </Collapse>
                                  )
                                )}
                              </TransitionGroup>
                            </List>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    )}
                  </Formik>
                  <FormField
                    type={flashcardFrontImgAttachments.type}
                    label={flashcardFrontImgAttachments.label}
                    name={flashcardFrontImgAttachments.name}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12} sm={12}>
                  <MDBox p={1} bgColor="light" borderRadius="lg">
                    <MDBox mb={1}>
                      <MDTypography variant="body3" fontWeight="medium">
                        Preview Flashcard (Front)
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      bgColor="white"
                      borderRadius="lg"
                      p={1}
                      mb={1}
                      minHeight={300}
                    >
                      <MDTypography variant="body3" fontWeight="bold">
                        <pre
                          style={{
                            fontFamily: "inherit",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {mainFormValues.flashcardFront}
                        </pre>
                      </MDTypography>
                      {mainFormValues.flashcardFrontImgAttachments?.length >
                        0 && (
                        <ContainerDimensions>
                          {({ width }) => {
                            const imgWidth = width > 950 ? 950 : width;
                            return (
                              <MDBox
                                width={imgWidth}
                                component="img"
                                src={
                                  mainFormValues.flashcardFrontImgAttachments[0]
                                    .attachmentURL
                                }
                                maxWidth="100%"
                                position="relative"
                                zIndex={1}
                              />
                            );
                          }}
                        </ContainerDimensions>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <MDBox mt={3} ml={2}>
          <MDBox sx={{ p: 1, border: "2px solid grey" }} borderRadius="xl">
            <Grid container p={1} rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <MDBox>
                    <MDTypography variant="body3" fontWeight="bold">
                      {flashcardBack.label}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextField
                    label="Enter free text here"
                    multiline
                    rows={4}
                    form={mainForm}
                    field={flashcardBack}
                    variant="outlined"
                    disabled={modeDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Formik
                    initialValues={flashcardBackImgAttachmentsInitialValues}
                    validationSchema={flashcardBackImgAttachmentsValidation}
                    onSubmit={submitImgAttachment}
                  >
                    {({
                      isSubmitting,
                      handleSubmit,
                      setFieldValue: setSectionFormFieldValue,
                    }) => (
                      <MDBox
                        id={flashcardBackImgAttachmentsFormId}
                        autoComplete="off"
                      >
                        <MDBox p={1} bgColor="light" borderRadius="lg">
                          <MDBox mb={1}>
                            <MDTypography variant="body3" fontWeight="medium">
                              Upload {flashcardBackImgAttachmentFile.label}
                            </MDTypography>
                          </MDBox>
                          <MDBox
                            bgColor="white"
                            borderRadius="lg"
                            p={1}
                            mb={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="baseline"
                            flexWrap="nowrap"
                          >
                            <MDBox flexBasis="100%">
                              <TextField
                                inputRef={inputFlashcardBackImgFile}
                                // size="small"
                                type="file"
                                fullWidth
                                onChange={(e) => {
                                  const file = e.currentTarget.files[0];
                                  if (file) {
                                    setSectionFormFieldValue(
                                      flashcardBackImgAttachmentFile.name,
                                      file
                                    );
                                    if (file.size < 5 * 1024 * 1024) {
                                      setChoosenFlashcardBackImgFile(file);
                                    }
                                  }
                                }}
                                disabled={modeDisabled}
                              />
                              <FormField
                                type={flashcardBackImgAttachmentFile.type}
                                name={flashcardBackImgAttachmentFile.name}
                              />
                            </MDBox>
                            {!modeDisabled && (
                              <MDBox ml={2}>
                                <MDButton
                                  size="small"
                                  onClick={handleSubmit}
                                  color="success"
                                  disabled={
                                    (!!mainFormValues.flashcardBackImgAttachments &&
                                      mainFormValues.flashcardBackImgAttachments
                                        .length >= 20) ||
                                    isSubmitting
                                  }
                                  iconOnly
                                >
                                  <UploadIcon />
                                </MDButton>
                              </MDBox>
                            )}
                          </MDBox>
                          <MDBox bgColor="white" borderRadius="lg">
                            <List dense>
                              <TransitionGroup>
                                {mainFormValues.flashcardBackImgAttachments.map(
                                  (flashcardBackImgAttachment, index) => (
                                    <Collapse
                                      key={
                                        flashcardBackImgAttachment.attachmentURL
                                      }
                                    >
                                      {renderItem(
                                        flashcardBackImgAttachment,
                                        index,
                                        selectedFlashcardBackImgAttachmentIndex,
                                        handleRemoveFlashcardBackImgAttachment,
                                        handleImgAttachmentListItemClick
                                      )}
                                    </Collapse>
                                  )
                                )}
                              </TransitionGroup>
                            </List>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    )}
                  </Formik>
                  <FormField
                    type={flashcardBackImgAttachments.type}
                    label={flashcardBackImgAttachments.label}
                    name={flashcardBackImgAttachments.name}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12} sm={12}>
                  <MDBox p={1} bgColor="light" borderRadius="lg">
                    <MDBox mb={1}>
                      <MDTypography variant="body3" fontWeight="medium">
                        Preview Flashcard (Back)
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      bgColor="white"
                      borderRadius="lg"
                      p={1}
                      mb={1}
                      minHeight={300}
                    >
                      <MDTypography variant="body3" fontWeight="bold">
                        <pre
                          style={{
                            fontFamily: "inherit",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {mainFormValues.flashcardBack}
                        </pre>
                      </MDTypography>

                      {mainFormValues.flashcardBackImgAttachments?.length >
                        0 && (
                        <ContainerDimensions>
                          {({ width }) => {
                            const imgWidth = width > 950 ? 950 : width;
                            return (
                              <MDBox
                                width={imgWidth}
                                component="img"
                                src={
                                  mainFormValues.flashcardBackImgAttachments[0]
                                    .attachmentURL
                                }
                                maxWidth="100%"
                                position="relative"
                                zIndex={1}
                              />
                            );
                          }}
                        </ContainerDimensions>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Information;
