import { useEffect, useState, useReducer, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";

import { useFlashcardActionManager } from "pages/flashcard/hooks/useFlashcardActionManager";

import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import ContainerDimensions from "react-container-dimensions";

import { AllowedTo, useAbac } from "react-abac";
import { Permission } from "models/abac";

import {
  columnOptions,
  defaultFlashcardColumns,
} from "pages/flashcard/manage/schemas/setups";

import { parse } from "json2csv";

// import {
//   anaesthesiaModeList,
//   airwayManagementList,
//   additionalProceduresList,
//   dispositionList,
// } from "schema/setups";

// const flatAnaesthesiaModeList = anaesthesiaModeList.flatMap((option) => {
//   if (option.children && option.children.length > 0) {
//     return [option, ...option.children];
//   } else {
//     return option;
//   }
// });

// const flatAirwayManagementList = airwayManagementList.flatMap((option) => {
//   if (option.children && option.children.length > 0) {
//     return [option, ...option.children];
//   } else {
//     return option;
//   }
// });

// const flatAdditionalProceduresList = additionalProceduresList.flatMap(
//   (option) => {
//     if (option.children && option.children.length > 0) {
//       return [option, ...option.children];
//     } else {
//       return option;
//     }
//   }
// );

// const flatDispositionList = dispositionList.flatMap((option) => {
//   if (option.children && option.children.length > 0) {
//     return [option, ...option.children];
//   } else {
//     return option;
//   }
// });

// const flatTagList = [
//   ...flatAnaesthesiaModeList,
//   ...flatAirwayManagementList,
//   ...flatAdditionalProceduresList,
//   ...flatDispositionList,
// ];

let initialState = {
  isPending: false,
  data: null,
  success: null,
  error: null,
};

const schemaReducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return initialState;
    case "IS_PENDING":
      return {
        isPending: true,
        data: null,
        success: null,
        error: null,
      };
    case "EXPORTED_FLASHCARDS":
      return {
        isPending: false,
        data: null,
        success: `Successfully exported the flashcards.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: null,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useRecordsManager = () => {
  const [response, dispatch] = useReducer(schemaReducer, initialState);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [isChanged, setIsChanged] = useState(0);

  const { user } = useAuthContext();
  const { userHasPermissions } = useAbac();

  const { getActionState } = useFlashcardActionManager();
  const { retrieveDoc, updateDoc, serverTimestamp } = useDocument();

  const flashcardsQueries = {
    whereQueries: [
      {
        field: "deletedAt",
        condition: "==",
        value: null,
      },
      {
        field: "createdBy",
        condition: "==",
        value: user.uid,
      },
    ],
    // orderByQueries: [
    //   {
    //     field: "createdAt",
    //     direction: "desc",
    //   },
    // ],
  };

  const usersQueries = {
    whereQueries: [
      {
        field: "deletedAt",
        condition: "==",
        value: null,
      },
    ],
  };

  const { collectionData: flashcardsData } = useCollectionSnapshot(
    "myflashcards",
    flashcardsQueries
  );

  const { collectionData: usersData } = useCollectionSnapshot(
    "users",
    usersQueries
  );

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        ![
          "PermissionDeniedError",
          "OperationInvalidError",
          "CustomerInvalidError",
        ].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const navigate = useNavigate();

  const toPresentationValue = (data) => {
    try {
      const toDateString = (value) => {
        return value ? value.toDate().toLocaleDateString("en-Sg") : "-";
      };

      const toDateTimeString = (value) => {
        return value ? value.toDate().toLocaleString("en-Sg") : "-";
      };

      const toDisplayName = (userId) => {
        const user = usersData.find((document) => document.id === userId);
        return userId ? (user ? user.data.displayName : "Unknown") : "-";
      };

      data.flashcardFrontImgAttachmentsURL =
        data.flashcardFrontImgAttachments.map((ele) => ele.attachmentURL);
      data.flashcardBackImgAttachmentsURL =
        data.flashcardBackImgAttachments.map((ele) => ele.attachmentURL);

      // data.tagsText = data.tags?.toString() ?? "-";

      data.createdAtText = toDateString(data.createdAt);
      data.modifiedAtText = toDateString(data.modifiedAt);
      data.deletedAtText = toDateString(data.deletedAt);
      data.createdByText = toDisplayName(data.createdBy);
      data.modifiedByText = toDisplayName(data.modifiedBy);
      data.deletedByText = toDisplayName(data.deletedBy);

      data.toBeRevisedAtText = toDateTimeString(data.toBeRevisedAt);

      const { latestAction, canEdit, canDelete } = getActionState(data);

      data.canEdit = canEdit;
      data.canDelete = canDelete;

      switch (latestAction.name) {
        case "created":
          data.status = "Created";
          break;
        case "modified":
          data.status = "Modified";
          break;
        case "deleted":
          data.status = "Deleted";
          break;
        default:
          data.status = "-";
      }

      return data;
    } catch (err) {
      dispatchError(err);
    }
  };

  const toExportValue = (data) => {
    try {
      // const toDateString = (value) => {
      //   return value ? value.toDate().toLocaleDateString("en-Sg") : "-";
      // };

      const toDisplayName = (userId) => {
        const user = usersData.find((document) => document.id === userId);
        return userId ? (user ? user.data.displayName : "Unknown") : "-";
      };

      return {
        createdBy: toDisplayName(data.createdBy),
        modifiedBy: toDisplayName(data.modifiedBy),
        flashcardFront: data.flashcardFront,
        flashcardBack: data.flashcardBack,
        // tags: data.tagsText,
      };
    } catch (err) {
      dispatchError(err);
    }
  };

  const setupTable = useCallback(async () => {
    const userData = user && (await retrieveDoc("users", user.uid));

    const defaultFlashcardColumnsKey = defaultFlashcardColumns.map(
      (elem) => elem.key
    );

    const preferredFlashcardColumnsKey =
      userData.data?.preferredFlashcardColumns?.map((elem) => elem.key) ??
      defaultFlashcardColumnsKey;

    const selectedColumns =
      preferredFlashcardColumnsKey ?? defaultFlashcardColumnsKey;
    const allColumnsKey = columnOptions.map((elem) => elem.key);
    const unselectedColumns = allColumnsKey.filter(
      (columnKey) => !selectedColumns.includes(columnKey)
    );

    setUserData(userData);
    setHiddenColumns(unselectedColumns);
    setIsDataReady(true);
  }, [retrieveDoc, user]);

  useEffect(() => {
    try {
      setupTable();
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, setupTable, isChanged]);

  const handleUserPreference = async (newdata) => {
    try {
      await updateDoc("users", user.uid, {
        preferredFlashcardColumns: newdata.preferredFlashcardColumns,
        modifiedAt: serverTimestamp(),
        modifiedBy: user.uid,
      });
      setIsChanged(isChanged + 1);
    } catch (err) {
      dispatchError(err);
    }
  };

  const handleExport = async (permission) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(permission)) {
        const fields = [
          "createdBy",
          "modifiedBy",
          "flashcardFront",
          "flashcardBack",
          // "tagsText",
        ];
        const opts = { fields };

        const data =
          flashcardsData &&
          flashcardsData.map((flashcard) => toExportValue(flashcard.data));

        try {
          const csv = parse(data, opts);
          let csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
            csv
          )}`;
          const encodedUri = csvContent;
          window.open(encodedUri);
        } catch (err) {
          console.error(err);
        }
        dispatchIfNotUnmounted({
          type: "EXPORTED_FLASHCARDS",
          payload: null,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to export flashcards."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const rows =
    flashcardsData &&
    flashcardsData.map((flashcard) => {
      return toPresentationValue({ id: flashcard.id, ...flashcard.data });
    });

  const tooltipCell = (accessor) => {
    return (
      <Tooltip title={accessor ?? "-"}>
        <MDBox>
          {accessor ? accessor.substring(0, 30) : "-"}
          {accessor && accessor.length > 30 ? "..." : ""}
        </MDBox>
      </Tooltip>
    );
  };

  const columns = [
    {
      Header: "canEdit",
      Footer: "canEdit",
      accessor: "canEdit",
    },
    {
      Header: "canDelete",
      Footer: "canDelete",
      accessor: "canDelete",
    },
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
    },
    {
      Header: "LogbookId",
      Footer: "LogbookId",
      accessor: "logbookId",
    },
    {
      Header: "Front Image Atth",
      Footer: "Front Image Atth",
      accessor: "flashcardFrontImgAttachmentsURL",
    },
    {
      Header: "Back Image Atth",
      Footer: "Back Image Atth",
      accessor: "flashcardBackImgAttachmentsURL",
    },

    {
      Header: "Flashcard Front",
      Footer: "Flashcard Front",
      accessor: "flashcardFront",
      Cell: ({ cell }) => {
        const flashcardFront = cell.row.values.flashcardFront;
        const flashcardFrontImgAttachmentsURL =
          cell.row.values.flashcardFrontImgAttachmentsURL;
        return (
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <MDTypography variant="body3" fontWeight="bold">
              <pre style={{ fontFamily: "inherit", whiteSpace: "pre-wrap" }}>
                {flashcardFront ? flashcardFront.substring(0, 30) : "-"}
                {flashcardFront && flashcardFront.length > 30 ? "..." : ""}
              </pre>
            </MDTypography>
            {flashcardFrontImgAttachmentsURL?.length > 0 && (
              <ContainerDimensions>
                {({ width }) => {
                  const imgWidth = width > 950 ? 950 : width;
                  return (
                    <MDBox
                      minWidth={300}
                      width={imgWidth}
                      component="img"
                      src={flashcardFrontImgAttachmentsURL[0]}
                      maxWidth="100%"
                      position="relative"
                      zIndex={1}
                    />
                  );
                }}
              </ContainerDimensions>
            )}
          </MDBox>
        );
      },
    },
    {
      Header: "Flashcard Back",
      Footer: "Flashcard Back",
      accessor: "flashcardBack",
      Cell: ({ cell }) => {
        const flashcardBack = cell.row.values.flashcardBack;
        const flashcardBackImgAttachmentsURL =
          cell.row.values.flashcardBackImgAttachmentsURL;
        return (
          <>
            <MDTypography variant="body3" fontWeight="bold">
              <pre style={{ fontFamily: "inherit", whiteSpace: "pre-wrap" }}>
                {flashcardBack ? flashcardBack.substring(0, 30) : "-"}
                {flashcardBack && flashcardBack.length > 30 ? "..." : ""}
              </pre>
            </MDTypography>
            {flashcardBackImgAttachmentsURL?.length > 0 && (
              <ContainerDimensions>
                {({ width }) => {
                  const imgWidth = width > 950 ? 950 : width;
                  return (
                    <MDBox
                      minWidth={300}
                      width={imgWidth}
                      component="img"
                      src={flashcardBackImgAttachmentsURL[0]}
                      maxWidth="100%"
                      position="relative"
                      zIndex={1}
                    />
                  );
                }}
              </ContainerDimensions>
            )}
          </>
        );
      },
    },

    {
      Header: "Subspecialty",
      Footer: "Subspecialty",
      accessor: "operationLevel1",
      Cell: ({ cell }) => {
        const operationLevel1 = cell.row.values.operationLevel1;

        return (
          <Tooltip placement="right" title={operationLevel1 ?? "-"}>
            <MDBox>
              {operationLevel1 ? operationLevel1.substring(0, 30) : "-"}
              {operationLevel1 && operationLevel1.length > 30 ? "..." : ""}
            </MDBox>
          </Tooltip>
        );
      },
    },

    {
      Header: "Operation/Procedure",
      Footer: "Operation/Procedure",
      accessor: "operationLevel2",
      Cell: ({ cell }) => {
        const operationLevel2 = cell.row.values.operationLevel2;

        return (
          <Tooltip placement="right" title={operationLevel2 ?? "-"}>
            <MDBox>
              {operationLevel2 ? operationLevel2.substring(0, 30) : "-"}
              {operationLevel2 && operationLevel2.length > 30 ? "..." : ""}
            </MDBox>
          </Tooltip>
        );
      },
    },
    // {
    //   Header: "Tags",
    //   Footer: "Tags",
    //   accessor: "tagsText",
    //   Cell: ({ cell }) => {
    //     const tagsText = cell.row.values.tagsText;
    //     const items = tagsText !== "-" ? tagsText.split(",") : null;
    //     return (
    //       items?.map((item) => {
    //         const itemFullName =
    //           flatTagList.find((ele) => ele.id === item)?.name ?? "-";
    //         return (
    //           <Tooltip placement="right" title={itemFullName ?? "-"}>
    //             <MDBox>
    //               {item ? item.substring(0, 30) : "-"}
    //               {item && item.length > 30 ? "..." : ""}
    //             </MDBox>
    //           </Tooltip>
    //         );
    //       }) ?? "-"
    //     );
    //   },
    // },
    {
      Header: "Due for Revision",
      Footer: "Due for Revision",
      accessor: "toBeRevisedAtText",
    },
    {
      Header: "Created At",
      Footer: "Created At",
      accessor: "createdAtText",
    },
    {
      Header: "Created By",
      Footer: "Created By",
      accessor: "createdByText",
      Cell: ({ cell }) => {
        const createdByText = cell.row.values.createdByText;
        return tooltipCell(createdByText);
      },
    },
    {
      Header: "Modified At",
      Footer: "Modified At",
      accessor: "modifiedAtText",
    },
    {
      Header: "Modified By",
      Footer: "Modified By",
      accessor: "modifiedByText",
      Cell: ({ cell }) => {
        const modifiedByText = cell.row.values.modifiedByText;
        return tooltipCell(modifiedByText);
      },
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
    },
    {
      Header: "Deleted At",
      Footer: "Deleted At",
      accessor: "deletedAtText",
    },
    {
      Header: "Deleted By",
      Footer: "Deleted By",
      accessor: "deletedByText",
      Cell: ({ cell }) => {
        const deletedByText = cell.row.values.deletedByText;
        return tooltipCell(deletedByText);
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ cell }) => {
        const canEdit = cell.row.values.canEdit;
        const canDelete = cell.row.values.canDelete;
        return (
          <>
            <AllowedTo perform={Permission.READ_FLASHCARD}>
              <MDButton
                variant="gradient"
                color="info"
                iconOnly
                onClick={() =>
                  navigate(`/flashcard/manage/view/${cell.row.values.id}`)
                }
              >
                <VisibilityIcon />
              </MDButton>
              &nbsp;&nbsp;
            </AllowedTo>
            {canEdit && (
              <AllowedTo perform={Permission.UPDATE_FLASHCARD}>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() =>
                    navigate(`/flashcard/manage/edit/${cell.row.values.id}`)
                  }
                >
                  <EditIcon />
                </MDButton>
                &nbsp;&nbsp;
              </AllowedTo>
            )}
            {canDelete && (
              <AllowedTo perform={Permission.DELETE_FLASHCARD}>
                <MDButton
                  variant="gradient"
                  color="warning"
                  iconOnly
                  onClick={() =>
                    navigate(`/flashcard/manage/delete/${cell.row.values.id}`)
                  }
                >
                  <DeleteIcon />
                </MDButton>
              </AllowedTo>
            )}
          </>
        );
      },
    },
  ];

  return {
    columns,
    rows,
    hiddenColumns,
    response,
    userData,
    handleExport,
    handleUserPreference,
    dispatchDismiss,
    dispatchError,
    isDataReady,
  };
};
