import { useParams } from "react-router-dom";
import LogbookStatistics from "pages/logbookstatistics/manage/LogbookStatistics";

// using Proxy Page to remount when route to same route location but different param
function LogbookStatisticsProxy() {
  const { mode, csesurveyId } = useParams();
  return <LogbookStatistics key={`${mode}/${csesurveyId}`} />;
}

export default LogbookStatisticsProxy;
