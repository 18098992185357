import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Permission } from "models/abac";
import { noValidation } from "pages/csesurveys/manage/schemas/validations";

const initialState = {
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        success: null,
        error: null,
      };
    case "LOAD_ALL_RECORDS":
      return {
        isPending: false,
        success: null, //`Successfully loaded all records.`,
        error: null,
      };
    case "LOAD_INDIVIDUAL_RECORDS":
      return {
        isPending: false,
        success: `Successfully loaded individual records.`,
        error: null,
      };
    case "SUBMITTED_CSESURVEY":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully submitted the CSE Survey.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "UPDATED_CSESURVEY":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully updated the CSE Record.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

//export const useCseSurveyManager = (mode, userId, recordId) => {
export const useCseSurveyManager = (mode, recordId) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { pathname } = useLocation();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";
      switch (mode) {
        case "all":
          if (!pathname.includes("/logbookstatistics/all")) {
            throw operationInvalidError;
          }
          dispatchIfNotUnmounted({
            type: "LOAD_ALL_RECORDS",
          });
          break;
        case "individual":
          if (!pathname.includes("/logbookstatistics/individual")) {
            throw operationInvalidError;
          }
          dispatchIfNotUnmounted({
            type: "LOAD_INDIVIDUAL_RECORDS",
          });
          break;
        case "view":
          if (!pathname.includes("/csesurveys/viewchart")) {
            throw operationInvalidError;
          }
          break;
        default:
          throw operationInvalidError;
      }
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchIfNotUnmounted, mode, pathname, dispatchError]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "";
  let modeSubmit = "";
  let modePermission = "";
  let modeValidation = noValidation;
  // Set permission.
  // Check the permission in CseSurvey.js (<AllowedTo perform={modePermission} no={() => <PermissionDenied />} />)
  switch (mode) {
    case "all":
      modeTitle = "All records";
      modePermission = Permission.READ_ALL_USERS;
      break;
    case "individual":
      modeTitle = "Individual record";
      modePermission = Permission.READ_ALL_USERS;
      break;
    case "view":
      modeTitle = "View Cusum Chart";
      modePermission = Permission.VIEW_CSECHART;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  return {
    modeTitle,
    modeSubmit,
    modePermission,
    modeValidation,
    response,
    dispatchDismiss,
    dispatchError,
  };
};
