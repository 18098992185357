import { useCallback, useState, useEffect } from "react";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import DefaultLineChart from "components/charts/LineCharts/DefaultLineChart";
import { std, mean, sqrt } from "mathjs";

const EWMA_lambda = 0.3;

function EWMAChart() {
  const { user } = useAuthContext();
  const { retrieveDoc, updateDoc } = useDocument();
  const [graphLabels, setGraphLabels] = useState([]);
  const [graphDatasets, setGraphDatasets] = useState([]);

  const initializeFieldValue = useCallback(async () => {
    //const retrievedRecords = await retrieveDoc(user.uid, "records");
    const retrievedRecords = await retrieveDoc("records", user.uid);

    let cseRecords = [];
    if (retrievedRecords.data !== undefined) {
      cseRecords = retrievedRecords.data.cseRecords;
    }

    //const retrievedCusumSettings = await retrieveDoc("methods", "cusum");
    const chart_labels = [];
    const X = [];
    const EWMA_X = [];
    let stats = {
      xDoubleBar: 0,
      UCL: 0,
      LCL: 0,
    };

    if (cseRecords.length > 0) {
      X.push(0);
      chart_labels.push(0);
      cseRecords.forEach((cseRecord, index) => {
        chart_labels.push(index + 1);

        if (
          cseRecord["question1"] === "YES" &&
          cseRecord["question2"] === "YES" &&
          cseRecord["question3"] === "YES" &&
          cseRecord["question4"] === "YES"
        )
          X.push(0);
        else X.push(1);
      });

      X.forEach((value, index) => {
        if (index === 0) EWMA_X[index] = X[index];
        else
          EWMA_X[index] =
            EWMA_lambda * X[index] + (1 - EWMA_lambda) * EWMA_X[index - 1];
      });

      stats.xDoubleBar = mean(EWMA_X);
      const EWMA_sigma = std(EWMA_X);
      const multiplier = sqrt(EWMA_lambda / (2 - EWMA_lambda));
      stats.UCL = stats.xDoubleBar + 3 * EWMA_sigma * multiplier;
      stats.LCL = stats.xDoubleBar - 3 * EWMA_sigma * multiplier;
    } else {
      X.push(0);
      chart_labels.push(0);
    }
    //X.splice(0, 0, 0); // Add 0 to the beginning (index-0) of S_data (start, delete-elm, value)

    await updateDoc("records", user.uid, {
      ...retrievedRecords.data,
      cseEWMAStats: stats,
    });

    setGraphLabels(chart_labels);

    console.log(JSON.stringify(X));
    // Prepare datasets for line chart
    let datasets = [
      {
        label: "Raw data",
        color: "info",
        data: X,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ];

    datasets.push({
      label: "EWMA Chart",
      color: "success",
      data: EWMA_X,
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
    });

    datasets.push({
      label: "UCL",
      color: "warning",
      data: Array(chart_labels.length).fill(stats.UCL),
      borderDash: [10, 5],
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBorderWidth: 0,
    });

    datasets.push({
      label: "LCL",
      color: "warning",
      data: Array(chart_labels.length).fill(stats.LCL),
      borderDash: [10, 5],
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBorderWidth: 0,
    });

    datasets.push({
      label: "X_bar",
      color: "success",
      data: Array(chart_labels.length).fill(stats.xDoubleBar),
      borderDash: [10, 5],
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBorderWidth: 0,
    });

    setGraphDatasets(datasets);
  }, [retrieveDoc, user, updateDoc]);

  const defaultLineChartData1 = {
    labels: graphLabels,
    datasets: graphDatasets,
  };

  useEffect(() => {
    try {
      initializeFieldValue();
    } catch (err) {}
  }, [initializeFieldValue]);

  return (
    <Card id="plot-ewma-chart" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox>
          <Grid item xs={12} md={12}>
            <DefaultLineChart
              icon={{ component: "auto_graph" }}
              title="EWMA chart"
              description=""
              chart={defaultLineChartData1}
            />
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

EWMAChart.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default EWMAChart;
