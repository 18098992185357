import { useMemo, useState, useEffect, useCallback } from "react";

// react-router components
import { Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import MDAvatar from "components/atoms/MDAvatar";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
// import MDBadge from "components/atoms/MDBadge";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import ListItemIcon from "@mui/material/ListItemIcon";
import Badge from "@mui/material/Badge";

import NotificationItem from "components/molecules/Items/NotificationItem";

// import NotificationsIcon from "@mui/icons-material/Notifications";
// import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LogoutIcon from "@mui/icons-material/Logout";
import RefreshIcon from "@mui/icons-material/Refresh";
import FindInPageIcon from "@mui/icons-material/FindInPage";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";

// Custom styles for Topnav2
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  // navbarMobileMenu,
} from "components/organisms/Topnav2/styles";

import {
  useMuiContext,
  // setTransparentNavbar,
  setMiniSidenav,
} from "context/MuiContext";

import { useSignout } from "pages/authentication/hooks/useSignout";

import { useCacheContext } from "context/CacheContext";

import logoImage from "assets/images/logo.png";

import moment from "moment-timezone";

function Topnav2({ title, absolute, light, isMini }) {
  const { user, roles } = useAuthContext();
  let { isLatestVersion, refreshCacheAndReload } = useCacheContext();
  const [navbarType, setNavbarType] = useState();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode, dispatch } =
    useMuiContext();
  const [openMenu, setOpenMenu] = useState(false);

  const navigate = useNavigate();
  const { signout } = useSignout();

  const { retrieveDoc } = useDocument();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    setAnchorEl(null);
    navigate("/account/profile");
  };
  const handleDashboard = () => {
    setAnchorEl(null);
    navigate("/");
  };

  const handleFlashcardRevision = () => {
    setAnchorEl(null);
    navigate("/flashcard/manage/revise");
  };

  const handleFlashcardDiscovery = () => {
    setAnchorEl(null);
    navigate("/flashcard/manage/discover");
  };

  const handleLogbookSubmission = () => {
    setAnchorEl(null);
    navigate("/logbook/manage/new");
  };

  const handleSignOut = async () => {
    setAnchorEl(null);
    await signout();
    navigate("/");
  };

  const defaultFlashcardsQueries = useMemo(
    () => ({
      whereQueries: [
        {
          field: "deletedAt",
          condition: "==",
          value: null,
        },
        {
          field: "createdBy",
          condition: "==",
          value: user.uid,
        },
        {
          field: "leechSuspended",
          condition: "==",
          value: false,
        },
        {
          field: "toBeRevisedAt",
          condition: "<",
          value: moment().tz("Asia/Singapore").toDate(),
        },
      ],
    }),
    [user.uid]
  );

  const [myflashcardsQueries, setMyflashcardsQueries] = useState(
    defaultFlashcardsQueries
  );

  const { collectionData: myflashcardsData } = useCollectionSnapshot(
    "myflashcards",
    myflashcardsQueries
  );

  const retrieveFlashcardList = useCallback(
    async (filter) => {
      try {
        const flashcardConfig = await retrieveDoc("flashcardconfigs", user.uid);

        const sortAscending = (a, b) => {
          const momentA = moment(a.data.toBeRevisedAt.toDate()).tz(
            "Asia/Singapore"
          );
          const momentB = moment(b.data.toBeRevisedAt.toDate()).tz(
            "Asia/Singapore"
          );
          if (momentA.isAfter(momentB)) {
            return 1;
          } else if (momentA.isBefore(momentB)) {
            return -1;
          } else {
            return 0;
          }
        };

        const sortDescending = (a, b) => {
          const momentA = moment(a.data.toBeRevisedAt.toDate()).tz(
            "Asia/Singapore"
          );
          const momentB = moment(b.data.toBeRevisedAt.toDate()).tz(
            "Asia/Singapore"
          );
          if (momentA.isAfter(momentB)) {
            return -1;
          } else if (momentA.isBefore(momentB)) {
            return 1;
          } else {
            return 0;
          }
        };

        if (myflashcardsData.length > 0) {
          const newCards = myflashcardsData
            .filter((item) => item.data.state === 0)
            .sort((a, b) => {
              return sortAscending(a, b);
            })
            .slice(0, flashcardConfig.data.newCardsPerDay);

          const learningRelearningCards = myflashcardsData
            .filter((item) => item.data.state === 1 || item.data.state === 3)
            .sort((a, b) => {
              return sortAscending(a, b);
            });

          const reviewCards = myflashcardsData
            .filter((item) => item.data.state === 2)
            .sort((a, b) => {
              return sortAscending(a, b);
            })
            .slice(0, flashcardConfig.data.maximumReviewPerDay);

          const longestDueDateReviewCard = reviewCards.find(
            (item) =>
              item.data.toBeRevisedAt.toDate().getMilliseconds() ===
              Math.max(
                ...reviewCards.map((card) =>
                  card.data.toBeRevisedAt.toDate().getMilliseconds()
                )
              )
          );

          const nonLongestDueDateReviewCard = reviewCards.filter(
            (item) =>
              item.data.toBeRevisedAt.toDate().getMilliseconds() !==
              Math.max(
                ...reviewCards.map((card) =>
                  card.data.toBeRevisedAt.toDate().getMilliseconds()
                )
              )
          );

          const sortedReviewCards =
            flashcardConfig.data.reviewSortOrder === "Due date, then random"
              ? [
                  longestDueDateReviewCard,
                  ...nonLongestDueDateReviewCard.sort(
                    () => 0.5 - Math.random()
                  ),
                ]
              : reviewCards.sort((a, b) => {
                  if (
                    flashcardConfig.data.reviewSortOrder ===
                    "Ascending intervals"
                  ) {
                    return sortAscending(a.b);
                  } else if (
                    flashcardConfig.data.reviewSortOrder ===
                    "Descending intervals"
                  ) {
                    return sortDescending(a, b);
                  } else {
                    return 0;
                  }
                });

          if (flashcardConfig.data.newReviewOrder === "Show after reviews") {
            if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show after reviews"
            ) {
              return [
                ...sortedReviewCards,
                ...learningRelearningCards,
                ...newCards,
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show before reviews"
            ) {
              return [
                ...learningRelearningCards,
                ...sortedReviewCards,
                ...newCards,
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Mixed with reviews"
            ) {
              return [
                ...reviewCards
                  .concat(learningRelearningCards)
                  .sort((a, b) => sortAscending(a, b)),
                ...newCards,
              ].filter((item) => !!item);
            }
          } else if (
            flashcardConfig.data.newReviewOrder === "Show before reviews"
          ) {
            if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show after reviews"
            ) {
              return [
                ...newCards,
                ...sortedReviewCards,
                ...learningRelearningCards,
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show before reviews"
            ) {
              return [
                ...newCards,
                ...learningRelearningCards,
                ...sortedReviewCards,
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Mixed with reviews"
            ) {
              return [
                ...newCards,
                ...reviewCards
                  .concat(learningRelearningCards)
                  .sort((a, b) => sortAscending(a, b)),
              ].filter((item) => !!item);
            }
          } else if (
            flashcardConfig.data.newReviewOrder === "Mixed with reviews"
          ) {
            if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show after reviews"
            ) {
              return [
                ...reviewCards
                  .concat(newCards)
                  .sort((a, b) => sortAscending(a, b)),
                ...learningRelearningCards,
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show before reviews"
            ) {
              return [
                ...learningRelearningCards,
                ...reviewCards
                  .concat(newCards)
                  .sort((a, b) => sortAscending(a, b)),
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Mixed with reviews"
            ) {
              return [
                ...reviewCards
                  .concat(newCards)
                  .concat(learningRelearningCards)
                  .sort((a, b) => sortAscending(a, b)),
              ].filter((item) => !!item);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    [myflashcardsData, retrieveDoc, user.uid]
  );

  const updateFilter = useCallback(async () => {
    try {
      let finalQueries = {
        whereQueries: [...defaultFlashcardsQueries.whereQueries],
      };

      const retrievedUser = await retrieveDoc("users", user.uid);
      const filter = {
        revisionFilterOperationLevel1:
          retrievedUser.data.revisionFilterOperationLevel1,
        revisionFilterOperationLevel2:
          retrievedUser.data.revisionFilterOperationLevel2,
      };

      if (
        filter.revisionFilterOperationLevel1 &&
        filter.revisionFilterOperationLevel1 !== "All"
      ) {
        finalQueries = {
          whereQueries: defaultFlashcardsQueries.whereQueries.concat([
            {
              field: "operationLevel1",
              condition: "==",
              value: filter.revisionFilterOperationLevel1,
            },
          ]),
        };
      }
      if (
        filter.revisionFilterOperationLevel2 &&
        filter.revisionFilterOperationLevel2 !== "All"
      ) {
        finalQueries = {
          whereQueries: finalQueries.whereQueries.concat([
            {
              field: "operationLevel1",
              condition: "==",
              value: filter.revisionFilterOperationLevel1,
            },
          ]),
        };
      }

      setMyflashcardsQueries(finalQueries);
    } catch (err) {
      console.error(err);
    }
  }, [defaultFlashcardsQueries, retrieveDoc, user.uid]);

  const [badgeCount, setBadgeCount] = useState("0");

  const updateBadge = useCallback(async () => {
    try {
      const flashcardList = await retrieveFlashcardList();
      if (flashcardList) {
        setBadgeCount(
          // flashcardList.filter((ele) => ele.data.state === 2).length.toString()
          flashcardList.length.toString()
        );
      }
    } catch (err) {
      console.error(err);
    }
  }, [retrieveFlashcardList]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    updateFilter();
    updateBadge();

    // A function that sets the transparent state of the navbar.
    // function handleTransparentNavbar() {
    //   setTransparentNavbar(
    //     dispatch,
    //     (fixedNavbar && window.scrollY === 0) || !fixedNavbar
    //   );
    // }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    // commented because causing too many rerender when scrolling
    //window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    //handleTransparentNavbar();

    // Remove event listener on cleanup
    //return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [fixedNavbar, updateBadge, updateFilter]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<Icon>groups</Icon>}
        title="Notification Message 1"
      />
      <NotificationItem
        icon={<Icon>question_answer</Icon>}
        title="Notification Message 2"
      />
      <NotificationItem
        icon={<Icon>currency_exchange</Icon>}
        title="Notification Message 3"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox display="flex" alignItems="center">
          {!roles?.includes("SAAS") && (
            <IconButton
              sx={navbarDesktopMenu}
              onClick={handleMiniSidenav}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
          )}
          <Tooltip title="GATE 121" key="gate121">
            <MDBox component={Link} to="/account/profile" lineHeight={1}>
              <MDBox
                bgColor="white"
                borderRadius="50%"
                component="img"
                src={logoImage}
                alt="Brand"
                width="2.5rem"
              />
            </MDBox>
          </Tooltip>
          {/* <Tooltip title="My Dashboard" key="mydashboard">
            <IconButton component={Link} to="/">
              <HomeIcon />
            </IconButton>
          </Tooltip> */}
        </MDBox>

        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <MDTypography
            fontWeight="bold"
            textTransform="capitalize"
            variant="h4"
            color={light ? "white" : "dark"}
            noWrap
          >
            {title}
          </MDTypography>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton> */}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <MDBadge badgeContent={3} color="error" size="xs" circular>
                  <NotificationsIcon sx={iconsStyle}></NotificationsIcon>
                </MDBadge>
              </IconButton> */}
              {!isLatestVersion && (
                <IconButton
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={refreshCacheAndReload}
                >
                  <RefreshIcon sx={iconsStyle}></RefreshIcon>
                </IconButton>
              )}

              <MDBox display="flex" gap="10px" alignItems="center">
                <MDBox display={{ xs: "none", lg: "flex" }}>
                  <Tooltip title="My Dashboard" key="mydashboard">
                    <IconButton component={Link} to="/">
                      <DashboardIcon sx={iconsStyle}></DashboardIcon>
                    </IconButton>
                  </Tooltip>
                </MDBox>
                <MDBox display={{ xs: "none", lg: "flex" }}>
                  <Tooltip title="Logbook Submission" key="logbooksubmission">
                    <IconButton component={Link} to="/logbook/manage/new">
                      <LibraryBooksIcon sx={iconsStyle}></LibraryBooksIcon>
                    </IconButton>
                  </Tooltip>
                </MDBox>
                <MDBox display={{ xs: "none", lg: "flex" }}>
                  <Tooltip title="Flashcard Revision" key="flashcardrevise">
                    <IconButton component={Link} to="/flashcard/manage/revise">
                      <Badge badgeContent={badgeCount} color="primary">
                        <LocalLibraryIcon sx={iconsStyle}></LocalLibraryIcon>
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </MDBox>
                <MDBox display={{ xs: "none", lg: "flex" }}>
                  <Tooltip title="Flashcard Discovery" key="flashcarddiscover">
                    <IconButton
                      component={Link}
                      to="/flashcard/manage/discover"
                    >
                      <FindInPageIcon sx={iconsStyle}></FindInPageIcon>
                    </IconButton>
                  </Tooltip>
                </MDBox>
                <MDBox key="avatar" display="block">
                  <MDAvatar bgColor="info" onClick={handleClick} size="sm">
                    {user.displayName?.charAt(0).toUpperCase()}
                  </MDAvatar>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuList dense>
                      <MenuItem onClick={handleProfile}>
                        <ListItemIcon>
                          <PersonIcon fontSize="medium"></PersonIcon>
                        </ListItemIcon>
                        <MDTypography variant="body2">Profile</MDTypography>
                      </MenuItem>
                      <MenuItem onClick={handleDashboard}>
                        <ListItemIcon>
                          <DashboardIcon fontSize="medium"></DashboardIcon>
                        </ListItemIcon>
                        <MDTypography variant="body2">
                          My Dashboard
                        </MDTypography>
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={handleLogbookSubmission}>
                        <ListItemIcon>
                          <LibraryBooksIcon fontSize="medium"></LibraryBooksIcon>
                        </ListItemIcon>
                        <MDTypography variant="body2">
                          Logbook Submission
                        </MDTypography>
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={handleFlashcardRevision}>
                        <ListItemIcon>
                          <LocalLibraryIcon fontSize="medium"></LocalLibraryIcon>
                        </ListItemIcon>
                        <MDTypography variant="body2">
                          Flashcard Revision
                        </MDTypography>
                      </MenuItem>
                      <MenuItem onClick={handleFlashcardDiscovery}>
                        <ListItemIcon>
                          <FindInPageIcon fontSize="medium"></FindInPageIcon>
                        </ListItemIcon>
                        <MDTypography variant="body2">
                          Flashcard Discovery
                        </MDTypography>
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={handleSignOut}>
                        <ListItemIcon>
                          <LogoutIcon fontSize="medium"></LogoutIcon>
                        </ListItemIcon>
                        <MDTypography variant="body2">Sign Out</MDTypography>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </MDBox>
              </MDBox>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of Topnav2
Topnav2.defaultProps = {
  title: "",
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the Topnav2
Topnav2.propTypes = {
  title: PropTypes.string,
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default Topnav2;
