const defaultFlashcardColumns = [
  { key: "flashcardFront", name: "Flashcard (Front)" },
  { key: "flashcardBack", name: "Flashcard (Back)" },
  { key: "operationLevel1", name: "Subspecialty" },
  { key: "operationLevel2", name: "Operation/Procedure" },
  { key: "toBeRevisedAt", name: "Due for Revision" },
  { key: "status", name: "Status" },
  { key: "actions", name: "Actions" },
];

const columnOptions = [
  { key: "canEdit", name: "canEdit" },
  { key: "canDelete", name: "canDelete" },
  { key: "id", name: "ID" },
  { key: "logbookId", name: "Logbook ID" },
  { key: "flashcardFrontImgAttachmentsURL", name: "Front Image Atth" },
  { key: "flashcardBackImgAttachmentsURL", name: "Back Image Atth" },
  { key: "flashcardFront", name: "Flashcard (Front)" },
  { key: "flashcardBack", name: "Flashcard (Back)" },
  { key: "operationLevel1", name: "Subspecialty" },
  { key: "operationLevel2", name: "Operation/Procedure" },
  { key: "toBeRevisedAt", name: "Due for Revision" },
  { key: "createdAtText", name: "Created At" },
  { key: "createdByText", name: "Created By" },
  { key: "modifiedAtText", name: "Modified At" },
  { key: "modifiedByText", name: "Modified By" },
  { key: "status", name: "Status" },
  { key: "deletedAtText", name: "Deleted At" },
  { key: "deletedByText", name: "Deleted By" },
  { key: "actions", name: "Actions" },
];

export { columnOptions, defaultFlashcardColumns };
