import * as Yup from "yup";
import form, {
  flashcardFrontImgAttachmentsForm,
  flashcardBackImgAttachmentsForm,
} from "pages/logbook/manage/schemas/form";
// import parse from "date-fns/parse";

const {
  formField: {
    caseId,
    date,
    durationHour,
    duration,
    residencyYear,
    role,
    site,
    supervisor,
    age,
    asa,
    emergency,
    operationLevel1,
    operationLevel2,
    priority,
    anaesthesiaMode,
    airwayManagement,
    additionalProcedures,
    disposition,
    notes,
    createFlashcard,
    flashcardFront,
    flashcardFrontImgAttachments,
    flashcardBack,
    flashcardBackImgAttachments,
  },
} = form;

const validFileExtensions = {
  image: ["jpg", "jpeg", "png"],
  document: ["doc", "docx"],
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [caseId.name]: Yup.string().required(caseId.errorMsg),
  [date.name]: Yup.date()
    // .transform((value, originalValue) => {
    //   return originalValue === null
    //     ? null
    //     : parse(originalValue, "yyyy-MM-dd", new Date());
    // })
    .required(date.errorMsg)
    .typeError(date.invalidMsg),
  [durationHour.name]: Yup.string().notRequired(),
  [duration.name]: Yup.string().required(duration.errorMsg),
  [residencyYear.name]: Yup.string().required(residencyYear.errorMsg),
  [role.name]: Yup.string().required(role.errorMsg),
  [site.name]: Yup.string().required(site.errorMsg),
  [supervisor.name]: Yup.string().required(supervisor.errorMsg),
  [age.name]: Yup.string().required(age.errorMsg),
  [asa.name]: Yup.string().required(asa.errorMsg),
  [emergency.name]: Yup.string().required(emergency.errorMsg),
  [operationLevel1.name]: Yup.string().required(operationLevel1.errorMsg),
  [operationLevel2.name]: Yup.string().notRequired(),
  [priority.name]: Yup.string().required(priority.errorMsg),
  [anaesthesiaMode.name]: Yup.array(),
  [airwayManagement.name]: Yup.array(),
  [additionalProcedures.name]: Yup.array(),
  [disposition.name]: Yup.array(),
  [notes.name]: Yup.string().notRequired(),
  [createFlashcard.name]: Yup.boolean(),
  [flashcardFront.name]: Yup.string(),
  // .required(flashcardFront.errorMsg),
  [flashcardFrontImgAttachments.name]: Yup.array()
    // .min(1, flashcardFrontImgAttachments.min1Msg)
    .max(20, flashcardFrontImgAttachments.max20Msg),
  [flashcardBack.name]: Yup.string(),
  // .required(flashcardBack.errorMsg),
  [flashcardBackImgAttachments.name]: Yup.array()
    // .min(1, flashcardBackImgAttachments.min1Msg)
    .max(20, flashcardBackImgAttachments.max20Msg),
});

const {
  formField: { flashcardFrontImgAttachmentFile },
} = flashcardFrontImgAttachmentsForm;

const flashcardFrontImgAttachmentsValidation = Yup.object().shape({
  [flashcardFrontImgAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(flashcardFrontImgAttachmentFile.errorMsg)
    .test("fileType", flashcardFrontImgAttachmentFile.invalidTypeMsg, (value) =>
      isValidFileType(value && value.name.toLowerCase(), "image")
    )
    .test(
      "fileSize",
      flashcardFrontImgAttachmentFile.invalidSizeMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { flashcardBackImgAttachmentFile },
} = flashcardBackImgAttachmentsForm;

const flashcardBackImgAttachmentsValidation = Yup.object().shape({
  [flashcardBackImgAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(flashcardBackImgAttachmentFile.errorMsg)
    .test("fileType", flashcardBackImgAttachmentFile.invalidTypeMsg, (value) =>
      isValidFileType(value && value.name.toLowerCase(), "image")
    )
    .test(
      "fileSize",
      flashcardBackImgAttachmentFile.invalidSizeMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

export {
  validations as default,
  noValidation,
  flashcardFrontImgAttachmentsValidation,
  flashcardBackImgAttachmentsValidation,
};
