import { useCallback, useState, useEffect } from "react";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useNavigate, useParams } from "react-router-dom";
//import { Link } from "react-router-dom";
import Card from "@mui/material/Card";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav2 from "components/organisms/Topnav2";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
//import SubmissionProgress from "components/organisms/SubmissionProgress";
//import SaveAltIcon from "@mui/icons-material/SaveAlt";

import DataTable from "components/molecules/Tables/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import { AllowedTo } from "react-abac";
import { useCseSurveyManager } from "pages/csesurveys/hooks/useCseSurveyManager";

function DataTables() {
  const { user } = useAuthContext();
  const { retrieveDoc } = useDocument();
  const navigate = useNavigate();
  const { mode } = useParams();

  const { modeTitle, modePermission, response } = useCseSurveyManager(mode);

  const columns = [
    { Header: "No", Footer: "No", accessor: "rowIdx", isVisible: false },
    {
      Header: "Submission Date",
      Footer: "Submission Date",
      accessor: "date",
      isVisible: false,
    },
    {
      Header: "Patient ID",
      Footer: "Patient ID",
      accessor: "patientID",
      isVisible: false,
    },
    {
      Header: "Venue",
      Footer: "Venue",
      accessor: "venue",
      isVisible: false,
    },
    {
      Header: "Q1",
      Footer: "Q1",
      accessor: "question1",
      isVisible: false,
    },
    {
      Header: "Q2",
      Footer: "Q2",
      accessor: "question2",
      isVisible: false,
    },
    {
      Header: "Q3",
      Footer: "Q3",
      accessor: "question3",
      isVisible: false,
    },
    {
      Header: "Q4",
      Footer: "Q4",
      accessor: "question4",
      isVisible: false,
    },
    {
      Header: "Action",
      Footer: "Action",
      accessor: "action",
      Cell: ({ cell }) => {
        return (
          <MDButton
            variant="gradient"
            color="success"
            iconOnly
            onClick={() => {
              navigate("/csesurveys/manage/edit/" + cell.row.values.rowIdx);
            }}
          >
            <EditIcon />
          </MDButton>
        );
      },
    },
    {
      Header: "Remark",
      Footer: "Remark",
      accessor: "remark",
      isVisible: false,
    },
  ];
  const [rows, setRows] = useState([]);

  const initializeFieldValue = useCallback(async () => {
    //const retrievedRecords = await retrieveDoc(user.uid, "records");
    const retrievedRecords = await retrieveDoc("records", user.uid);

    let cseRecords = [];
    if (retrievedRecords.data !== undefined) {
      cseRecords = retrievedRecords.data.cseRecords;

      const m_rows = [];
      let rowIdx = 1;
      for (let record of cseRecords) {
        const remark = record?.remark;

        m_rows.push({
          rowIdx: rowIdx,
          date: record["createdAt"],
          patientID: record["patientID"],
          venue: record["venue"],
          question1: record["question1"],
          question2: record["question2"],
          question3: record["question3"],
          question4: record["question4"],
          remark: remark,
        });
        rowIdx = rowIdx + 1;
      }

      setRows(m_rows);
    }
  }, [retrieveDoc, user]);

  useEffect(() => {
    try {
      initializeFieldValue();
    } catch (err) {}
  }, [initializeFieldValue]);

  return (
    <DashboardLayout>
      <Topnav2 title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      {response.error && (
        <>
          {response.error?.name === "OperationInvalidError" && (
            <InvalidOperation />
          )}
          <ErrorSnackbar
            title={modeTitle}
            dateTime=""
            message={response.error?.message}
            autoDismiss
          />
        </>
      )}
      {response.success && (
        <SuccessSnackbar
          title={modeTitle}
          dateTime=""
          message={response.success}
          autoDismiss
        />
      )}
      <MDBox pt={3} pb={3}>
        {response.error?.name !== "OperationInvalidError" && (
          <Card>
            <MDBox m={3}>
              <DataTable
                table={{
                  columns: columns,
                  rows: rows,
                }}
                canGlobalSearch
                canColumnSearch
                canColumnSort
              />
            </MDBox>
          </Card>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataTables;
