import { useState, useCallback, useEffect } from "react";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
//import FormField from "components/molecules/FormField";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";
//import FormControlLabel from "@mui/material/FormControlLabel";
//import { styled } from "@mui/material/styles";
//import Switch from "@mui/material/Switch";
import FormDatePicker from "components/molecules/Formik/FormDatePicker";
import FormField from "components/molecules/FormField";
import ToggleSwitch from "components/atoms/ToggleSwitch/ToggleSwitch";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import { venueOptions } from "pages/csesurveys/manage/schemas/setups";

function Questionnaire({ mainForm, mainFormField, modeDisabled }) {
  const {
    medicalID,
    patientID,
    submissionDate,
    venue,
    question1,
    question2,
    question3,
    question4,
    remark,
  } = mainFormField;

  const { values } = mainForm;
  const { user } = useAuthContext();
  const { retrieveDoc } = useDocument();
  const { mode } = useParams();

  // destructure from Attachments Form for current section
  let [answerQue1, setAnswerQue1] = useState(true);
  let [answerQue2, setAnswerQue2] = useState(true);
  let [answerQue3, setAnswerQue3] = useState(true);
  let [answerQue4, setAnswerQue4] = useState(true);
  let [modeDisabledDatePicker, setModeDatePicker] = useState(false);

  const answerQue1OnChange = (checked) => {
    setAnswerQue1(checked);
    checked === true
      ? (values["question1"] = "YES")
      : (values["question1"] = "NO");
  };
  const answerQue2OnChange = (checked) => {
    setAnswerQue2(checked);
    checked === true
      ? (values["question2"] = "YES")
      : (values["question2"] = "NO");
  };
  const answerQue3OnChange = (checked) => {
    setAnswerQue3(checked);
    checked === true
      ? (values["question3"] = "YES")
      : (values["question3"] = "NO");
  };
  const answerQue4OnChange = (checked) => {
    setAnswerQue4(checked);
    checked === true
      ? (values["question4"] = "YES")
      : (values["question4"] = "NO");
  };

  const initializeFieldValue = useCallback(async () => {
    const retrievedDoc = await retrieveDoc("users", user.uid);
    const medicalID = retrievedDoc.data.medicalID;
    values["medicalID"] = medicalID;

    if (values["question1"] === "NO") setAnswerQue1(false);
    if (values["question2"] === "NO") setAnswerQue2(false);
    if (values["question3"] === "NO") setAnswerQue3(false);
    if (values["question4"] === "NO") setAnswerQue4(false);
    if (mode === "edit") setModeDatePicker(true);

    //answerQue1 ? (values["question1"] = "YES") : (values["question1"] = "NO");
    //answerQue2 ? (values["question2"] = "YES") : (values["question2"] = "NO");
    //answerQue3 ? (values["question3"] = "YES") : (values["question3"] = "NO");
    //answerQue4 ? (values["question4"] = "YES") : (values["question4"] = "NO");
  }, [
    retrieveDoc,
    user,
    values,
    //answerQue1,
    //answerQue2,
    //answerQue3,
    //answerQue4,
    mode,
  ]);

  useEffect(() => {
    try {
      initializeFieldValue();
    } catch (err) {}
  }, [initializeFieldValue]);

  return (
    <Card id="cusum-questionnaire" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Cusum Questionnaire</MDTypography>
        </MDBox>
        <MDBox>
          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={6}>
              <FormDatePicker
                form={mainForm}
                field={submissionDate}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabledDatePicker}
              />
            </Grid>
          </Grid>

          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={10} sm={5}>
              <FormField
                type={medicalID.type}
                label={medicalID.label}
                name={medicalID.name}
                value={values.medicalID}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={venue}
                options={venueOptions}
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>

          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={10} sm={5}>
              <FormTextFieldFast
                form={mainForm}
                field={patientID}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={10} sm={10}>
              <MDTypography variant="body2" fontWeight="regular">
                {question1.label}
              </MDTypography>
            </Grid>
            <Grid item xs={2} sm={2}>
              <ToggleSwitch
                id="question1"
                checked={answerQue1}
                onChange={answerQue1OnChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={10} sm={10}>
              <MDTypography variant="body2" fontWeight="regular">
                {question2.label}
              </MDTypography>
            </Grid>
            <Grid item xs={2} sm={2}>
              <ToggleSwitch
                id="question2"
                checked={answerQue2}
                onChange={answerQue2OnChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={10} sm={10}>
              <MDTypography variant="body2" fontWeight="regular">
                {question3.label}
              </MDTypography>
            </Grid>
            <Grid item xs={2} sm={2}>
              <ToggleSwitch
                id="question3"
                checked={answerQue3}
                onChange={answerQue3OnChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={10} sm={10}>
              <MDTypography variant="body2" fontWeight="regular">
                {question4.label}
              </MDTypography>
            </Grid>
            <Grid item xs={2} sm={2}>
              <ToggleSwitch
                id="question4"
                checked={answerQue4}
                onChange={answerQue4OnChange}
              />
            </Grid>
          </Grid>

          <Grid container mt={2} mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={10} sm={10}>
              <FormTextFieldFast
                form={mainForm}
                field={remark}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Questionnaire.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default Questionnaire;
