import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";

import MDBox from "components/atoms/MDBox";

import { useMuiContext, setLayout } from "context/MuiContext";

function GenericTemplate({ background, children }) {
  const { dispatch } = useMuiContext();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [dispatch, pathname]);

  return (
    <MDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden" }}
    >
      {children}
    </MDBox>
  );
}

// Setting default values for the props for GenericTemplate
GenericTemplate.defaultProps = {
  background: "default",
};

// Typechecking props for the GenericTemplate
GenericTemplate.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default GenericTemplate;
