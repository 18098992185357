import { useNavigate, useParams } from "react-router-dom";
import { AllowedTo } from "react-abac";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav2 from "components/organisms/Topnav2";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import SubmissionProgress from "components/organisms/SubmissionProgress";
import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import Sidenav from "pages/logbook/manage/components/Sidenav";
import BasicInformation from "pages/logbook/manage/components/BasicInformation";
import AdvancedInformation from "pages/logbook/manage/components/AdvancedInformation";
import Notes from "pages/logbook/manage/components/Notes";
import Flashcard from "pages/logbook/manage/components/Flashcard";

import { useLogbookManager } from "pages/logbook/hooks/useLogbookManager";

import { Formik, Form } from "formik";
import form from "pages/logbook/manage/schemas/form";

function Logbook() {
  const { mode, logbookId, flashcardId } = useParams();

  const { formId, formField } = form;

  const {
    submitNew,
    submitEdit,
    submitDelete,
    response,
    modeTitle,
    modeValidation,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    dispatchError,
  } = useLogbookManager(mode, logbookId, flashcardId);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };
  /*const dispatchDismissAfter = (millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        dispatchDismiss();
      }, millisecond);
    });
  };*/

  const handleSubmit = async (values, actions) => {
    try {
      switch (mode) {
        case "new":
          await submitNew(values);
          actions.resetForm();
          break;
        case "edit":
          await submitEdit(values);
          await navigateAfter("/logbook/records", 3000);
          break;
        case "view":
          navigate("/logbook/records");
          break;
        case "delete":
          await submitDelete();
          await navigateAfter("/logbook/records", 3000);
          break;
        default:
          actions.setSubmitting(false);
          break;
      }
    } catch (err) {
      console.error(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <Topnav2 title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      {response.error && (
        <>
          {response.error?.name === "OperationInvalidError" && (
            <InvalidOperation />
          )}
          {response.error?.name === "OperationIncompleteError" && (
            <IncompleteOperation />
          )}
          <ErrorSnackbar
            title={modeTitle}
            dateTime=""
            message={response.error?.message}
            autoDismiss
          />
        </>
      )}
      {response.success && (
        <SuccessSnackbar
          title={modeTitle}
          dateTime=""
          message={response.success}
          autoDismiss
        />
      )}
      {response.isPending ? (
        <SubmissionProgress />
      ) : (
        <>
          <MDBox pt={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Sidenav />
              </Grid>
              <Grid item xs={12} lg={10}>
                <MDBox mb={3}>
                  <Formik
                    enableReinitialize
                    initialValues={response.data}
                    validationSchema={modeValidation}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form id={formId} autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <BasicInformation
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              mode={mode}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AdvancedInformation
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              mode={mode}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Notes
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              mode={mode}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Flashcard
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              mode={mode}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MDBox
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <MDBox></MDBox>
                              <MDButton
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                {modeSubmit}
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}

export default Logbook;
