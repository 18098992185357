import PropTypes from "prop-types";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { ErrorMessage, Field } from "formik";
import DebounceAutocompleteWrapper from "components/molecules/Formik/Wrapper/DebounceAutocompleteWrapper";

function FormAutocompleteFast({
  form,
  field,
  options,
  onChange,
  onInputChange,
  disabled,
  hideLabel,
  hideStatus,
  notTouchable,
  multiple,
  freeSolo,
  ...rest
}) {
  const { values, errors, touched, setFieldValue } = form;
  let value = null;
  if (multiple) {
    if (values[field.name] && values[field.name].length > 0) {
      value = values[field.name];
    } else {
      value = [];
    }
  } else {
    if (values[field.name]) {
      value = values[field.name];
    } else {
      value = null;
    }
  }

  const error = disabled
    ? false
    : !!errors[field.name] && (!!notTouchable || !!touched[field.name]);
  const success = disabled
    ? false
    : !!values[field.name] &&
      values[field.name].length > 0 &&
      !errors[field.name];
  const handleChange = (e, v) => {
    setFieldValue(field.name, v);
    onChange && onChange(e, v);
  };
  const handleInputChange = (e, value) => {
    if (!multiple && (freeSolo || options.includes(value))) {
      setFieldValue(field.name, value);
    }
    onInputChange && onInputChange(e, value);
  };

  return (
    <MDBox mb={2}>
      <Field
        name={field.name}
        type={field.type}
        label={hideLabel ? "" : field.label}
        value={value}
        options={options}
        onChange={handleChange}
        onInputChange={handleInputChange}
        as={DebounceAutocompleteWrapper}
        placeholder={field.placeholder}
        disabled={disabled}
        error={hideStatus ? false : error}
        success={hideStatus ? false : success}
        multiple={multiple}
        freeSolo={freeSolo}
        {...rest}
      />
      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          <ErrorMessage name={field.name}></ErrorMessage>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of FormAutocompleteFast
FormAutocompleteFast.defaultProps = {
  hideLabel: false,
  hideStatus: false,
  notTouchable: false,
};

// typechecking props for FormAutocompleteFast
FormAutocompleteFast.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  variant: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideStatus: PropTypes.bool,
  notTouchable: PropTypes.bool,
  children: PropTypes.node,
};

export default FormAutocompleteFast;
