import { useState } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav2 from "components/organisms/Topnav2";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AddIcon from "@mui/icons-material/Add";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

import DataTable from "components/molecules/Tables/DataTable";

import { Formik, Form } from "formik";
import FormCheckboxGroup from "components/molecules/Formik/FormCheckboxGroup";
import form from "pages/settings/users/manage/schemas/form";
import { columnOptions } from "pages/logbook/manage/schemas/setups";

import { useRecordsManager } from "pages/logbookstatistics/hooks/useRecordsManager";

import { AllowedTo } from "react-abac";
import { Permission } from "models/abac";

function DataTables() {
  const { userId } = useParams();

  const {
    columns,
    rows,
    hiddenColumns,
    handleExport,
    handleUserPreference,
    response,
    userData,
    isDataReady,
  } = useRecordsManager(userId);

  const [open, setOpen] = useState(false);

  const { formId, formField } = form;
  const { preferredLogbookColumns } = formField;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (values, actions) => {
    try {
      handleClose();
      await handleUserPreference(values);
    } catch (err) {
      // Unmount Formik
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <Topnav2 title="Logbook Records" />
      <AllowedTo
        perform={Permission.READ_ALL_LOGBOOKS}
        no={() => <PermissionDenied />}
      >
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title="Logbook Records"
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title="Logbook Records"
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}
            <MDBox pt={2} pb={2}>
              <Card>
                <MDBox m={3}>
                  {response.error?.name !== "OperationInvalidError" &&
                    !!userData && (
                      <DataTable
                        table={{
                          columns: columns,
                          rows: rows,
                        }}
                        hiddenColumns={hiddenColumns}
                        canGlobalSearch
                        canColumnSearch
                        canColumnSort
                        stickyFirstColumn
                        actionMenu={
                          <>
                            <MDBox
                              display="flex"
                              gap="10px"
                              alignItems="center"
                            >
                              <AllowedTo perform={Permission.CREATE_LOGBOOK}>
                                <Link to="/logbook/manage/new">
                                  <MDButton
                                    variant="gradient"
                                    color="success"
                                    iconOnly
                                  >
                                    <AddIcon />
                                  </MDButton>
                                </Link>
                              </AllowedTo>
                              <AllowedTo perform={Permission.EXPORT_LOGBOOKS}>
                                <MDButton
                                  variant="gradient"
                                  color="success"
                                  disabled={!isDataReady}
                                  onClick={() =>
                                    handleExport(Permission.EXPORT_LOGBOOKS)
                                  }
                                  iconOnly
                                >
                                  <SaveAltIcon />
                                </MDButton>
                              </AllowedTo>
                            </MDBox>
                            <MDBox>
                              <MDButton
                                variant="gradient"
                                color="light"
                                iconOnly
                                disabled={!isDataReady}
                                onClick={handleClickOpen}
                              >
                                <ViewColumnIcon />
                              </MDButton>
                            </MDBox>
                          </>
                        }
                      />
                    )}
                </MDBox>
              </Card>
            </MDBox>

            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Show/Hide Columns</DialogTitle>
              <DialogContent>
                {!!userData && (
                  <Formik
                    enableReinitialize
                    initialValues={userData.data}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form id={formId} autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <FormCheckboxGroup
                              showTitle={false}
                              form={formik}
                              field={preferredLogbookColumns}
                              options={columnOptions}
                              hiddenOptions={[
                                "canEdit",
                                "canDelete",
                                "id",
                                "flashcardId",
                              ]}
                              nameProperty="name"
                              valueProperty="key"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MDBox
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <MDButton
                                disabled={formik.isSubmitting}
                                variant="gradient"
                                color="dark"
                                onClick={handleClose}
                              >
                                Cancel
                              </MDButton>
                              <MDButton
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                Save
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                )}
              </DialogContent>
            </Dialog>
            <Footer />
          </>
        )}
      </AllowedTo>
    </DashboardLayout>
  );
}

export default DataTables;
