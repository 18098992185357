import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import FormTextField from "components/molecules/Formik/FormTextField";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

function Notes({
  mainForm,
  mainFormField,
  mode,
  modeDisabled,
  dispatchMainError,
}) {
  // destructure from Main Form for entire Question
  const { values: mainFormValues } = mainForm;
  const { notes } = mainFormField;

  return (
    <Card id="logbook-notes" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox
          variant="gradient"
          bgColor="error"
          color="white"
          //coloredShadow="red"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="15rem"
          height="2.5rem"
          mt={-4}
        >
          <MDBox>
            <MDTypography variant="body2" color="white" fontWeight="medium">
              NOTES
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox mt={1} ml={2}>
          <Grid container p={1} alignItems="center" rowSpacing={2}>
            {mainFormValues.notes !== null && (
              <>
                <Grid item xs={12}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {notes.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={11}>
                  <FormTextField
                    label="Enter free text here"
                    multiline
                    rows={4}
                    form={mainForm}
                    field={notes}
                    variant="outlined"
                    disabled={modeDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={1}></Grid>
              </>
            )}
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Notes;
