const form = {
  formId: "cusum-variables-form",
  formField: {
    acceptableFailureRate: {
      name: "acceptableFailureRate",
      label: "Acceptable Failure Rate, f0 [ 0.0 - 1.0 ]",
      type: "text",
      errorMsg: "Acceptable Failure Rate is required.",
      invalidMsg: "Acceptable Failure Rate is invalid.",
    },
    unacceptableFailureRate: {
      name: "unacceptableFailureRate",
      label: "Unacceptable Failure Rate, f1 [ 0.0 - 1.0 ]",
      type: "text",
      errorMsg: "Unacceptable Failure Rate is required.",
      invalidMsg: "Unacceptable Failure Rate is invalid.",
    },
    type1Error: {
      name: "type1Error",
      label: "Type 1 Error, alpha [ 0.0 - 1.0 ]",
      type: "text",
      errorMsg: "Type 1 Error is required.",
      invalidMsg: "Type 1 Error is invalid.",
    },
    type2Error: {
      name: "type2Error",
      label: "Type 2 Error, beta [ 0.0 - 1.0 ]",
      type: "text",
      errorMsg: "Type 2 Error is required.",
      invalidMsg: "Type 2 Error is invalid.",
    },
    param_P: {
      name: "param_P",
      label: "Param P",
      type: "text",
      errorMsg: "Param P is required.",
      invalidMsg: "Param P is invalid.",
    },
    param_Q: {
      name: "param_Q",
      label: "Param Q",
      type: "text",
      errorMsg: "Param Q is required.",
      invalidMsg: "Param Q is invalid.",
    },
    param_a: {
      name: "param_a",
      label: "Param a",
      type: "text",
      errorMsg: "Param a is required.",
      invalidMsg: "Param a is invalid.",
    },
    param_b: {
      name: "param_b",
      label: "Param b",
      type: "text",
      errorMsg: "Param b is required.",
      invalidMsg: "Param b is invalid.",
    },
    lowerDecisionLimit: {
      name: "lowerDecisionLimit",
      label: "Lower Decision Limit, h0",
      type: "text",
      errorMsg: "Lower Decision Limit is required.",
      invalidMsg: "Lower Decision Limit is invalid.",
    },
    upperDecisionLimit: {
      name: "upperDecisionLimit",
      label: "Upper Decision Limit, h1",
      type: "text",
      errorMsg: "Upper Decision Limit is required.",
      invalidMsg: "Upper Decision Limit is invalid.",
    },
  },
};

export { form as default };
