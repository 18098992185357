const form = {
  formId: "flashcard-variables-form",
  formField: {
    autoShare: {
      name: "autoShare",
      label: "Auto share flashcard",
      tooltip: "Flashcards are auto shared when created. Untick to opt-out.",
      type: "box",
      errorMsg: "Auto share flashcard is required.",
      invalidMsg: "Auto share flashcard is invalid.",
    },
    newCardsPerDay: {
      name: "newCardsPerDay",
      label: "New cards/day",
      tooltip:
        "Controls how many new cards are introduced each day you open the program. If you study fewer than the limit, or miss a day, the next day the counts will be back to your limit - they do not accumulate.",
      type: "text",
      errorMsg: "New cards/day is required.",
      invalidMsg: "New cards/day is invalid.",
    },
    maximumReviewPerDay: {
      name: "maximumReviewPerDay",
      label: "Maximum review/day",
      tooltip:
        "Allows you to set an upper limit on the number of reviews to show each day.",
      type: "text",
      errorMsg: "Maximum review/day is required.",
      invalidMsg: "Maximum review/day is invalid.",
    },
    learningSteps: {
      name: "learningSteps",
      label: "Learning steps",
      tooltip:
        "Controls the number of learning repetitions, and the delay between them. One or more delays, separated by spaces must be entered. Each time you press Good during review, the card moves to the next step.",
      type: "text",
      errorMsg: "Learning steps is required.",
      invalidMsg: "Learning steps is invalid.",
    },
    graduatingInterval: {
      name: "graduatingInterval",
      label: "Graduating interval (days)",
      tooltip:
        "The delay in days between answering Good on a learning card with no steps left, and seeing the card again as a review card.",
      type: "text",
      errorMsg: "Graduating interval is required.",
      invalidMsg: "Graduating interval is invalid.",
    },
    easyInterval: {
      name: "easyInterval",
      label: "Easy interval (days)",
      tooltip:
        "The delay between answering Easy on a learning card, and seeing it in review mode for the first time.",
      type: "text",
      errorMsg: "Easy interval is required.",
      invalidMsg: "Easy interval is invalid.",
    },
    relearningSteps: {
      name: "relearningSteps",
      label: "Relearning steps",
      tooltip:
        "The same as 'learning steps', but for forgotten reviews. When you fail a card (press Again), the card enters the relearning phase, and before it becomes a review card again, you will have to pass all the relearning steps — or, alternatively, press Easy on the card.",
      type: "text",
      errorMsg: "Relearning steps is required.",
      invalidMsg: "Relearning steps is invalid.",
    },
    minimumInterval: {
      name: "minimumInterval",
      label: "Minimum interval (days)",
      tooltip:
        "Specifies a minimum number of days a card should wait after it finishes relearning.",
      type: "text",
      errorMsg: "Minimum interval is required.",
      invalidMsg: "Minimum interval is invalid.",
    },
    leechThreshold: {
      name: "leechThreshold",
      label: "Leech threshold",
      tooltip:
        "Each time a review card is failed (press Again), a counter increases. When this counter reaches the threshold, system tags the note as a leech or suspends the card.",
      type: "text",
      errorMsg: "Leech threshold is required.",
      invalidMsg: "Leech threshold is invalid.",
    },
    leechAction: {
      name: "leechAction",
      label: "Leech action",
      tooltip:
        "When this counter reaches the leecch threshold, system tags the note as a leech or suspends the card.",
      type: "text",
      errorMsg: "Leech action is required.",
      invalidMsg: "Leech action is invalid.",
    },
    newReviewOrder: {
      name: "newReviewOrder",
      label: "New/review order",
      tooltip:
        "Whether new cards should be mixed in with reviews, or shown before or after them.",
      type: "text",
      errorMsg: "New/review order is required.",
      invalidMsg: "New/review order is invalid.",
    },
    interdayLearningReviewOrder: {
      name: "interdayLearningReviewOrder",
      label: "Interday learning/review order",
      tooltip:
        "Whether learning cards should be mixed in with reviews, or shown before or after them.",
      type: "text",
      errorMsg: "Interday learning/review order is required.",
      invalidMsg: "Interday learning/review order is invalid.",
    },
    reviewSortOrder: {
      name: "reviewSortOrder",
      label: "Review sort order",
      tooltip:
        "Controls how review cards are sorted while reviewing. Due date, then random: Prioritizes cards that have been waiting longer. Ascending intervals: This will ensure cards with shorter intervals are shown first. Ascending intervals: This will ensure cards with shorter intervals are shown first. Descending intervals: This will ensure cards with larger intervals are shown first.",
      type: "text",
      errorMsg: "Review sort order is required.",
      invalidMsg: "Review sort order is invalid.",
    },
    maximumInterval: {
      name: "maximumInterval",
      label: "Maximum interval (days)",
      tooltip: "Upper limit on the time system will wait to reshow a card.",
      type: "text",
      errorMsg: "Maximum interval is required.",
      invalidMsg: "Maximum interval is invalid.",
    },
    startingEase: {
      name: "startingEase",
      label: "Starting ease",
      tooltip:
        "Controls the easiness that cards start out with. It is set when a card graduates from learning for the first time. It defaults to 2.50, meaning that once you have finished learning a card, answering Good on subsequent reviews will increase the delay by approximately 2.5x (e.g. if the last delay was 10 days, the next delay would be around 25 days). Based upon how you rate the card in subsequent reviews, the easiness may increase or decrease from its starting value.",
      type: "text",
      errorMsg: "Starting ease is required.",
      invalidMsg: "Starting ease is invalid.",
    },
    easyBonus: {
      name: "easyBonus",
      label: "Easy bonus",
      tooltip:
        "An extra multiplier applied to the interval when a review card is answered Easy. With the default value of 1.30, Easy will give an interval that is 1.3 times the Good interval (e.g. if the Good interval was 10 days, the Easy interval would be around 13 days).",
      type: "text",
      errorMsg: "Easy bonus is required.",
      invalidMsg: "Easy bonus is invalid.",
    },
    hardInterval: {
      name: "hardInterval",
      label: "Hard interval",
      tooltip:
        "The multiplier used when you use the Hard button. The percentage is relative to the previous interval: e.g. with a default of 1.20, a card with a 10-day interval will be given 12 days.",
      type: "text",
      errorMsg: "Hard interval is required.",
      invalidMsg: "Hard interval is invalid.",
    },
    newInterval: {
      name: "newInterval",
      label: "New interval",
      tooltip:
        "The multiplier used when you use the Again button on a review card. 0.00 means that a review card's delay is reset to zero and apply the minimum interval. Any value larger than 0.0 allows to preserve the card's delay, for example, if a card had a 100 day interval, and you set the New Interval to 0.20, the new interval would be 20 days.",
      type: "text",
      errorMsg: "New interval is required.",
      invalidMsg: "New interval is invalid.",
    },
  },
};

export { form as default };
