import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormAutocomplete from "components/molecules/Formik/FormAutocomplete";
import FormCheckbox from "components/molecules/Formik/FormCheckbox";
import FormCheckboxGroup from "components/molecules/Formik/FormCheckboxGroup";

import {
  operationConfigs,
  asaOptions,
  priorityOptions,
  anaesthesiaModeList,
  airwayManagementList,
  additionalProceduresList,
  dispositionList,
} from "schema/setups";

function AdvancedInformation({ mainForm, mainFormField, modeDisabled, mode }) {
  const { values } = mainForm;

  const {
    asa,
    emergency,
    operationLevel1,
    operationLevel2,
    priority,
    anaesthesiaMode,
    airwayManagement,
    additionalProcedures,
    disposition,
  } = mainFormField;

  const operationLevel1Opts = operationConfigs.map((operation) => {
    return operation.name;
  });

  const [operationLevel2Opts, setOperationLevel2Opts] = useState([]);

  useEffect(() => {
    try {
      if (values.operationLevel1) {
        const operationConfig = operationConfigs.find(
          (config) => config.name === values.operationLevel1
        );
        !!operationConfig &&
          setOperationLevel2Opts(
            operationConfig.children.map((obj) => obj.name)
          );
      }
    } catch (err) {
      console.error(err);
    }
  }, [mode, values]);

  return (
    <Card id="logbook-details" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox
          variant="gradient"
          bgColor="info"
          color="white"
          //coloredShadow="red"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="15rem"
          height="2.5rem"
          mt={-4}
        >
          <MDBox>
            <MDTypography variant="body2" color="white" fontWeight="medium">
              DETAILED INFORMATION
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox mt={1}>
          <Grid container p={1} rowSpacing={1}>
            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {asa.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormAutocomplete
                    form={mainForm}
                    field={asa}
                    label="Select ASA Category"
                    size="small"
                    options={asaOptions}
                    disabled={modeDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormCheckbox
                    size="small"
                    form={mainForm}
                    field={emergency}
                    disabled={modeDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {operationLevel1.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormAutocomplete
                    form={mainForm}
                    field={operationLevel1}
                    label="Select Operation/Procedure"
                    size="small"
                    options={operationLevel1Opts}
                    disabled={modeDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormAutocomplete
                    form={mainForm}
                    field={operationLevel2}
                    label="Select Operation/Procedure"
                    size="small"
                    options={operationLevel2Opts}
                    disabled={modeDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {priority.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormAutocomplete
                    form={mainForm}
                    field={priority}
                    label="Select Priority"
                    size="small"
                    options={priorityOptions}
                    disabled={modeDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {anaesthesiaMode.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <MDBox
                    borderRadius="lg"
                    sx={{ p: 2, border: "1px solid silver" }}
                  >
                    <FormCheckboxGroup
                      showTitle={false}
                      form={mainForm}
                      field={anaesthesiaMode}
                      formfield={mainFormField}
                      options={anaesthesiaModeList}
                      disabled={modeDisabled}
                      nameProperty="name"
                      valueProperty="id"
                      childRow={true}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {airwayManagement.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <MDBox
                    borderRadius="lg"
                    sx={{ p: 2, border: "1px solid silver" }}
                  >
                    <FormCheckboxGroup
                      showTitle={false}
                      form={mainForm}
                      field={airwayManagement}
                      formfield={mainFormField}
                      options={airwayManagementList}
                      disabled={modeDisabled}
                      nameProperty="name"
                      valueProperty="id"
                      childRow={true}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {additionalProcedures.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <MDBox
                    borderRadius="lg"
                    sx={{ p: 2, border: "1px solid silver" }}
                  >
                    <FormCheckboxGroup
                      showTitle={false}
                      form={mainForm}
                      field={additionalProcedures}
                      formfield={mainFormField}
                      options={additionalProceduresList}
                      disabled={modeDisabled}
                      nameProperty="name"
                      valueProperty="id"
                      row
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {disposition.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <MDBox
                    borderRadius="lg"
                    sx={{ p: 2, border: "1px solid silver" }}
                  >
                    <FormCheckboxGroup
                      showTitle={false}
                      form={mainForm}
                      field={disposition}
                      formfield={mainFormField}
                      options={dispositionList}
                      disabled={modeDisabled}
                      nameProperty="name"
                      valueProperty="id"
                      row
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default AdvancedInformation;
