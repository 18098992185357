import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";

import { createNewUser, setRolesOfUser } from "services/authentication";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import initialValues from "pages/settings/user/new/schemas/initialValues";
import validations from "pages/settings/user/new/schemas/validations";

import { defaultLogbookColumns } from "pages/logbook/manage/schemas/setups";
import { defaultFlashcardColumns } from "pages/flashcard/manage/schemas/setups";

import configInitialValues from "pages/settings/flashcard/manage/schemas/initialValues";

const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "CREATED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully created the user, ${action.payload.displayName}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useSettingsManager = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const { user } = useAuthContext();

  const { userHasPermissions } = useAbac();

  const { createDoc } = useDocument();

  const { pathname } = useLocation();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        ![
          "PermissionDeniedError",
          "OperationInvalidError",
          "CustomerInvalidError",
        ].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  // TODO: Refactor to DAO Layer
  const toPersistenceValue = useCallback(
    (document) => {
      try {
        if (document.signatureAttachments) {
          document.signatureAttachments = document.signatureAttachments.map(
            (element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            }
          );
        }

        return document;
      } catch (err) {
        console.error(err);
        dispatchError(err);
      }
    },
    [dispatchError]
  );

  // TODO: Refactor to DAO Layer
  const toPresentationValue = useCallback(
    (data) => {
      try {
        // nothing to convert at this moment

        return data;
      } catch (err) {
        console.error(err);
        dispatchError(err);
      }
    },
    [dispatchError]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      if (!pathname.includes("/settings/user")) {
        throw operationInvalidError;
      }

      dispatchIfNotUnmounted({
        type: "INITIAL_USER",
        payload: initialValues,
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchIfNotUnmounted, pathname, dispatchError]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "New User";
  let modeSubmit = "Create";
  let modeFieldDisabled = false;
  let modePermission = Permission.VIEW_ADMIN_DASHBOARD;
  let modeValidation = validations;

  const submitNew = async (values, continueURL) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (
        userHasPermissions([
          Permission.PERFORM_ADMIN_ACTION,
          Permission.CREATE_USER,
        ])
      ) {
        values = toPersistenceValue(values);

        const result = await createNewUser({
          email: values.email,
          password: values.password,
          emailVerified: true,
          displayName: values.displayName,
          photoURL: values.photoURL,
        });

        const userRecord = result.data;
        const userId = userRecord.uid;

        await setRolesOfUser({
          roles: [...values.roles, "MEMBER"],
          userId: userId,
        });

        // Create new user under "users" collection
        const createdDoc = await createDoc(
          "users",
          {
            email: values.email,
            designation: values.designation,
            displayName: values.displayName,
            medicalID: values.medicalID,
            roles: [...values.roles, "MEMBER"],
            root: false,
            system: false,
            preferredLogbookColumns: defaultLogbookColumns,
            preferredFlashcardColumns: defaultFlashcardColumns,
          },
          user.uid,
          userId
        );

        // Create "userId" collection & "records" document
        /*await createDoc(
          userId,
          {
            cseRecords: [],
          },
          user.uid,
          "records"
        );*/
        await createDoc(
          "records",
          {
            cseRecords: [],
          },
          user.uid,
          userId
        );

        // Create default flashcard configs for user
        await createDoc(
          "flashcardconfigs",
          configInitialValues,
          user.uid,
          userId
        );

        dispatchIfNotUnmounted({
          type: "CREATED_USER",
          payload: toPresentationValue(createdDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to update user."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitNew,
    response,
    dispatchDismiss,
    dispatchError,
  };
};
