import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import { operationConfigs } from "schema/setups";

function Tag({ mainForm, mainFormField, modeDisabled, mode }) {
  const { values } = mainForm;

  const { operationLevel1, operationLevel2 } = mainFormField;

  const operationLevel1Opts = operationConfigs.map((operation) => {
    return operation.name;
  });

  const [operationLevel2Opts, setOperationLevel2Opts] = useState([]);

  useEffect(() => {
    try {
      if (values.operationLevel1) {
        const operationConfig = operationConfigs.find(
          (config) => config.name === values.operationLevel1
        );
        !!operationConfig &&
          setOperationLevel2Opts(
            operationConfig.children.map((obj) => obj.name)
          );
      }
    } catch (err) {
      console.error(err);
    }
  }, [mode, values]);

  return (
    <Card id="flashcard-tag" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox
          variant="gradient"
          bgColor="info"
          color="white"
          //coloredShadow="red"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="15rem"
          height="2.5rem"
          mt={-4}
        >
          <MDBox>
            <MDTypography variant="body2" color="white" fontWeight="medium">
              TAGGING
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox mt={1} ml={2}>
          <Grid container p={1} spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="body3" fontWeight="bold">
                {operationLevel1.label}
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              <FormAutocompleteFast
                form={mainForm}
                field={operationLevel1}
                size="small"
                options={operationLevel1Opts}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormAutocompleteFast
                form={mainForm}
                field={operationLevel2}
                size="small"
                options={operationLevel2Opts}
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Tag;
