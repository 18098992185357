import { useEffect, useState, useReducer, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";

import { useLogbookActionManager } from "pages/logbook/hooks/useLogbookActionManager";

import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { AllowedTo, useAbac } from "react-abac";
import { Permission } from "models/abac";

import {
  columnOptions,
  defaultLogbookColumns,
} from "pages/logbook/manage/schemas/setups";

import { parse } from "json2csv";

import {
  anaesthesiaModeList,
  airwayManagementList,
  additionalProceduresList,
  dispositionList,
} from "schema/setups";

const flatAnaesthesiaModeList = anaesthesiaModeList.flatMap((option) => {
  if (option.children && option.children.length > 0) {
    return [option, ...option.children];
  } else {
    return option;
  }
});

const flatAirwayManagementList = airwayManagementList.flatMap((option) => {
  if (option.children && option.children.length > 0) {
    return [option, ...option.children];
  } else {
    return option;
  }
});

const flatAdditionalProceduresList = additionalProceduresList.flatMap(
  (option) => {
    if (option.children && option.children.length > 0) {
      return [option, ...option.children];
    } else {
      return option;
    }
  }
);

const flatDispositionList = dispositionList.flatMap((option) => {
  if (option.children && option.children.length > 0) {
    return [option, ...option.children];
  } else {
    return option;
  }
});

let initialState = {
  isPending: false,
  data: null,
  success: null,
  error: null,
};

const schemaReducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return initialState;
    case "IS_PENDING":
      return {
        isPending: true,
        data: null,
        success: null,
        error: null,
      };
    case "EXPORTED_LOGBOOKS":
      return {
        isPending: false,
        data: null,
        success: `Successfully exported the logbooks.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: null,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useRecordsManager = () => {
  const [response, dispatch] = useReducer(schemaReducer, initialState);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [isChanged, setIsChanged] = useState(0);

  const { user } = useAuthContext();
  const { userHasPermissions } = useAbac();

  const { getActionState } = useLogbookActionManager();
  const { retrieveDoc, updateDoc, serverTimestamp } = useDocument();

  const logbooksQueries = {
    whereQueries: [
      {
        field: "deletedAt",
        condition: "==",
        value: null,
      },
      {
        field: "createdBy",
        condition: "==",
        value: user.uid,
      },
    ],
    // orderByQueries: [
    //   {
    //     field: "createdAt",
    //     direction: "desc",
    //   },
    // ],
  };

  const usersQueries = {
    whereQueries: [
      {
        field: "deletedAt",
        condition: "==",
        value: null,
      },
    ],
  };

  const { collectionData: logbooksData } = useCollectionSnapshot(
    "logbooks",
    logbooksQueries
  );

  const { collectionData: usersData } = useCollectionSnapshot(
    "users",
    usersQueries
  );

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        ![
          "PermissionDeniedError",
          "OperationInvalidError",
          "CustomerInvalidError",
        ].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const navigate = useNavigate();

  const toPresentationValue = (data) => {
    try {
      const toDateString = (value) => {
        return value ? value.toDate().toLocaleDateString("en-Sg") : "-";
      };

      const toDisplayName = (userId) => {
        const user = usersData.find((document) => document.id === userId);
        return userId ? (user ? user.data.displayName : "Unknown") : "-";
      };

      data.dateText = toDateString(data.date);
      data.ageText = data.age + " " + data.ageUnit;

      data.anaesthesiaModeText = data.anaesthesiaMode?.toString() ?? "-";
      data.airwayManagementText = data.airwayManagement?.toString() ?? "-";
      data.additionalProceduresText =
        data.additionalProcedures?.toString() ?? "-";
      data.dispositionText = data.disposition?.toString() ?? "-";

      data.createdAtText = toDateString(data.createdAt);
      data.modifiedAtText = toDateString(data.modifiedAt);
      data.deletedAtText = toDateString(data.deletedAt);
      data.createdByText = toDisplayName(data.createdBy);
      data.modifiedByText = toDisplayName(data.modifiedBy);
      data.deletedByText = toDisplayName(data.deletedBy);

      data.durationhm =
        parseInt(data.durationHour ? data.durationHour : "0") * 60 +
        parseInt(data.duration);

      const { latestAction, canEdit, canDelete } = getActionState(data);

      data.canEdit = canEdit;
      data.canDelete = canDelete;

      switch (latestAction.name) {
        case "created":
          data.status = "Created";
          break;
        case "modified":
          data.status = "Modified";
          break;
        case "deleted":
          data.status = "Deleted";
          break;
        default:
          data.status = "-";
      }

      return data;
    } catch (err) {
      dispatchError(err);
    }
  };

  const toExportValue = (data) => {
    try {
      const toDateString = (value) => {
        return value ? value.toDate().toLocaleDateString("en-Sg") : "-";
      };

      const toDisplayName = (userId) => {
        const user = usersData.find((document) => document.id === userId);
        return userId ? (user ? user.data.displayName : "Unknown") : "-";
      };

      return {
        ProcedureDate: toDateString(data.date),
        Resident: toDisplayName(data.createdBy),
        Procedure: data.operationLevel2,
        Role: data.role,
        Diagnosis: "-",
        Supervisor: data.supervisor,
        Confirmation: "-",
        ConfirmationNotes: "-",
        Location: data.site,
        EncounterID: data.caseId,
        PatientGender: "-",
        PatientAge: data.age + " " + data.ageUnit,
        DateRecorded: toDateString(data.modifiedAt),
        Complications: "-",
        Notes: data.notes,
      };
    } catch (err) {
      dispatchError(err);
    }
  };

  const setupTable = useCallback(async () => {
    const userData = user && (await retrieveDoc("users", user.uid));

    const defaultLogbookColumnsKey = defaultLogbookColumns.map(
      (elem) => elem.key
    );

    const preferredLogbookColumnsKey =
      userData.data?.preferredLogbookColumns?.map((elem) => elem.key) ??
      defaultLogbookColumnsKey;

    const selectedColumns =
      preferredLogbookColumnsKey ?? defaultLogbookColumnsKey;
    const allColumnsKey = columnOptions.map((elem) => elem.key);
    const unselectedColumns = allColumnsKey.filter(
      (columnKey) => !selectedColumns.includes(columnKey)
    );

    setUserData(userData);
    setHiddenColumns(unselectedColumns);
    setIsDataReady(true);
  }, [retrieveDoc, user]);

  useEffect(() => {
    try {
      setupTable();
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, setupTable, isChanged]);

  const handleUserPreference = async (newdata) => {
    try {
      const newPreference = newdata.preferredLogbookColumns.filter(
        (column) => !!column
      );

      await updateDoc("users", user.uid, {
        preferredLogbookColumns: newPreference,
        modifiedAt: serverTimestamp(),
        modifiedBy: user.uid,
      });

      setIsChanged(isChanged + 1);
    } catch (err) {
      dispatchError(err);
    }
  };

  const handleExport = async (permission) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(permission)) {
        const fields = [
          "ProcedureDate",
          "Resident",
          "Procedure",
          "Role",
          "Diagnosis",
          "Supervisor",
          "Confirmation",
          "ConfirmationNotes",
          "Location",
          "EncounterID",
          "PatientGender",
          "PatientAge",
          "DateRecorded",
          "Complications",
          "Notes",
        ];
        const opts = { fields };

        const data =
          logbooksData &&
          logbooksData.map((logbook) => toExportValue(logbook.data));

        try {
          const csv = parse(data, opts);
          let csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
            csv
          )}`;
          const encodedUri = csvContent;
          window.open(encodedUri);
        } catch (err) {
          console.error(err);
        }
        dispatchIfNotUnmounted({
          type: "EXPORTED_LOGBOOKS",
          payload: null,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to export logbooks."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const rows =
    logbooksData &&
    logbooksData.map((logbook) => {
      return toPresentationValue({ id: logbook.id, ...logbook.data });
    });

  const tooltipCell = (accessor) => {
    return (
      <Tooltip title={accessor ?? "-"}>
        <MDBox>
          {accessor ? accessor.substring(0, 30) : "-"}
          {accessor && accessor.length > 30 ? "..." : ""}
        </MDBox>
      </Tooltip>
    );
  };

  const columns = [
    {
      Header: "canEdit",
      Footer: "canEdit",
      accessor: "canEdit",
    },
    {
      Header: "canDelete",
      Footer: "canDelete",
      accessor: "canDelete",
    },
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
    },
    {
      Header: "FlashcardId",
      Footer: "FlashcardId",
      accessor: "flashcardId",
    },
    {
      Header: "Case ID",
      Footer: "Case ID",
      accessor: "caseId",
    },
    {
      Header: "Date",
      Footer: "Date",
      accessor: "dateText",
    },
    {
      Header: "Duration (mins)",
      Footer: "Duration (mins)",
      accessor: "durationhm",
    },
    {
      Header: "Year of Residency",
      Footer: "Year of Residency",
      accessor: "residencyYear",
    },
    {
      Header: "Role",
      Footer: "Role",
      accessor: "role",
    },
    {
      Header: "Site",
      Footer: "Site",
      accessor: "site",
    },
    {
      Header: "Supervisor",
      Footer: "Supervisor",
      accessor: "supervisor",
    },
    {
      Header: "Age",
      Footer: "Age",
      accessor: "ageText",
    },
    {
      Header: "ASA",
      Footer: "ASA",
      accessor: "asa",
    },
    {
      Header: "Emergency",
      Footer: "Emergency",
      accessor: "emergency",
    },
    {
      Header: "Subspecialty",
      Footer: "Subspecialty",
      accessor: "operationLevel1",
    },
    {
      Header: "Operation/Procedure",
      Footer: "Operation/Procedure",
      accessor: "operationLevel2",
    },
    {
      Header: "Priority of chit",
      Footer: "Priority of chit",
      accessor: "priority",
    },
    {
      Header: "Anaesthesia Mode",
      Footer: "Anaesthesia Mode",
      accessor: "anaesthesiaModeText",
      Cell: ({ cell }) => {
        const id = cell.row.values.id;
        const anaesthesiaModeText = cell.row.values.anaesthesiaModeText;
        const items =
          anaesthesiaModeText !== "-" ? anaesthesiaModeText.split(",") : "-";
        return items.map((item, idx) => {
          const itemFullName =
            flatAnaesthesiaModeList.find((ele) => ele.id === item)?.name ?? "-";
          return (
            <Tooltip
              placement="right"
              title={itemFullName ?? "-"}
              key={id + "-anaemode-" + idx}
            >
              <MDBox>
                {item ? item.substring(0, 30) : "-"}
                {item && item.length > 30 ? "..." : ""}
              </MDBox>
            </Tooltip>
          );
        });
      },
    },
    {
      Header: "Airway Management",
      Footer: "Airway Management",
      accessor: "airwayManagementText",
      Cell: ({ cell }) => {
        const id = cell.row.values.id;
        const airwayManagementText = cell.row.values.airwayManagementText;
        const items =
          airwayManagementText !== "-" ? airwayManagementText.split(",") : "-";
        return items.map((item, idx) => {
          const itemFullName =
            flatAirwayManagementList.find((ele) => ele.id === item)?.name ??
            "-";
          return (
            <Tooltip
              placement="right"
              title={itemFullName ?? "-"}
              key={id + "-airmgmt-" + idx}
            >
              <MDBox>
                {item ? item.substring(0, 30) : "-"}
                {item && item.length > 30 ? "..." : ""}
              </MDBox>
            </Tooltip>
          );
        });
      },
    },
    {
      Header: "Additional Procedures",
      Footer: "Additional Procedures",
      accessor: "additionalProceduresText",
      Cell: ({ cell }) => {
        const id = cell.row.values.id;
        const additionalProceduresText =
          cell.row.values.additionalProceduresText;
        const items =
          additionalProceduresText !== "-"
            ? additionalProceduresText.split(",")
            : "-";
        return items.map((item, idx) => {
          const itemFullName =
            flatAdditionalProceduresList.find((ele) => ele.id === item)?.name ??
            "-";
          return (
            <Tooltip
              placement="right"
              title={itemFullName ?? "-"}
              key={id + "-addproc-" + idx}
            >
              <MDBox>
                {item ? item.substring(0, 30) : "-"}
                {item && item.length > 30 ? "..." : ""}
              </MDBox>
            </Tooltip>
          );
        });
      },
    },

    {
      Header: "Disposition",
      Footer: "Disposition",
      accessor: "dispositionText",
      Cell: ({ cell }) => {
        const id = cell.row.values.id;
        const dispositionText = cell.row.values.dispositionText;
        const items =
          dispositionText !== "-" ? dispositionText.split(",") : "-";
        return items.map((item, idx) => {
          const itemFullName =
            flatDispositionList.find((ele) => ele.id === item)?.name ?? "-";
          return (
            <Tooltip
              placement="right"
              title={itemFullName ?? "-"}
              key={id + "-dispo-" + idx}
            >
              <MDBox>
                {item ? item.substring(0, 30) : "-"}
                {item && item.length > 30 ? "..." : ""}
              </MDBox>
            </Tooltip>
          );
        });
      },
    },
    {
      Header: "Notes",
      Footer: "Notes",
      accessor: "notes",
    },
    {
      Header: "Created At",
      Footer: "Created At",
      accessor: "createdAtText",
    },
    {
      Header: "Created By",
      Footer: "Created By",
      accessor: "createdByText",
      Cell: ({ cell }) => {
        const createdByText = cell.row.values.createdByText;
        return tooltipCell(createdByText);
      },
    },
    {
      Header: "Modified At",
      Footer: "Modified At",
      accessor: "modifiedAtText",
    },
    {
      Header: "Modified By",
      Footer: "Modified By",
      accessor: "modifiedByText",
      Cell: ({ cell }) => {
        const modifiedByText = cell.row.values.modifiedByText;
        return tooltipCell(modifiedByText);
      },
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
    },
    {
      Header: "Deleted At",
      Footer: "Deleted At",
      accessor: "deletedAtText",
    },
    {
      Header: "Deleted By",
      Footer: "Deleted By",
      accessor: "deletedByText",
      Cell: ({ cell }) => {
        const deletedByText = cell.row.values.deletedByText;
        return tooltipCell(deletedByText);
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ cell }) => {
        const canEdit = cell.row.values.canEdit;
        const canDelete = cell.row.values.canDelete;
        return (
          <>
            <AllowedTo perform={Permission.READ_LOGBOOK}>
              <MDButton
                variant="gradient"
                color="info"
                iconOnly
                onClick={() =>
                  navigate(
                    `/logbook/manage/view/${cell.row.values.id}/${cell.row.values.flashcardId}`
                  )
                }
              >
                <VisibilityIcon />
              </MDButton>
              &nbsp;&nbsp;
            </AllowedTo>
            {canEdit && (
              <AllowedTo perform={Permission.UPDATE_LOGBOOK}>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() =>
                    navigate(
                      `/logbook/manage/edit/${cell.row.values.id}/${cell.row.values.flashcardId}`
                    )
                  }
                >
                  <EditIcon />
                </MDButton>
                &nbsp;&nbsp;
              </AllowedTo>
            )}
            {canDelete && (
              <AllowedTo perform={Permission.DELETE_LOGBOOK}>
                <MDButton
                  variant="gradient"
                  color="warning"
                  iconOnly
                  onClick={() =>
                    navigate(
                      `/logbook/manage/delete/${cell.row.values.id}/${cell.row.values.flashcardId}`
                    )
                  }
                >
                  <DeleteIcon />
                </MDButton>
              </AllowedTo>
            )}
          </>
        );
      },
    },
  ];

  return {
    columns,
    rows,
    hiddenColumns,
    response,
    userData,
    handleExport,
    handleUserPreference,
    dispatchDismiss,
    dispatchError,
    isDataReady,
  };
};
