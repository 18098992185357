import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import initialValues from "pages/csesurveys/manage/schemas/initialValues";
import validations, {
  noValidation,
} from "pages/csesurveys/manage/schemas/validations";

import parse from "date-fns/parse";

const collectionPath = "csesurveys";

const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_CSESURVEY":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "SUBMITTED_CSESURVEY":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully submitted the CSE Survey.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "UPDATED_CSESURVEY":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully updated the CSE Record.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

//export const useCseSurveyManager = (mode, userId, recordId) => {
export const useCseSurveyManager = (mode, recordId) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { user } = useAuthContext();

  const { userHasPermissions } = useAbac();

  const {
    //createDoc,
    retrieveDoc,
    updateDoc,
    serverTimestamp,
    convertToTimestamp,
  } = useDocument();

  const { pathname } = useLocation();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  // TODO: Refactor to DAO Layer
  const toPersistenceValue = useCallback(
    (document) => {
      try {
        if (document.submissionDate) {
          document.submissionDate = convertToTimestamp(
            parse(document.submissionDate, "dd/MM/yyyy", new Date())
          );
        }

        if (document.installationStartDate) {
          document.installationStartDate = convertToTimestamp(
            parse(document.installationStartDate, "dd/MM/yyyy", new Date())
          );
        }

        if (document.installationStartDate) {
          document.installationEndDate = convertToTimestamp(
            parse(document.installationEndDate, "dd/MM/yyyy", new Date())
          );
        }

        if (document.emailAttachments) {
          document.emailAttachments = document.emailAttachments.map(
            (element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            }
          );
        }

        if (document.csesurveyAttachments) {
          document.csesurveyAttachments = document.csesurveyAttachments.map(
            (element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            }
          );
        }

        return document;
      } catch (err) {
        console.error(err);
        dispatchError(err);
      }
    },
    [convertToTimestamp, dispatchError]
  );

  // TODO: Refactor to DAO Layer
  const toPresentationValue = useCallback(
    (data) => {
      try {
        if (data.submissionDate)
          data.submissionDate = data.submissionDate
            .toDate()
            .toLocaleDateString("en-SG");

        if (data.csesurveyNumber === "---------")
          data.csesurveyNumber = "000000-00";

        if (data.csesurveyRevision === "-") data.csesurveyRevision = "0";

        if (data.installationStartDate)
          data.installationStartDate = data.installationStartDate
            .toDate()
            .toLocaleDateString("en-SG");

        if (data.installationEndDate)
          data.installationEndDate = data.installationEndDate
            .toDate()
            .toLocaleDateString("en-SG");

        return data;
      } catch (err) {
        console.error(err);
        dispatchError(err);
      }
    },
    [dispatchError]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      //const retrievedCseSurvey =
      //  csesurveyId && (await retrieveDoc(collectionPath, csesurveyId));

      // Reset the purchase order number as "000000-00"
      // The system will assign the next PO number after submission
      // validate operation
      switch (mode) {
        case "new":
          if (!pathname.includes("/csesurveys/manage")) {
            throw operationInvalidError;
          }
          dispatchIfNotUnmounted({
            type: "INITIAL_CSESURVEY",
            payload: initialValues,
          });
          break;
        case "edit":
          if (!pathname.includes("/csesurveys/manage/edit")) {
            throw operationInvalidError;
          }

          let idx = parseInt(recordId) - 1;
          //const retrievedRecords = await retrieveDoc(user.uid, "records");
          const retrievedRecords = await retrieveDoc("records", user.uid);
          const cseRecord = retrievedRecords.data.cseRecords[idx];
          const datetime = cseRecord["createdAt"].split(" ");

          const remark = cseRecord?.remark;

          const payloadValues = {
            medicalID: cseRecord["medicalID"],
            patientID: cseRecord["patientID"],
            submissionDate: datetime[0],
            venue: cseRecord["venue"],
            question1: cseRecord["question1"],
            question2: cseRecord["question2"],
            question3: cseRecord["question3"],
            question4: cseRecord["question4"],
            remark: remark,
          };

          dispatchIfNotUnmounted({
            type: "INITIAL_CSESURVEY",
            payload: payloadValues,
          });
          break;
        case "view":
          if (!pathname.includes("/csesurveys/viewchart")) {
            throw operationInvalidError;
          }
          break;
        case "allrecords":
          if (!pathname.includes("/csesurveys/records")) {
            throw operationInvalidError;
          }
          break;
        default:
          throw operationInvalidError;
      }
    } catch (err) {
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    mode,
    pathname,
    dispatchError,
    user,
    //userId,
    recordId,
    retrieveDoc,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "";
  let modeSubmit = "";
  let modeFieldDisabled = true;
  let modePermission = "";
  let modeValidation = noValidation;
  // Set permission.
  // Check the permission in CseSurvey.js (<AllowedTo perform={modePermission} no={() => <PermissionDenied />} />)
  switch (mode) {
    case "new":
      modeTitle = "Create CSE Survey";
      modeSubmit = "Submit";
      modeFieldDisabled = false;
      modePermission = Permission.CREATE_CSESURVEY;
      modeValidation = validations;
      break;
    case "edit":
      modeTitle = "Update CSE Survey";
      modeSubmit = "Update";
      modeFieldDisabled = false;
      modePermission = Permission.UPDATE_CSESURVEY;
      modeValidation = validations;
      break;
    case "view":
      modeTitle = "View Cusum Chart";
      modeSubmit = "Next Submission";
      modeFieldDisabled = true;
      modePermission = Permission.VIEW_CSECHART;
      modeValidation = noValidation;
      break;
    case "allrecords":
      modeTitle = "All Submissions";
      modePermission = Permission.READ_ALL_CSESURVEYS;
      modeValidation = validations;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  const submitNew = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_CSESURVEY)) {
        values = toPersistenceValue(values);
        // Remove creating individual document for each submission
        /*const createdDoc = await createDoc(
          user.uid,
          {
            ...values,
          },
          user.uid
        );*/

        //const retrievedDoc = await retrieveDoc(user.uid, "records");
        const retrievedDoc = await retrieveDoc("records", user.uid);
        const m_date = new Date();
        const dateTime =
          m_date.toLocaleDateString("en-SG") +
          " " +
          m_date.toLocaleTimeString("en-SG");

        const rec = {
          createdAt: dateTime,
          createdBy: user.uid,
          modifiedAt: null,
          modifiedBy: null,
          deletedAt: null,
          deletedBy: null,
          medicalID: values.medicalID,
          patientID: values.patientID,
          question1: values.question1,
          question2: values.question2,
          question3: values.question3,
          question4: values.question4,
          venue: values.venue,
          remark: values.remark,
        };
        const recArray = [...retrievedDoc.data.cseRecords, rec];

        /* await updateDoc(user.uid, "records", {
          ...retrievedDoc.data,
          cseRecords: recArray,
        }); */
        await updateDoc("records", user.uid, {
          ...retrievedDoc.data,
          cseRecords: recArray,
        });

        const dispatchType = "SUBMITTED_CSESURVEY";
        dispatchIfNotUnmounted({
          type: dispatchType,
          payload: toPresentationValue(retrievedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitEdit = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_CSESURVEY)) {
        values = toPersistenceValue(values);

        //let retrievedRecords = await retrieveDoc(user.uid, "records");
        let retrievedRecords = await retrieveDoc("records", user.uid);
        const idx = parseInt(recordId) - 1;

        let cseRecord = retrievedRecords.data.cseRecords[idx];

        const m_date = new Date();
        const dateTime =
          m_date.toLocaleDateString("en-SG") +
          " " +
          m_date.toLocaleTimeString("en-SG");

        cseRecord["modifiedAt"] = dateTime;
        cseRecord["patientID"] = values.patientID;
        cseRecord["question1"] = values.question1;
        cseRecord["question2"] = values.question2;
        cseRecord["question3"] = values.question3;
        cseRecord["question4"] = values.question4;
        cseRecord["venue"] = values.venue;
        cseRecord["remark"] = values.remark;

        retrievedRecords.data.cseRecords[idx] = cseRecord;
        //const recArray = [...retrievedDoc.data.cseRecords, rec];
        /* await updateDoc(user.uid, "records", {
          ...retrievedRecords.data,
        }); */
        await updateDoc("records", user.uid, {
          ...retrievedRecords.data,
        });

        const dispatchType = "UPDATED_CSESURVEY";
        dispatchIfNotUnmounted({
          type: dispatchType,
          payload: toPresentationValue(retrievedRecords.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitSend = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.SEND_CSESURVEY)) {
        // await sendMail({
        //   fromName: values.fromName,
        //   fromEmail: values.fromEmail,
        //   toEmails: values.toEmails,
        //   ccEmails: values.ccEmails,
        //   bccEmails: values.bccEmails,
        //   emailSubject: values.emailSubject,
        //   emailContent: values.emailContent,
        //   emailAttachments: values.emailAttachments.map((attachment) => {
        //     return {
        //       filename: attachment.attachmentName,
        //       path: attachment.attachmentURL,
        //     };
        //   }),
        // });

        values = toPersistenceValue(values);

        //const updatedDoc = await updateDoc(collectionPath, userId, {
        const updatedDoc = await updateDoc(collectionPath, user.uid, {
          ...values,
          sentAt: serverTimestamp(),
          sentBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "SENT_CSESURVEY",
          payload: toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitNew,
    submitEdit,
    submitSend,
    response,
    dispatchDismiss,
    dispatchError,
  };
};
