import { auth, functions } from "configs/firebase";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  signInWithEmailAndPassword,
  setPersistence,
  sendPasswordResetEmail,
  browserLocalPersistence,
  browserSessionPersistence,
  inMemoryPersistence,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";

const persistence = {
  local: browserLocalPersistence,
  session: browserSessionPersistence,
  none: inMemoryPersistence,
};

const createAuthnUser = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

const createNewUser = httpsCallable(functions, "createAuthUser");

const updateUserProfile = (userDisplayName, userPhotoURL) => {
  return updateProfile(auth.currentUser, {
    ...(userDisplayName && { displayName: userDisplayName }),
    ...(userPhotoURL && { photoURL: userPhotoURL }),
  });
};

const sendUserEmailVerification = (continueURL, newUser = auth.currentUser) => {
  return sendEmailVerification(newUser, { url: continueURL });
};

const setRolesOfAuthnUser = httpsCallable(functions, "setCustomClaimsRoles");

const setRolesOfUser = httpsCallable(functions, "setCustomClaimsRolesOfUser");

const getUserRoles = async () => {
  let roles = null;
  const user = auth.currentUser;
  if (user) {
    await user.getIdToken(true);
    const result = await user.getIdTokenResult();
    roles = result?.claims?.roles;
  }
  return roles;
};

const signInUser = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const signInUserWithGoogle = async () => {
  const provider = new GoogleAuthProvider();

  await signInWithRedirect(auth, provider);

  return getRedirectResult(auth);
};

const signInUserWithSingpass = async () => {
  const provider = new OAuthProvider("oidc.singpass");
  // provider.setCustomParameters({
  //   redirect_uri:
  //     "https://kkh-cusum-dev-project.firebaseapp.com/__/auth/handler",
  // });
  provider.setCustomParameters({
    // scope: "openid",
    response_type: "code",
    redirect_uri: "https://partner.gov.sg/redirect",
    nonce: "24d3f319-6531-4533-8fb2-9b3c93807fb7",
    client_id: "T5sM5a53Yaw3URyDEv2y9129CbElCN2F",
    state: "dGVzdCBzdHJpbmcK",
    code_challenge: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
    code_challenge_method: "S256",
  });
  provider.addScope("openid");
  await signInWithRedirect(auth, provider);

  return getRedirectResult(auth);
};

const setAuthStatePersistence = (persistenceType) => {
  return setPersistence(auth, persistence[persistenceType]);
};

const signOutUser = () => {
  return signOut(auth);
};

const sendUserPasswordResetEmail = (email, continueURL) => {
  return sendPasswordResetEmail(auth, email, { url: continueURL });
};

const subscribeAuthState = (handleAuthStateChanged) => {
  return onAuthStateChanged(auth, (user) => {
    handleAuthStateChanged(user);
  });
};

const getAuthUser = httpsCallable(functions, "getAuthUser");
const disableAuthUser = httpsCallable(functions, "disableAuthUser");
const enableAuthUser = httpsCallable(functions, "enableAuthUser");

export {
  createAuthnUser,
  createNewUser,
  sendUserEmailVerification,
  updateUserProfile,
  setRolesOfAuthnUser,
  setRolesOfUser,
  getUserRoles,
  signInUser,
  signInUserWithGoogle,
  signInUserWithSingpass,
  setAuthStatePersistence,
  sendUserPasswordResetEmail,
  signOutUser,
  subscribeAuthState,
  getAuthUser,
  disableAuthUser,
  enableAuthUser,
};
