import form from "pages/settings/flashcard/manage/schemas/form";

const {
  formField: {
    autoShare,
    newCardsPerDay,
    maximumReviewPerDay,
    learningSteps,
    graduatingInterval,
    easyInterval,
    relearningSteps,
    minimumInterval,
    leechThreshold,
    leechAction,
    newReviewOrder,
    interdayLearningReviewOrder,
    reviewSortOrder,
    maximumInterval,
    startingEase,
    easyBonus,
    hardInterval,
    newInterval,
  },
} = form;

const initialValues = {
  [autoShare.name]: true,
  [newCardsPerDay.name]: "999",
  [maximumReviewPerDay.name]: "9999",
  [learningSteps.name]: "15m 1d 3d",
  [graduatingInterval.name]: "6",
  [easyInterval.name]: "8",
  [relearningSteps.name]: "20m 1d",
  [minimumInterval.name]: "3",
  [leechThreshold.name]: "8",
  [leechAction.name]: "Tag",
  [newReviewOrder.name]: "Show after reviews", // (Review studied before new), "Show before reviews" (New show first before review). "Mixed with reviews" (Random order)
  [interdayLearningReviewOrder.name]: "Show after reviews", // (Review studied before learning), "Show before reviews" (Learning show first before review). "Mixed with reviews" (Random order)
  [reviewSortOrder.name]: "Due date, then random", // (longest due date show firs, other random), Ascending intervals (shorter interval first), Descending intervals (larger interval first)
  [maximumInterval.name]: "240", // default 100years
  [startingEase.name]: "2.5",
  [easyBonus.name]: "1.3",
  [hardInterval.name]: "1.2",
  [newInterval.name]: "0.0",
};

export { initialValues as default };
