import form, {
  flashcardFrontImgAttachmentsForm,
  flashcardBackImgAttachmentsForm,
} from "pages/flashcard/manage/schemas/form";

const {
  formField: {
    operationLevel1,
    operationLevel2,
    flashcardFront,
    flashcardFrontImgAttachments,
    flashcardBack,
    flashcardBackImgAttachments,
  },
} = form;

const initialValues = {
  [operationLevel1.name]: "",
  [operationLevel2.name]: "",
  [flashcardFront.name]: "",
  [flashcardFrontImgAttachments.name]: [],
  [flashcardBack.name]: "",
  [flashcardBackImgAttachments.name]: [],
};

const {
  formField: { flashcardFrontImgAttachmentFile },
} = flashcardFrontImgAttachmentsForm;

const flashcardFrontImgAttachmentsInitialValues = {
  [flashcardFrontImgAttachmentFile.name]: "",
};

const {
  formField: { flashcardBackImgAttachmentFile },
} = flashcardBackImgAttachmentsForm;

const flashcardBackImgAttachmentsInitialValues = {
  [flashcardBackImgAttachmentFile.name]: "",
};

export {
  initialValues as default,
  flashcardFrontImgAttachmentsInitialValues,
  flashcardBackImgAttachmentsInitialValues,
};
