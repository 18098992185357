const durationOptions = ["0", "15", "30", "45", "60"];
const residencyYearOptions = ["1", "2", "3", "4", "5"];
const roleOptions = ["direct", "indirect", "oversight"];
const siteOptions = ["KKH", "SGH", "SKH", "CGH", "TTSH", "KTPH", "NUH"];

const defaultLogbookColumns = [
  { key: "caseId", name: "Case ID" },
  { key: "dateText", name: "Date" },
  { key: "durationhm", name: "Duration (mins)" },
  { key: "residencyYear", name: "Year of Residency" },
  { key: "role", name: "Role" },
  { key: "site", name: "Site" },
  { key: "status", name: "Status" },
  { key: "actions", name: "Actions" },
];

const columnOptions = [
  { key: "canEdit", name: "canEdit" },
  { key: "canDelete", name: "canDelete" },
  { key: "id", name: "ID" },
  { key: "flashcardId", name: "Flashcard ID" },
  { key: "caseId", name: "Case ID" },
  { key: "dateText", name: "Date" },
  { key: "durationhm", name: "Duration (mins)" },
  { key: "residencyYear", name: "Year of Residency" },
  { key: "role", name: "Role" },
  { key: "site", name: "Site" },
  { key: "supervisor", name: "Supervisor" },
  { key: "ageText", name: "Age" },
  { key: "asa", name: "ASA" },
  { key: "emergency", name: "Emergency" },
  { key: "operationLevel1", name: "Subspecialty" },
  { key: "operationLevel2", name: "Operation/Procedure" },
  { key: "priority", name: "Priority of chit" },
  { key: "anaesthesiaModeText", name: "Anaesthesia Mode" },
  { key: "airwayManagementText", name: "Airway Management" },
  { key: "additionalProceduresText", name: "Additional Procedures" },
  { key: "dispositionText", name: "Disposition" },
  { key: "notes", name: "Notes" },
  { key: "createdAtText", name: "Created At" },
  { key: "createdByText", name: "Created By" },
  { key: "modifiedAtText", name: "Modified At" },
  { key: "modifiedByText", name: "Modified By" },
  { key: "status", name: "Status" },
  { key: "deletedAtText", name: "Deleted At" },
  { key: "deletedByText", name: "Deleted By" },
  { key: "actions", name: "Actions" },
];
export {
  durationOptions,
  residencyYearOptions,
  roleOptions,
  siteOptions,
  columnOptions,
  defaultLogbookColumns,
};
