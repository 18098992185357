import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";

import Card from "@mui/material/Card";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav2 from "components/organisms/Topnav2";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

import Grid from "@mui/material/Grid";
import DataTable from "components/molecules/Tables/DataTable";

// CseSurvey page components
//import Questionnaire from "pages/csesurveys/manage/components/Questionnaire";
//import CusumChart from "pages/csesurveys/manage/components/CusumChart";
//import Sidenav from "pages/csesurveys/manage/components/Sidenav";

//import { Formik, Form } from "formik";
//import form from "pages/csesurveys/manage/schemas/form";

import { AllowedTo } from "react-abac";

import { useCseSurveyManager } from "pages/cohortstatistics/hooks/useCseSurveyManager";
import { useDocument } from "hooks/useDocument";
import { std, max, min, mean } from "mathjs";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ComplexStatisticsCard from "components/molecules/Cards/StatisticsCards/ComplexStatisticsCard";

function CseSurvey() {
  const [isProgressing, setIsProgressing] = useState(true);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [numMinOneSubmission, setNumMinOneSubmission] = useState(0);
  const [minCusumScore, setMinCusumScore] = useState(null);
  const [participantMinCusumScore, setParticipantMinCusumScore] =
    useState(null);
  const [msgMinCusumScore, setMsgMinCusumScore] = useState(null);
  const [maxCusumScore, setMaxCusumScore] = useState(null);
  const [participantMaxCusumScore, setParticipantMaxCusumScore] =
    useState(null);
  const [msgMaxCusumScore, setMsgMaxCusumScore] = useState(null);
  const { mode } = useParams();
  const { retrieveDoc, updateDoc } = useDocument();
  const { modeTitle, modePermission, response } = useCseSurveyManager(mode);
  const navigate = useNavigate();
  const usersQueries = {
    whereQueries: [
      {
        field: "deletedAt",
        condition: "==",
        value: null,
      },
    ],
  };

  const columns = [
    { Header: "No", Footer: "No", accessor: "rowIdx", isVisible: false },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      isVisible: false,
    },
    {
      Header: "ID",
      Footer: "ID",
      accessor: "id",
      isVisible: false,
    },
    {
      Header: "MCR",
      Footer: "MCR",
      accessor: "mcr",
      isVisible: false,
    },
    {
      Header: "Designation",
      Footer: "Designation",
      accessor: "designation",
      isVisible: false,
    },
    {
      Header: "Number attempts",
      Footer: "Number attempts",
      accessor: "numAttempts",
      isVisible: false,
    },
    {
      Header: "Max (Index)",
      Footer: "Max (Index)",
      accessor: "max",
      isVisible: false,
    },
    {
      Header: "Min (Index)",
      Footer: "Min (Index)",
      accessor: "min",
      isVisible: false,
    },
    {
      Header: "Mean",
      Footer: "Mean",
      accessor: "mean",
      isVisible: false,
    },
    {
      Header: "Std",
      Footer: "Std",
      accessor: "std",
      isVisible: false,
    },
    {
      Header: "Action",
      Footer: "Action",
      accessor: "action",
      Cell: ({ cell }) => {
        const showIcon = cell.row.values.numAttempts !== 0;
        return showIcon ? (
          <MDButton
            variant="gradient"
            color="success"
            iconOnly
            onClick={() => {
              navigate(
                "/cohortstatistics/individual/" +
                  cell.row.values.id +
                  "/" +
                  cell.row.values.name
              );
            }}
          >
            <QueryStatsIcon />
          </MDButton>
        ) : (
          <>{}</>
        );
      },
    },
  ];
  const hiddenColumns = ["id"];
  const [rows, setRows] = useState([]);

  const { collectionData: usersData } = useCollectionSnapshot(
    "users",
    usersQueries
  );

  const initializeFieldValue = useCallback(async () => {
    if (usersData.length > 0) {
      const m_rows = [];
      let rowIdx = 1;
      let numSubmissionMinOne = 0;
      let minScore = 10000;
      let participantMinScore = "";
      let msgMinScore = "";
      let maxScore = -10000;
      let participantMaxScore = "";
      let msgMaxScore = "";
      for (let user of usersData) {
        if (
          user.data.medicalID !== "MID 1144" &&
          user.data.medicalID !== "MID 0385" &&
          user.data.medicalID !== "MID-9979"
        ) {
          let stats = {
            numpts: 0,
            max: 0.0,
            maxidx: 0,
            min: 0.0,
            minidx: 0,
            mean: 0.0,
            std: 0.0,
          };
          //const retrievedRecords = await retrieveDoc(user.id, "records");
          const retrievedRecords = await retrieveDoc("records", user.id);

          // Added condition check. For new user, the "cseCusumStats" data field is not available
          if (retrievedRecords?.data?.cseCusumStats) {
            let stmt_max = "";
            let stmt_min = "";
            let stmt_mean = "";
            let stmt_std = "";
            if (retrievedRecords.data.cseCusumStats.numpts > 0) {
              stmt_max =
                parseFloat(retrievedRecords.data.cseCusumStats.max).toFixed(2) +
                " (" +
                retrievedRecords.data.cseCusumStats.maxidx +
                ")";
              stmt_min =
                parseFloat(retrievedRecords.data.cseCusumStats.min).toFixed(2) +
                " (" +
                retrievedRecords.data.cseCusumStats.minidx +
                ")";
              stmt_mean = parseFloat(
                retrievedRecords.data.cseCusumStats.mean
              ).toFixed(2);
              stmt_std = parseFloat(
                retrievedRecords.data.cseCusumStats.std
              ).toFixed(2);

              numSubmissionMinOne = numSubmissionMinOne + 1;
              if (minScore > retrievedRecords.data.cseCusumStats.min) {
                minScore = retrievedRecords.data.cseCusumStats.min;
                participantMinScore = user.data.displayName;
                msgMinScore =
                  ", achieved at Attempt #" +
                  retrievedRecords.data.cseCusumStats.minidx;
              }

              if (maxScore < retrievedRecords.data.cseCusumStats.max) {
                maxScore = retrievedRecords.data.cseCusumStats.max;
                participantMaxScore = user.data.displayName;
                msgMaxScore =
                  ", achieved at Attempt #" +
                  retrievedRecords.data.cseCusumStats.maxidx;
              }
            }

            m_rows.push({
              rowIdx: rowIdx,
              name: user.data.displayName,
              id: user.id,
              mcr: user.data.medicalID,
              designation: user.data.designation,
              numAttempts: retrievedRecords.data.cseCusumStats.numpts,
              max: stmt_max,
              min: stmt_min,
              mean: stmt_mean,
              std: stmt_std,
            });

            //if (true && user.data.medicalID === "64495A") {
            if (false) {
              console.log(user.data.medicalID);
              console.log(user.id);
              //const retrievedRecords = await retrieveDoc(user.id, "records");
              const retrievedRecords = await retrieveDoc("records", user.id);

              let cseRecords = [];
              if (retrievedRecords.data !== undefined) {
                cseRecords = retrievedRecords.data.cseRecords;
              }
              //console.log(JSON.stringify(cseRecords));

              const retrievedCusumSettings = await retrieveDoc(
                "methods",
                "cusum"
              );
              const acceptableFailureRate =
                retrievedCusumSettings.data.acceptableFailureRate;
              //const h1 = retrievedCusumSettings.data.upperDecisionLimit;
              //const h0 = retrievedCusumSettings.data.lowerDecisionLimit;
              //console.log("h0: " + h0);
              //console.log("h1: " + h1);
              const cusum_labels = [];
              const S_data = [];
              //S_data.push(0);
              cusum_labels.push(0);
              if (cseRecords.length > 0) {
                for (let ii = 0; ii < cseRecords.length; ii++) {
                  let sum_delta = 0;
                  for (let jj = 0; jj < ii + 1; jj++) {
                    const cseRecord = cseRecords[jj];
                    let Xn;
                    if (
                      cseRecord["question1"] === "YES" &&
                      cseRecord["question2"] === "YES" &&
                      cseRecord["question3"] === "YES" &&
                      cseRecord["question4"] === "YES"
                    )
                      Xn = 0;
                    else Xn = 1;

                    let delta = Xn - acceptableFailureRate;
                    sum_delta = sum_delta + delta;
                  }

                  S_data.push(sum_delta);
                  cusum_labels.push(ii + 1);
                }

                stats.numpts = cseRecords.length;
                stats.max = max(...S_data);
                stats.maxidx = S_data.indexOf(stats.max) + 1;
                stats.min = min(...S_data);
                stats.minidx = S_data.indexOf(stats.min) + 1;
                stats.mean = mean(...S_data);
                stats.std = std(...S_data);
              }
              S_data.splice(0, 0, 0); // Add 0 to the beginning (index-0) of S_data (start, delete-elm, value)

              console.log(JSON.stringify(retrievedRecords));
              console.log(JSON.stringify(user.id));
              /*const recArray = [...retrievedDoc.data.cseRecords, rec];*/
              /*await updateDoc(user.id, "records", {
                ...retrievedRecords.data,
                cseCusumValues: S_data,
                cseCusumStats: stats,
              });*/
              await updateDoc("records", user.id, {
                ...retrievedRecords.data,
                cseCusumValues: S_data,
                cseCusumStats: stats,
              });
            } // end-of patching cseCusumValues and cseCusumStats
            rowIdx = rowIdx + 1;
          } else {
            // User account created but user hasn't logged in
            m_rows.push({
              rowIdx: rowIdx,
              name: user.data.displayName,
              id: user.id,
              mcr: user.data.medicalID,
              designation: user.data.designation,
              numAttempts: 0,
              max: "",
              min: "",
              mean: "",
              std: "",
            });
            rowIdx = rowIdx + 1;
          }
        }
      }
      setRows(m_rows);
      setTotalParticipants(m_rows.length);
      setNumMinOneSubmission(numSubmissionMinOne);
      if (participantMinScore.length > 0) {
        setMinCusumScore(minScore.toFixed(2));
        setParticipantMinCusumScore(participantMinScore);
        setMsgMinCusumScore(msgMinScore);
      }
      if (participantMaxScore.length > 0) {
        setMaxCusumScore(maxScore.toFixed(2));
        setParticipantMaxCusumScore(participantMaxScore);
        setMsgMaxCusumScore(msgMaxScore);
      }
      setIsProgressing(false);
    }
  }, [usersData, retrieveDoc, updateDoc]);

  useEffect(() => {
    try {
      initializeFieldValue();
    } catch (err) {}
  }, [initializeFieldValue]);

  return (
    <DashboardLayout>
      <Topnav2 title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={3} pb={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mt={1.5} mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Participants"
                count={totalParticipants}
                percentage={{
                  color: "success",
                  amount: numMinOneSubmission,
                  label: "have made at least one submission",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mt={1.5} mb={1.5}>
              <ComplexStatisticsCard
                icon="trending_down"
                title="Best Cusum Score (Cohort)"
                count={minCusumScore}
                percentage={{
                  color: "success",
                  amount: participantMinCusumScore,
                  label: msgMinCusumScore,
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mt={1.5} mb={1.5}>
              <ComplexStatisticsCard
                icon="trending_up"
                title="Worst Cusum Score (Cohort)"
                count={maxCusumScore}
                percentage={{
                  color: "success",
                  amount: participantMaxCusumScore,
                  label: msgMaxCusumScore,
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox pt={3} pb={3}>
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        <Card>
          <MDBox m={3}>
            {response.error?.name !== "OperationInvalidError" && (
              <DataTable
                table={{
                  columns: columns,
                  rows: rows,
                }}
                hiddenColumns={hiddenColumns}
                canGlobalSearch
                canColumnSearch
                canColumnSort
              />
            )}
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CseSurvey;
