import { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";

import { AllowedTo } from "react-abac";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import FormBasicAutocomplete from "components/molecules/Formik/FormBasicAutocomplete";

import ListAltIcon from "@mui/icons-material/ListAlt";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LaunchIcon from "@mui/icons-material/Launch";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav2 from "components/organisms/Topnav2";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import SubmissionProgress from "components/organisms/SubmissionProgress";
import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import ContainerDimensions from "react-container-dimensions";

import { useFlashcardRevisionManager } from "pages/flashcard/hooks/useFlashcardRevisionManager";

import { useDocument } from "hooks/useDocument";

import moment from "moment-timezone";

import { Formik, Form } from "formik";
import form from "pages/flashcard/revise/schemas/form";
import initialValues from "pages/flashcard/revise/schemas/initialValues";
import validations from "pages/flashcard/revise/schemas/validations";

import { operationConfigs } from "schema/setups";

import { algoGrade, algoState, supermemo } from "pages/flashcard/algo";

import { useDocumentSnapshot } from "hooks/useDocumentSnapshot";
import MDBadge from "components/atoms/MDBadge";

function FlashcardRevision() {
  const { mode } = useParams();

  const {
    submitShare,
    submitUnshare,
    submitSchedule,
    submitFilterRevision,
    response,
    modeTitle,
    // modeValidation,
    // modeSubmit,
    modePermission,
    dispatchDismiss,
    dispatchError,
  } = useFlashcardRevisionManager(mode);

  const { user } = useAuthContext();

  const { retrieveDocs } = useDocument();

  const [filterValues, setFilterValues] = useState(initialValues);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isMyFlashcard, setIsMyFlashcard] = useState(false);
  const [isPublicFlashcard, setIsPublicFlashcard] = useState(false);
  const [flashcardIndex, setFlashcardIndex] = useState(0);

  const { documentData: configData } = useDocumentSnapshot(
    "flashcardconfigs",
    user.uid
  );

  const { documentData: userData } = useDocumentSnapshot("users", user.uid);

  const nextFlashcard = () => {
    const nextFlashcardIdx = flashcardIndex + 1;

    if (nextFlashcardIdx < response.data.length) {
      setFlashcardIndex(nextFlashcardIdx);
      setIsFlipped(false);
    }
  };

  const backFlashcard = () => {
    const backFlashcardIdx = flashcardIndex - 1;
    if (backFlashcardIdx >= 0) {
      setFlashcardIndex(backFlashcardIdx);
      setIsFlipped(false);
    }
  };

  const { formId, formField } = form;

  const { operationLevel1, operationLevel2 } = formField;

  const defaultOption = useMemo(() => ["All"], []);

  const handleSubmit = async (values, actions) => {
    try {
      await submitFilterRevision(values);
      closeFilterDialog();
      setIsFlipped(false);
      dispatchDismissAfter(1000);
    } catch (err) {
      console.error(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const initFlashcardPrivacy = useCallback(async () => {
    if (response.data[flashcardIndex].id) {
      setIsMyFlashcard(response.data[flashcardIndex]?.createdBy === user.uid);

      const publicflashcardsQueries = {
        whereQueries: [
          {
            field: "deletedAt",
            condition: "==",
            value: null,
          },
          {
            field: "sharedFrom",
            condition: "==",
            value: response.data[flashcardIndex].id,
          },
        ],
      };

      const retrievedPublicFlashcards = await retrieveDocs(
        "publicflashcards",
        publicflashcardsQueries
      );

      setIsPublicFlashcard(retrievedPublicFlashcards.length > 0);
    }
  }, [flashcardIndex, response.data, retrieveDocs, user.uid]);

  const initFilter = useCallback(async () => {
    const retrievedOperationLevel1 =
      userData.revisionFilterOperationLevel1 ?? null;
    const retrievedOperationLevel2 =
      userData.revisionFilterOperationLevel2 ?? null;
    const selectedFilters = {
      [operationLevel1.name]: retrievedOperationLevel1,
      [operationLevel2.name]: retrievedOperationLevel2,
    };
    setFilterValues(selectedFilters);
  }, [operationLevel1.name, operationLevel2.name, userData]);

  const [openFilter, setOpenFilter] = useState(false);

  const closeFilterDialog = () => {
    setOpenFilter(false);
  };

  const openFilterDialog = () => {
    setOpenFilter(true);
  };

  const [openList, setOpenList] = useState(false);

  const closeListDialog = () => {
    setOpenList(false);
  };

  const openListDialog = () => {
    setOpenList(true);
  };

  useEffect(() => {
    try {
      if (userData) {
        initFilter();
      }
      if (response.data.length > 0 && userData) {
        initFlashcardPrivacy();
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  }, [
    dispatchError,
    initFilter,
    initFlashcardPrivacy,
    response.data.length,
    userData,
  ]);

  const scheduleSection = response.data.length > 0 &&
    response.data[flashcardIndex] &&
    configData && (
      <MDBox m={2} display="flex" justifyContent="center">
        <MDBox mx={1} display="flex" flexDirection="column" alignItems="center">
          <MDTypography variant="body2" verticalAlign="middle">
            {moment
              .duration(
                supermemo(
                  {
                    state: response.data[flashcardIndex].state,
                    stateRepetition:
                      response.data[flashcardIndex].stateRepetition,
                    hardRepetition:
                      response.data[flashcardIndex].hardRepetition,
                    goodRepetition:
                      response.data[flashcardIndex].goodRepetition,
                    interval: response.data[flashcardIndex].interval,
                    efactor: response.data[flashcardIndex].efactor,
                    leechCount: response.data[flashcardIndex].leechCount,
                  },
                  algoGrade.Again,
                  configData
                ).interval
              )
              .humanize()
              .replace("an ", "1 ")
              .replace("a ", "1 ")
              .replace("hours", "hrs")
              .replace("hour", "hr")
              .replace("minutes", "mins")
              .replace("minute", "min")
              .replace("days", "day")
              .replace("day", "days")}
          </MDTypography>
          <MDButton
            size="small"
            variant="gradient"
            onClick={async () => {
              const processedItem = supermemo(
                {
                  state: response.data[flashcardIndex].state,
                  stateRepetition:
                    response.data[flashcardIndex].stateRepetition,
                  hardRepetition: response.data[flashcardIndex].hardRepetition,
                  goodRepetition: response.data[flashcardIndex].goodRepetition,
                  interval: response.data[flashcardIndex].interval,
                  efactor: response.data[flashcardIndex].efactor,
                  leechCount: response.data[flashcardIndex].leechCount,
                },
                algoGrade.Again,
                configData
              );
              await submitSchedule(
                processedItem,
                response.data[flashcardIndex].id
              );
              setIsFlipped(false);
              dispatchDismissAfter(1000);
            }}
            color="dark"
          >
            Again
          </MDButton>
        </MDBox>
        <MDBox mx={1} display="flex" flexDirection="column" alignItems="center">
          <MDTypography variant="body2" verticalAlign="middle">
            {moment
              .duration(
                supermemo(
                  {
                    state: response.data[flashcardIndex].state,
                    stateRepetition:
                      response.data[flashcardIndex].stateRepetition,
                    hardRepetition:
                      response.data[flashcardIndex].hardRepetition,
                    goodRepetition:
                      response.data[flashcardIndex].goodRepetition,
                    interval: response.data[flashcardIndex].interval,
                    efactor: response.data[flashcardIndex].efactor,
                    leechCount: response.data[flashcardIndex].leechCount,
                  },
                  algoGrade.Hard,
                  configData
                ).interval
              )
              .humanize()
              .replace("an ", "1 ")
              .replace("a ", "1 ")
              .replace("hours", "hrs")
              .replace("hour", "hr")
              .replace("minutes", "mins")
              .replace("minute", "min")
              .replace("days", "day")
              .replace("day", "days")}
          </MDTypography>
          <MDButton
            size="small"
            variant="gradient"
            onClick={async () => {
              const processedItem = supermemo(
                {
                  state: response.data[flashcardIndex].state,
                  stateRepetition:
                    response.data[flashcardIndex].stateRepetition,
                  hardRepetition: response.data[flashcardIndex].hardRepetition,
                  goodRepetition: response.data[flashcardIndex].goodRepetition,
                  interval: response.data[flashcardIndex].interval,
                  efactor: response.data[flashcardIndex].efactor,
                  leechCount: response.data[flashcardIndex].leechCount,
                },
                algoGrade.Hard,
                configData
              );
              await submitSchedule(
                processedItem,
                response.data[flashcardIndex].id
              );
              setIsFlipped(false);
              dispatchDismissAfter(1000);
            }}
            color="dark"
          >
            Hard
          </MDButton>
        </MDBox>
        <MDBox mx={1} display="flex" flexDirection="column" alignItems="center">
          <MDTypography variant="body2" verticalAlign="middle">
            {moment
              .duration(
                supermemo(
                  {
                    state: response.data[flashcardIndex].state,
                    stateRepetition:
                      response.data[flashcardIndex].stateRepetition,
                    hardRepetition:
                      response.data[flashcardIndex].hardRepetition,
                    goodRepetition:
                      response.data[flashcardIndex].goodRepetition,
                    interval: response.data[flashcardIndex].interval,
                    efactor: response.data[flashcardIndex].efactor,
                    leechCount: response.data[flashcardIndex].leechCount,
                  },
                  algoGrade.Good,
                  configData
                ).interval
              )
              .humanize()
              .replace("an ", "1 ")
              .replace("a ", "1 ")
              .replace("hours", "hrs")
              .replace("hour", "hr")
              .replace("minutes", "mins")
              .replace("minute", "min")
              .replace("days", "day")
              .replace("day", "days")}
          </MDTypography>
          <MDButton
            size="small"
            variant="gradient"
            onClick={async () => {
              const processedItem = supermemo(
                {
                  state: response.data[flashcardIndex].state,
                  stateRepetition:
                    response.data[flashcardIndex].stateRepetition,
                  hardRepetition: response.data[flashcardIndex].hardRepetition,
                  goodRepetition: response.data[flashcardIndex].goodRepetition,
                  interval: response.data[flashcardIndex].interval,
                  efactor: response.data[flashcardIndex].efactor,
                  leechCount: response.data[flashcardIndex].leechCount,
                },
                algoGrade.Good,
                configData
              );
              await submitSchedule(
                processedItem,
                response.data[flashcardIndex].id
              );
              setIsFlipped(false);
              dispatchDismissAfter(1000);
            }}
            color="dark"
          >
            Good
          </MDButton>
        </MDBox>
        <MDBox mx={1} display="flex" flexDirection="column" alignItems="center">
          <MDTypography variant="body2" verticalAlign="middle">
            {moment
              .duration(
                supermemo(
                  {
                    state: response.data[flashcardIndex].state,
                    stateRepetition:
                      response.data[flashcardIndex].stateRepetition,
                    hardRepetition:
                      response.data[flashcardIndex].hardRepetition,
                    goodRepetition:
                      response.data[flashcardIndex].goodRepetition,
                    interval: response.data[flashcardIndex].interval,
                    efactor: response.data[flashcardIndex].efactor,
                    leechCount: response.data[flashcardIndex].leechCount,
                  },
                  algoGrade.Easy,
                  configData
                ).interval
              )
              .humanize()
              .replace("an ", "1 ")
              .replace("a ", "1 ")
              .replace("hours", "hrs")
              .replace("hour", "hr")
              .replace("minutes", "mins")
              .replace("minute", "min")
              .replace("days", "day")
              .replace("day", "days")}
          </MDTypography>
          <MDButton
            size="small"
            variant="gradient"
            onClick={async () => {
              const processedItem = supermemo(
                {
                  state: response.data[flashcardIndex].state,
                  stateRepetition:
                    response.data[flashcardIndex].stateRepetition,
                  hardRepetition: response.data[flashcardIndex].hardRepetition,
                  goodRepetition: response.data[flashcardIndex].goodRepetition,
                  interval: response.data[flashcardIndex].interval,
                  efactor: response.data[flashcardIndex].efactor,
                  leechCount: response.data[flashcardIndex].leechCount,
                },
                algoGrade.Easy,
                configData
              );
              await submitSchedule(
                processedItem,
                response.data[flashcardIndex].id
              );
              setIsFlipped(false);
              dispatchDismissAfter(1000);
            }}
            color="dark"
          >
            Easy
          </MDButton>
        </MDBox>
      </MDBox>
    );

  const sharingSection = response.data.length > 0 &&
    response.data[flashcardIndex] && (
      <MDBox mt={1} display="flex" justifyContent="center">
        {isMyFlashcard && isPublicFlashcard && (
          <MDBox
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <MDBox>
              <MDTypography variant="body2" fontWeight="bold">
                Shared to Public
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDButton
                onClick={async () => {
                  await submitUnshare(response.data[flashcardIndex].id);
                  dispatchDismissAfter(1000);
                }}
                color="info"
              >
                Unshare
              </MDButton>
            </MDBox>
          </MDBox>
        )}
        {isMyFlashcard && !isPublicFlashcard && (
          <MDBox
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <MDBox>
              <MDTypography variant="body2" fontWeight="bold">
                Privately Owned
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDButton
                onClick={async () => {
                  await submitShare(response.data[flashcardIndex].id);
                  dispatchDismissAfter(1000);
                }}
                color="info"
              >
                Share to Public
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    );

  const dispatchDismissAfter = (millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        dispatchDismiss();
      }, millisecond);
    });
  };

  return (
    <DashboardLayout>
      <Topnav2 title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            {response.error?.name === "OperationIncompleteError" && (
              <IncompleteOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            <MDBox pt={2} pb={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDBox p={2} borderRadius="lg" bgColor="white">
                    <MDBox display="flex" justifyContent="center">
                      <ContainerDimensions>
                        {({ width }) => {
                          const cardWidth = width > 650 ? 650 : width;
                          const filterReviewCard = response.data.length;
                          const count =
                            filterReviewCard && filterReviewCard === 0
                              ? "No"
                              : filterReviewCard.toString();

                          return (
                            <MDBox
                              pb={2}
                              width={cardWidth}
                              bgColor="white"
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <MDBox display="flex" gap={2} color="warning">
                                {count} flashcards left to revise
                              </MDBox>
                              <MDBox display="flex" gap={2}>
                                <Tooltip title="Filter">
                                  <Avatar
                                    sx={{ bgcolor: "#1A73E8" }}
                                    onClick={() => {
                                      openFilterDialog();
                                    }}
                                  >
                                    <FilterAltIcon />
                                  </Avatar>
                                </Tooltip>
                                <Tooltip title="Revision List">
                                  <Avatar
                                    sx={{ bgcolor: "#1A73E8" }}
                                    onClick={() => {
                                      openListDialog();
                                    }}
                                  >
                                    <ListAltIcon />
                                  </Avatar>
                                </Tooltip>
                              </MDBox>
                            </MDBox>
                          );
                        }}
                      </ContainerDimensions>
                    </MDBox>

                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ContainerDimensions>
                        {({ width }) => {
                          const cardWidth = width > 650 ? 650 : width;
                          return (
                            <>
                              <Tooltip title="Backward">
                                <IconButton
                                  sx={{
                                    fontSize: { xs: 50, lg: 100 },
                                    mr: -2,
                                    ml: -4,
                                  }}
                                  color={flashcardIndex > 0 ? "dark" : "light"}
                                  onClick={() => {
                                    backFlashcard();
                                  }}
                                >
                                  <ArrowLeftIcon />
                                </IconButton>
                              </Tooltip>
                              <MDBox
                                width={cardWidth}
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <ContainerDimensions>
                                  {({ width }) => {
                                    const cardWidth = width > 650 ? 650 : width;
                                    return (
                                      <MDBox
                                        sx={{
                                          p: 2,
                                          border: "2px solid grey",
                                          boxShadow: "5px 5px lightgrey",
                                        }}
                                        width={cardWidth}
                                        bgColor="white"
                                        borderRadius="lg"
                                        mb={1}
                                        minHeight={300}
                                      >
                                        {response.data.length > 0 &&
                                        response.data[flashcardIndex] ? (
                                          <>
                                            <MDBox
                                              display="flex"
                                              justifyContent="flex-end"
                                              gap={1}
                                            >
                                              <MDBadge
                                                badgeContent={Object.keys(
                                                  algoState
                                                ).find(
                                                  (key) =>
                                                    algoState[key] ===
                                                    response.data[
                                                      flashcardIndex
                                                    ].state
                                                )}
                                                size="md"
                                                variant="contained"
                                                container
                                              />
                                              {response.data[flashcardIndex]
                                                .leechTagged && (
                                                <MDBadge
                                                  badgeContent="LEECH"
                                                  size="md"
                                                  variant="contained"
                                                  container
                                                  color="error"
                                                />
                                              )}
                                            </MDBox>
                                            <MDTypography
                                              variant="h5"
                                              fontWeight="bold"
                                            >
                                              <pre
                                                style={{
                                                  fontFamily: "inherit",
                                                  whiteSpace: "pre-wrap",
                                                }}
                                              >
                                                {
                                                  response.data[flashcardIndex]
                                                    .flashcardFront
                                                }
                                              </pre>
                                            </MDTypography>
                                            {response.data[flashcardIndex]
                                              .flashcardFrontImgAttachments
                                              ?.length > 0 && (
                                              <ContainerDimensions>
                                                {({ width }) => {
                                                  const imgWidth =
                                                    width > 650 ? 650 : width;

                                                  return (
                                                    <MDBox>
                                                      {response.data[
                                                        flashcardIndex
                                                      ].flashcardFrontImgAttachments?.map(
                                                        (attachment, idx) => (
                                                          <MDBox
                                                            key={"front" + idx}
                                                            borderRadius="lg"
                                                            width={imgWidth}
                                                            component="img"
                                                            src={
                                                              attachment.attachmentURL
                                                            }
                                                            maxWidth="100%"
                                                            position="relative"
                                                            zIndex={1}
                                                          />
                                                        )
                                                      )}
                                                    </MDBox>
                                                  );
                                                }}
                                              </ContainerDimensions>
                                            )}
                                          </>
                                        ) : (
                                          <MDTypography
                                            variant="h4"
                                            fontWeight="bold"
                                            color="error"
                                          >
                                            No Cards Match Your Search
                                          </MDTypography>
                                        )}
                                      </MDBox>
                                    );
                                  }}
                                </ContainerDimensions>
                                <MDBox m={2}>
                                  <MDButton
                                    variant="gradient"
                                    color="info"
                                    size="large"
                                    disabled={isFlipped}
                                    onClick={() => {
                                      setIsFlipped(!isFlipped);
                                    }}
                                  >
                                    Show Answer
                                  </MDButton>
                                </MDBox>
                                {isFlipped && (
                                  <ContainerDimensions>
                                    {({ width }) => {
                                      const cardWidth =
                                        width > 650 ? 650 : width;
                                      return (
                                        <MDBox
                                          sx={{
                                            p: 2,
                                            border: "2px solid grey",
                                            boxShadow: "5px 5px lightgrey",
                                          }}
                                          width={cardWidth}
                                          bgColor="white"
                                          borderRadius="lg"
                                          mb={1}
                                          minHeight={300}
                                        >
                                          {response.data.length > 0 &&
                                          response.data[flashcardIndex] ? (
                                            <>
                                              <MDBox
                                                display="flex"
                                                justifyContent="flex-end"
                                              >
                                                <MDBadge
                                                  badgeContent={Object.keys(
                                                    algoState
                                                  ).find(
                                                    (key) =>
                                                      algoState[key] ===
                                                      response.data[
                                                        flashcardIndex
                                                      ].state
                                                  )}
                                                  size="md"
                                                  variant="contained"
                                                  container
                                                />
                                              </MDBox>
                                              <MDTypography
                                                variant="h5"
                                                fontWeight="bold"
                                              >
                                                <pre
                                                  style={{
                                                    fontFamily: "inherit",
                                                    whiteSpace: "pre-wrap",
                                                  }}
                                                >
                                                  {
                                                    response.data[
                                                      flashcardIndex
                                                    ].flashcardBack
                                                  }
                                                </pre>
                                              </MDTypography>
                                              {response.data[flashcardIndex]
                                                .flashcardBackImgAttachments
                                                ?.length > 0 && (
                                                <ContainerDimensions>
                                                  {({ width }) => {
                                                    const imgWidth =
                                                      width > 650 ? 650 : width;
                                                    return (
                                                      <MDBox>
                                                        {response.data[
                                                          flashcardIndex
                                                        ].flashcardBackImgAttachments?.map(
                                                          (attachment, idx) => (
                                                            <MDBox
                                                              key={"back" + idx}
                                                              borderRadius="lg"
                                                              width={imgWidth}
                                                              component="img"
                                                              src={
                                                                attachment.attachmentURL
                                                              }
                                                              maxWidth="100%"
                                                              position="relative"
                                                              zIndex={1}
                                                            />
                                                          )
                                                        )}
                                                      </MDBox>
                                                    );
                                                  }}
                                                </ContainerDimensions>
                                              )}
                                            </>
                                          ) : (
                                            <MDTypography
                                              variant="h4"
                                              fontWeight="bold"
                                              color="error"
                                            >
                                              No Cards Match Your Search
                                            </MDTypography>
                                          )}
                                        </MDBox>
                                      );
                                    }}
                                  </ContainerDimensions>
                                )}
                              </MDBox>
                              <Tooltip title="Forward">
                                <IconButton
                                  sx={{
                                    fontSize: { xs: 50, lg: 100 },
                                    ml: -2,
                                    mr: -4,
                                  }}
                                  color={
                                    flashcardIndex < response.data.length - 1
                                      ? "dark"
                                      : "light"
                                  }
                                  onClick={() => {
                                    nextFlashcard();
                                  }}
                                >
                                  <ArrowRightIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          );
                        }}
                      </ContainerDimensions>
                    </MDBox>

                    {isFlipped && scheduleSection}
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      sx={{
                        mt: 1,
                        pt: 1,
                        borderTop: "2px solid silver",
                      }}
                      gap={1}
                    >
                      {sharingSection}
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              <SwipeableDrawer
                PaperProps={{
                  sx: { width: 340 },
                }}
                open={openFilter}
                onClose={closeFilterDialog}
                onOpen={openFilterDialog}
              >
                <Formik
                  enableReinitialize
                  initialValues={filterValues}
                  validationSchema={validations}
                  onSubmit={handleSubmit}
                >
                  {(formik) => {
                    const operationLevel1Options = () => {
                      try {
                        const opLvl1Opts = operationConfigs.map((ele) => {
                          return ele.name;
                        });

                        return ["All", ...opLvl1Opts];
                      } catch (err) {
                        console.error(err);
                        return defaultOption;
                      }
                    };

                    const operationLevel2Options = (
                      selectedOperationLevel1
                    ) => {
                      try {
                        const opLvl2Opts = operationConfigs?.find(
                          (ele) => ele.name === selectedOperationLevel1
                        );

                        return opLvl2Opts
                          ? [
                              "All",
                              ...opLvl2Opts.children?.map((ele) => {
                                return ele.name;
                              }),
                            ]
                          : defaultOption;
                      } catch (err) {
                        console.error(err);
                        return defaultOption;
                      }
                    };

                    return (
                      <Form id={formId} autoComplete="off">
                        <MDBox mt={1} ml={2}>
                          <Grid
                            container
                            p={1}
                            alignItems="center"
                            rowSpacing={2}
                          >
                            <Grid item xs={12}>
                              <MDTypography variant="body3" fontWeight="bold">
                                {operationLevel1.label}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12}>
                              <FormBasicAutocomplete
                                form={formik}
                                field={operationLevel1}
                                size="small"
                                options={operationLevel1Options()}
                                // isOptionEqualToValue={(option, value) =>
                                //   option.value === value.value
                                // }
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <FormBasicAutocomplete
                                form={formik}
                                field={operationLevel2}
                                size="small"
                                options={operationLevel2Options(
                                  formik.values.operationLevel1
                                )}
                                // isOptionEqualToValue={(option, value) =>
                                //   option.value === value.value
                                // }
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>

                            <Grid item xs={12}>
                              <MDBox
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                              >
                                <MDBox></MDBox>
                                <MDButton
                                  disabled={formik.isSubmitting}
                                  type="submit"
                                  variant="gradient"
                                  color="dark"
                                >
                                  Filter
                                </MDButton>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Form>
                    );
                  }}
                </Formik>
              </SwipeableDrawer>
              <SwipeableDrawer
                PaperProps={{
                  sx: { width: 340 },
                }}
                open={openList}
                onClose={closeListDialog}
                onOpen={openListDialog}
              >
                <MDBox p={2}>
                  {response.data.length > 0 &&
                    response.data.map((card, idx) => {
                      return (
                        <MDBox
                          key={card.id}
                          sx={{
                            p: 1,
                            border:
                              card.id !== response.data[flashcardIndex].id
                                ? "1px solid grey"
                                : "4px solid black",
                          }}
                          borderRadius="lg"
                          mb={2}
                        >
                          <MDBox
                            mb={1}
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDBox display="flex" gap={1}>
                              <MDBadge
                                badgeContent={Object.keys(algoState).find(
                                  (key) => algoState[key] === card.state
                                )}
                                size="sm"
                                variant="contained"
                                container
                              />
                              {card.leechTagged && (
                                <MDBadge
                                  badgeContent="LEECH"
                                  size="md"
                                  variant="contained"
                                  container
                                  color="error"
                                />
                              )}
                            </MDBox>
                            {card.id !== response.data[flashcardIndex].id ? (
                              <Tooltip title="Open">
                                <Avatar
                                  sx={{
                                    bgcolor: "#1A73E8",
                                  }}
                                  onClick={() => {
                                    setFlashcardIndex(idx);
                                    setIsFlipped(false);
                                  }}
                                >
                                  <LaunchIcon size="small" />
                                </Avatar>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Currently Viewing">
                                <Avatar
                                  sx={{
                                    bgcolor: "#000000",
                                  }}
                                >
                                  <AutoStoriesIcon />
                                </Avatar>
                              </Tooltip>
                            )}
                          </MDBox>
                          <MDBox mb={1}>
                            <MDTypography variant="body3">
                              To Be Revised At &nbsp;
                              {card.toBeRevisedAt
                                .toDate()
                                .toLocaleString("en-Sg")}
                            </MDTypography>
                          </MDBox>
                          <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap={2}
                          >
                            <MDBox
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                              alignItems="center"
                              gap={2}
                              sx={{
                                p: 1,
                                border: "1px solid grey",
                                boxShadow: "5px 5px lightgrey",
                              }}
                              borderRadius="lg"
                              mb={1}
                            >
                              <MDTypography variant="caption">
                                <pre
                                  style={{
                                    fontFamily: "inherit",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {card.flashcardFront.substring(0, 15) + "..."}
                                </pre>
                              </MDTypography>
                              {card.flashcardFrontImgAttachments?.map(
                                (attachment, idx) => (
                                  <MDBox
                                    key={"front" + idx}
                                    borderRadius="lg"
                                    width={100}
                                    component="img"
                                    src={attachment.attachmentURL}
                                    maxWidth="100%"
                                    position="relative"
                                    zIndex={1}
                                  />
                                )
                              )}
                            </MDBox>
                            <MDBox
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                              alignItems="center"
                              gap={2}
                              sx={{
                                p: 1,
                                border: "1px solid grey",
                                boxShadow: "5px 5px lightgrey",
                              }}
                              borderRadius="lg"
                              mb={1}
                            >
                              <MDTypography variant="caption">
                                <pre
                                  style={{
                                    fontFamily: "inherit",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {card.flashcardBack.substring(0, 15) + "..."}
                                </pre>
                              </MDTypography>
                              {card.flashcardBackImgAttachments?.map(
                                (attachment, idx) => (
                                  <MDBox
                                    key={"back" + idx}
                                    borderRadius="lg"
                                    width={100}
                                    component="img"
                                    src={attachment.attachmentURL}
                                    maxWidth="100%"
                                    position="relative"
                                    zIndex={1}
                                  />
                                )
                              )}
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      );
                    })}
                </MDBox>
              </SwipeableDrawer>
            </MDBox>
            <Footer />
          </>
        )}
      </AllowedTo>
    </DashboardLayout>
  );
}

export default FlashcardRevision;
