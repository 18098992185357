import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDInput from "components/atoms/MDInput";
import MDPagination from "components/atoms/MDPagination";

import DataTableHeadCell from "components/molecules/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/molecules//Tables/DataTable/DataTableBodyCell";

import { ColumnFilter } from "components/molecules/Tables/DataTable/ColumnFilter";
import { Divider } from "@mui/material";
function DataTable({
  entriesPerPage,
  canGlobalSearch,
  canColumnSearch,
  showTotalEntries,
  table,
  hiddenColumns,
  pagination,
  canColumnSort,
  noEndBorder,
  stickyFirstColumn,
  actionMenu,
  maxHeight,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const defaultColumn = useMemo(() => ({ Filter: ColumnFilter }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, hiddenColumns: hiddenColumns },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // ******************************************************************************
  // Pagination Setting
  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue, setPageSize]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));
  // eof Pagination Setting
  // ******************************************************************************

  // ******************************************************************************
  // Global Search setting
  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);
  // eof Global Search setting
  // ******************************************************************************

  // ******************************************************************************
  // Set sorting value
  // A function that sets the sorted value for the table

  const isLeafColumn = (column) => !column.columns;

  const setSortedValue = (column) => {
    let sortedValue;

    if (
      isLeafColumn(column) &&
      canColumnSort &&
      column.canSort &&
      column.isSorted
    ) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isLeafColumn(column) && canColumnSort && column.canSort) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };
  // eof Set sorting value
  // ******************************************************************************

  return (
    <>
      {actionMenu || canGlobalSearch ? (
        <>
          <MDBox
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="flex-end"
          >
            {actionMenu && (
              <MDBox flexGrow="9">
                <MDBox
                  display="flex"
                  gap="10px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {actionMenu}
                </MDBox>
              </MDBox>
            )}
            {canGlobalSearch && (
              <MDBox flexGrow="1">
                <MDInput
                  placeholder="Search..."
                  size="small"
                  fullWidth
                  value={search}
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </MDBox>
            )}
          </MDBox>
          <Divider variant="middle" />
        </>
      ) : (
        <></>
      )}
      <TableContainer sx={{ boxShadow: "none", maxHeight: maxHeight }}>
        <Table stickyHeader {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps()}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={isLeafColumn && setSortedValue(column)}
                    sticky={index === 0 && stickyFirstColumn}
                  >
                    <MDBox
                      {...(isLeafColumn &&
                        canColumnSort &&
                        column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </MDBox>
                    {canColumnSearch && (
                      <MDBox mt={1}>
                        {column.canFilter ? column.render("Filter") : null}
                      </MDBox>
                    )}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, index) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      sticky={index === 0 && stickyFirstColumn}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Display pagination */}
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entries}
              onChange={(event, newValue) => {
                setEntriesPerPage(parseInt(newValue, 10));
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;entries per page
            </MDTypography>
          </MDBox>
        )}
        {/* Display "entries Start" to "entries End" */}
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}

        {/* Show pagination (i) arrow previous, (ii) page numbers & (iii) arrow next  */}
        {pageOptions.length > 1 ? (
          <MDPagination variant={pagination.variant} color={pagination.color}>
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{
                    type: "number",
                    min: 1,
                    max: customizedPageOptions.length,
                  }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        ) : (
          <MDBox></MDBox>
        )}
      </MDBox>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 25, entries: [5, 10, 15, 20, 25] },
  canGlobalSearch: false,
  canColumnSearch: false,
  canColumnSort: false,
  showTotalEntries: true,
  hiddenColumns: [],
  pagination: { variant: "gradient", color: "info" },
  noEndBorder: false,
  maxHeight: "50vh",
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canGlobalSearch: PropTypes.bool,
  canColumnSearch: PropTypes.bool,
  canColumnSort: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  hiddenColumns: PropTypes.array,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  noEndBorder: PropTypes.bool,
  actionMenu: PropTypes.node,
  maxHeight: PropTypes.string,
};

export default DataTable;
