import PropTypes from "prop-types";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { ErrorMessage, Field } from "formik";
import DebounceTextFieldWrapper from "components/molecules/Formik/Wrapper/DebounceTextFieldWrapper";

function FormTextField({
  form,
  field,
  defaultValue = "",
  onChange,
  disabled,
  hideLabel,
  hideStatus,
  hideField,
  notTouchable,
  ...rest
}) {
  const { values, errors, touched, handleChange } = form;
  const value = values[field.name] ?? defaultValue;
  const error = disabled
    ? false
    : !!errors[field.name] && (notTouchable || touched[field.name]);
  const success = disabled
    ? false
    : !!values[field.name] &&
      values[field.name].length > 0 &&
      !errors[field.name];

  const onChangeCombined = onChange
    ? (e) => {
        handleChange(e);
        onChange(e);
      }
    : (e) => {
        handleChange(e);
      };

  return (
    <MDBox mb={2}>
      <Field
        name={field.name}
        type={field.type}
        label={hideLabel ? "" : field.label}
        value={value}
        onChange={onChangeCombined}
        as={DebounceTextFieldWrapper}
        placeholder={field.placeholder}
        disabled={disabled}
        error={hideStatus ? false : error}
        success={hideStatus ? false : success}
        sx={hideField ? { display: "none" } : {}}
        {...rest}
      />
      {!hideStatus && (
        <MDBox mt={0.75}>
          <MDTypography
            component="div"
            variant="caption"
            color="error"
            fontWeight="regular"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            <ErrorMessage name={field.name}></ErrorMessage>
          </MDTypography>
        </MDBox>
      )}
    </MDBox>
  );
}

// Setting default values for the props of FormTextField
FormTextField.defaultProps = {
  hideLabel: false,
  hideStatus: false,
  notTouchable: false,
};

// typechecking props for FormTextField
FormTextField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideStatus: PropTypes.bool,
  notTouchable: PropTypes.bool,
  children: PropTypes.node,
};

export default FormTextField;
