import PropTypes from "prop-types";

import Container from "@mui/material/Container";

import MDBox from "components/atoms/MDBox";
import Footer from "components/organisms/Footer";

function FooterFragment({ color, children }) {
  return (
    <>
      <MDBox position="absolute" width="100%" bottom={0} py={4}>
        <Container>
          <Footer color={color} />
        </Container>
      </MDBox>
      {children}
    </>
  );
}

// Typechecking props for the FooterFragment
FooterFragment.propTypes = {
  color: PropTypes.oneOf(["text", "white", "dark"]),
  children: PropTypes.node,
};

export default FooterFragment;
