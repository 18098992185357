import { useParams } from "react-router-dom";
import CseChart from "pages/csesurveys/viewchart/CseChart";

// using Proxy Page to remount when route to same route location but different param
console.log("csechartproxy.js");

function CseChartProxy() {
  const { mode, csechartId } = useParams();
  return <CseChart key={`${mode}/${csechartId}`} />;
}

export default CseChartProxy;
