import { useEffect, useState } from "react";
import {
  signInUser,
  signInUserWithGoogle,
  signInUserWithSingpass,
  setAuthStatePersistence,
  getUserRoles,
} from "services/authentication";
import { useAuthContext } from "context/AuthContext";

export const useSignin = () => {
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { dispatch } = useAuthContext();

  const signin = async (email, password, remember) => {
    try {
      setError(null);

      let persistenceType = remember ? "local" : "none";

      await setAuthStatePersistence(persistenceType);

      const userCredential = await signInUser(email, password);

      if (!userCredential) {
        throw new Error("Could not complete signin");
      }

      const user = userCredential.user;
      const roles = await getUserRoles();
      //console.log("[useSignin] roles: " + JSON.stringify(roles));
      dispatch({
        type: "SIGNIN",
        payload: { user: user, roles: roles },
      });

      if (!isUnmounted) {
        setError(null);
        setSuccess("Sign in succeed.");
      }
    } catch (err) {
      if (!isUnmounted) {
        // SEE https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html#authentication-and-error-messages
        // Don't show error on console
        // Respond with generic error message regardless
        // console.error(err.message);
        setError("Sign in failed; Invalid email or password.");
        setSuccess(null);
      }
    }
  };

  const signinWithGoogle = async () => {
    try {
      setError(null);

      let persistenceType = "local";
      await setAuthStatePersistence(persistenceType);

      const userCredential = await signInUserWithGoogle();

      if (!userCredential) {
        throw new Error("Could not complete signin");
      }

      const user = userCredential.user;
      const roles = await getUserRoles();
      console.log("[useSignin] roles: " + JSON.stringify(roles));
      dispatch({
        type: "SIGNIN",
        payload: { user: user, roles: roles },
      });

      if (!isUnmounted) {
        setError(null);
        setSuccess("Sign in succeed.");
      }
    } catch (err) {
      if (!isUnmounted) {
        // SEE https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html#authentication-and-error-messages
        // Don't show error on console
        // Respond with generic error message regardless
        // console.error(err.message);
        setError("Sign in failed; Invalid email or password.");
        setSuccess(null);
      }
    }
  };

  const signinWithSingpass = async () => {
    try {
      setError(null);

      let persistenceType = "local";
      await setAuthStatePersistence(persistenceType);

      const userCredential = await signInUserWithSingpass();

      if (!userCredential) {
        throw new Error("Could not complete signin");
      }

      const user = userCredential.user;
      const roles = await getUserRoles();
      console.log("[useSignin] roles: " + JSON.stringify(roles));
      dispatch({
        type: "SIGNIN",
        payload: { user: user, roles: roles },
      });

      if (!isUnmounted) {
        setError(null);
        setSuccess("Sign in succeed.");
      }
    } catch (err) {
      if (!isUnmounted) {
        // SEE https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html#authentication-and-error-messages
        // Don't show error on console
        // Respond with generic error message regardless
        // console.error(err.message);
        setError("Sign in failed; Invalid email or password.");
        setSuccess(null);
      }
    }
  };

  useEffect(() => {
    return () => setIsUnmounted(true);
  }, []);

  return { signin, signinWithGoogle, signinWithSingpass, error, success };
};
