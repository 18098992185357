const Role = Object.freeze({
  //SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  FACULTY: "FACULTY",
  RESIDENT: "RESIDENT",
  MEMBER: "MEMBER",
});

const Permission = Object.freeze({
  // permission of dashboard (as shown on side nav bar)
  //VIEW_SUPERADMIN_DASHBOARD: "VIEW_SUPERADMIN_DASHBOARD",
  VIEW_ADMIN_DASHBOARD: "VIEW_ADMIN_DASHBOARD",
  VIEW_MEMBER_DASHBOARD: "VIEW_MEMBER_DASHBOARD",
  // VIEW_MANAGER_DASHBOARD: "VIEW_MANAGER_DASHBOARD",
  // VIEW_SALES_DASHBOARD: "VIEW_SALES_DASHBOARD",
  // VIEW_PURCHASES_DASHBOARD: "VIEW_PURCHASES_DASHBOARD",
  // VIEW_ACCOUNT_DASHBOARD: "VIEW_ACCOUNT_DASHBOARD",
  // VIEW_FINANCE_DASHBOARD: "VIEW_FINANCE_DASHBOARD",
  // VIEW_SERVICE_DASHBOARD: "VIEW_SERVICE_DASHBOARD",
  // VIEW_HIDE_DASHBOARD: "VIEW_HIDE_DASHBOARD",
  // permission of action (as shown on side nav bar)
  // VIEW_USERS_ACTION: "VIEW_USERS_ACTION",
  // VIEW_CUSTOMERS_ACTION: "VIEW_CUSTOMERS_ACTION",
  // VIEW_SUPPLIERS_ACTION: "VIEW_SUPPLIERS_ACTION",
  // VIEW_QUOTATIONS_ACTION: "VIEW_QUOTATIONS_ACTION",
  // VIEW_SALESORDERS_ACTION: "VIEW_SALESORDERS_ACTION",
  // VIEW_INVOICES_ACTION: "VIEW_INVOICES_ACTION",
  // VIEW_PURCHASEORDER_ACTION: "VIEW_PURCHASEORDER_ACTION",
  // VIEW_SERVICEREPORTS_ACTION: "VIEW_SERVICEREPORTS_ACTION",
  VIEW_FLASHCARD_SETTINGS: "VIEW_FLASHCARD_SETTINGS",

  // permission for data (also use for firestore rules)
  //PERFORM_SUPERADMIN_ACTION: "PERFORM_SUPERADMIN_ACTION",
  PERFORM_ADMIN_ACTION: "PERFORM_ADMIN_ACTION",

  READ_ALL_ROLES: "READ_ALL_ROLES",
  CREATE_ROLE: "CREATE_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  READ_ROLE: "READ_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  // PURGE_ROLE: "PURGE_ROLE",

  READ_ALL_USERS: "READ_ALL_USERS",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  READ_USER: "READ_USER",
  DELETE_USER: "DELETE_USER",
  // PURGE_USER: "PURGE_USER",

  READ_CCMETHODS: "READ_CCMETHODS",
  WRITE_CCMETHODS: "WRITE_CCMETHODS",

  VIEW_CSESURVEY_ACTION: "VIEW_CSESURVEY_ACTION",
  CREATE_CSESURVEY: "CREATE_CSESURVEY",
  READ_ALL_CSESURVEYS: "READ_ALL_CSESURVEYS",
  VIEW_CSECHART: "VIEW_CSECHART",
  UPDATE_CSESURVEY: "UPDATE_CSESURVEY",
  READ_CSESURVEY: "READ_CSESURVEY",
  PRINT_CSESURVEY: "PRINT_CSESURVEY",
  DELETE_CSESURVEY: "DELETE_CSESURVEY",
  PURGE_CSESURVEY: "PURGE_CSESURVEY",
  EXPORT_CSESURVEYS: "EXPORT_CSESURVEYS",

  VIEW_LOGBOOK_ACTION: "VIEW_LOGBOOK_ACTION",
  CREATE_LOGBOOK: "CREATE_LOGBOOK",
  READ_LOGBOOK: "READ_LOGBOOK",
  READ_ALL_LOGBOOKS: "READ_ALL_LOGBOOKS",
  UPDATE_LOGBOOK: "UPDATE_LOGBOOK",
  DELETE_LOGBOOK: "DELETE_LOGBOOK",
  EXPORT_LOGBOOKS: "EXPORT_LOGBOOKS",

  UPDATE_FLASHCARD_SETTINGS: "UPDATE_FLASHCARD_SETTINGS",

  VIEW_FLASHCARD_ACTION: "VIEW_FLASHCARD_ACTION",
  CREATE_FLASHCARD: "CREATE_FLASHCARD",
  UPDATE_FLASHCARD: "UPDATE_FLASHCARD",
  REVISE_FLASHCARD: "REVISE_FLASHCARD",
  DISCOVER_FLASHCARD: "DISCOVER_FLASHCARD",
  RATE_FLASHCARD: "RATE_FLASHCARD",
  ENDORSE_FLASHCARD: "ENDORSE_FLASHCARD",
  SHARE_FLASHCARD: "SHARE_FLASHCARD",
  FILTER_FLASHCARD: "FILTER_FLASHCARD",
  DELETE_FLASHCARD: "DELETE_FLASHCARD",
  READ_ALL_FLASHCARDS: "READ_ALL_FLASHCARDS",
});

export { Role, Permission };
