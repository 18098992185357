import moment from "moment-timezone";

export const algoState = {
  New: 0,
  Learning: 1,
  Review: 2,
  Relearning: 3,
};

export const algoGrade = {
  Again: 0,
  Hard: 1,
  Good: 2,
  Easy: 3,
};

export function supermemo(item, rate, config) {
  let {
    state,
    stateRepetition,
    hardRepetition,
    goodRepetition,
    interval,
    efactor,
    leechCount,
  } = item;

  let leechTagged = false;
  let leechSuspended = false;

  const learningSteps = config.learningSteps
    .split(" ")
    .map(
      (ele) =>
        moment.duration(
          ele.substring(0, ele.length - 1),
          ele.substring(ele.length - 1)
        ) * 1
    );
  const relearningSteps = config.relearningSteps
    .split(" ")
    .map(
      (ele) =>
        moment.duration(
          ele.substring(0, ele.length - 1),
          ele.substring(ele.length - 1)
        ) * 1
    );

  const graduatingInterval =
    moment.duration(config.graduatingInterval, "d") * 1;
  const easyInterval = moment.duration(config.easyInterval, "d") * 1;
  const minimumInterval = moment.duration(config.minimumInterval, "d") * 1;
  const maximumInterval = moment.duration(config.maximumInterval, "d") * 1;

  stateRepetition = stateRepetition + 1;

  if (state === algoState.New || state === algoState.Learning) {
    if (state === algoState.New) {
      // first time from New to Learning
      state = algoState.Learning;
      efactor = config.startingEase;
      // New state is merely use for the display order.
    }
    if (rate === algoGrade.Again) {
      hardRepetition = 0;
      goodRepetition = 0;
      interval = learningSteps[0];
    } else if (rate === algoGrade.Hard) {
      hardRepetition = hardRepetition + 1;
      goodRepetition = 0;
      // https://docs.ankiweb.net/studying.html#learningrelearning-cards
      if (hardRepetition === 1 && learningSteps.length === 1) {
        // If the card is on the first (and the only) step,
        // the delay is 50 % larger than the step.
        // But, this delay is at most one day (86400000 milliseconds) larger than the step.
        interval = learningSteps[0] * 1.5;
        if (interval > learningSteps[0] + 86400000) {
          interval = learningSteps[0] + 86400000;
        }
      } else if (hardRepetition === 1 && learningSteps.length > 1) {
        // If the card is on the first step and you have configured more than one step,
        // the delay will be the average of Again and Good, i.e., the average of the first two steps.
        interval = (learningSteps[0] + learningSteps[1]) / 2;
      } else {
        //If the card is on any subsequent step, Hard repeats the previous delay.
        interval = interval * 1;
      }
    } else if (rate === algoGrade.Good) {
      hardRepetition = 0;
      goodRepetition = goodRepetition + 1;
      if (goodRepetition < learningSteps.length) {
        //https://docs.ankiweb.net/studying.html#learningrelearning-cards
        // By default there are two steps: 1 minute and 10 minutes.
        // When cards are seen for the first time, they start at step one.
        // This means answering Good on a card for the first time will show it one more time in 10 minutes, and the initial 1 minute step will be skipped.
        interval = learningSteps[goodRepetition];
      } else {
        goodRepetition = 0;
        stateRepetition = 0;
        state = algoState.Review;
        interval = graduatingInterval;
      }
    } else if (rate === algoGrade.Easy) {
      hardRepetition = 0;
      goodRepetition = 0;
      stateRepetition = 0;
      state = algoState.Review;
      interval = easyInterval;
    }
  } else if (state === algoState.Review) {
    if (rate === algoGrade.Again) {
      hardRepetition = 0;
      goodRepetition = 0;
      if (stateRepetition === 1) {
        stateRepetition = 0;
        state = algoState.Relearning;
        interval = relearningSteps[0];
        efactor = efactor - 0.2;
      } else {
        if (config.newInterval > 0) {
          interval = interval * config.newInterval;
        } else {
          interval = minimumInterval;
        }
      }
    } else if (rate === algoGrade.Hard) {
      hardRepetition = hardRepetition + 1;
      goodRepetition = 0;
      interval = interval * config.hardInterval;
      efactor = efactor - 0.15;
    } else if (rate === algoGrade.Good) {
      hardRepetition = 0;
      goodRepetition = goodRepetition + 1;
      interval = interval * efactor;
    } else if (rate === algoGrade.Easy) {
      hardRepetition = 0;
      goodRepetition = 0;
      interval = interval * efactor * config.easyBonus;
    }
  } else if (state === algoState.Relearning) {
    if (rate === algoGrade.Again) {
      hardRepetition = 0;
      goodRepetition = 0;
      stateRepetition = 0;
      interval = relearningSteps[0];
      leechCount = leechCount + 1;
      if (leechCount > config.leechThreshold) {
        if (config.leechAction === "Tag") {
          leechTagged = true;
        } else if (config.leechAction === "Suspend") {
          leechSuspended = true;
        }
        // warnings occur at half the initial leech threshold.
        // For example, if you set the warning at 8 lapses,
        // future warnings will happen every 4 lapses(at 12, 16, and so on).
        // leech action here (Tag, or Suspend), suspend can be unsuspend in future
      }
    } else if (rate === algoGrade.Hard) {
      hardRepetition = hardRepetition + 1;
      goodRepetition = 0;
      // https://docs.ankiweb.net/studying.html#learningrelearning-cards
      if (hardRepetition === 1 && learningSteps.length === 1) {
        // If the card is on the first (and the only) step,
        // the delay is 50 % larger than the step.
        // But, this delay is at most one day (86400000 milliseconds) larger than the step.
        interval = learningSteps[0] * 1.5;
        if (interval > learningSteps[0] + 86400000) {
          interval = learningSteps[0] + 86400000;
        }
      } else if (hardRepetition === 1 && learningSteps.length > 1) {
        // If the card is on the first step and you have configured more than one step,
        // the delay will be the average of Again and Good, i.e., the average of the first two steps.
        interval = (learningSteps[0] + learningSteps[1]) / 2;
      } else {
        //If the card is on any subsequent step, Hard repeats the previous delay.
        interval = interval * 1;
      }
    } else if (rate === algoGrade.Good) {
      hardRepetition = 0;
      goodRepetition = goodRepetition + 1;
      if (goodRepetition < relearningSteps.length) {
        interval = relearningSteps[goodRepetition];
      } else {
        goodRepetition = 0;
        stateRepetition = 0;
        state = algoState.Review;
        interval = minimumInterval;
      }
    } else if (rate === algoGrade.Easy) {
      hardRepetition = 0;
      goodRepetition = 0;
      stateRepetition = 0;
      state = algoState.Review;
      interval = minimumInterval;
    }
  }
  if (interval > maximumInterval) {
    interval = maximumInterval;
  }
  const due = moment()
    .tz("Asia/Singapore")
    .add(moment.duration(interval))
    .toDate();

  return {
    state: state,
    stateRepetition: stateRepetition,
    hardRepetition: hardRepetition,
    goodRepetition: goodRepetition,
    interval: interval,
    efactor: efactor,
    leechCount: leechCount,
    leechTagged: leechTagged,
    leechSuspended: leechSuspended,
    toBeRevisedAt: due,
  };
}
