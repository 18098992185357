import form, { rolesForm } from "pages/settings/user/new/schemas/form";

const {
  formField: { displayName, designation, email, password, medicalID, roles },
} = form;

const initialValues = {
  [displayName.name]: "",
  [designation.name]: "",
  [email.name]: "",
  [password.name]: "",
  [medicalID.name]: "",
  [roles.name]: [],
};

const {
  formField: { roleName },
} = rolesForm;

const rolesInitialValues = {
  [roleName.name]: "",
};

export { initialValues as default, rolesInitialValues };
