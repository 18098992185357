import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PlainFullLayout from "components/templates/generic/layouts/PlainFullLayout";

import Card from "@mui/material/Card";

import MDAvatar from "components/atoms/MDAvatar";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import ErrorAlert from "components/molecules/Notifications/ErrorAlert";
import SuccessAlert from "components/molecules/Notifications/SuccessAlert";

import FormField from "components/molecules/FormField";

import logoImage from "assets/images/logo.png";
import bgImage from "assets/images/bg-signin.jpeg";
// import signinwithgoogleImage from "assets/images/signinwithgoogle.png";
// import loginwithsingpassImage from "assets/images/loginwithsingpass.png";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import RedirectAuth from "components/molecules/RedirectAuth";

import { useSignin } from "pages/authentication/hooks/useSignin";
import { useResetPassword } from "pages/authentication/hooks/useResetPassword";
//import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
//import { Icon } from "@mui/material";

function SignIn() {
  const { signin, /*signinWithGoogle, signinWithSingpass,*/ error, success } =
    useSignin();
  const { sendPasswordResetEmail } = useResetPassword();
  const continueURL = window.location.href.replace(
    window.location.pathname,
    "/authentication/signin"
  );
  const [errorResetPassword, setErrorResetPassword] = useState(null);
  const [successResetPassword, setSuccessResetPassword] = useState(null);
  const msgSuccessResetPassword =
    "A link to reset your password has been emailed to the address provided. Please click the link in the email to complete the password reset process.";

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [showDetails, setShowDetails] = useState(true);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const handleMinimize = async () => {
    showDetails === true ? setShowDetails(false) : setShowDetails(true);
  };

  const handleShowResetPassword = async () => {
    showResetPassword === true
      ? setShowResetPassword(false)
      : setShowResetPassword(true);
  };

  // const signinUsingGoogle = async () => {
  //   await signinWithGoogle();
  // };

  // const signinUsingSingpass = async () => {
  //   await signinWithSingpass();
  // };

  return (
    <RedirectAuth>
      {error && <ErrorAlert message={error} />}
      {success && <SuccessAlert message={success} />}
      {errorResetPassword && <ErrorAlert message={errorResetPassword} />}
      {successResetPassword && <SuccessAlert message={successResetPassword} />}
      <PlainFullLayout image={bgImage}>
        <MDBox sx={{ width: "100%", pt: 10 }}>
          <Card>
            <MDBox
              mt={-8}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MDAvatar
                src={logoImage}
                alt="KKH Cusum"
                bgColor="light"
                size="xxl"
              />
            </MDBox>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-2}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography
                variant="h4"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Welcome to the KKH WAN Cusum portal!
              </MDTypography>

              {showDetails === true ? (
                <MDTypography
                  display="block"
                  variant="body3"
                  color="white"
                  my={1}
                  textAlign="justify"
                >
                  Cumulative sum analysis is a validated quality control tool to
                  determine competence for medical procedures. Our aim is to
                  provide you with formative real-time feedback on your labour
                  epidural learning as you log your cases.
                </MDTypography>
              ) : (
                ""
              )}

              {showDetails === true ? (
                <MDTypography
                  display="block"
                  variant="body3"
                  color="white"
                  my={1}
                  textAlign="justify"
                >
                  The automated analysis is plotted graphically and we
                  appreciate your help to verify some criteria during CSE
                  attempt. We have designed a simple form!
                </MDTypography>
              ) : (
                ""
              )}

              {showDetails === true ? (
                <MDTypography
                  display="block"
                  variant="body3"
                  color="white"
                  my={1}
                  textAlign="justify"
                >
                  You should aim to achieve a steady downslope in the graph
                  indicating improving performance. When the graph crosses two
                  boundary lines from the peak, it signifies that you have
                  achieved competence. Continued monitoring will help to track
                  your learning.
                </MDTypography>
              ) : (
                ""
              )}

              {showDetails === true ? (
                <MDTypography
                  display="block"
                  variant="body3"
                  color="white"
                  my={1}
                  pt={2}
                >
                  Enjoy your posting at KKH Women’s Anaesthesia!
                </MDTypography>
              ) : (
                ""
              )}

              <MDBox textAlign="right">
                <MDButton
                  type="submit"
                  variant="text"
                  color="light"
                  size="small"
                  onClick={() => handleMinimize()}
                >
                  {showDetails === true ? "hide ..." : "show ..."}
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox pt={1} pb={0} px={3}>
              <MDTypography variant="body2" my={1}>
                Enter your email and password to sign in.
              </MDTypography>
            </MDBox>

            <MDBox pt={0} pb={3} px={3}>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  remember: false,
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .required("Required")
                    .email("Invalid email address"),
                  password: Yup.string().required("Required"),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  await signin(values.email, values.password, values.remember);
                  error && setSubmitting(false);
                  success && navigate(from, { replace: true });
                }}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form>
                    <FormField
                      type="email"
                      label="Email"
                      name="email"
                      error={errors.email && touched.email}
                      success={values.email.length > 0 && !errors.email}
                    />
                    <FormField
                      type="password"
                      label="Password"
                      name="password"
                      error={errors.password && touched.password}
                      success={values.password.length > 0 && !errors.password}
                    />
                    <MDBox mt={-1} ml={-1}>
                      <FormField type="checkbox" label="" name="remember">
                        <MDTypography
                          variant="body3"
                          fontWeight="regular"
                          color="text"
                          sx={{
                            cursor: "pointer",
                            userSelect: "none",
                            ml: -1,
                          }}
                        >
                          &nbsp;&nbsp;Remember me
                        </MDTypography>
                      </FormField>
                    </MDBox>
                    <MDBox mt={2} mb={1}>
                      <MDButton
                        disabled={isSubmitting}
                        type="submit"
                        variant="gradient"
                        color="success"
                        fullWidth
                      >
                        sign in
                      </MDButton>
                    </MDBox>
                    {/*<MDBox mt={-1} textAlign="right">
                      <MDTypography
                        component={Link}
                        to="/authentication/resetpassword"
                        variant="caption"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Forgot password?
                      </MDTypography>
                        </MDBox>*/}
                  </Form>
                )}
              </Formik>
              {/* <MDBox mt={2} mb={1} textAlign="center">
                <MDBox
                  component="img"
                  src={signinwithgoogleImage}
                  // alt="Brand"
                  height="2.5rem"
                  onClick={() => signinUsingGoogle()}
                />
              </MDBox>
              <MDBox mt={2} mb={1} textAlign="center">
                <MDBox
                  component="img"
                  src={loginwithsingpassImage}
                  // alt="Brand"
                  height="2.5rem"
                  onClick={() => signinUsingSingpass()}
                />
              </MDBox> */}
            </MDBox>
            <MDBox textAlign="right">
              <MDButton
                type="submit"
                variant="text"
                color="primary"
                size="small"
                onClick={() => handleShowResetPassword()}
              >
                Forgot password?
              </MDButton>
            </MDBox>
            {showResetPassword && (
              <MDBox pt={4} pb={3} px={3}>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .required("Required")
                      .email("Invalid email address"),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    await sendPasswordResetEmail(values.email, continueURL);
                    setSuccessResetPassword(msgSuccessResetPassword);
                    setErrorResetPassword(null);
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form>
                      <FormField
                        type="email"
                        label="Email"
                        name="email"
                        errorResetPassword={errors.email && touched.email}
                        successResetPassword={
                          values.email.length > 0 && !errors.email
                        }
                      />
                      <MDBox mt={3} mb={1}>
                        <MDButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="success"
                          fullWidth
                        >
                          Send Password Reset Email
                        </MDButton>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </MDBox>
            )}
            <MDBox mt={2} mb={1} textAlign="center">
              <MDBox>
                <MDTypography variant="caption" color="text" textGradient>
                  Don&apos;t have an account?{" "}
                </MDTypography>
              </MDBox>
              <MDBox mt={-1}>
                <MDTypography
                  variant="caption"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Please Consult with Your Administrator
                </MDTypography>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      </PlainFullLayout>
    </RedirectAuth>
  );
}

export default SignIn;
