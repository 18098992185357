import * as Yup from "yup";
import form from "pages/flashcard/revise/schemas/form";

const {
  formField: {
    // anaesthesiaMode,
    // airwayManagement,
    // additionalProcedures,
    // disposition,
    // tags,
    operationLevel1,
    operationLevel2,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  //   [anaesthesiaMode.name]: Yup.array(),
  //   [airwayManagement.name]: Yup.array(),
  //   [additionalProcedures.name]: Yup.array(),
  //   [disposition.name]: Yup.array(),
  //   [tags.name]: Yup.array().max(30, tags.max30Msg),
  [operationLevel1.name]: Yup.string()
    .nullable()
    .required(operationLevel1.errorMsg),
  [operationLevel2.name]: Yup.string()
    .nullable()
    .required(operationLevel2.errorMsg),
});

export { validations as default, noValidation };
