import MDBox from "components/atoms/MDBox";
import MDInput from "components/atoms/MDInput";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;

  return (
    <span>
      <MDBox width="5rem">
        <MDInput
          placeholder="Search..."
          size="small"
          value={filterValue || ""}
          onChange={(e) => setFilter(e.target.value)}
        />
      </MDBox>
    </span>
  );
};
