const defaultAcceotableFailureRate = 10;
const defaultUnacceotableFailureRate = 14;
const defaultType1Error = 0.1;
const defaultType2Error = 0.15;

export {
  defaultAcceotableFailureRate,
  defaultUnacceotableFailureRate,
  defaultType1Error,
  defaultType2Error,
};
