import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useFile } from "hooks/useFile";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import { noValidation } from "pages/flashcard/manage/schemas/validations";

const initialState = {
  isPending: true,
  data: [],
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: [],
        success: null,
        error: null,
      };
    case "INITIAL_FLASHCARD":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_FLASHCARD":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };

    case "RATED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully rated the flashcard.`,
        error: null,
      };
    case "ENDORSED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully endorsed the flashcard.`,
        error: null,
      };
    case "DISENDORSED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully disendorsed the flashcard.`,
        error: null,
      };
    case "ADDED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully added the flashcard.`,
        error: null,
      };
    case "FILTERED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully filtered the flashcard.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: [],
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useFlashcardDiscoveryManager = (mode) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();

  const { createDoc, retrieveDoc, retrieveDocs, updateDoc, serverTimestamp } =
    useDocument();

  const { getFileRef } = useFile();

  const { pathname } = useLocation();

  const retrieveFlashcardList = useCallback(
    async (filter) => {
      try {
        const publicflashcardsQueries = {
          whereQueries: [
            {
              field: "deletedAt",
              condition: "==",
              value: null,
            },
          ],
          orderByQueries: [
            {
              field: "flashcardEndorsementCount",
              direction: "desc",
            },
            {
              field: "flashcardRating",
              direction: "desc",
            },
          ],
        };

        if (
          filter.discoveryFilterOperationLevel1 &&
          filter.discoveryFilterOperationLevel1 !== "All"
        ) {
          publicflashcardsQueries.whereQueries.push({
            field: "operationLevel1",
            condition: "==",
            value: filter.discoveryFilterOperationLevel1,
          });
        }
        if (
          filter.discoveryFilterOperationLevel2 &&
          filter.discoveryFilterOperationLevel2 !== "All"
        ) {
          publicflashcardsQueries.whereQueries.push({
            field: "operationLevel2",
            condition: "==",
            value: filter.discoveryFilterOperationLevel2,
          });
        }

        const retrievedPublicFlashcards = await retrieveDocs(
          "publicflashcards",
          publicflashcardsQueries
        );

        return retrievedPublicFlashcards;
      } catch (err) {
        console.error(err);
      }
    },
    [retrieveDocs]
  );

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  // TODO: Refactor to DAO Layer
  const toPresentationValue = useCallback(
    async (data) => {
      try {
        if (mode !== "new" && data.date) data.date = data.date.toDate();

        if (data.flashcardFrontImgAttachments) {
          data.flashcardFrontImgAttachments.forEach(async (element) => {
            try {
              const fileRef = await getFileRef(element.attachmentPath);
              element.attachmentURL = fileRef.url;
            } catch (err) {
              element.attachmentURL = null;
            }
          });
        }

        if (data.flashcardBackImgAttachments) {
          data.flashcardBackImgAttachments.forEach(async (element) => {
            try {
              const fileRef = await getFileRef(element.attachmentPath);
              element.attachmentURL = fileRef.url;
            } catch (err) {
              element.attachmentURL = null;
            }
          });
        }

        return data;
      } catch (err) {
        console.error(err);
        dispatchError(err);
      }
    },
    [dispatchError, getFileRef, mode]
  );

  const dispatchDismiss = useCallback(async () => {
    let operationInvalidError = new Error(
      "Invalid Operation. You are not allowed to carry out this activity."
    );
    operationInvalidError.name = "OperationInvalidError";

    switch (mode) {
      case "discover":
        const retrievedUser = await retrieveDoc("users", user.uid);
        const filter = {
          discoveryFilterOperationLevel1:
            retrievedUser.data.discoveryFilterOperationLevel1,
          discoveryFilterOperationLevel2:
            retrievedUser.data.discoveryFilterOperationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "RETRIEVED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });

        break;
      default:
        throw operationInvalidError;
    }
  }, [
    dispatchIfNotUnmounted,
    mode,
    retrieveDoc,
    retrieveFlashcardList,
    toPresentationValue,
    user.uid,
  ]);

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      switch (mode) {
        case "discover":
          if (!pathname.includes("/flashcard/manage")) {
            throw operationInvalidError;
          }

          const retrievedUser = await retrieveDoc("users", user.uid);
          const filter = {
            discoveryFilterOperationLevel1:
              retrievedUser.data.discoveryFilterOperationLevel1,
            discoveryFilterOperationLevel2:
              retrievedUser.data.discoveryFilterOperationLevel2,
          };

          const flashcardList = await retrieveFlashcardList(filter);

          dispatchIfNotUnmounted({
            type: "RETRIEVED_FLASHCARD",
            payload: await Promise.all(
              flashcardList?.map(async (ele) => {
                const data = await toPresentationValue(ele.data);
                return {
                  id: ele.id,
                  ...data,
                };
              }) ?? []
            ),
          });
          break;
        default:
          throw operationInvalidError;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    mode,
    pathname,
    retrieveDoc,
    user.uid,
    retrieveFlashcardList,
    toPresentationValue,
    dispatchError,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "";
  let modeSubmit = "";
  let modeFieldDisabled = true;
  let modePermission = "";
  let modeValidation = noValidation;
  switch (mode) {
    case "discover":
      modeTitle = "Discover Flashcard";
      modeSubmit = "Close";
      modeFieldDisabled = false;
      modePermission = Permission.DISCOVER_FLASHCARD;
      modeValidation = noValidation;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  // Flashcard Discovery functions
  const submitRating = async (values, flashcardId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.RATE_FLASHCARD)) {
        await updateDoc("publicflashcards", flashcardId, {
          ...values,
          ratedAt: serverTimestamp(),
          ratedBy: user.uid,
        });

        const retrievedUser = await retrieveDoc("users", user.uid);
        const filter = {
          discoveryFilterOperationLevel1:
            retrievedUser.data.discoveryFilterOperationLevel1,
          discoveryFilterOperationLevel2:
            retrievedUser.data.discoveryFilterOperationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "RATED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitEndorse = async (values, flashcardId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.ENDORSE_FLASHCARD)) {
        await updateDoc("publicflashcards", flashcardId, {
          ...values,
          ratedAt: serverTimestamp(),
          ratedBy: user.uid,
        });
        const retrievedUser = await retrieveDoc("users", user.uid);
        const filter = {
          discoveryFilterOperationLevel1:
            retrievedUser.data.discoveryFilterOperationLevel1,
          discoveryFilterOperationLevel2:
            retrievedUser.data.discoveryFilterOperationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "ENDORSED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitDisendorse = async (values, flashcardId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.ENDORSE_FLASHCARD)) {
        await updateDoc("publicflashcards", flashcardId, {
          ...values,
          ratedAt: serverTimestamp(),
          ratedBy: user.uid,
        });

        const retrievedUser = await retrieveDoc("users", user.uid);
        const filter = {
          discoveryFilterOperationLevel1:
            retrievedUser.data.discoveryFilterOperationLevel1,
          discoveryFilterOperationLevel2:
            retrievedUser.data.discoveryFilterOperationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "DISENDORSED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitAdd = async (flashcardId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_FLASHCARD)) {
        const retrievedMyFlashcard = await retrieveDoc(
          "publicflashcards",
          flashcardId
        );

        delete retrievedMyFlashcard.data.ratedAt;
        delete retrievedMyFlashcard.data.ratedBy;
        delete retrievedMyFlashcard.data.logbookid;
        delete retrievedMyFlashcard.data.flashcardRatingUsers;
        delete retrievedMyFlashcard.data.flashcardRating;
        delete retrievedMyFlashcard.data.flashcardRatingCount;
        delete retrievedMyFlashcard.data.flashcardEndorsedBy;
        delete retrievedMyFlashcard.data.flashcardEndorsementCount;

        await createDoc(
          "myflashcards",
          {
            ...retrievedMyFlashcard.data,
            forkedFrom: flashcardId,
            toBeRevisedAt: serverTimestamp(),
            state: 0,
            stateRepetition: 0,
            hardRepetition: 0,
            goodRepetition: 0,
            interval: 0,
            efactor: 0,
            leechCount: 0,
            leechTagged: false,
            leechSuspended: false,
            modifiedAt: null,
            modifiedBy: null,
            deletedAt: null,
            deletedBy: null,
          },
          user.uid
        );

        const retrievedUser = await retrieveDoc("users", user.uid);
        const filter = {
          discoveryFilterOperationLevel1:
            retrievedUser.data.discoveryFilterOperationLevel1,
          discoveryFilterOperationLevel2:
            retrievedUser.data.discoveryFilterOperationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "ADDED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitFilterDiscovery = async (values) => {
    try {
      // dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.FILTER_FLASHCARD)) {
        await updateDoc("users", user.uid, {
          discoveryFilterOperationLevel1: values.operationLevel1,
          discoveryFilterOperationLevel2: values.operationLevel2,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        const filter = {
          discoveryFilterOperationLevel1: values.operationLevel1,
          discoveryFilterOperationLevel2: values.operationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "FILTERED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  return {
    submitRating,
    submitEndorse,
    submitDisendorse,
    submitAdd,
    submitFilterDiscovery,
    response,
    setIsUnmounted,
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    dispatchDismiss,
  };
};
