const form = {
  formId: "flashcard-form",
  formField: {
    operationLevel1: {
      name: "operationLevel1",
      label: "Subspecialty",
      type: "text",
      errorMsg: "Subspecialty is required.",
      invalidMsg: "Subspecialty is invalid.",
    },
    operationLevel2: {
      name: "operationLevel2",
      label: "Operation/Procedure",
      type: "text",
      errorMsg: "Sub-operation/Sub-procedure is required.",
      invalidMsg: "Sub-operation/Sub-procedure is invalid.",
    },
    flashcardFront: {
      name: "flashcardFront",
      label: "Flashcard (Front) Content",
      type: "text",
      errorMsg: "Flashcard (Front) is required.",
      invalidMsg: "Flashcard (Front) is invalid.",
    },
    flashcardFrontImgAttachments: {
      name: "flashcardFrontImgAttachments",
      label: "Upload Image Files",
      type: "box",
      min1Msg: "Must have at least 1 File.",
      max20Msg: "Must have at most 20 Files.",
    },
    flashcardBack: {
      name: "flashcardBack",
      label: "Flashcard (Back) Content",
      type: "text",
      errorMsg: "Flashcard (Back) is required.",
      invalidMsg: "Flashcard (Back) is invalid.",
    },
    flashcardBackImgAttachments: {
      name: "flashcardBackImgAttachments",
      label: "Upload Image Files",
      type: "box",
      min1Msg: "Must have at least 1 File.",
      max20Msg: "Must have at most 20 Files.",
    },
  },
};

const flashcardFrontImgAttachmentsForm = {
  formId: "flashcardfront-img-attachments-form",
  formField: {
    flashcardFrontImgAttachmentFile: {
      name: "flashcardFrontImgAttachmentFile",
      label: "Flashcard (Front) Image File",
      type: "box",
      errorMsg: "Flashbard Front Image File is required.",
      invalidTypeMsg: "File type must be jpg, jpeg or png.",
      invalidSizeMsg: "File size must be less than 5 MB.",
    },
  },
};

const flashcardBackImgAttachmentsForm = {
  formId: "flashcardfront-img-attachments-form",
  formField: {
    flashcardBackImgAttachmentFile: {
      name: "flashcardBackImgAttachmentFile",
      label: "Flashcard (Back) Image File",
      type: "box",
      errorMsg: "Flashbard Back Image File is required.",
      invalidTypeMsg: "File type must be jpg, jpeg or png.",
      invalidSizeMsg: "File size must be less than 5 MB.",
    },
  },
};

export {
  form as default,
  flashcardFrontImgAttachmentsForm,
  flashcardBackImgAttachmentsForm,
};
