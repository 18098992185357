import { useState } from "react";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav2 from "components/organisms/Topnav2";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";

import SubmissionProgress from "components/organisms/SubmissionProgress";

// import Accounts from "pages/settings/user/new/components/Accounts";
import BasicInformation from "pages/settings/user/new/components/BasicInformation";
import Sidenav from "pages/settings/user/new/components/Sidenav";

import { Formik, Form } from "formik";
import form from "pages/settings/user/new/schemas/form";

import { AllowedTo } from "react-abac";

import { useSettingsManager } from "pages/settings/user/hooks/useSettingsManager";

function NewUser() {
  const [isProgressing, setIsProgressing] = useState(false);

  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modeValidation,
    submitNew,
    response,
    modePermission,
    dispatchDismiss,
    dispatchError,
  } = useSettingsManager();

  const dispatchDismissAfter = (millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        dispatchDismiss();
      }, millisecond);
    });
  };

  const { formId, formField } = form;

  const continueURL = window.location.href.replace(
    window.location.pathname,
    ""
  );

  const handleSubmit = async (values, actions) => {
    try {
      setIsProgressing(true);
      await submitNew(values, continueURL);
    } catch (err) {
      dispatchError(err);
      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
      setIsProgressing(false);
      await dispatchDismissAfter(3000);
    }
  };

  return (
    <DashboardLayout>
      <Topnav2 title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={3}>
        {response.isPending && <SubmissionProgress />}
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            {response.error?.name === "OperationIncompleteError" && (
              <IncompleteOperation />
            )}
            OperationIncompleteError
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        <MDBox>
          {response.error?.name !== "OperationInvalidError" && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Sidenav />
              </Grid>

              <Grid item xs={12} lg={10}>
                <MDBox mb={3}>
                  <Formik
                    enableReinitialize
                    initialValues={response.data}
                    validationSchema={modeValidation}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form id={formId} autoComplete="off">
                        <Grid container spacing={3}>
                          {/* <Grid item xs={12}>
                            <Accounts
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              dispatchMainError={dispatchError}
                              selectedAccount={selectedAccount}
                              setSelectedAccount={setSelectedAccount}
                            />
                          </Grid> */}
                          <Grid item xs={12}>
                            <BasicInformation
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MDBox
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <MDBox></MDBox>
                              <MDButton
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                {modeSubmit}
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUser;
