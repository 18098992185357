import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDocument } from "hooks/useDocument";
//import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";
import SubmissionProgress from "components/organisms/SubmissionProgress";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import Footer from "components/organisms/Footer";
//import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import { DatePicker, InputGroup } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";

import {
  anaesthesiaModeList,
  airwayManagementList,
  additionalProceduresList,
  dispositionList,
} from "schema/setups";

let mfindIndex = anaesthesiaModeList.findIndex((elm) => elm.id === "GA");
const GABreakdownList = anaesthesiaModeList[mfindIndex].children;
mfindIndex = anaesthesiaModeList.findIndex((elm) => elm.id === "PNB");
const PNBBreakdownList = anaesthesiaModeList[mfindIndex].children;
mfindIndex = airwayManagementList.findIndex((elm) => elm.id === "INT");
const INTBreakdownList = airwayManagementList[mfindIndex].children;
mfindIndex = airwayManagementList.findIndex((elm) => elm.id === "ETT");
const ETTBreakdownList = airwayManagementList[mfindIndex].children;
mfindIndex = airwayManagementList.findIndex((elm) => elm.id === "OTH");
const OTHBreakdownList = airwayManagementList[mfindIndex].children;
const highchartsColorMap = ["#2EE0CA", "#D568FB", "#feb56a", "#fa4b42"];

function LogbookCharts() {
  const { retrieveDocs } = useDocument();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isProgressing, setIsProgressing] = useState(false);

  const [caseData, setCaseData] = useState([]);
  const [anaesthesiaData, setAnaesthesiaData] = useState([]);
  const [GABreakdownData, setGABreakdownData] = useState([]);
  const [PNBBreakdownData, setPNBBreakdownData] = useState([]);
  const [airwayData, setAirwayData] = useState([]);
  const [INTBreakdownData, setINTBreakdownData] = useState([]);
  const [ETTBreakdownData, setETTBreakdownData] = useState([]);
  const [OTHBreakdownData, setOTHBreakdownData] = useState([]);
  const [additionalProceduresData, setAdditionalProceduresData] = useState([]);
  const [dispositionData, setDispositionData] = useState([]);
  const [startMonth, setStartMonth] = useState(null);
  const [endMonth, setEndMonth] = useState(null);

  //const { modeTitle, modePermission, response } = useCseSurveyManager(mode);
  const modeTitle = "Charts";
  const response = { success: null, error: null };

  const prepareCaseChart = useCallback(
    (startMonth, endMonth, retrievedLogbooks) => {
      const smYear = startMonth.getFullYear();
      const smMonth = startMonth.getMonth();
      const smUnitMonths = smYear * 12 + smMonth + 1;

      const emYear = endMonth.getFullYear();
      const emMonth = endMonth.getMonth();
      const emUnitMonths = emYear * 12 + emMonth + 1;
      const monthLength = emUnitMonths - smUnitMonths + 1;
      const rangeMonths = Array.from(
        { length: monthLength },
        (_, index) => index - monthLength + 1
      ); // [-11, -10, ..., 0]

      let caseData = rangeMonths.map((value) => {
        const currentMonth = moment(endMonth).add(value, "months").toDate();
        const cmUnitMonths =
          currentMonth.getFullYear() * 12 + (currentMonth.getMonth() + 1);
        const label = currentMonth.toLocaleDateString("en-SG", {
          year: "numeric",
          month: "short",
        });
        return {
          label: label,
          value: 0,
          unitMonths: cmUnitMonths,
          date: currentMonth,
        };
      });

      retrievedLogbooks.forEach((doc) => {
        const date = doc.data.date;
        const year = date.toDate().getFullYear();
        const month = date.toDate().getMonth();
        const unitMonths = year * 12 + month + 1;
        const index = caseData.findIndex(
          (elm) => elm.unitMonths === unitMonths
        );

        if (index >= 0) {
          caseData[index].value += 1;
        }
      });
      const mCaseData = caseData.map((elm) => {
        return [elm.label, elm.value];
      });
      setCaseData(mCaseData);
    },
    [setCaseData]
  );

  const prepareAnaesthesiaChart = useCallback(
    (retrievedLogbooks) => {
      let mAnaesthesiaModeList = anaesthesiaModeList.map((elm) => {
        return {
          id: elm.id,
          label: elm.label,
          value: 0,
        };
      });
      let anaesthesiaSubtitle = "";
      mAnaesthesiaModeList.forEach(
        (elm) =>
          (anaesthesiaSubtitle =
            anaesthesiaSubtitle + elm.id + ": " + elm.label + ", ")
      );
      anaesthesiaSubtitle = anaesthesiaSubtitle.slice(0, -1);

      let mGABreakdownList = GABreakdownList.map((elm) => {
        return {
          id: elm.id,
          label: elm.label,
          value: 0,
        };
      });

      let mPNBBreakdownList = PNBBreakdownList.map((elm) => {
        return {
          id: elm.id,
          label: elm.label,
          value: 0,
        };
      });

      retrievedLogbooks.forEach((doc) => {
        const anaesthesiaMode = doc.data.anaesthesiaMode;

        mAnaesthesiaModeList.forEach((elm, index) => {
          const id = elm.id;
          const len = id.length;
          const mfindIndex = anaesthesiaMode.findIndex(
            (value) => value.substring(0, len) === id
          );
          if (mfindIndex > -1) mAnaesthesiaModeList[index].value += 1;
        });

        mGABreakdownList.forEach((elm, index) => {
          const id = elm.id;
          const len = id.length;
          const mfindIndex = anaesthesiaMode.findIndex(
            (value) => value.substring(0, len) === id
          );
          if (mfindIndex > -1) mGABreakdownList[index].value += 1;
        });

        mPNBBreakdownList.forEach((elm, index) => {
          const id = elm.id;
          const len = id.length;
          const mfindIndex = anaesthesiaMode.findIndex(
            (value) => value.substring(0, len) === id
          );
          if (mfindIndex > -1) mPNBBreakdownList[index].value += 1;
        });
      });

      const mAnaesthesiaData = mAnaesthesiaModeList.map((elm) => {
        return [elm.id, elm.value];
      });
      setAnaesthesiaData({
        data: mAnaesthesiaData,
        subtitle: "LEGENDS- " + anaesthesiaSubtitle,
      });

      const mGABreakdownData = mGABreakdownList.map((elm) => {
        return [elm.label, elm.value];
      });
      const totalGABreakdown = mGABreakdownList.reduce(
        (acc, elm) => acc + elm.value,
        0
      );
      setGABreakdownData({
        data: mGABreakdownData,
        total: totalGABreakdown,
      });

      const mPNBBreakdownData = mPNBBreakdownList.map((elm) => {
        return [elm.label, elm.value];
      });
      const totalPNBBreakdown = mPNBBreakdownList.reduce(
        (acc, elm) => acc + elm.value,
        0
      );
      setPNBBreakdownData({
        data: mPNBBreakdownData,
        total: totalPNBBreakdown,
      });
    },
    [setAnaesthesiaData, setGABreakdownData, setPNBBreakdownData]
  );

  const prepareAirwayChart = useCallback(
    (retrievedLogbooks) => {
      let mAirwayManagementList = airwayManagementList.map((elm) => {
        return {
          id: elm.id,
          label: elm.label,
          value: 0,
        };
      });

      let airwaySubtitle = "";
      mAirwayManagementList.forEach(
        (elm) =>
          (airwaySubtitle = airwaySubtitle + elm.id + ": " + elm.label + ", ")
      );
      airwaySubtitle = airwaySubtitle.slice(0, -1);

      let mINTBreakdownList = INTBreakdownList.map((elm) => {
        return {
          id: elm.id,
          label: elm.label,
          value: 0,
        };
      });

      let mETTBreakdownList = ETTBreakdownList.map((elm) => {
        return {
          id: elm.id,
          label: elm.label,
          value: 0,
        };
      });

      let mOTHBreakdownList = OTHBreakdownList.map((elm) => {
        return {
          id: elm.id,
          label: elm.label,
          value: 0,
        };
      });

      retrievedLogbooks.forEach((doc) => {
        const airwayManagement = doc.data.airwayManagement;

        mAirwayManagementList.forEach((elm, index) => {
          const id = elm.id;
          const len = id.length;
          const mfindIndex = airwayManagement.findIndex(
            (value) => value.substring(0, len) === id
          );
          if (mfindIndex > -1) mAirwayManagementList[index].value += 1;
        });

        mINTBreakdownList.forEach((elm, index) => {
          const id = elm.id;
          const len = id.length;
          const mfindIndex = airwayManagement.findIndex(
            (value) => value.substring(0, len) === id
          );
          if (mfindIndex > -1) mINTBreakdownList[index].value += 1;
        });

        mETTBreakdownList.forEach((elm, index) => {
          const id = elm.id;
          const len = id.length;
          const mfindIndex = airwayManagement.findIndex(
            (value) => value.substring(0, len) === id
          );
          if (mfindIndex > -1) mETTBreakdownList[index].value += 1;
        });

        mOTHBreakdownList.forEach((elm, index) => {
          const id = elm.id;
          const len = id.length;
          const mfindIndex = airwayManagement.findIndex(
            (value) => value.substring(0, len) === id
          );
          if (mfindIndex > -1) mOTHBreakdownList[index].value += 1;
        });
      });
      const mAirwayData = mAirwayManagementList.map((elm) => {
        return [elm.id, elm.value];
      });
      setAirwayData({
        data: mAirwayData,
        subtitle: "LEGENDS- " + airwaySubtitle,
      });

      const mINTBreakdownData = mINTBreakdownList.map((elm) => {
        return [elm.label, elm.value];
      });
      const totalINTBreakdown = mINTBreakdownList.reduce(
        (acc, elm) => acc + elm.value,
        0
      );
      setINTBreakdownData({
        data: mINTBreakdownData,
        total: totalINTBreakdown,
      });

      const mETTBreakdownData = mETTBreakdownList.map((elm) => {
        return [elm.label, elm.value];
      });
      const totalETTBreakdown = mETTBreakdownList.reduce(
        (acc, elm) => acc + elm.value,
        0
      );
      setETTBreakdownData({
        data: mETTBreakdownData,
        total: totalETTBreakdown,
      });

      const mOTHBreakdownData = mOTHBreakdownList.map((elm) => {
        return [elm.label, elm.value];
      });
      const totalOTHBreakdown = mOTHBreakdownList.reduce(
        (acc, elm) => acc + elm.value,
        0
      );
      setOTHBreakdownData({
        data: mOTHBreakdownData,
        total: totalOTHBreakdown,
      });
    },
    [
      setAirwayData,
      setINTBreakdownData,
      setETTBreakdownData,
      setOTHBreakdownData,
    ]
  );

  const prepareAdditionalProceduresChart = useCallback(
    (retrievedLogbooks) => {
      let mAdditionalProceduresList = additionalProceduresList.map((elm) => {
        return {
          id: elm.id,
          label: elm.label,
          value: 0,
        };
      });

      let additionalProceduresSubtitle = "";
      mAdditionalProceduresList.forEach(
        (elm) =>
          (additionalProceduresSubtitle =
            additionalProceduresSubtitle + elm.id + ": " + elm.label + ", ")
      );
      additionalProceduresSubtitle = additionalProceduresSubtitle.slice(0, -1);

      retrievedLogbooks.forEach((doc) => {
        const additionalProcedures = doc.data.additionalProcedures;

        mAdditionalProceduresList.forEach((elm, index) => {
          const id = elm.id;
          const len = id.length;
          const mfindIndex = additionalProcedures.findIndex(
            (value) => value.substring(0, len) === id
          );
          if (mfindIndex > -1) mAdditionalProceduresList[index].value += 1;
        });
      });
      const mAdditionalProceduresData = mAdditionalProceduresList.map((elm) => {
        return [elm.id, elm.value];
      });
      setAdditionalProceduresData({
        data: mAdditionalProceduresData,
        subtitle: "LEGENDS- " + additionalProceduresSubtitle,
      });
    },
    [setAdditionalProceduresData]
  );

  const prepareDispositionChart = useCallback(
    (retrievedLogbooks) => {
      let mDispositionList = dispositionList.map((elm) => {
        return {
          id: elm.id,
          label: elm.label,
          value: 0,
        };
      });

      let dispositionSubtitle = "";
      mDispositionList.forEach(
        (elm) =>
          (dispositionSubtitle =
            dispositionSubtitle + elm.id + ": " + elm.label + ", ")
      );
      dispositionSubtitle = dispositionSubtitle.slice(0, -1);
      retrievedLogbooks.forEach((doc) => {
        const disposition = doc.data.disposition;

        mDispositionList.forEach((elm, index) => {
          const id = elm.id;
          const len = id.length;
          const mfindIndex = disposition.findIndex(
            (value) => value.substring(0, len) === id
          );
          if (mfindIndex > -1) mDispositionList[index].value += 1;
        });
      });
      const mDispositionData = mDispositionList.map((elm) => {
        return [elm.id, elm.value];
      });
      setDispositionData({
        data: mDispositionData,
        subtitle: "LEGENDS- " + dispositionSubtitle,
      });
    },
    [setDispositionData]
  );

  const prepareAllCharts = useCallback(
    async (startMonth, endMonth) => {
      setIsProgressing(true);
      const logbooksQueries = {
        whereQueries: [
          {
            field: "deletedAt",
            condition: "==",
            value: null,
          },
          {
            field: "createdBy",
            condition: "==",
            value: userId,
          },
        ],
      };
      const retrievedLogbooks = await retrieveDocs("logbooks", logbooksQueries);

      prepareCaseChart(startMonth, endMonth, retrievedLogbooks);
      prepareAnaesthesiaChart(retrievedLogbooks);
      prepareAirwayChart(retrievedLogbooks);
      prepareAdditionalProceduresChart(retrievedLogbooks);
      prepareDispositionChart(retrievedLogbooks);
      setIsProgressing(false);
    },
    [
      retrieveDocs,
      userId,
      prepareCaseChart,
      prepareAnaesthesiaChart,
      prepareAirwayChart,
      prepareAdditionalProceduresChart,
      prepareDispositionChart,
    ]
  );

  useEffect(() => {
    try {
      const now = new Date();
      const start = moment(now).add(-11, "months").toDate();
      setStartMonth(start);
      setEndMonth(now);
      prepareAllCharts(start, now);
    } catch (err) {}
  }, [prepareAllCharts]);

  const options = {
    title: {
      text: "Cases Attended",
      align: "left",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Verdana, sans-serif",
      },
    },
    chart: {
      type: "column",
      spacing: 20,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of cases",
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    series: [
      {
        data: caseData,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: "#000000",
          align: "center",
          y: 0, // 10 pixels down from the top
          style: {
            fontSize: "10px",
            fontFamily: "Verdana, sans-serif",
            textShadow: false,
            fontWeight: "bold",
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const optionsAnaestheia = {
    title: {
      text: "Mode of anaesthesia",
      align: "left",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Verdana, sans-serif",
      },
    },
    subtitle: {
      text: anaesthesiaData.subtitle,
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Verdana, sans-serif",
      },
    },
    chart: {
      type: "bar",
      spacing: 20,
    },
    yAxis: {
      min: 0,
      max: 10,
      title: {
        text: "Number of cases",
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 0,
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 1,
    },
    series: [
      {
        data: anaesthesiaData.data,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: "#000000",
          align: "center",
          y: 0, // 10 pixels down from the top
          style: {
            fontSize: "10px",
            fontFamily: "Verdana, sans-serif",
            textShadow: false,
            fontWeight: "bold",
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const optionsAirway = {
    title: {
      text: "Airway Management",
      align: "left",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Verdana, sans-serif",
      },
    },
    subtitle: {
      text: airwayData.subtitle,
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Verdana, sans-serif",
      },
    },
    chart: {
      type: "bar",
      spacing: 20,
    },
    yAxis: {
      min: 0,
      max: 10,
      title: {
        text: "Number of cases",
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 0,
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 1,
    },
    series: [
      {
        data: airwayData.data,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: "#000000",
          align: "center",
          y: 0, // 10 pixels down from the top
          style: {
            fontSize: "10px",
            fontFamily: "Verdana, sans-serif",
            textShadow: false,
            fontWeight: "bold",
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const optionsAdditionalProcedures = {
    title: {
      text: "Additional Procedures",
      align: "left",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Verdana, sans-serif",
      },
    },
    subtitle: {
      text: additionalProceduresData.subtitle,
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Verdana, sans-serif",
      },
    },
    chart: {
      type: "bar",
      spacing: 20,
    },
    yAxis: {
      min: 0,
      max: 10,
      title: {
        text: "Number of cases",
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 0,
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 1,
    },
    series: [
      {
        data: additionalProceduresData.data,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: "#000000",
          align: "center",
          y: 0, // 10 pixels down from the top
          style: {
            fontSize: "10px",
            fontFamily: "Verdana, sans-serif",
            textShadow: false,
            fontWeight: "bold",
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const optionsDisposition = {
    title: {
      text: "Disposition",
      align: "left",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Verdana, sans-serif",
      },
    },
    subtitle: {
      text: dispositionData.subtitle,
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Verdana, sans-serif",
      },
    },
    chart: {
      type: "bar",
      spacing: 20,
    },
    yAxis: {
      min: 0,
      max: 10,
      title: {
        text: "Number of cases",
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 0,
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 1,
    },
    series: [
      {
        data: dispositionData.data,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: "#000000",
          align: "center",
          y: 0, // 10 pixels down from the top
          style: {
            fontSize: "10px",
            fontFamily: "Verdana, sans-serif",
            textShadow: false,
            fontWeight: "bold",
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const optionsGABreakdown = {
    title: {
      text: "GA Breakdown (" + GABreakdownData.total + ")",
      align: "left",
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        fontFamily: "Verdana, sans-serif",
      },
    },
    subtitle: {
      text: "",
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Verdana, sans-serif",
      },
    },
    chart: {
      type: "column",
      spacing: 20,
      height: 300,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "Percentage",
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 0,
      stackLabels: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
      categories: ["GA", "PNB"],
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 1,
    },
    plotOptions: {
      column: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.0f}%",
        },
      },
    },
    series: GABreakdownData?.data
      ? GABreakdownData?.data.map((elm, index) => {
          return {
            name: "Value",
            color: highchartsColorMap[index],
            data: [elm],
          };
        })
      : [["", 0]],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const optionsPNBBreakdown = {
    title: {
      text: "PNB Breakdown (" + PNBBreakdownData.total + ")",
      align: "left",
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        fontFamily: "Verdana, sans-serif",
      },
    },
    subtitle: {
      text: "",
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Verdana, sans-serif",
      },
    },
    chart: {
      type: "column",
      spacing: 20,
      height: 300,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "Percentage",
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 0,
      stackLabels: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
      categories: ["PNB"],
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 1,
    },
    plotOptions: {
      column: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.0f}%",
        },
      },
    },
    series: PNBBreakdownData?.data
      ? PNBBreakdownData?.data.map((elm, index) => {
          return {
            name: "Value",
            color: highchartsColorMap[index],
            data: [elm],
          };
        })
      : [["", 0]],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const optionsINTBreakdown = {
    title: {
      text: "INT Breakdown (" + INTBreakdownData.total + ")",
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "bold",
        fontFamily: "Verdana, sans-serif",
      },
    },
    subtitle: {
      text: "",
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Verdana, sans-serif",
      },
    },
    chart: {
      type: "column",
      spacing: 20,
      height: 300,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "Percentage",
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 0,
      stackLabels: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
      categories: ["INT"],
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 1,
    },
    plotOptions: {
      column: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.0f}%",
        },
      },
    },
    series: INTBreakdownData?.data
      ? INTBreakdownData?.data.map((elm, index) => {
          return {
            name: "Value",
            color: highchartsColorMap[index],
            data: [elm],
          };
        })
      : [["", 0]],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const optionsETTBreakdown = {
    title: {
      text: "ETT Breakdown (" + ETTBreakdownData.total + ")",
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "bold",
        fontFamily: "Verdana, sans-serif",
      },
    },
    subtitle: {
      text: "",
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Verdana, sans-serif",
      },
    },
    chart: {
      type: "column",
      spacing: 20,
      height: 300,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "Percentage",
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 0,
      stackLabels: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
      categories: ["ETT"],
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 1,
    },
    plotOptions: {
      column: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.0f}%",
        },
      },
    },
    series: ETTBreakdownData?.data
      ? ETTBreakdownData?.data.map((elm, index) => {
          return {
            name: "Value",
            color: highchartsColorMap[index],
            data: [elm],
          };
        })
      : [["", 0]],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const optionsOTHBreakdown = {
    title: {
      text: "OTH Breakdown (" + OTHBreakdownData.total + ")",
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "bold",
        fontFamily: "Verdana, sans-serif",
      },
    },
    subtitle: {
      text: "",
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Verdana, sans-serif",
      },
    },
    chart: {
      type: "column",
      spacing: 20,
      height: 300,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "Percentage",
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 0,
      stackLabels: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
      categories: ["OTH"],
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      gridLineWidth: 1,
    },
    plotOptions: {
      column: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.0f}%",
        },
      },
    },
    series: OTHBreakdownData?.data
      ? OTHBreakdownData?.data.map((elm, index) => {
          return {
            name: "Value",
            color: highchartsColorMap[index],
            data: [elm],
          };
        })
      : [["", 0]],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      {isProgressing && <SubmissionProgress />}
      {/*<AllowedTo perform={modePermission} no={() => <PermissionDenied />} />*/}
      {response.error && (
        <>
          {response.error?.name === "OperationInvalidError" && (
            <InvalidOperation />
          )}
          <ErrorSnackbar
            title={modeTitle}
            dateTime=""
            message={response.error?.message}
            autoDismiss
          />
        </>
      )}
      {response.success && (
        <SuccessSnackbar
          title={modeTitle}
          dateTime=""
          message={response.success}
          autoDismiss
        />
      )}
      <MDBox pt={3} pb={3}>
        {response.error?.name !== "OperationInvalidError" && (
          <Card>
            <MDBox m={3}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12} md={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    Duration
                  </MDTypography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputGroup>
                    <DatePicker
                      format="MMM yyyy"
                      block
                      appearance="subtle"
                      placement="autoVerticalStart"
                      style={{ width: 600 }}
                      size="xs"
                      value={startMonth}
                      onChange={(date) => {
                        if (date !== null) {
                          setStartMonth(date);
                          prepareAllCharts(date, endMonth);
                        }
                      }}
                    />
                    <InputGroup.Addon>to</InputGroup.Addon>
                    <DatePicker
                      format="MMM yyyy"
                      block
                      appearance="subtle"
                      placement="autoVerticalEnd"
                      style={{ width: 600 }}
                      size="xs"
                      value={endMonth}
                      onChange={(date) => {
                        if (date !== null) {
                          setEndMonth(date);
                          prepareAllCharts(startMonth, date);
                        }
                      }}
                    />
                  </InputGroup>
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12}></Grid>
                {caseData.length > 0 && (
                  <Grid item xs={12} md={12} sx={{ border: "1px solid grey" }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                  </Grid>
                )}
                <Grid item xs={12}></Grid>
                {caseData.length > 0 && (
                  <Grid item xs={12} md={6} sx={{ border: "1px solid grey" }}>
                    <>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsAnaestheia}
                      />
                      <Grid container rowSpacing={2}>
                        <Grid item xs={6}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsGABreakdown}
                          />
                        </Grid>
                        {PNBBreakdownData?.data && (
                          <Grid item xs={6}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={optionsPNBBreakdown}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  </Grid>
                )}

                {caseData.length > 0 && (
                  <Grid item xs={12} md={6} sx={{ border: "1px solid grey" }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={optionsAirway}
                    />
                    <Grid container rowSpacing={2}>
                      <Grid item xs={4}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={optionsINTBreakdown}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={optionsETTBreakdown}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={optionsOTHBreakdown}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {caseData.length > 0 && (
                  <Grid item xs={12} md={6} sx={{ border: "1px solid grey" }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={optionsAdditionalProcedures}
                    />
                  </Grid>
                )}
                {caseData.length > 0 && (
                  <Grid item xs={12} md={6} sx={{ border: "1px solid grey" }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={optionsDisposition}
                    />
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Card>
        )}
      </MDBox>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={4} md={4} lg={4}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                navigate("/logbookstatistics/all/");
              }}
            >
              <ArrowBackIcon />
              &nbsp;&nbsp;
              {"Back"}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LogbookCharts;
