import form from "pages/settings/cusum/manage/schemas/form";

import {
  defaultAcceotableFailureRate,
  defaultUnacceotableFailureRate,
  defaultType1Error,
  defaultType2Error,
} from "pages/settings/cusum/manage/schemas/setups";

const {
  formField: {
    acceptableFailureRate,
    unacceptableFailureRate,
    type1Error,
    type2Error,
    param_P,
    param_Q,
    param_a,
    param_b,
    lowerDecisionLimit,
    upperDecisionLimit,
  },
} = form;

const initialValues = {
  [acceptableFailureRate.name]: defaultAcceotableFailureRate,
  [unacceptableFailureRate.name]: defaultUnacceotableFailureRate,
  [type1Error.name]: defaultType1Error,
  [type2Error.name]: defaultType2Error,
  [param_P.name]: "",
  [param_Q.name]: "",
  [param_a.name]: "",
  [param_b.name]: "",
  [lowerDecisionLimit.name]: "",
  [upperDecisionLimit.name]: "",
};

export { initialValues as default };
