import { useParams } from "react-router-dom";
import CseSurvey from "pages/csesurveys/manage/CseSurvey";

// using Proxy Page to remount when route to same route location but different param
function CseSurveyProxy() {
  const { mode, csesurveyId } = useParams();
  return <CseSurvey key={`${mode}/${csesurveyId}`} />;
}

export default CseSurveyProxy;
