import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
//import FormCheckboxGroup from "components/molecules/Formik/FormCheckboxGroup";

//import { roleOptions } from "pages/settings/user/new/schemas/setups";

function CusumSettings({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  //mainForm.values.param_P = 22;
  const { values: mainFormValues, setFieldValue: setMainFormFieldValue } =
    mainForm;

  const {
    acceptableFailureRate,
    unacceptableFailureRate,
    type1Error,
    type2Error,
    param_P,
    param_Q,
    param_a,
    param_b,
    lowerDecisionLimit,
    upperDecisionLimit,
  } = mainFormField;

  const recalculateCusumSettings = (value, param) => {
    if (param === "f0") {
      mainFormValues.acceptableFailureRate = value;
    }

    if (param === "f1") {
      mainFormValues.unacceptableFailureRate = value;
    }

    if (param === "alpha") {
      mainFormValues.type1Error = value;
    }

    if (param === "beta") {
      mainFormValues.type2Error = value;
    }

    let f0 = parseFloat(mainFormValues.acceptableFailureRate);
    let f1 = parseFloat(mainFormValues.unacceptableFailureRate);
    let alpha = parseFloat(mainFormValues.type1Error);
    let beta = parseFloat(mainFormValues.type2Error);

    let P = Math.log(f1 / f0);
    let Q = Math.log((1 - f0) / (1 - f1));
    let a = Math.log((1 - beta) / alpha);
    let b = Math.log((1 - alpha) / beta);
    let h0 = -b / (P + Q);
    let h1 = a / (P + Q);

    setMainFormFieldValue(acceptableFailureRate.name, f0.toString());
    setMainFormFieldValue(unacceptableFailureRate.name, f1.toString());
    setMainFormFieldValue(type1Error.name, alpha.toString());
    setMainFormFieldValue(type2Error.name, beta.toString());
    setMainFormFieldValue(param_P.name, P.toFixed(4).toString());
    setMainFormFieldValue(param_Q.name, Q.toFixed(4).toString());
    setMainFormFieldValue(param_a.name, a.toFixed(4).toString());
    setMainFormFieldValue(param_b.name, b.toFixed(4).toString());
    setMainFormFieldValue(lowerDecisionLimit.name, h0.toFixed(4).toString());
    setMainFormFieldValue(upperDecisionLimit.name, h1.toFixed(4).toString());
  };

  return (
    <Card id="cusum-variables" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Cusum Variables</MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {acceptableFailureRate.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={acceptableFailureRate}
                variant="standard"
                disabled={modeDisabled}
                onChange={(e) => {
                  recalculateCusumSettings(e.target.value, "f0");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}></Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {unacceptableFailureRate.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={unacceptableFailureRate}
                variant="standard"
                disabled={modeDisabled}
                onChange={(e) => {
                  recalculateCusumSettings(e.target.value, "f1");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}></Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {type1Error.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={type1Error}
                variant="standard"
                disabled={modeDisabled}
                onChange={(e) => {
                  recalculateCusumSettings(e.target.value, "alpha");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}></Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {type2Error.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={type2Error}
                variant="standard"
                disabled={modeDisabled}
                onChange={(e) => {
                  recalculateCusumSettings(e.target.value, "beta");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}></Grid>

            <Grid item xs={3} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {param_P.label}
              </MDTypography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormTextFieldFast
                form={mainForm}
                field={param_P}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3} sm={1}></Grid>
            <Grid item xs={3} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {param_Q.label}
              </MDTypography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormTextFieldFast
                form={mainForm}
                field={param_Q}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3} sm={1}></Grid>

            <Grid item xs={3} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {param_a.label}
              </MDTypography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormTextFieldFast
                form={mainForm}
                field={param_a}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3} sm={1}></Grid>
            <Grid item xs={3} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {param_b.label}
              </MDTypography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormTextFieldFast
                form={mainForm}
                field={param_b}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3} sm={1}></Grid>

            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {lowerDecisionLimit.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={lowerDecisionLimit}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography variant="body3" fontWeight="bold">
                {upperDecisionLimit.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextFieldFast
                form={mainForm}
                field={upperDecisionLimit}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CusumSettings;
