import PropTypes from "prop-types";

import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/atoms/MDInput";
import MDTypography from "components/atoms/MDTypography";

function BasicAutocompleteWrapper({
  type,
  label,
  placeholder,
  variant,
  disabled,
  error,
  success,
  multiple,
  freeSolo,
  ...rest
}) {
  // textarea
  const renderOptionProp = freeSolo
    ? {
        renderOption: (props, option) => {
          return (
            <MDTypography
              {...props}
              className="MuiAutocomplete-option"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {option}
            </MDTypography>
          );
        },
      }
    : {};

  const multilineProp = freeSolo
    ? {
        multiline: true,
      }
    : {};

  return (
    <>
      <Autocomplete
        disabled={disabled}
        multiple={multiple}
        freeSolo={freeSolo}
        {...renderOptionProp}
        {...rest}
        renderInput={(params) => (
          <MDInput
            {...params}
            {...multilineProp}
            type={type}
            label={label}
            placeholder={placeholder}
            variant={variant}
            disabled={disabled}
            error={error}
            success={success}
            fullWidth
          />
        )}
      />
    </>
  );
}

// Setting default values for the props of TextFieldWrapper
BasicAutocompleteWrapper.defaultProps = {
  label: "",
};

// typechecking props for TextFieldWrapper
BasicAutocompleteWrapper.propTypes = {
  name: PropTypes.string,
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.node,
};

export default BasicAutocompleteWrapper;
