// Don't handle exception in this React Hook to allow exception bubbling
// Handle exception at upwards level:
//   send error log back to server
//   show generic error message on snackbar to inform user to report to admin

import { useCallback } from "react";

export const useLogbookActionManager = () => {
  const getActionState = useCallback((data) => {
    const actions = [
      {
        name: "created",
        time: data.createdAt ? data.createdAt.toMillis() : null,
      },
      {
        name: "modified",
        time: data.modifiedAt ? data.modifiedAt.toMillis() : null,
      },
      {
        name: "deleted",
        time: data.deletedAt ? data.deletedAt.toMillis() : null,
      },
    ];

    const times = actions
      .map((action) => action.time)
      .filter((time) => time !== null);

    const latestAction = actions.find(
      (action) => action.time === Math.max(...times)
    );

    const canEdit = ["created", "modified"].includes(latestAction.name);
    const canDelete = true;

    return {
      latestAction,
      canEdit,
      canDelete,
    };
  }, []);

  return {
    getActionState,
  };
};
