import { Link } from "react-router-dom";

import PlainFullLayout from "components/templates/generic/layouts/PlainFullLayout";

import Card from "@mui/material/Card";

import MDAvatar from "components/atoms/MDAvatar";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
import ErrorAlert from "components/molecules/Notifications/ErrorAlert";
import SuccessAlert from "components/molecules/Notifications/SuccessAlert";

import FormField from "components/molecules/FormField";

import logoImage from "assets/images/logo.png";
import bgImage from "assets/images/bg-signin.jpeg";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { useResetPassword } from "pages/authentication/hooks/useResetPassword";

function ResetPassword() {
  const { sendPasswordResetEmail, error, success } = useResetPassword();
  const continueURL = window.location.href.replace(
    window.location.pathname,
    "/authentication/signin"
  );

  return (
    <>
      {error && <ErrorAlert message={error} />}
      {success && <SuccessAlert message={success} />}
      <PlainFullLayout image={bgImage}>
        <Card>
          <MDBox
            mt={-8}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MDAvatar
              src={logoImage}
              alt="Goodair"
              bgColor="light"
              size="xxl"
            />
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </MDTypography>
            <MDTypography display="block" variant="body3" color="white" my={1}>
              Enter your email to request password reset.
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Required")
                  .email("Invalid email address"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                await sendPasswordResetEmail(values.email, continueURL);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form>
                  <FormField
                    type="email"
                    label="Email"
                    name="email"
                    error={errors.email && touched.email}
                    success={values.email.length > 0 && !errors.email}
                  />
                  <MDBox mt={3} mb={1}>
                    <MDButton
                      disabled={isSubmitting}
                      type="submit"
                      variant="gradient"
                      color="success"
                      fullWidth
                    >
                      Send Password Reset Email
                    </MDButton>
                  </MDBox>
                  <MDBox mt={-1} textAlign="right">
                    <MDTypography
                      component={Link}
                      to="/authentication/signin"
                      variant="caption"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign in
                    </MDTypography>
                  </MDBox>
                </Form>
              )}
            </Formik>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDBox>
                <MDTypography variant="caption" color="text" textGradient>
                  Didn&apos;t received password reset email?{" "}
                </MDTypography>
              </MDBox>
              <MDBox mt={-1}>
                <MDTypography
                  variant="caption"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Please Consult with Your Administrator
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </PlainFullLayout>
    </>
  );
}

export default ResetPassword;
