import PropTypes from "prop-types";

import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";

import { ErrorMessage, Field } from "formik";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDInput from "components/atoms/MDInput";
function FormField({ type, label, name, variant, children, ...rest }) {
  let asComponent;
  let fullWidthProp;
  let outerChildren;
  switch (type) {
    case "checkbox":
      asComponent = Checkbox;
      fullWidthProp = {};
      outerChildren = true;
      break;
    case "switch":
      asComponent = Switch;
      fullWidthProp = {};
      outerChildren = true;
      break;
    case "radio":
      asComponent = Radio;
      fullWidthProp = {};
      outerChildren = true;
      break;
    case "box":
      asComponent = MDBox;
      fullWidthProp = { fullWidth: true };
      outerChildren = false;
      break;
    default:
      asComponent = MDInput;
      fullWidthProp = { fullWidth: true };
      outerChildren = false;
  }
  let innerChildren = !outerChildren;

  return (
    <MDBox mb={2}>
      <Field
        {...rest}
        type={type}
        label={label}
        name={name}
        as={asComponent}
        variant={variant}
        {...fullWidthProp}
      >
        {innerChildren && children}
      </Field>
      {outerChildren && children}
      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
        >
          <MDTypography
            component="div"
            variant="caption"
            color="error"
            fontWeight="regular"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            <ErrorMessage name={name}></ErrorMessage>
          </MDTypography>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
FormField.defaultProps = {
  label: "",
};

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  children: PropTypes.node,
};

export default FormField;
