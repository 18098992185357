import { useEffect, useCallback, useState, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useFile } from "hooks/useFile";

import { useFlashcardActionManager } from "pages/flashcard/hooks/useFlashcardActionManager";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import initialValues from "pages/flashcard/manage/schemas/initialValues";

import validations, {
  noValidation,
} from "pages/flashcard/manage/schemas/validations";

import _ from "lodash";

import moment from "moment-timezone";

const collectionPathFlashcards = "myflashcards";

const initialState = {
  isPending: true,
  data: initialValues,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: null,
        success: null,
        error: null,
      };
    case "INITIAL_FLASHCARD":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_FLASHCARD":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "CREATED_FLASHCARD":
      const initialValuesNextFlashcard = _.cloneDeep(initialValues);
      return {
        isPending: false,
        data: initialValuesNextFlashcard,
        success: `Successfully created the flashcard.`,
        error: null,
      };
    case "UPDATED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully updated the flashcard.`,
        error: null,
      };
    case "RATED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully rated the flashcard.`,
        error: null,
      };
    case "ENDORSED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully endorsed the flashcard.`,
        error: null,
      };
    case "DISENDORSED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully disendorsed the flashcard.`,
        error: null,
      };
    case "ADDED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully added the flashcard.`,
        error: null,
      };
    case "SHARED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully shared the flashcard.`,
        error: null,
      };
    case "SCHEDULED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully scheduled the flashcard.`,
        error: null,
      };
    case "FILTERED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully filtered the flashcard.`,
        error: null,
      };
    case "DELETED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully deleted the flashcard.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useFlashcardManager = (mode, flashcardId) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();

  const { getActionState } = useFlashcardActionManager();

  const {
    createDoc,
    retrieveDoc,
    retrieveDocs,
    updateDoc,
    deleteDoc,
    serverTimestamp,
    convertToTimestamp,
  } = useDocument();

  const { getFileRef } = useFile();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const toPersistenceValue = useCallback(
    async (document) => {
      try {
        if (document.flashcardFrontImgAttachments) {
          document.flashcardFrontImgAttachments =
            document.flashcardFrontImgAttachments.map((element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            });
        }

        if (document.flashcardBackImgAttachments) {
          document.flashcardBackImgAttachments =
            document.flashcardBackImgAttachments.map((element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            });
        }

        return document;
      } catch (err) {
        console.error(err);
        dispatchError(err);
      }
    },
    [dispatchError]
  );

  // TODO: Refactor to DAO Layer
  const toPresentationValue = useCallback(
    async (data) => {
      try {
        if (mode !== "new" && data.date) data.date = data.date.toDate();

        if (data.flashcardFrontImgAttachments) {
          data.flashcardFrontImgAttachments.forEach(async (element) => {
            try {
              const fileRef = await getFileRef(element.attachmentPath);
              element.attachmentURL = fileRef.url;
            } catch (err) {
              element.attachmentURL = null;
            }
          });
        }

        if (data.flashcardBackImgAttachments) {
          data.flashcardBackImgAttachments.forEach(async (element) => {
            try {
              const fileRef = await getFileRef(element.attachmentPath);
              element.attachmentURL = fileRef.url;
            } catch (err) {
              element.attachmentURL = null;
            }
          });
        }

        return data;
      } catch (err) {
        console.error(err);
        dispatchError(err);
      }
    },
    [dispatchError, getFileRef, mode]
  );

  const dispatchDismiss = useCallback(
    async (flashcardId) => {
      if (mode !== "discover") {
        const retrievedMyFlashcard = !!flashcardId
          ? await retrieveDoc(collectionPathFlashcards, flashcardId)
          : null;

        dispatchIfNotUnmounted({
          type: "DISMISS",
          payload: await toPresentationValue(retrievedMyFlashcard.data),
        });
      } else {
        const retrievedPublicFlashcard = !!flashcardId
          ? await retrieveDoc("publicflashcards", flashcardId)
          : null;

        dispatchIfNotUnmounted({
          type: "DISMISS",
          payload: await toPresentationValue(retrievedPublicFlashcard.data),
        });
      }
    },
    // eslint-disable-next-line no-use-before-define
    [dispatchIfNotUnmounted, mode, retrieveDoc, toPresentationValue]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      const retrievedMyFlashcard =
        mode !== "discover" && !!flashcardId
          ? await retrieveDoc(collectionPathFlashcards, flashcardId)
          : null;

      const retrievedPublicFlashcard =
        mode === "discover" && !!flashcardId
          ? await retrieveDoc("publicflashcards", flashcardId)
          : null;

      const { canEdit, canDelete } =
        mode !== "new" &&
        !!retrievedMyFlashcard &&
        getActionState(retrievedMyFlashcard.data);

      switch (mode) {
        case "new":
          if (!pathname.includes("/flashcard/manage")) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "INITIAL_FLASHCARD",
            payload: initialValues,
          });
          break;
        case "edit":
          if (!pathname.includes("/flashcard/manage") && canEdit) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_FLASHCARD",
            payload: await toPresentationValue(retrievedMyFlashcard.data),
          });
          break;
        case "discover":
          if (!pathname.includes("/flashcard/manage")) {
            throw operationInvalidError;
          }

          // retrieve tagging filters from user collection
          // apply it to queries

          // public flashcard
          //recommend the top rated (temp solution)
          const publicflashcardsQueries = {
            whereQueries: [
              {
                field: "deletedAt",
                condition: "==",
                value: null,
              },
            ],
            orderByQueries: [
              {
                field: "flashcardEndorsementCount",
                direction: "desc",
              },
              {
                field: "flashcardRating",
                direction: "desc",
              },
            ],
            limitQuery: { number: 1 },
          };

          const retrievedHighestRating = await retrieveDocs(
            "publicflashcards",
            publicflashcardsQueries
          );

          if (!retrievedPublicFlashcard) {
            if (retrievedHighestRating.length === 1) {
              navigate(
                `/flashcard/manage/discover/${retrievedHighestRating[0].id}`
              );
            }
          } else {
            dispatchIfNotUnmounted({
              type: "RETRIEVED_FLASHCARD",
              payload: await toPresentationValue(retrievedPublicFlashcard.data),
            });
          }
          break;
        case "view":
          if (!pathname.includes("/flashcard/manage")) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_FLASHCARD",
            payload: await toPresentationValue(retrievedMyFlashcard.data),
          });
          break;
        case "delete":
          if (!(pathname.includes("/flashcard/manage") && canDelete)) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_FLASHCARD",
            payload: await toPresentationValue(retrievedMyFlashcard.data),
          });
          break;
        default:
          throw operationInvalidError;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    mode,
    flashcardId,
    retrieveDoc,
    getActionState,
    pathname,
    toPresentationValue,
    retrieveDocs,
    navigate,
    dispatchError,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "";
  let modeSubmit = "";
  let modeFieldDisabled = true;
  let modePermission = "";
  let modeValidation = noValidation;
  switch (mode) {
    case "new":
      modeTitle = "Flashcard";
      modeSubmit = "Submit";
      modeFieldDisabled = false;
      modePermission = Permission.CREATE_FLASHCARD;
      modeValidation = validations;
      break;
    case "edit":
      modeTitle = "Edit Flashcard";
      modeSubmit = "Update";
      modeFieldDisabled = false;
      modePermission = Permission.UPDATE_FLASHCARD;
      modeValidation = validations;
      break;
    case "view":
      modeTitle = "View Flashcard";
      modeSubmit = "Close";
      modeFieldDisabled = true;
      modePermission = Permission.READ_FLASHCARD;
      modeValidation = noValidation;
      break;
    case "discover":
      modeTitle = "Discover Flashcard";
      modeSubmit = "Close";
      modeFieldDisabled = false;
      modePermission = Permission.DISCOVER_FLASHCARD;
      modeValidation = noValidation;
      break;
    case "delete":
      modeTitle = "Delete Flashcard";
      modeSubmit = "Delete";
      modeFieldDisabled = true;
      modePermission = Permission.DELETE_FLASHCARD;
      modeValidation = noValidation;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  const submitNew = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_FLASHCARD)) {
        values = await toPersistenceValue(values);

        const retrievedFlashcardConfig = await retrieveDoc(
          "flashcardconfigs",
          user.uid
        );

        const learningSteps = retrievedFlashcardConfig.data.learningSteps
          .split(" ")
          .map(
            (ele) =>
              moment.duration(
                ele.substring(0, ele.length - 1),
                ele.substring(ele.length - 1)
              ) * 1
          );
        const interval = learningSteps[0];
        const revisedDate = moment()
          .tz("Asia/Singapore")
          .add(moment.duration(interval))
          .toDate();
        const createdFlashcard = await createDoc(
          collectionPathFlashcards,
          {
            ...values,
            flashcardRatingUsers: [],
            flashcardRatingCount: 0,
            flashcardRating: 0,
            flashcardEndorsedBy: [],
            flashcardEndorsementCount: 0,
            toBeRevisedAt: convertToTimestamp(revisedDate),
            state: 0,
            stateRepetition: 0,
            hardRepetition: 0,
            goodRepetition: 0,
            interval: 0,
            efactor: 0,
            leechCount: 0,
            leechTagged: false,
            leechSuspended: false,
            modifiedAt: null,
            modifiedBy: null,
            deletedAt: null,
            deletedBy: null,
          },
          user.uid
        );

        if (retrievedFlashcardConfig.data.autoShare) {
          delete createdFlashcard.data.state;
          delete createdFlashcard.data.interval;
          delete createdFlashcard.data.efactor;
          delete createdFlashcard.data.hardRepetition;
          delete createdFlashcard.data.goodRepetition;
          delete createdFlashcard.data.stateRepetition;
          delete createdFlashcard.data.leechCount;
          delete createdFlashcard.data.leechTagged;
          delete createdFlashcard.data.leechSuspended;

          await createDoc(
            "publicflashcards",
            {
              ...createdFlashcard.data,
              flashcardRatingUsers: [],
              flashcardRatingCount: 0,
              flashcardRating: 0,
              flashcardEndorsedBy: [],
              flashcardEndorsementCount: 0,
              sharedFrom: createdFlashcard.id,
              modifiedAt: null,
              modifiedBy: null,
              deletedAt: null,
              deletedBy: null,
            },
            user.uid
          );
        }

        dispatchIfNotUnmounted({
          type: "CREATED_FLASHCARD",
          payload: await toPresentationValue(createdFlashcard.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitEdit = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_FLASHCARD)) {
        const updatedDoc = await updateDoc(
          collectionPathFlashcards,
          flashcardId,
          {
            ...values,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          }
        );

        dispatchIfNotUnmounted({
          type: "UPDATED_FLASHCARD",
          payload: await toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitRating = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.RATE_FLASHCARD)) {
        const updatedDoc = await updateDoc("publicflashcards", flashcardId, {
          ...values,
          ratedAt: serverTimestamp(),
          ratedBy: user.uid,
        });
        console.log(updatedDoc);
        dispatchIfNotUnmounted({
          type: "RATED_FLASHCARD",
          payload: await toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitEndorse = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.ENDORSE_FLASHCARD)) {
        const updatedDoc = await updateDoc("publicflashcards", flashcardId, {
          ...values,
          ratedAt: serverTimestamp(),
          ratedBy: user.uid,
        });
        console.log(updatedDoc);
        dispatchIfNotUnmounted({
          type: "ENDORSED_FLASHCARD",
          payload: await toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitDisendorse = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.ENDORSE_FLASHCARD)) {
        const updatedDoc = await updateDoc("publicflashcards", flashcardId, {
          ...values,
          ratedAt: serverTimestamp(),
          ratedBy: user.uid,
        });
        console.log(updatedDoc);
        dispatchIfNotUnmounted({
          type: "DISENDORSED_FLASHCARD",
          payload: await toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitAdd = async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_FLASHCARD)) {
        const retrievedMyFlashcard = await retrieveDoc(
          "publicflashcards",
          flashcardId
        );

        delete retrievedMyFlashcard.data.ratedAt;
        delete retrievedMyFlashcard.data.ratedBy;
        delete retrievedMyFlashcard.data.logbookid;

        const createdFlashcard = await createDoc(
          "myflashcards",
          {
            ...retrievedMyFlashcard.data,
            flashcardRatingUsers: [],
            flashcardRatingCount: 0,
            flashcardRating: 0,
            flashcardEndorsedBy: [],
            flashcardEndorsementCount: 0,
            forkedFrom: flashcardId,
            toBeRevisedAt: serverTimestamp(),
            state: 0,
            stateRepetition: 0,
            hardRepetition: 0,
            goodRepetition: 0,
            interval: 0,
            efactor: 0,
            leechCount: 0,
            leechTagged: false,
            leechSuspended: false,
            modifiedAt: null,
            modifiedBy: null,
            deletedAt: null,
            deletedBy: null,
          },
          user.uid
        );

        dispatchIfNotUnmounted({
          type: "ADDED_FLASHCARD",
          payload: await toPresentationValue(createdFlashcard.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitFilterDiscovery = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.FILTER_FLASHCARD)) {
        const updatedDoc = await updateDoc("users", user.uid, {
          discoveryFilterOperationLevel1: values.operationLevel1,
          discoveryFilterOperationLevel2: values.operationLevel2,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "FILTERED_FLASHCARD",
          payload: await toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitDelete = async () => {
    try {
      //dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.DELETE_FLASHCARD)) {
        const deletedDoc = await deleteDoc(
          collectionPathFlashcards,
          flashcardId,
          {
            deletedAt: serverTimestamp(),
            deletedBy: user.uid,
          }
        );

        dispatchIfNotUnmounted({
          type: "DELETED_FLASHCARD",
          payload: await toPresentationValue(deletedDoc.id, deletedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to delete."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    submitNew,
    submitEdit,
    submitRating,
    submitEndorse,
    submitDisendorse,
    submitAdd,
    submitFilterDiscovery,
    submitDelete,
    response,
    setIsUnmounted,
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    dispatchDismiss,
  };
};
