import * as Yup from "yup";
import form from "pages/settings/flashcard/manage/schemas/form";

const {
  formField: {
    autoShare,
    newCardsPerDay,
    maximumReviewPerDay,
    learningSteps,
    graduatingInterval,
    easyInterval,
    relearningSteps,
    minimumInterval,
    leechThreshold,
    leechAction,
    newReviewOrder,
    interdayLearningReviewOrder,
    reviewSortOrder,
    maximumInterval,
    startingEase,
    easyBonus,
    hardInterval,
    newInterval,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [autoShare.name]: Yup.boolean().required(autoShare.errorMsg),
  [newCardsPerDay.name]: Yup.number().required(newCardsPerDay.errorMsg),
  [maximumReviewPerDay.name]: Yup.number().required(
    maximumReviewPerDay.errorMsg
  ),
  [learningSteps.name]: Yup.string().required(learningSteps.errorMsg),
  [graduatingInterval.name]: Yup.number().required(graduatingInterval.errorMsg),
  [easyInterval.name]: Yup.number().required(easyInterval.errorMsg),
  [relearningSteps.name]: Yup.string().required(relearningSteps.errorMsg),
  [minimumInterval.name]: Yup.number().required(minimumInterval.errorMsg),
  [leechThreshold.name]: Yup.number().required(leechThreshold.errorMsg),
  [leechAction.name]: Yup.string().required(leechAction.errorMsg),
  [newReviewOrder.name]: Yup.string().required(newReviewOrder.errorMsg),
  [interdayLearningReviewOrder.name]: Yup.string().required(
    interdayLearningReviewOrder.errorMsg
  ),
  [reviewSortOrder.name]: Yup.string().required(reviewSortOrder.errorMsg),
  [maximumInterval.name]: Yup.string().required(maximumInterval.errorMsg),
  [startingEase.name]: Yup.number().required(startingEase.errorMsg),
  [easyBonus.name]: Yup.number().required(easyBonus.errorMsg),
  [hardInterval.name]: Yup.number().required(hardInterval.errorMsg),
  [newInterval.name]: Yup.number().required(newInterval.errorMsg),
});

export { validations as default, noValidation };
