import PropTypes from "prop-types";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { ErrorMessage, Field } from "formik";
import BasicAutocompleteWrapper from "components/molecules/Formik/Wrapper/BasicAutocompleteWrapper";

function FormBasicAutocomplete({
  form,
  field,
  options,
  onChange,
  onInputChange,
  disabled,
  hideLabel,
  hideStatus,
  notTouchable,
  multiple,
  freeSolo,
  ...rest
}) {
  // options can be array of string or array of object
  // if it is array of object,
  // it must follow format { label: "string show on dropdown", value: "value" }
  const isStringOptions = options && typeof options[0] === "string";

  const { values, errors, touched, setFieldValue } = form;

  let value = null;

  if (multiple) {
    if (values[field.name] && values[field.name].length > 0) {
      value = isStringOptions
        ? values[field.name]
        : values[field.name].map(
            (fieldValue) =>
              options.find((ele) => ele.value === fieldValue) ?? null
          );
    } else {
      value = [];
    }
  } else {
    if (values[field.name]) {
      value = isStringOptions
        ? values[field.name]
        : options.find((ele) => ele.value === values[field.name]) ?? null;
    } else {
      value = null;
    }
  }

  const error = disabled
    ? false
    : !!errors[field.name] && (!!notTouchable || !!touched[field.name]);
  const success = disabled
    ? false
    : !!values[field.name] &&
      values[field.name].length > 0 &&
      !errors[field.name];
  const handleChange = (e, v) => {
    try {
      let fieldValue = null;
      if (multiple) {
        fieldValue = isStringOptions ? v : v.map((item) => item.value);
      } else {
        fieldValue = isStringOptions ? v : v?.value;
      }
      setFieldValue(field.name, fieldValue);
      onChange && onChange(e, v);
    } catch (err) {
      console.log(err);
    }
  };
  const handleInputChange = (e, v, r) => {
    if (!multiple && (freeSolo || options.includes(v))) {
      let fieldValue = null;
      if (multiple) {
        fieldValue = isStringOptions ? v : v.map((item) => item.value);
      } else {
        fieldValue = isStringOptions ? v : v?.value;
      }
      setFieldValue(field.name, fieldValue);
    }
    onInputChange && onInputChange(e, v, r);
  };

  return (
    <MDBox mb={2}>
      <Field
        name={field.name}
        type={field.type}
        label={hideLabel ? "" : field.label}
        value={value}
        options={options}
        onChange={handleChange}
        onInputChange={handleInputChange}
        as={BasicAutocompleteWrapper}
        placeholder={field.placeholder}
        disabled={disabled}
        error={hideStatus ? false : error}
        success={hideStatus ? false : success}
        multiple={multiple}
        freeSolo={freeSolo}
        {...rest}
      />
      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          <ErrorMessage name={field.name}></ErrorMessage>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of FormBasicAutocomplete
FormBasicAutocomplete.defaultProps = {
  hideLabel: false,
  hideStatus: false,
  notTouchable: false,
};

// typechecking props for FormBasicAutocomplete
FormBasicAutocomplete.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  variant: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideStatus: PropTypes.bool,
  notTouchable: PropTypes.bool,
  children: PropTypes.node,
};

export default FormBasicAutocomplete;
