import { useReducer, useCallback, useEffect, useState } from "react";
import { addFile, purgeFile, getURL } from "services/storage";

let initialState = {
  data: null,
  isPending: false,
  error: null,
  success: null,
};

const fileReducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return initialState;
    case "IS_PENDING":
      return { isPending: true, data: null, success: null, error: null };
    case "UPLOADED_FILE":
      return {
        isPending: false,
        data: action.payload,
        success: "Successfully uploaded the file.",
        error: null,
      };
    case "DOWNLOADED_FILE":
      return {
        isPending: false,
        data: action.payload,
        success: "Successfully downloaded the file.",
        error: null,
      };
    case "LISTED_FILES":
      return {
        isPending: false,
        data: action.payload,
        success: "Successfully listed files.",
        error: null,
      };
    case "PURGED_FILE":
      return {
        isPending: false,
        data: action.payload,
        success: "Successfully deleted the file.",
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: null,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useFile = () => {
  const [response, dispatch] = useReducer(fileReducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const getFileRef = useCallback(
    async (fullPath) => {
      try {
        dispatchIfNotUnmounted({ type: "IS_PENDING" });
        const fileURL = await getURL(fullPath);
        const fileRef = {
          path: fullPath,
          name: fullPath.substring(fullPath.lastIndexOf("/") + 1),
          url: fileURL,
        };
        dispatchIfNotUnmounted({
          type: "REFERRED_FILE",
          payload: fileRef,
        });
        return fileRef;
      } catch (err) {
        dispatchError(err);
        return null;
      }
    },
    [dispatchError, dispatchIfNotUnmounted]
  );

  const uploadFile = useCallback(
    async (fullPath, file) => {
      try {
        dispatchIfNotUnmounted({ type: "IS_PENDING" });
        const resultRef = await addFile(fullPath, file);
        const downloadURL = await getURL(resultRef.fullPath);
        const fileRef = {
          path: resultRef.fullPath,
          name: resultRef.name,
          url: downloadURL,
        };
        dispatchIfNotUnmounted({
          type: "UPLOADED_FILE",
          payload: fileRef,
        });
        return fileRef;
      } catch (err) {
        dispatchError(err);
        return null;
      }
    },
    [dispatchError, dispatchIfNotUnmounted]
  );

  const deleteFile = useCallback(
    async (fullPath) => {
      try {
        dispatchIfNotUnmounted({ type: "IS_PENDING" });

        await purgeFile(fullPath);
        dispatchIfNotUnmounted({ type: "PURGED_FILE" });
      } catch (err) {
        dispatchError(err);
        return null;
      }
    },
    [dispatchError, dispatchIfNotUnmounted]
  );

  const downloadFile = () => {};

  const listFile = () => {};

  useEffect(() => {
    return () => {
      setIsUnmounted(true);
    };
  }, []);

  return {
    getFileRef,
    uploadFile,
    downloadFile,
    deleteFile,
    listFile,
    response,
    dispatchDismiss,
    dispatchError,
  };
};
