const form = {
  formId: "csesurvey-form",
  formField: {
    medicalID: {
      name: "medicalID",
      label: "MCR",
      type: "text",
      errorMsg: "MCR is required,",
      invalidMsg: "MCR is required,",
    },
    patientID: {
      name: "patientID",
      label:
        "Patient ID (Last 3 numerical digits and last alphabet of the NRIC)",
      type: "text",
      errorMsg: "Patient ID is required,",
      invalidMsg:
        "Please enter the last 3 numerical digits and last alphabet of the NRIC,",
    },
    question1: {
      name: "question1",
      label: "1. Procedure completed without physical assistance",
      type: "text",
      errorMsg: "Question 1 is required,",
      invalidMsg: "Question 1 is required.",
    },
    question2: {
      name: "question2",
      label: "2. 2 attempts or less (each new skin puncture = 1 attempt)",
      type: "text",
      errorMsg: "Question 2 is required,",
      invalidMsg: "Question 2 is required.",
    },
    question3: {
      name: "question3",
      label: "3. Absence of inadvertent dura puncture",
      type: "text",
      errorMsg: "Question 3 is required,",
      invalidMsg: "Question 3 is required.",
    },
    question4: {
      name: "question4",
      label: "4. Absence of repeat procedure within 4 hours of initiation",
      type: "text",
      errorMsg: "Question 4 is required,",
      invalidMsg: "Question 4 is required.",
    },
    submissionDate: {
      name: "submissionDate",
      label: "Procedure Date",
      type: "text",
      errorMsg: "Procedure Date is required.",
      invalidMsg: "Procedure Date is invalid.",
    },
    venue: {
      name: "venue",
      label: "Venue",
      type: "text",
      errorMsg: "Venue is required.",
      invalidMsg: "Venue is invalid.",
    },
    remark: {
      name: "remark",
      label: "Remark",
      type: "text",
      errorMsg: "Remark is required.",
      invalidMsg: "Remark is invalid.",
    },
  },
};

export { form as default };
