import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import Container from "@mui/material/Container";
import Footer from "components/organisms/Footer";
function CenterFragment({ content, children }) {
  return (
    <>
      <MDBox px={0} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={10} md={9} lg={7} xl={6}>
            {content}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container>
              <Footer color={"white"} />
            </Container>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

// Typechecking props for the BackgroundLayout
CenterFragment.propTypes = {
  content: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default CenterFragment;
