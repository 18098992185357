import { useParams } from "react-router-dom";
import Logbook from "pages/logbook/manage/Logbook";

// using Proxy Page to remount when route to same route location but different param
function LogbookProxy() {
  const { mode, logbookId, flashcardId } = useParams();
  return <Logbook key={`${mode}/${logbookId}/${flashcardId}`} />;
}

export default LogbookProxy;
