import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useFile } from "hooks/useFile";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import { noValidation } from "pages/flashcard/manage/schemas/validations";

import moment from "moment-timezone";

const initialState = {
  isPending: true,
  data: [],
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: [],
        success: null,
        error: null,
      };
    case "INITIAL_FLASHCARD":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_FLASHCARD":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "SHARED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully shared the flashcard.`,
        error: null,
      };
    case "UNSHARED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully unshared the flashcard.`,
        error: null,
      };
    case "SCHEDULED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully scheduled the flashcard.`,
        error: null,
      };
    case "FILTERED_FLASHCARD":
      return {
        isPending: true,
        data: action.payload,
        success: `Successfully filtered the flashcard.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: [],
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useFlashcardRevisionManager = (mode) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();

  const {
    createDoc,
    retrieveDoc,
    retrieveDocs,
    updateDoc,
    deleteDoc,
    serverTimestamp,
    convertToTimestamp,
  } = useDocument();

  const { getFileRef } = useFile();

  const { pathname } = useLocation();

  const retrieveFlashcardList = useCallback(
    async (filter) => {
      try {
        const flashcardConfig = await retrieveDoc("flashcardconfigs", user.uid);
        const myflashcardsQueries = {
          whereQueries: [
            {
              field: "deletedAt",
              condition: "==",
              value: null,
            },
            {
              field: "createdBy",
              condition: "==",
              value: user.uid,
            },
            {
              field: "leechSuspended",
              condition: "==",
              value: false,
            },
            {
              field: "toBeRevisedAt",
              condition: "<",
              value: moment().tz("Asia/Singapore").toDate(),
            },
          ],
        };

        if (
          filter.revisionFilterOperationLevel1 &&
          filter.revisionFilterOperationLevel1 !== "All"
        ) {
          myflashcardsQueries.whereQueries.push({
            field: "operationLevel1",
            condition: "==",
            value: filter.revisionFilterOperationLevel1,
          });
        }
        if (
          filter.revisionFilterOperationLevel2 &&
          filter.revisionFilterOperationLevel2 !== "All"
        ) {
          myflashcardsQueries.whereQueries.push({
            field: "operationLevel2",
            condition: "==",
            value: filter.revisionFilterOperationLevel2,
          });
        }

        const retrievedFlashcardsForRevision = await retrieveDocs(
          "myflashcards",
          myflashcardsQueries
        );

        const sortAscending = (a, b) => {
          const momentA = moment(a.data.toBeRevisedAt.toDate()).tz(
            "Asia/Singapore"
          );
          const momentB = moment(b.data.toBeRevisedAt.toDate()).tz(
            "Asia/Singapore"
          );
          if (momentA.isAfter(momentB)) {
            return 1;
          } else if (momentA.isBefore(momentB)) {
            return -1;
          } else {
            return 0;
          }
        };

        const sortDescending = (a, b) => {
          const momentA = moment(a.data.toBeRevisedAt.toDate()).tz(
            "Asia/Singapore"
          );
          const momentB = moment(b.data.toBeRevisedAt.toDate()).tz(
            "Asia/Singapore"
          );
          if (momentA.isAfter(momentB)) {
            return -1;
          } else if (momentA.isBefore(momentB)) {
            return 1;
          } else {
            return 0;
          }
        };

        if (retrievedFlashcardsForRevision.length > 0) {
          const newCards = retrievedFlashcardsForRevision
            .filter((item) => item.data.state === 0)
            .sort((a, b) => {
              return sortAscending(a, b);
            })
            .slice(0, flashcardConfig.data.newCardsPerDay);

          const learningRelearningCards = retrievedFlashcardsForRevision
            .filter((item) => item.data.state === 1 || item.data.state === 3)
            .sort((a, b) => {
              return sortAscending(a, b);
            });

          const reviewCards = retrievedFlashcardsForRevision
            .filter((item) => item.data.state === 2)
            .sort((a, b) => {
              return sortAscending(a, b);
            })
            .slice(0, flashcardConfig.data.maximumReviewPerDay);

          const longestDueDateReviewCard = reviewCards.find(
            (item) =>
              item.data.toBeRevisedAt.toDate().getMilliseconds() ===
              Math.max(
                ...reviewCards.map((card) =>
                  card.data.toBeRevisedAt.toDate().getMilliseconds()
                )
              )
          );

          const nonLongestDueDateReviewCard = reviewCards.filter(
            (item) =>
              item.data.toBeRevisedAt.toDate().getMilliseconds() !==
              Math.max(
                ...reviewCards.map((card) =>
                  card.data.toBeRevisedAt.toDate().getMilliseconds()
                )
              )
          );

          const sortedReviewCards =
            flashcardConfig.data.reviewSortOrder === "Due date, then random"
              ? [
                  longestDueDateReviewCard,
                  ...nonLongestDueDateReviewCard.sort(
                    () => 0.5 - Math.random()
                  ),
                ]
              : reviewCards.sort((a, b) => {
                  if (
                    flashcardConfig.data.reviewSortOrder ===
                    "Ascending intervals"
                  ) {
                    return sortAscending(a.b);
                  } else if (
                    flashcardConfig.data.reviewSortOrder ===
                    "Descending intervals"
                  ) {
                    return sortDescending(a, b);
                  } else {
                    return 0;
                  }
                });

          if (flashcardConfig.data.newReviewOrder === "Show after reviews") {
            if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show after reviews"
            ) {
              return [
                ...sortedReviewCards,
                ...learningRelearningCards,
                ...newCards,
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show before reviews"
            ) {
              return [
                ...learningRelearningCards,
                ...sortedReviewCards,
                ...newCards,
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Mixed with reviews"
            ) {
              return [
                ...reviewCards
                  .concat(learningRelearningCards)
                  .sort((a, b) => sortAscending(a, b)),
                ...newCards,
              ].filter((item) => !!item);
            }
          } else if (
            flashcardConfig.data.newReviewOrder === "Show before reviews"
          ) {
            if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show after reviews"
            ) {
              return [
                ...newCards,
                ...sortedReviewCards,
                ...learningRelearningCards,
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show before reviews"
            ) {
              return [
                ...newCards,
                ...learningRelearningCards,
                ...sortedReviewCards,
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Mixed with reviews"
            ) {
              return [
                ...newCards,
                ...reviewCards
                  .concat(learningRelearningCards)
                  .sort((a, b) => sortAscending(a, b)),
              ].filter((item) => !!item);
            }
          } else if (
            flashcardConfig.data.newReviewOrder === "Mixed with reviews"
          ) {
            if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show after reviews"
            ) {
              return [
                ...reviewCards
                  .concat(newCards)
                  .sort((a, b) => sortAscending(a, b)),
                ...learningRelearningCards,
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Show before reviews"
            ) {
              return [
                ...learningRelearningCards,
                ...reviewCards
                  .concat(newCards)
                  .sort((a, b) => sortAscending(a, b)),
              ].filter((item) => !!item);
            } else if (
              flashcardConfig.data.interdayLearningReviewOrder ===
              "Mixed with reviews"
            ) {
              return [
                ...reviewCards
                  .concat(newCards)
                  .concat(learningRelearningCards)
                  .sort((a, b) => sortAscending(a, b)),
              ].filter((item) => !!item);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    [retrieveDoc, retrieveDocs, user.uid]
  );

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  // TODO: Refactor to DAO Layer
  const toPresentationValue = useCallback(
    async (data) => {
      try {
        if (mode !== "new" && data.date) data.date = data.date.toDate();

        if (data.flashcardFrontImgAttachments) {
          data.flashcardFrontImgAttachments.forEach(async (element) => {
            try {
              const fileRef = await getFileRef(element.attachmentPath);
              element.attachmentURL = fileRef.url;
            } catch (err) {
              element.attachmentURL = null;
            }
          });
        }

        if (data.flashcardBackImgAttachments) {
          data.flashcardBackImgAttachments.forEach(async (element) => {
            try {
              const fileRef = await getFileRef(element.attachmentPath);
              element.attachmentURL = fileRef.url;
            } catch (err) {
              element.attachmentURL = null;
            }
          });
        }

        return data;
      } catch (err) {
        console.error(err);
        dispatchError(err);
      }
    },
    [dispatchError, getFileRef, mode]
  );

  const dispatchDismiss = useCallback(async () => {
    let operationInvalidError = new Error(
      "Invalid Operation. You are not allowed to carry out this activity."
    );
    operationInvalidError.name = "OperationInvalidError";

    switch (mode) {
      case "revise":
        const retrievedUser = await retrieveDoc("users", user.uid);
        const filter = {
          revisionFilterOperationLevel1:
            retrievedUser.data.revisionFilterOperationLevel1,
          revisionFilterOperationLevel2:
            retrievedUser.data.revisionFilterOperationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "RETRIEVED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });

        break;

      default:
        throw operationInvalidError;
    }
  }, [
    dispatchIfNotUnmounted,
    mode,
    retrieveDoc,
    retrieveFlashcardList,
    toPresentationValue,
    user.uid,
  ]);

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      switch (mode) {
        case "revise":
          if (!pathname.includes("/flashcard/manage")) {
            throw operationInvalidError;
          }

          const retrievedUser = await retrieveDoc("users", user.uid);
          const filter = {
            revisionFilterOperationLevel1:
              retrievedUser.data.revisionFilterOperationLevel1,
            revisionFilterOperationLevel2:
              retrievedUser.data.revisionFilterOperationLevel2,
          };

          const flashcardList = await retrieveFlashcardList(filter);

          dispatchIfNotUnmounted({
            type: "RETRIEVED_FLASHCARD",
            payload: await Promise.all(
              flashcardList?.map(async (ele) => {
                const data = await toPresentationValue(ele.data);
                return {
                  id: ele.id,
                  ...data,
                };
              }) ?? []
            ),
          });
          break;
        default:
          throw operationInvalidError;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    mode,
    pathname,
    retrieveDoc,
    user.uid,
    retrieveFlashcardList,
    toPresentationValue,
    dispatchError,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "";
  let modeSubmit = "";
  let modeFieldDisabled = true;
  let modePermission = "";
  let modeValidation = noValidation;
  switch (mode) {
    case "revise":
      modeTitle = "Revise Flashcard";
      modeSubmit = "Close";
      modeFieldDisabled = false;
      modePermission = Permission.REVISE_FLASHCARD;
      modeValidation = noValidation;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  // Flashcard Revision functions
  const submitShare = async (flashcardId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.SHARE_FLASHCARD)) {
        const retrievedMyFlashcard = await retrieveDoc(
          "myflashcards",
          flashcardId
        );

        delete retrievedMyFlashcard.data.state;
        delete retrievedMyFlashcard.data.interval;
        delete retrievedMyFlashcard.data.efactor;
        delete retrievedMyFlashcard.data.hardRepetition;
        delete retrievedMyFlashcard.data.goodRepetition;
        delete retrievedMyFlashcard.data.stateRepetition;
        delete retrievedMyFlashcard.data.leechCount;
        delete retrievedMyFlashcard.data.leechTagged;
        delete retrievedMyFlashcard.data.leechSuspended;

        await createDoc(
          "publicflashcards",
          {
            ...retrievedMyFlashcard.data,
            flashcardRatingUsers: [],
            flashcardRatingCount: 0,
            flashcardRating: 0,
            flashcardEndorsedBy: [],
            flashcardEndorsementCount: 0,
            sharedFrom: flashcardId,
            modifiedAt: null,
            modifiedBy: null,
            deletedAt: null,
            deletedBy: null,
          },
          user.uid
        );

        const retrievedUser = await retrieveDoc("users", user.uid);
        const filter = {
          revisionFilterOperationLevel1:
            retrievedUser.data.revisionFilterOperationLevel1,
          revisionFilterOperationLevel2:
            retrievedUser.data.revisionFilterOperationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "SHARED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitUnshare = async (flashcardId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.SHARE_FLASHCARD)) {
        const publicflashcardsQueries = {
          whereQueries: [
            {
              field: "deletedAt",
              condition: "==",
              value: null,
            },
            {
              field: "sharedFrom",
              condition: "==",
              value: flashcardId,
            },
          ],
        };

        const retrievedSharedFlashcards = await retrieveDocs(
          "publicflashcards",
          publicflashcardsQueries
        );

        await Promise.all(
          retrievedSharedFlashcards.map(async (card) => {
            const deletedDoc = await deleteDoc("publicflashcards", card.id, {
              deletedAt: serverTimestamp(),
              deletedBy: user.uid,
            });
            return deletedDoc;
          })
        );

        const retrievedUser = await retrieveDoc("users", user.uid);
        const filter = {
          revisionFilterOperationLevel1:
            retrievedUser.data.revisionFilterOperationLevel1,
          revisionFilterOperationLevel2:
            retrievedUser.data.revisionFilterOperationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "UNSHARED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitSchedule = async (values, flashcardId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.REVISE_FLASHCARD)) {
        await updateDoc("myflashcards", flashcardId, {
          ...values,
          toBeRevisedAt: convertToTimestamp(values.toBeRevisedAt),
          revisedAt: serverTimestamp(),
          revisedBy: user.uid,
        });

        const retrievedUser = await retrieveDoc("users", user.uid);
        const filter = {
          revisionFilterOperationLevel1:
            retrievedUser.data.revisionFilterOperationLevel1,
          revisionFilterOperationLevel2:
            retrievedUser.data.revisionFilterOperationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "SCHEDULED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  const submitFilterRevision = async (values) => {
    try {
      // dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.FILTER_FLASHCARD)) {
        await updateDoc("users", user.uid, {
          revisionFilterOperationLevel1: values.operationLevel1,
          revisionFilterOperationLevel2: values.operationLevel2,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        const filter = {
          revisionFilterOperationLevel1: values.operationLevel1,
          revisionFilterOperationLevel2: values.operationLevel2,
        };

        const flashcardList = await retrieveFlashcardList(filter);

        dispatchIfNotUnmounted({
          type: "FILTERED_FLASHCARD",
          payload: await Promise.all(
            flashcardList?.map(async (ele) => {
              const data = await toPresentationValue(ele.data);
              return {
                id: ele.id,
                ...data,
              };
            }) ?? []
          ),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      console.error(err);
      dispatchError(err);
    }
  };

  return {
    submitShare,
    submitUnshare,
    submitSchedule,
    submitFilterRevision,
    response,
    setIsUnmounted,
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    dispatchDismiss,
    retrieveFlashcardList,
  };
};
