import { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  setRolesOfAuthnUser,
  signInUserWithGoogle,
} from "services/authentication";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import PropTypes from "prop-types";

function RedirectAuth({ children }) {
  // const [isUnmounted, setIsUnmounted] = useState(false);
  const { retrieveDoc, createDoc } = useDocument();
  const { user } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const initiateGoogleSignIn = useCallback(async () => {
    if (
      user &&
      user.emailVerified &&
      location.pathname !== "/authentication/signup"
    ) {
      // first time google signin
      const retrievedUser = await retrieveDoc("users", user.uid);
      const providers = user.providerData.map(
        (provider) => provider.providerId
      );
      if (!retrievedUser && providers.includes("google.com")) {
        await createDoc(
          "users",
          {
            email: user.email,
            designation: "-",
            displayName: user.displayName,
            medicalID: "-",
            photoURL: user.photoURL,
            roles: ["MEMBER", "RESIDENT"],
            root: false,
            system: false,
            signatureAttachments: [],
          },
          user.uid,
          user.uid
        );
        await setRolesOfAuthnUser({ roles: ["MEMBER", "RESIDENT"] });
        // re-authenticate after set new custom claims
        await signInUserWithGoogle();
      } else {
        // user is signed in
        // redirect user to / page
        navigate("/", { replace: true });
      }
    } else if (
      user &&
      !user.emailVerified &&
      location.pathname !== "/authentication/verifyemail" &&
      location.pathname !== "/authentication/signup"
    ) {
      // user is signed in but email is not verified
      // redirect user to /authentication/verifyemail page
      navigate("/authentication/verifyemail", {
        state: { from: location },
        replace: true,
      });
    }
  }, [createDoc, location, navigate, retrieveDoc, user]);

  useEffect(() => {
    try {
      initiateGoogleSignIn();
    } catch (error) {
      console.log(error);
    }
    // return () => setIsUnmounted(true);
  }, [initiateGoogleSignIn]);

  return children;
}

RedirectAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RedirectAuth;
