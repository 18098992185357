import { useEffect, useState } from "react";
import { signOutUser } from "services/authentication";
import { useAuthContext } from "context/AuthContext";

export const useSignout = () => {
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();

  const signout = async () => {
    try {
      setError(null);

      await signOutUser();

      dispatch({ type: "SIGNOUT" });

      if (!isUnmounted) {
        setError(null);
      }
    } catch (err) {
      if (!isUnmounted) {
        // SEE https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html#authentication-and-error-messages
        // Don't show error on console
        // Respond with generic error message regardless
        // console.error(err.message);
        setError("Sign out failed.");
      }
    }
  };

  useEffect(() => {
    return () => setIsUnmounted(true);
  }, []);

  return { signout, error };
};
