import form, { rolesForm } from "pages/settings/users/manage/schemas/form";

const {
  formField: {
    displayName,
    designation,
    medicalID,
    roles,
    preferredLogbookColumns,
    preferredFlashcardColumns,
  },
} = form;

const initialValues = {
  [displayName.name]: "",
  [designation.name]: "",
  [medicalID.name]: "",
  [roles.name]: [],
  [preferredLogbookColumns.name]: [],
  [preferredFlashcardColumns.name]: [],
};

const {
  formField: { roleName },
} = rolesForm;

const rolesInitialValues = {
  [roleName.name]: "",
};

export { initialValues as default, rolesInitialValues };
