import { messaging } from "configs/firebase";
import { getToken, onMessage } from "firebase/messaging";

export const requestMessagingPermission = (getTokenAction) => {
  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");

      return getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("Client Token: ", currentToken);
            getTokenAction(currentToken);
          } else {
            console.log("Failed to generate the registration token.");
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      resolve(payload);
    });
  });
