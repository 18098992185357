import { useCallback, useState, useEffect } from "react";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import DefaultLineChart from "components/charts/LineCharts/DefaultLineChart";
import { std, max, min, mean } from "mathjs";

function CusumChart() {
  const { user } = useAuthContext();
  const { retrieveDoc, updateDoc } = useDocument();
  const [graphLabels, setGraphLabels] = useState([]);
  const [graphDatasets, setGraphDatasets] = useState([]);

  const initializeFieldValue = useCallback(async () => {
    //const retrievedRecords = await retrieveDoc(user.uid, "records");
    const retrievedRecords = await retrieveDoc("records", user.uid);

    let cseRecords = [];
    if (retrievedRecords.data !== undefined) {
      cseRecords = retrievedRecords.data.cseRecords;
    }

    const retrievedCusumSettings = await retrieveDoc("methods", "cusum");
    const acceptableFailureRate =
      retrievedCusumSettings.data.acceptableFailureRate;
    const h1 = retrievedCusumSettings.data.upperDecisionLimit;
    const h0 = retrievedCusumSettings.data.lowerDecisionLimit;

    const cusum_labels = [];
    const S_data = [];
    let S_min, S_max;
    let stats = {
      numpts: 0,
      max: 0.0,
      maxidx: 0,
      min: 0.0,
      minidx: 0,
      mean: 0.0,
      std: 0.0,
    };
    if (cseRecords.length > 0) {
      S_min = 1000;
      S_max = -1000;
      //S_data.push(0);
      cusum_labels.push(0);
      for (let ii = 0; ii < cseRecords.length; ii++) {
        let sum_delta = 0;
        for (let jj = 0; jj < ii + 1; jj++) {
          const cseRecord = cseRecords[jj];
          let Xn;
          if (
            cseRecord["question1"] === "YES" &&
            cseRecord["question2"] === "YES" &&
            cseRecord["question3"] === "YES" &&
            cseRecord["question4"] === "YES"
          )
            Xn = 0;
          else Xn = 1;

          let delta = Xn - acceptableFailureRate;
          sum_delta = sum_delta + delta;
        }

        if (S_min > sum_delta) S_min = sum_delta;
        if (S_max < sum_delta) S_max = sum_delta;

        S_data.push(sum_delta);
        cusum_labels.push(ii + 1);
      }

      stats.numpts = cseRecords.length;
      stats.max = max(...S_data);
      stats.maxidx = S_data.indexOf(stats.max) + 1;
      stats.min = min(...S_data);
      stats.minidx = S_data.indexOf(stats.min) + 1;
      stats.mean = mean(...S_data);
      stats.std = std(...S_data);
    } else {
      S_min = -h0;
      S_max = h1;
      //S_data.push(0);
      //cusum_labels.push(0);
    }
    S_data.splice(0, 0, 0); // Add 0 to the beginning (index-0) of S_data (start, delete-elm, value)

    await updateDoc("records", user.uid, {
      ...retrievedRecords.data,
      cseCusumValues: S_data,
      cseCusumStats: stats,
    });

    setGraphLabels(cusum_labels);
    // Prepare datasets for line chart
    let datasets = [
      {
        label: "Cusum",
        color: "info",
        data: S_data,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ];

    let num_h0, num_h1;
    if (S_max < 0) num_h1 = 1;
    else num_h1 = Math.ceil(S_max / h1);
    if (S_min > 0) num_h0 = 1;
    else num_h0 = Math.ceil(S_min / h0);
    const labels_len = cusum_labels.length;

    for (let ii = 0; ii < num_h1; ii++) {
      datasets.push({
        label: (ii + 1).toString() + "h1",
        color: "warning",
        data: Array(labels_len).fill((ii + 1) * h1),
        borderDash: [10, 5],
        pointRadius: 0,
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBorderWidth: 0,
      });
    }

    for (let ii = 0; ii < num_h0; ii++) {
      datasets.push({
        label: (ii + 1).toString() + "h0",
        color: "success",
        data: Array(labels_len).fill((ii + 1) * h0),
        borderDash: [10, 5],
        pointRadius: 0,
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBorderWidth: 0,
      });
    }

    setGraphDatasets(datasets);
  }, [retrieveDoc, user, updateDoc]);

  const defaultLineChartData1 = {
    labels: graphLabels,
    datasets: graphDatasets,
  };

  useEffect(() => {
    try {
      initializeFieldValue();
    } catch (err) {}
  }, [initializeFieldValue]);

  return (
    <Card id="plot-cusum-chart" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox>
          <Grid item xs={12} md={12}>
            <DefaultLineChart
              icon={{ component: "show_chart" }}
              title="Cusum chart"
              description=""
              chart={defaultLineChartData1}
            />
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

CusumChart.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default CusumChart;
