/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `auth` key is used for marking an item on the Sidenav that require authentication to access the link.
  9. The `permissions` key is used for marking an item on the Sidenav that require specified roles to access the link.
  10. The `route` key is used for the "path" parameter of <Route> component.
  11. The `link` key is used for the "to" parameter of <Link> component.
  12. The `href` key is used to store the external links location.
  13. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  14. The `component` key is used to store the component of its route.
*/

import { Navigate } from "react-router-dom";

import AccountProfile from "pages/account/manage/AccountProfile";
import NewUser from "pages/settings/user/new/NewUser";
import ManageUsers from "pages/settings/users/manage/ManageUsers";
import ManageRoles from "pages/settings/roles/manage/ManageRoles";
import SetCusumSettings from "pages/settings/cusum/manage/SetCusumSettings";
import SetFlashcardSettings from "pages/settings/flashcard/manage/SetFlashcardSettings";

import CseSurveyProxy from "pages/csesurveys/manage/CseSurveyProxy";
import CseChartProxy from "pages/csesurveys/viewchart/CseChartProxy";
import CseRecords from "pages/csesurveys/records/CseRecords";

import LogbookProxy from "pages/logbook/LogbookProxy";
import LogbookRecords from "pages/logbook/records/LogbookRecords";
import LogbookCharts from "pages/logbook/charts/LogbookCharts";

import FlashcardProxy from "pages/flashcard/FlashcardProxy";
import FlashcardRecords from "pages/flashcard/records/FlashcardRecords";

import CohortStatistics from "pages/cohortstatistics/manage/CohortStatisticsProxy";
import IndividualRecord from "pages/cohortstatistics/viewindividualrecord/IndividualRecordProxy";

import LogbookStatistics from "pages/logbookstatistics/manage/LogbookStatisticsProxy";
import IndividualLogbookCharts from "pages/logbookstatistics/viewindividualchart/IndividualLogbookCharts";
import IndividualLogbookRecords from "pages/logbookstatistics/viewindividuallogbook/IndividualLogbookRecords";

import SignIn from "pages/authentication/signin";
import SignUp from "pages/authentication/signup";
import VerifyEmail from "pages/authentication/verifyemail";
import ResetPassword from "pages/authentication/resetpassword";

import MDAvatar from "components/atoms/MDAvatar";

// import Icon from "@mui/material/Icon";
import SettingsIcon from "@mui/icons-material/Settings";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import InsightsIcon from "@mui/icons-material/Insights";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";

import { Permission } from "models/abac";

const navigate = (displayName, displayNameKey) => {
  return [
    {
      name: displayName,
      key: displayNameKey,
      auth: true,
      permissions: Permission.VIEW_MEMBER_DASHBOARD,
      link: `/account/profile`,
      route: `/account/profile`,
      component: <AccountProfile />,
      icon: (
        <MDAvatar bgColor="info" size="sm">
          {displayName?.charAt(0).toUpperCase()}
        </MDAvatar>
      ),
    },
    {
      name: "Flashcard",
      key: "manage-flashcard-settings",
      auth: true,
      permissions: [Permission.VIEW_MEMBER_DASHBOARD],
      icon: <SettingsIcon />,
      route: "/settings/setflashcardsettings",
      link: "/settings/setflashcardsettings",
      component: <SetFlashcardSettings />,
    },
    {
      type: "divider",
      key: "divider-action",
      permissions: Permission.VIEW_MEMBER_DASHBOARD,
    },
    {
      type: "title",
      title: "Action",
      key: "title-action",
      permissions: Permission.VIEW_CSESURVEY_ACTION,
    },
    {
      type: "collapse",
      name: "CSE Procedures",
      key: "cseprocedures",
      permissions: Permission.VIEW_CSESURVEY_ACTION,
      icon: <VaccinesIcon />,
      collapse: [
        {
          name: "Submit Survey",
          key: "new-survey",
          auth: true,
          permissions: Permission.CREATE_CSESURVEY,
          link: "/csesurveys/manage/new",
          component: <CseSurveyProxy />,
        },
        {
          name: "View CUSUM Chart",
          key: "view-cusum-chart",
          auth: true,
          permissions: Permission.VIEW_CSECHART,
          link: "/csesurveys/viewchart/view",
          component: <CseChartProxy />,
        },
        {
          name: "All Submissions",
          key: "all-submissions",
          auth: true,
          permissions: Permission.READ_ALL_CSESURVEYS,
          //route: "/csesurveys/records",
          link: "/csesurveys/records/allrecords",
          component: <CseRecords />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Logbook",
      key: "logbook",
      permissions: Permission.VIEW_LOGBOOK_ACTION,
      icon: <LibraryBooksIcon />,
      collapse: [
        {
          name: "Submit Logbook",
          key: "new-logbook",
          auth: true,
          permissions: Permission.CREATE_LOGBOOK,
          link: "/logbook/manage/new",
          component: <LogbookProxy />,
        },
        {
          name: "All Submissions",
          key: "all-logbook-submissions",
          auth: true,
          permissions: Permission.READ_ALL_LOGBOOKS,
          route: "/logbook/records",
          link: "/logbook/records",
          component: <LogbookRecords />,
        },
        {
          name: "Logbook Charts",
          key: "logbook-charts",
          auth: true,
          permissions: Permission.READ_ALL_LOGBOOKS,
          route: "/logbook/charts",
          link: "/logbook/charts",
          component: <LogbookCharts />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Flashcard",
      key: "flashcard",
      permissions: Permission.VIEW_FLASHCARD_ACTION,
      icon: <LocalLibraryIcon />,
      collapse: [
        {
          name: "Revise Flashcards",
          key: "revise-flashcards",
          auth: true,
          permissions: Permission.READ_ALL_FLASHCARDS,
          link: "/flashcard/manage/revise",
          component: <FlashcardProxy />,
        },
        {
          name: "Discover Flashcards",
          key: "discover-flashcards",
          auth: true,
          permissions: Permission.READ_ALL_FLASHCARDS,
          link: "/flashcard/manage/discover",
          component: <FlashcardProxy />,
        },
        {
          name: "Create Flashcard",
          key: "new-flashcard",
          auth: true,
          permissions: Permission.CREATE_FLASHCARD,
          link: "/flashcard/manage/new",
          component: <FlashcardProxy />,
        },
        {
          name: "My Flashcard Bank",
          key: "all-flashcard-submissions",
          auth: true,
          permissions: Permission.READ_ALL_FLASHCARDS,
          route: "/flashcard/records",
          link: "/flashcard/records",
          component: <FlashcardRecords />,
        },
      ],
    },
    {
      type: "divider",
      key: "divider-settings",
      auth: true,
      permissions: [Permission.VIEW_MEMBER_DASHBOARD],
    },
    {
      type: "title",
      title: "Admin Dashboard",
      key: "title-admin-dashboard",
      auth: true,
      permissions: [Permission.VIEW_ADMIN_DASHBOARD],
    },
    {
      name: "Cohort Statistics",
      key: "cohor-statistics",
      auth: true,
      permissions: [Permission.VIEW_ADMIN_DASHBOARD],
      icon: <InsightsIcon />,
      //route: "/cohortstatistics/all",
      link: "/cohortstatistics/all",
      component: <CohortStatistics />,
    },
    {
      name: "Logbook Statistics",
      key: "logbook-statistics",
      auth: true,
      permissions: [Permission.VIEW_ADMIN_DASHBOARD],
      icon: <InsightsIcon />,
      link: "/logbookstatistics/all",
      component: <LogbookStatistics />,
    },
    {
      type: "title",
      title: "Settings",
      key: "title-settings",
      auth: true,
      permissions: [Permission.VIEW_ADMIN_DASHBOARD],
    },
    {
      name: "New User",
      key: "new-user",
      auth: true,
      permissions: [Permission.VIEW_ADMIN_DASHBOARD],
      icon: <SettingsIcon />,
      route: "/settings/user",
      link: "/settings/user",
      component: <NewUser />,
    },
    {
      name: "Users",
      key: "manage-users",
      auth: true,
      permissions: [Permission.VIEW_ADMIN_DASHBOARD],
      icon: <SettingsIcon />,
      route: "/settings/users",
      link: "/settings/users",
      component: <ManageUsers />,
    },
    {
      name: "Roles",
      key: "manage-roles",
      auth: true,
      permissions: [Permission.VIEW_ADMIN_DASHBOARD],
      icon: <SettingsIcon />,
      route: "/settings/roles",
      link: "/settings/roles",
      component: <ManageRoles />,
    },
    {
      name: "Cusum",
      key: "manage-cusum",
      auth: true,
      permissions: [Permission.VIEW_ADMIN_DASHBOARD],
      icon: <SettingsIcon />,
      route: "/settings/setcusumsettings",
      link: "/settings/setcusumsettings",
      component: <SetCusumSettings />,
    },

    {
      name: "Sign In",
      key: "signin",
      route: "/authentication/signin",
      component: <SignIn />,
    },
    {
      name: "Sign Up",
      key: "sign-up",
      // auth: true,
      // permissions: Permission.PERFORM_ACTION,
      route: "/authentication/signup",
      component: <SignUp />,
    },
    {
      name: "Reset Password",
      key: "reset-password",
      route: "/authentication/resetpassword",
      component: <ResetPassword />,
    },
    {
      name: "Verify Email",
      key: "verify-email",
      auth: true,
      route: "/authentication/verifyemail",
      component: <VerifyEmail />,
    },
    {
      name: "All",
      key: "all",
      auth: true,
      permissions: Permission.VIEW_MEMBER_DASHBOARD,
      route: "*",
      component: <Navigate to={`/csesurveys/manage/new`} />,
    },
    {
      name: "Manage CSE Survey",
      key: "manage-csesurveys",
      auth: true,
      route: "/csesurveys/manage/:mode",
      component: <CseSurveyProxy />,
    },
    {
      name: "Manage CSE Survey",
      key: "manage-csesurvey-id",
      auth: true,
      route: "/csesurveys/manage/:mode/:recordId",
      component: <CseSurveyProxy />,
    },
    {
      name: "View Cusum Chart",
      key: "view-cusum-chart",
      auth: true,
      route: "/csesurveys/viewchart/:mode",
      component: <CseChartProxy />,
    },
    {
      name: "View Cusum Chart",
      key: "view-cusum-chart-id",
      auth: true,
      route: "/csesurveys/viewchart/:mode/:csesurveyId",
      component: <CseChartProxy />,
    },
    {
      name: "View All Submissions",
      key: "view-all-submissions",
      auth: true,
      route: "/csesurveys/records/:mode",
      component: <CseRecords />,
    },
    {
      name: "View Cohort All Records",
      key: "view-cohort-all-record",
      auth: true,
      route: "/cohortstatistics/:mode",
      component: <CohortStatistics />,
    },
    {
      name: "View Individual Record",
      key: "view-individual-record",
      auth: true,
      route: "/cohortstatistics/:mode/:id/:displayname",
      component: <IndividualRecord />,
    },
    {
      name: "Manage Logbook",
      key: "manage-logbook",
      auth: true,
      route: "/logbook/manage/:mode",
      component: <LogbookProxy />,
    },
    {
      name: "Manage Logbook Ids",
      key: "manage-logbook-logbookId-flashcardId",
      auth: true,
      route: "/logbook/manage/:mode/:logbookId/:flashcardId",
      component: <LogbookProxy />,
    },
    {
      name: "View All Logbook Submissions",
      key: "view-all-logbook-submissions",
      auth: true,
      route: "/logbook/records/:mode",
      component: <LogbookRecords />,
    },
    {
      name: "Manage Flashcard",
      key: "manage-flashcard",
      auth: true,
      route: "/flashcard/manage/:mode",
      component: <FlashcardProxy />,
    },
    {
      name: "Manage Flashcard Ids",
      key: "manage-flashcard-flashcardId",
      auth: true,
      route: "/flashcard/manage/:mode/:flashcardId",
      component: <FlashcardProxy />,
    },
    {
      name: "View All Flashcard Submissions",
      key: "view-all-flashcard-submissions",
      auth: true,
      route: "/flashcard/records/:mode",
      component: <FlashcardRecords />,
    },
    {
      name: "View Cohort Logbook Statistics",
      key: "view-cohort-logbook-statistics",
      auth: true,
      route: "/logbookstatistics/:mode",
      component: <LogbookStatistics />,
    },
    {
      name: "View Individual Logbook Charts",
      key: "view-individual-logbook-charts",
      auth: true,
      route: "/logbookstatistics/:mode/:userId",
      component: <IndividualLogbookCharts />,
    },
    {
      name: "View Individual Logbook Records",
      key: "view-individual-logbook-records",
      auth: true,
      route: "/cohortlogbookrecords/:mode/:userId",
      component: <IndividualLogbookRecords />,
    },
  ];
};

export default navigate;
