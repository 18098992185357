import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormTextField from "components/molecules/Formik/FormTextField";
import FormAutocomplete from "components/molecules/Formik/FormAutocomplete";

import { subDays } from "date-fns";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import FormField from "components/molecules/FormField";

import {
  durationOptions,
  residencyYearOptions,
  roleOptions,
  siteOptions,
} from "pages/logbook/manage/schemas/setups";

const today = new Date();
const yesterday = subDays(today, 1);

function BasicInformation({ mainForm, mainFormField, modeDisabled, mode }) {
  const { values, setFieldValue } = mainForm;
  const {
    caseId,
    date,
    durationHour,
    duration,
    residencyYear,
    role,
    site,
    supervisor,
    age,
    ageUnit,
  } = mainFormField;

  return (
    <Card id="logbook-basic" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox
          variant="gradient"
          bgColor="success"
          color="white"
          //coloredShadow="red"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="15rem"
          height="2.5rem"
          mt={-4}
        >
          <MDBox>
            <MDTypography variant="body2" color="white" fontWeight="medium">
              BASIC INFORMATION
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox mt={1}>
          <Grid container pt={1} rowSpacing={1}>
            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {caseId.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormTextField
                    form={mainForm}
                    field={caseId}
                    size="small"
                    disabled={modeDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {date.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    format="yyyy-MM-dd"
                    value={values.date}
                    ranges={[
                      {
                        label: "Now",
                        value: today,
                      },
                      {
                        label: "Yesterday",
                        value: yesterday,
                      },
                    ]}
                    onChange={(v) => {
                      setFieldValue(date.name, v);
                    }}
                    disabled={modeDisabled}
                  />
                  <FormField type={date.type} name={date.name} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={3}>
                      <MDTypography variant="body3" fontWeight="bold">
                        Duration
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <FormTextField
                        form={mainForm}
                        field={durationHour}
                        size="small"
                        disabled={modeDisabled}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <FormAutocomplete
                        form={mainForm}
                        field={duration}
                        size="small"
                        options={durationOptions}
                        disabled={modeDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {residencyYear.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormAutocomplete
                    form={mainForm}
                    field={residencyYear}
                    size="small"
                    options={residencyYearOptions}
                    disabled={modeDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {role.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormAutocomplete
                    form={mainForm}
                    field={role}
                    size="small"
                    options={roleOptions}
                    disabled={modeDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {site.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormAutocomplete
                    form={mainForm}
                    field={site}
                    size="small"
                    options={siteOptions}
                    disabled={modeDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {supervisor.label}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormTextField
                    form={mainForm}
                    field={supervisor}
                    size="small"
                    disabled={modeDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="body3" fontWeight="bold">
                    {age.label} {" ("} {values.ageUnit} {")"}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormTextField
                    form={mainForm}
                    field={age}
                    size="small"
                    disabled={modeDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <ToggleButtonGroup
                    color="info"
                    size="small"
                    variant="contained"
                    value={values.ageUnit}
                    exclusive
                    fullWidth
                    onChange={(event, value) => {
                      setFieldValue(ageUnit.name, value);
                    }}
                    disabled={modeDisabled}
                  >
                    <ToggleButton value="DAY">DAY</ToggleButton>
                    <ToggleButton value="MONTH">MONTH</ToggleButton>
                    <ToggleButton value="YEAR">YEAR</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BasicInformation;
