import PlainFullLayout from "components/templates/generic/layouts/PlainFullLayout";

import Card from "@mui/material/Card";

import MDAvatar from "components/atoms/MDAvatar";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import ErrorAlert from "components/molecules/Notifications/ErrorAlert";
import SuccessAlert from "components/molecules/Notifications/SuccessAlert";

import logoImage from "assets/images/logo.png";
import bgImage from "assets/images/bg-signup.jpeg";

import { Formik, Form } from "formik";

import RedirectAuth from "components/molecules/RedirectAuth";

import { useVerifyEmail } from "pages/authentication/hooks/useVerifyEmail";

function VerifyEmail() {
  const { sendEmailVerification, error, success } = useVerifyEmail();
  const continueURL = window.location.href.replace(
    window.location.pathname,
    ""
  );

  return (
    <RedirectAuth>
      {error && <ErrorAlert message={error} />}
      {success && <SuccessAlert message={success} />}
      <PlainFullLayout image={bgImage}>
        <Card>
          <MDBox
            mt={-8}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MDAvatar
              src={logoImage}
              alt="Goodair"
              bgColor="light"
              size="xxl"
            />
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Verify Email
            </MDTypography>
            <MDTypography display="block" variant="body3" color="white" my={1}>
              Request to resend email verification.
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
              mx={2}
              p={2}
              mb={1}
              textAlign="center"
              border={1}
            >
              <MDTypography
                display="block"
                variant="body3"
                my={1}
                sx={{ whiteSpace: "pre-wrap" }}
              >
                We have sent an email with a confirmation link to your email
                address upon sign up. <br />
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                color="warning"
                fontWeight="medium"
                mt={2}
                mb={1}
              >
                Please click the confirmation link in the email to complete the
                sign up process.
              </MDTypography>
            </MDBox>
            <MDBox bgColor="white" my={2} textAlign="center">
              <MDTypography display="block" variant="caption" mx={2} my={2}>
                * Please check your junk or spam mail folder if you do not see
                the email in your inbox.
              </MDTypography>
            </MDBox>

            <Formik
              initialValues={{ resend: true }}
              onSubmit={async (values, { setSubmitting }) => {
                await sendEmailVerification(continueURL);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <MDBox mt={3} mb={1}>
                    <MDButton
                      disabled={isSubmitting}
                      type="submit"
                      variant="gradient"
                      color="success"
                      fullWidth
                    >
                      Resend Verification Email
                    </MDButton>
                  </MDBox>
                </Form>
              )}
            </Formik>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDBox>
                <MDTypography variant="caption" color="text" textGradient>
                  Didn&apos;t received verification email?{" "}
                </MDTypography>
              </MDBox>
              <MDBox mt={-1}>
                <MDTypography
                  variant="caption"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Please Consult with Your Administrator
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </PlainFullLayout>
    </RedirectAuth>
  );
}

export default VerifyEmail;
