import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  requestMessagingPermission,
  onMessageListener,
} from "services/messaging";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import MDBox from "components/atoms/MDBox";
import Alert from "@mui/material/Alert";
import MDTypography from "components/atoms/MDTypography";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const { user } = useAuthContext();
  const { updateDoc, retrieveDoc, serverTimestamp } = useDocument();

  const notify = () => toast(<ToastDisplay />);

  useEffect(() => {
    if (notification?.title) {
      notify();
    }

    requestMessagingPermission(async (token) => {
      const retrievedUser = user && (await retrieveDoc("users", user.uid));
      if (
        !retrievedUser.data.messagingTokens ||
        (retrievedUser.data.messagingTokens &&
          !retrievedUser.data.messagingTokens.includes(token))
      ) {
        const tokens = retrievedUser.data.messagingTokens?.concat([token]) ?? [
          token,
        ];
        user &&
          (await updateDoc("users", user.uid, {
            messagingTokens: tokens,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          }));
      }
    });
  }, [notification, retrieveDoc, serverTimestamp, updateDoc, user]);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  function ToastDisplay() {
    return (
      <Alert severity="info">
        <MDBox>
          <MDTypography variant="h6">{notification?.title}</MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography variant="body3">{notification?.body}</MDTypography>
        </MDBox>
      </Alert>
    );
  }

  return (
    <Toaster
      toastOptions={{
        className: "",
        style: {
          paddingTop: "16px",
          paddingBottom: "16px",
          background: "rgba(0, 0, 0, 0.3)",
        },
      }}
    />
  );
};

export default Notification;
