import { useCallback, useState, useEffect } from "react";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import DefaultLineChart from "components/charts/LineCharts/DefaultLineChart";
import { std, mean, gamma, sqrt } from "mathjs";

const Shewhart_N = 3;

function ShewhartChart() {
  const { user } = useAuthContext();
  const { retrieveDoc, updateDoc } = useDocument();
  const [graphLabels, setGraphLabels] = useState([]);
  const [graphDatasets, setGraphDatasets] = useState([]);

  const initializeFieldValue = useCallback(async () => {
    //const retrievedRecords = await retrieveDoc(user.uid, "records");
    const retrievedRecords = await retrieveDoc("records", user.uid);

    let cseRecords = [];
    if (retrievedRecords.data !== undefined) {
      cseRecords = retrievedRecords.data.cseRecords;
    }

    //const retrievedCusumSettings = await retrieveDoc("methods", "cusum");
    const chart_labels = [];
    const X = [];
    let stats = {
      xDoubleBar: 0,
      UCL: 0,
      LCL: 0,
    };
    if (cseRecords.length > 0) {
      X.push(0);
      chart_labels.push(0);
      cseRecords.forEach((cseRecord, index) => {
        chart_labels.push(index + 1);

        if (
          cseRecord["question1"] === "YES" &&
          cseRecord["question2"] === "YES" &&
          cseRecord["question3"] === "YES" &&
          cseRecord["question4"] === "YES"
        )
          X.push(0);
        else X.push(1);
      });

      const chunks = [];
      for (let i = 0; i < cseRecords.length; i += Shewhart_N) {
        chunks.push(X.slice(i, i + Shewhart_N));
      }

      const chunksStat = [];
      chunks.forEach((arr) => {
        chunksStat.push({
          x_bar: mean(arr),
          sigma_bar: std(arr),
        });
      });

      const num = sqrt(2) * gamma(Shewhart_N / 2);
      const den = sqrt(Shewhart_N - 1) * gamma(Shewhart_N / 2 - 0.5);
      const a_n = num / den;

      const sum_x_bar = chunksStat.reduce((acc, cur) => acc + cur.x_bar, 0);
      stats.xDoubleBar = sum_x_bar / chunksStat.length;
      const sum_sigma_bar = chunksStat.reduce(
        (acc, cur) => acc + cur.sigma_bar,
        0
      );
      const sigma_double_bar = sum_sigma_bar / chunksStat.length;

      stats.LCL =
        stats.xDoubleBar - (3 * sigma_double_bar) / (a_n * sqrt(Shewhart_N));
      stats.UCL =
        stats.xDoubleBar + (3 * sigma_double_bar) / (a_n * sqrt(Shewhart_N));
    } else {
      X.push(0);
      chart_labels.push(0);
    }
    //X.splice(0, 0, 0); // Add 0 to the beginning (index-0) of S_data (start, delete-elm, value)

    await updateDoc("records", user.uid, {
      ...retrievedRecords.data,
      cseShewhartStats: stats,
    });

    setGraphLabels(chart_labels);

    // Prepare datasets for line chart
    let datasets = [
      {
        label: "Shewhart Chart",
        color: "info",
        data: X,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ];

    datasets.push({
      label: "UCL",
      color: "warning",
      data: Array(chart_labels.length).fill(stats.UCL),
      borderDash: [10, 5],
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBorderWidth: 0,
    });

    datasets.push({
      label: "LCL",
      color: "warning",
      data: Array(chart_labels.length).fill(stats.LCL),
      borderDash: [10, 5],
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBorderWidth: 0,
    });

    datasets.push({
      label: "X_bar",
      color: "success",
      data: Array(chart_labels.length).fill(stats.xDoubleBar),
      borderDash: [10, 5],
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBorderWidth: 0,
    });

    setGraphDatasets(datasets);
  }, [retrieveDoc, user, updateDoc]);

  const defaultLineChartData1 = {
    labels: graphLabels,
    datasets: graphDatasets,
  };

  useEffect(() => {
    try {
      initializeFieldValue();
    } catch (err) {}
  }, [initializeFieldValue]);

  return (
    <Card id="plot-shewhart-chart" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox>
          <Grid item xs={12} md={12}>
            <DefaultLineChart
              icon={{ component: "insights" }}
              title="Shewhart chart"
              description=""
              chart={defaultLineChartData1}
            />
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

ShewhartChart.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default ShewhartChart;
