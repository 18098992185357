const form = {
  formId: "from",
  formField: {
    // anaesthesiaMode: {
    //   name: "anaesthesiaMode",
    //   label: "Mode of anaesthesia ",
    //   type: "text",
    //   errorMsg: "Mode of anaesthesia is required.",
    //   invalidMsg: "Mode of anaesthesia is invalid.",
    // },
    // airwayManagement: {
    //   name: "airwayManagement",
    //   label: "Airway management",
    //   type: "text",
    //   errorMsg: "Airway management is required.",
    //   invalidMsg: "Airway management is invalid.",
    // },
    // additionalProcedures: {
    //   name: "additionalProcedures",
    //   label: "Additional procedures",
    //   type: "text",
    //   errorMsg: "Additional procedure is required.",
    //   invalidMsg: "Additional procedure is invalid.",
    // },
    // disposition: {
    //   name: "disposition",
    //   label: "Disposition",
    //   type: "text",
    //   errorMsg: "Disposition is required.",
    //   invalidMsg: "Disposition is invalid.",
    // },
    // tags: {
    //   name: "tags",
    //   label: "Tags",
    //   type: "box",
    //   errorMsg: "Tags is required.",
    //   invalidMsg: "Tags is invalid.",
    //   max30Msg: "Must have at most 30 tags.",
    // },
    operationLevel1: {
      name: "operationLevel1",
      label: "Subspecialty",
      type: "text",
      errorMsg: "Subspecialty is required.",
      invalidMsg: "Subspecialty is invalid.",
    },
    operationLevel2: {
      name: "operationLevel2",
      label: "Operation/Procedure",
      type: "text",
      errorMsg: "Operation/Proceduree is required.",
      invalidMsg: "Operation/Proceduree is invalid.",
    },
  },
};

export { form as default };
