//import { de } from "date-fns/locale";
import form from "pages/csesurveys/manage/schemas/form";

import {
  defaultsubmissionDate,
  defaultVenue,
  defaultAnswerQ1,
  defaultAnswerQ2,
  defaultAnswerQ3,
  defaultAnswerQ4,
} from "pages/csesurveys/manage/schemas/setups";

const {
  formField: {
    medicalID,
    patientID,
    submissionDate,
    venue,
    question1,
    question2,
    question3,
    question4,
    remark,
  },
} = form;

const initialValues = {
  [medicalID.name]: "",
  [patientID.name]: "",
  [submissionDate.name]: defaultsubmissionDate,
  [venue.name]: defaultVenue,
  [question1.name]: defaultAnswerQ1,
  [question2.name]: defaultAnswerQ2,
  [question3.name]: defaultAnswerQ3,
  [question4.name]: defaultAnswerQ4,
  [remark.name]: "",
};

export { initialValues as default };
