import { useParams } from "react-router-dom";
import CohortStatistics from "pages/cohortstatistics/manage/CohortStatistics";

// using Proxy Page to remount when route to same route location but different param
function CohortStatisticsProxy() {
  const { mode, csesurveyId } = useParams();
  return <CohortStatistics key={`${mode}/${csesurveyId}`} />;
}

export default CohortStatisticsProxy;
