import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";

import Card from "@mui/material/Card";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav2 from "components/organisms/Topnav2";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

import Grid from "@mui/material/Grid";
import DataTable from "components/molecules/Tables/DataTable";

// CseSurvey page components
//import Questionnaire from "pages/csesurveys/manage/components/Questionnaire";
//import CusumChart from "pages/csesurveys/manage/components/CusumChart";
//import Sidenav from "pages/csesurveys/manage/components/Sidenav";

//import { Formik, Form } from "formik";
//import form from "pages/csesurveys/manage/schemas/form";

import { AllowedTo } from "react-abac";

import { useCseSurveyManager } from "pages/logbookstatistics/hooks/useCseSurveyManager";
import { useDocument } from "hooks/useDocument";
import BarChartIcon from "@mui/icons-material/BarChart";
import ListIcon from "@mui/icons-material/List";
import ComplexStatisticsCard from "components/molecules/Cards/StatisticsCards/ComplexStatisticsCard";

function LogbookStatistics() {
  const [isProgressing, setIsProgressing] = useState(true);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [numMinOneSubmission, setNumMinOneSubmission] = useState(0);
  const { mode } = useParams();
  const { retrieveDocs } = useDocument();
  const { modeTitle, modePermission, response } = useCseSurveyManager(mode);
  const navigate = useNavigate();
  const usersQueries = {
    whereQueries: [
      {
        field: "deletedAt",
        condition: "==",
        value: null,
      },
    ],
  };

  const columns = [
    { Header: "No", Footer: "No", accessor: "rowIdx", isVisible: false },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      isVisible: false,
    },
    {
      Header: "ID",
      Footer: "ID",
      accessor: "id",
      isVisible: false,
    },
    {
      Header: "MCR",
      Footer: "MCR",
      accessor: "mcr",
      isVisible: false,
    },
    {
      Header: "Designation",
      Footer: "Designation",
      accessor: "designation",
      isVisible: false,
    },
    {
      Header: "Number attempts",
      Footer: "Number attempts",
      accessor: "numAttempts",
      isVisible: false,
    },
    {
      Header: "Action",
      Footer: "Action",
      accessor: "action",
      Cell: ({ cell }) => {
        const showIcon = cell.row.values.numAttempts !== 0;
        return showIcon ? (
          <>
            <MDButton
              variant="gradient"
              color="success"
              iconOnly
              onClick={() => {
                navigate("/logbookstatistics/individual/" + cell.row.values.id);
              }}
            >
              <BarChartIcon />
            </MDButton>{" "}
            <MDButton
              variant="gradient"
              color="success"
              iconOnly
              onClick={() => {
                navigate(
                  "/cohortlogbookrecords/individual/" + cell.row.values.id
                );
              }}
            >
              <ListIcon />
            </MDButton>
          </>
        ) : (
          <>{}</>
        );
      },
    },
  ];
  const hiddenColumns = ["id"];
  const [rows, setRows] = useState([]);

  const { collectionData: usersData } = useCollectionSnapshot(
    "users",
    usersQueries
  );
  const initializeFieldValue = useCallback(async () => {
    if (usersData.length > 0) {
      const m_rows = [];

      await Promise.all(
        usersData.map(async (user, index) => {
          const logbooksQueries = {
            whereQueries: [
              {
                field: "deletedAt",
                condition: "==",
                value: null,
              },
              {
                field: "createdBy",
                condition: "==",
                value: user.id,
              },
            ],
          };
          const retrievedLogbooks = await retrieveDocs(
            "logbooks",
            logbooksQueries
          );

          m_rows.push({
            rowIdx: index + 1,
            name: user.data.displayName,
            id: user.id,
            mcr: user.data.medicalID,
            designation: user.data.designation,
            numAttempts: retrievedLogbooks.length,
          });
        })
      );

      const numSubmissionMinOne = m_rows.reduce(
        (acc, elm) => (elm.numAttempts > 0 ? acc + 1 : acc),
        0
      );
      console.log("numSubmissionMinOne: " + numSubmissionMinOne);

      setRows(m_rows);
      setTotalParticipants(m_rows.length);
      setNumMinOneSubmission(numSubmissionMinOne);
      setIsProgressing(false);
    }
  }, [usersData, retrieveDocs]);

  useEffect(() => {
    try {
      initializeFieldValue();
    } catch (err) {}
  }, [initializeFieldValue]);

  return (
    <DashboardLayout>
      <Topnav2 title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={3} pb={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mt={1.5} mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Participants"
                count={totalParticipants}
                percentage={{
                  color: "success",
                  amount: numMinOneSubmission,
                  label: "have made at least one submission",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox pt={3} pb={3}>
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        <Card>
          <MDBox m={3}>
            {response.error?.name !== "OperationInvalidError" && (
              <DataTable
                table={{
                  columns: columns,
                  rows: rows,
                }}
                hiddenColumns={hiddenColumns}
                canGlobalSearch
                canColumnSearch
                canColumnSort
              />
            )}
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LogbookStatistics;
