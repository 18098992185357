// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import MDBox from "components/atoms/MDBox";

import TableCell from "@mui/material/TableCell";

import { useMuiContext } from "context/MuiContext";

function DataTableHeadCell({
  width,
  children,
  sorted,
  align,
  sticky,
  colSpan,
  ...rest
}) {
  const { darkMode } = useMuiContext();
  return (
    <TableCell
      colSpan={colSpan}
      width={width}
      py={1.5}
      px={3}
      sx={({ palette: { light }, borders: { borderWidth } }) => {
        const stickyProps = sticky
          ? {
              position: "sticky",
              left: 0,
              "z-index": 101,
            }
          : {};
        return {
          borderBottom: `${borderWidth[1]} solid ${light.main}`,
          verticalAlign: "bottom",
          ...stickyProps,
        };
      }}
    >
      <MDBox
        {...rest}
        position="relative"
        textAlign={align}
        color={darkMode ? "white" : "secondary"}
        sx={({ typography: { size, fontWeightBold } }) => ({
          fontSize: size.xs,
          fontWeight: fontWeightBold,
          textTransform: "uppercase",
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
        })}
      >
        {sorted && (
          <MDBox
            position="absolute"
            top={0}
            right={align === "right" ? "0px" : "unset"}
            left={align === "left" ? "-18px" : "unset"}
            //right={align === "right" ? "16px" : 0}
            //left={align === "left" ? "-18px" : "unset"}
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            <MDBox
              position="absolute"
              top={0}
              color={sorted === "asce" ? "primary" : "secondary"}
              opacity={sorted === "asce" ? 1 : 0.5}
            >
              <ArrowDropUpIcon />
            </MDBox>
            <MDBox
              position="absolute"
              top={6}
              color={sorted === "desc" ? "primary" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <ArrowDropDownIcon />
            </MDBox>
          </MDBox>
        )}
        {children}
      </MDBox>
    </TableCell>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableHeadCell;
