import { Link } from "react-router-dom";

import PlainFullLayout from "components/templates/generic/layouts/PlainFullLayout";

import Card from "@mui/material/Card";

import MDAvatar from "components/atoms/MDAvatar";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
import FormField from "components/molecules/FormField";

import ErrorAlert from "components/molecules/Notifications/ErrorAlert";
import SuccessAlert from "components/molecules/Notifications/SuccessAlert";

import logoImage from "assets/images/logo.png";
import bgImage from "assets/images/bg-signup.jpeg";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import RedirectAuth from "components/molecules/RedirectAuth";

import { useSignup } from "pages/authentication/hooks/useSignup";

function SignUp() {
  const { signup, error, success } = useSignup();
  const continueURL = window.location.href.replace(
    window.location.pathname,
    ""
  );

  return (
    <RedirectAuth>
      {console.log("RedirectAuth: " + JSON.stringify(RedirectAuth))}
      {console.log("error: " + JSON.stringify(error))}
      {console.log("success: " + JSON.stringify(success))}
      {error && <ErrorAlert message={error} />}
      {success && <SuccessAlert message={success} />}
      <PlainFullLayout image={bgImage}>
        <Card>
          <MDBox
            mt={-8}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MDAvatar
              src={logoImage}
              alt="Goodair"
              bgColor="light"
              size="xxl"
            />
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign up
            </MDTypography>
            <MDTypography display="block" variant="body3" color="white" my={1}>
              Enter your name, email and password to sign up.
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <Formik
              initialValues={{
                displayName: "",
                email: "",
                password: "",
                agreed: false,
              }}
              validationSchema={Yup.object({
                displayName: Yup.string()
                  .required("Required")
                  .max(100, "Must be at most 100 characters"),
                email: Yup.string()
                  .required("Required")
                  .email("Invalid email address"),
                password: Yup.string()
                  .required("Required")
                  .matches(
                    "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()-\\[{}\\]:;',?/*~$^+=<>]).{8,100}$",
                    "Must have:\n At least 8 characters\n At least one digit\n At least one lowercase character\n At least one uppercase character\n At least one special character"
                  ),
                agreed: Yup.boolean().oneOf(
                  [true],
                  "Please agree to the Terms and Conditions"
                ),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                await signup(
                  values.email,
                  values.password,
                  values.displayName,
                  null,
                  continueURL
                );
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form>
                  <FormField
                    type="text"
                    label="Name"
                    name="displayName"
                    error={errors.displayName && touched.displayName}
                    success={
                      values.displayName.length > 0 && !errors.displayName
                    }
                  />
                  <FormField
                    type="email"
                    label="Email"
                    name="email"
                    error={errors.email && touched.email}
                    success={values.email.length > 0 && !errors.email}
                  />
                  <FormField
                    type="password"
                    label="Password"
                    name="password"
                    error={errors.password && touched.password}
                    success={values.password.length > 0 && !errors.password}
                  />
                  <FormField type="checkbox" label="" name="agreed">
                    <MDTypography
                      variant="body3"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;I agree the&nbsp;
                    </MDTypography>
                    <MDTypography
                      component="a"
                      href="#"
                      variant="body3"
                      fontWeight="bold"
                      color="info"
                      textGradient
                    >
                      Terms and Conditions
                    </MDTypography>
                  </FormField>
                  <MDBox mt={3} mb={1}>
                    <MDButton
                      disabled={isSubmitting}
                      type="submit"
                      variant="gradient"
                      color="success"
                      fullWidth
                    >
                      sign up
                    </MDButton>
                  </MDBox>
                </Form>
              )}
            </Formik>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="body3" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/signin"
                  variant="body3"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </Card>
      </PlainFullLayout>
    </RedirectAuth>
  );
}

export default SignUp;
